import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomInputProperties } from '../../types/component-properties/custom/custom-input-properties'
import { CustomFormProperties } from '../../types/component-properties/custom/custom-form-properties'
import classNames from 'classnames'

const CustomInput = (props: CustomInputProperties & CustomFormProperties): ReactElement => {
    return (
        <React.Fragment>
            <div css={[props.cssStyles]} className={props.className}>
                <div className="mb-1 font-bold text-darkgray text-xs" hidden={props.hidden || props.label === ''}>
                    {props.label} {props.required && props.label !== '' && <span className="text-red">*</span>}
                </div>
                <input
                    className={classNames({
                        'w-full h-10 py-1 px-2 text-sm rounded shadow-none border border-solid border-gray-100 outline-none placeholder-darkgray focus:border-primary': true,
                        'text-darkgray': !props.readonly,
                        'pointer-events-none text-gray-100': props.readonly
                    })}
                    defaultValue={props.value === undefined ? props.defaultValue as string | number : undefined}
                    disabled={props.disabled}
                    hidden={props.hidden}
                    ref={props.formRef}
                    autoComplete={props.autoComplete}
                    name={props.name}
                    type={props.type}
                    min={props.min}
                    max={props.max}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange} />
                <div className="h-6 font-bold text-red text-xs" hidden={props.name === '' || props.hideErrors}>{props.errorMessage}</div>
            </div>
        </React.Fragment>
    )
}

CustomInput.defaultProps = {
    className: '',
    cssStyles: [css``],
    defaultValue: '',
    selectDefaultValue: undefined,
    name: '',
    label: '',
    hidden: false,
    errorMessage: undefined,
    required: false,
    type: 'text',
    placeholder: '',
    readonly: false,
    disabled: false,
    hideErrors: false,
    autoComplete: '',
    value: undefined,
    formRef: (): void => undefined,
    selectRef: (): void => undefined,
    datePickerRef: (): void => undefined,
    onChange: (): void => undefined
}

export default CustomInput
