import { EquipmentsState } from '../types/states/equipments-state'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Equipment } from '../types/entity/equipment'
import { AppThunk } from '../types/app-thunk'
import { call } from '../common/api'
import { RequestQueryParams } from '../types/request-query-params'
import { toast } from 'react-toastify'
import { setPaths, setPage, setSidemenuData } from './ui'
import { push } from 'connected-react-router'
import { EditEquipment } from '../types/edit-equipment'
import { SimpleItem } from '../types/simple-item'
import { OptionsType, OptionTypeBase } from 'react-select'
import { IntegrationProperty } from '../types/integration-property'
import { EditIntegrationProperty } from '../types/edit-integration-property'
import { DateTime } from 'luxon'

const equipmentsInitialState: EquipmentsState = {
    equipments: [],
    equipmentsSize: 0,
    currentEquipment: null,
    analysersOptions: [],
    imageOptions: [],
    integrationProperties: null
}

const equipmentsSlice = createSlice({
    name: 'equipments',
    initialState: equipmentsInitialState,
    reducers: {
        setEquipments: (state: EquipmentsState, { payload }: PayloadAction<{ equipments: Equipment[]; size: number }>): void => {
            state.equipments = payload.equipments
            state.equipmentsSize = payload.size
        },
        setCurrentEquipment: (state: EquipmentsState, { payload }: PayloadAction<Equipment | null>): void => {
            state.currentEquipment = payload
        },
        archiveEquipment: (state: EquipmentsState, { payload }: PayloadAction<number>): void => {
            state.equipments[payload].archived = !state.equipments[payload].archived
        },
        setAnalysersOptions: (state: EquipmentsState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.analysersOptions = payload
        },
        setImageOptions: (state: EquipmentsState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.imageOptions = payload
        },
        setIntegrationProperties: (state: EquipmentsState, { payload }: PayloadAction<IntegrationProperty | null>): void => {
            state.integrationProperties = payload
        }
    }
})

export const { setEquipments, setCurrentEquipment, archiveEquipment, setAnalysersOptions, setImageOptions } = equipmentsSlice.actions

export default equipmentsSlice.reducer

export const fetchEquipments = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEquipmentsRequest = call({
            method: 'GET',
            url: '/equipments',
            params
        }, dispatch, true)
        const res = await getEquipmentsRequest()

        if (res.status >= 200 && res.status < 300) {
            const equipments = Array.from(res.data.equipments as any[])

            equipments.forEach((equipment) => {
                equipment.testObject = equipment.analysers.map((analyser: SimpleItem) => analyser.name)
            })

            dispatch(equipmentsSlice.actions.setEquipments({ equipments, size: res.data.size }))
            dispatch(setSidemenuData(res.data.mappedElements))

            if (res.data.equipments.length === 0 && res.data.total > 0) {
                dispatch(setPage(0))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateEquipment = (data: FormData, params: RequestQueryParams, message: string, administrator: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createEquipmentRequest = call({
            method: 'POST',
            url: '/equipments',
            data
        }, dispatch, true)
        const res = await createEquipmentRequest()

        if (res.status >= 200 && res.status < 300) {
            if (!administrator) {
                dispatch(fetchEquipments(params))
            }
            const paths = [
                {
                    label: res.data.customerName,
                    id: res.data.customerId,
                    destination: {
                        pathname: `/equipments`,
                        search: `?id=${res.data.id}`
                    },
                    param: {
                        property: `customerId` as keyof RequestQueryParams,
                        value: res.data.customerId
                    }
                },
                {
                    label: res.data.medicalCentreName,
                    id: res.data.medicalCentreId,
                    destination: {
                        pathname: `/equipments`,
                        search: `?id=${res.data.id}`
                    },
                    param: {
                        property: `medicalCentreId` as keyof RequestQueryParams,
                        value: res.data.medicalCentreId
                    }
                },
                {
                    label: res.data.name,
                    id: res.data.id,
                    destination: {
                        pathname: `/equipments/equipment`,
                        search: `?id=${res.data.id}`
                    },
                    param: null
                }
            ]
            dispatch(equipmentsSlice.actions.setCurrentEquipment(res.data))
            dispatch(setPaths(administrator ? paths : [paths[1], paths[2]]))
            dispatch(push({
                pathname: `/equipments/equipment`,
                search: `?id=${res.data.id}`
            }))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
} 

export const fetchEditEquipment = (equipmentId: string, data: EditEquipment, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editEquipmentRequest = call({
            method: 'PUT',
            url: `/equipments/${equipmentId}`,
            data
        }, dispatch, true)
        const res = await editEquipmentRequest()
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipments(params))
            dispatch(equipmentsSlice.actions.setCurrentEquipment(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
} 

// export const fetchEquipmentOptions = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
//     try {
//         const getEquipmentOptionsRequest = call({
//             method: 'GET',
//             url: '/equipments',
//             params
//         }, dispatch, true)
//         const res = await getEquipmentOptionsRequest()

//         dispatch(equipmentsSlice.actions.setEquipmentOptions(res.data.equipments))
//     } catch (err) {
//         console.log(err)
//         toast.error('Error trying to fetch data')
//     }
// }

export const fetchEquipment = (equipmentId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEquipmentRequest = call({
            method: 'GET',
            url: `/equipments/${equipmentId}`
        }, dispatch, true)
        const res = await getEquipmentRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentsSlice.actions.setCurrentEquipment(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchArchiveEquipment = (equipmentId: string, index: number, value: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const archiveEquipmentRequest = call({
            method: 'PUT',
            url: `/equipments/${equipmentId}`,
            data: {
                archived: value
            }
        }, dispatch, false)
        const res = await archiveEquipmentRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentsSlice.actions.archiveEquipment(index))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteEquipment = (equipmentId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteEquipmentRequest = call({
            method: 'DELETE',
            url: `/equipments/${equipmentId}`
        }, dispatch, true)
        const res = await deleteEquipmentRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipments(params))
            dispatch(setPaths([]))
            dispatch(push('/equipments'))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchAnalysersOptions = (equipmentId: string, type: 'IMAGE' | 'DOCUMENT' = 'IMAGE'): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getAnalysersOptionsRequest = call({
            method: 'GET',
            url: `/equipments/${equipmentId}/analysers`,
            params: {
                type
            }
        }, dispatch, false)
        const res = await getAnalysersOptionsRequest()

        if (res.status >= 200 && res.status < 300) {
            const analysersOptions: OptionsType<OptionTypeBase> = res.data.map((analyser: SimpleItem) => {
                return {
                    label: analyser.name,
                    value: analyser.id
                }
            })

            dispatch(equipmentsSlice.actions.setAnalysersOptions(analysersOptions))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchIntegrationProperties = (equipmentId: string, locale: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getIntegrationPropertiesRequest = call({
            method: 'GET',
            url: `/equipments/${equipmentId}/integration-properties`
        }, dispatch, false)
        const res = await getIntegrationPropertiesRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentsSlice.actions.setIntegrationProperties(res.data))
            dispatch(equipmentsSlice.actions.setImageOptions(res.data?.imagesDates?.map((timestamp: number) => {
                return {
                    label: DateTime.fromMillis(timestamp).toLocaleString({ ...DateTime.DATETIME_SHORT, locale, numberingSystem: undefined }),
                    value: timestamp.toString()
                }
            })))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchConfigureIntegrationProperties = (equipmentId: string, imageDate: string, locale: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const configureIntegrationProperitesRequest = call({
            method: 'PUT',
            url: `/equipments/${equipmentId}/integration-properties`,
            params: {
                imageDate
            }
        }, dispatch, false)
        const res = await configureIntegrationProperitesRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchIntegrationProperties(equipmentId, locale))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditIntegrationProperty = (equipmentId: string, propertyId: string, data: EditIntegrationProperty, locale: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editIntegrationPropertyRequest = call({
            method: 'PUT',
            url: `/equipments/${equipmentId}/integration-properties/${propertyId}`,
            data
        }, dispatch, false)
        const res = await editIntegrationPropertyRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchIntegrationProperties(equipmentId, locale))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

// export const fetchAttributeOptions = (equipmentId: string): AppThunk => async (dispatch): Promise<void> => {
//     try {
//         // const getAttributeOptionsRequest = call({
//         //     method: 'GET',
//         //     url: `/equipments/${equipmentId}/analysers`
//         // }, dispatch, false)
//         // const res = await getAttributeOptionsRequest()

//         // if (res.status >= 200 && res.status < 300) {
//         //     const imageOptions: OptionsType<OptionTypeBase> = res.data.map((analyser: SimpleItem) => {
//         //         return {
//         //             label: analyser.name,
//         //             value: analyser.id
//         //         }
//         //     })

//         //     dispatch(equipmentsSlice.actions.setImageOptions(imageOptions))
//         // }

//         // dispatch(equipmentsSlice.actions.setImageOptions([{
//         //     label: 'Patient ID',
//         //     value: 'patientId'
//         // }, {
//         //     label: 'SOP Instance UID',
//         //     value: 'sopInstanceUid'
//         // }, {
//         //     label: 'Detector ID',
//         //     value: 'detectorId'
//         // }]))
//     } catch (err) {
//         console.log(err)
//     }
// }