import React, { ChangeEvent, OptionHTMLAttributes, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomTable from '../custom/custom-table/custom-table'
import ActionsCell from '../custom/custom-table/actions-cell'
import { CellProps } from 'react-table'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/column-delete.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { CustomerEquipmentProperty } from '../../types/entity/customer-equipment-property'
import { ActionType } from '../../types/action-type'
import { TableAction } from '../../types/table-action'
import { useDispatch, useSelector } from 'react-redux'
import { EditableTableRow } from '../../types/editable-table-row'
import { CustomersEquipmentPropertiesProperties } from '../../types/component-properties/customers-equipment-properties-properties'
import CustomInput from '../custom/custom-input'
import CustomButton from '../custom/custom-button'
import { intl } from '../../intl'
import { fetchCreateCustomerEquipmentProperty, fetchDeleteCustomerEquipmentProperty, fetchEditCustomerEquipmentProperty } from '../../reducers/customers'
import { State } from '../../types/states/state'
import CustomSelect from '../custom/custom-select/custom-select'
import { OptionsType, OptionTypeBase } from 'react-select'

const classes = {
    'table': css`
        tbody {
            border: 1px solid var(--color-gray-100);
        }

        th, td {
            padding: 0.25rem 0.75rem;
        }

        tbody > tr:nth-of-type(odd) {
            background: var(--color-white);
        }

        tbody > tr:nth-of-type(even) {
            background: var(--color-background-second);
        }
    `
}

const CustomersEquipmentProperties = (props: CustomersEquipmentPropertiesProperties): ReactElement => {
    const dispatch = useDispatch()

    const [editedRowTemplate, setEditedRowTemplate] = useState<ReactElement>(<tr></tr>)
    const [newPropertyName, setNewPropertyName] = useState("")
    const [propertyName, setPropertyName] = useState<string | null>(null)
    const [selectedPredefinedEquipmentProperty, setSelectedPredefinedEquipmentProperty] = useState("")
    const [selectedProperty, setSelectedProperty] = useState<EditableTableRow>({
        name: null,
        id: null
    })
    const [equipmentProperties, setEquipmentProperties] = useState<OptionsType<OptionTypeBase>>([])

    const customer = useSelector((state: State) => state.customers.currentCustomer)
    const equipmentPropertiesPredefined = useSelector((state: State) => state.customers.equipmentPropertiesPredefined)

    const handleResetSelectedProperty = useCallback((property: CustomerEquipmentProperty | undefined, editRow: string | undefined): void => {
        if (property) {
            setPropertyName(property.name)
            setSelectedProperty({
                name: property.name,
                id: editRow
            })
        } else {
            setPropertyName(null)
            setSelectedProperty({
                name: null,
                id: editRow
            })
        }
    }, [])

    const [tableActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])

    const handleAction = useCallback((action: TableAction): void => {
        switch (action.type) {
            case 'Save':
                if (customer) {
                    Promise.all([dispatch(fetchEditCustomerEquipmentProperty(customer.id, (action.row as CustomerEquipmentProperty).id, intl.formatMessage({ id: 'organisations.toast.success.equipmentPropertyUpdated' }), { name: propertyName ?? "" }))]).then(() => {
                        handleResetSelectedProperty(undefined, undefined)
                    })
                }

                break
            case 'Edit':
                setTimeout(() => {
                    handleResetSelectedProperty(action.row as CustomerEquipmentProperty, (action.row as CustomerEquipmentProperty).id)
                }, 1)

                break
            case 'Delete':
                if (customer) {
                    Promise.all([dispatch(fetchDeleteCustomerEquipmentProperty(customer.id, (action.row as CustomerEquipmentProperty).id, intl.formatMessage({ id: 'organisations.toast.success.equipmentPropertyDeleted' })))]).then(() => {
                        handleResetSelectedProperty(undefined, undefined)
                    })
                }

                break
            case 'Cancel':
                handleResetSelectedProperty(undefined, undefined)

                break
        }
    }, [dispatch, handleResetSelectedProperty, propertyName, customer])

    const columns = useMemo(() => [
        { id: 'name', Header: 'organisations.equipmentProperties.table.header.name', canGroupBy: false, disableSortBy: true, accessor: 'name', width: "" },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 80, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<CustomerEquipmentProperty>>): ReactElement {
            return <ActionsCell actions={!rowProps.row.original.nameId ? tableActions : [tableActions[tableActions.length - 1]]} row={rowProps.row.original} handleAction={handleAction} editingRow={(selectedProperty.id ?? '-1') as string}></ActionsCell>
        } }
    ], [tableActions, handleAction, selectedProperty.id])

    function inputChanged(event: ChangeEvent<HTMLInputElement>): void {
        setNewPropertyName(event.target.value)
    }

    function createProperty(): void {
        if (customer) {
            Promise.all([dispatch(fetchCreateCustomerEquipmentProperty(customer.id, intl.formatMessage({ id: 'organisations.toast.success.equipmentPropertyCreated' }), { name: selectedPredefinedEquipmentProperty === "userDefined" ? newPropertyName : null, nameId: selectedPredefinedEquipmentProperty !== "userDefined" ? selectedPredefinedEquipmentProperty : null }))]).then(() => {
                handleResetSelectedProperty(undefined, undefined)
                setSelectedPredefinedEquipmentProperty("")
            })
        }
    }

    function handleChangeSelectedRequirement(): void {
        setEditedRowTemplate(
            <React.Fragment>
                <td>
                    {
                        propertyName &&
                        <CustomInput
                            className="w-40 mr-2 select-none"
                            defaultValue={propertyName as string}
                            onChange={(event): void => setPropertyName(event.target.value)} />
                    }
                </td>
                <td>
                    <ActionsCell
                        actions={[
                            { type: 'Save', Icon: SaveIcon },
                            { type: 'Edit', Icon: EditIcon },
                            { type: 'Cancel', Icon: CancelIcon }
                        ]}
                        row={selectedProperty as CustomerEquipmentProperty}
                        canSave={true}
                        handleAction={handleAction}
                        editingRow={selectedProperty.id as string}></ActionsCell>
                </td>
            </React.Fragment>
        )
    }

    useEffect(handleChangeSelectedRequirement, [selectedProperty, propertyName])

    useEffect(() => {
        setEquipmentProperties([{ label: intl.formatMessage({ id: 'select.options.userDefined' }), value: 'userDefined' }, ...equipmentPropertiesPredefined])
    }, [equipmentPropertiesPredefined])

    return (
        <div className="bg-background-first">
            <div className="flex items-center mt-6 mb-2">
                <CustomSelect
                    className="w-60"
                    options={equipmentProperties}
                    value={selectedPredefinedEquipmentProperty}
                    onChange={(event): void => setSelectedPredefinedEquipmentProperty((event as OptionHTMLAttributes<HTMLOptionElement>).value as string)} />
                {
                    selectedPredefinedEquipmentProperty === "userDefined" &&
                    <CustomInput 
                        className="w-60 ml-4 h-10"
                        value={newPropertyName}
                        onChange={inputChanged} />
                }
                <CustomButton
                    className="px-4 ml-4 bg-primary text-white"
                    label={intl.formatMessage({ id: "organisations.equipmentProperties.createButton.label" })}
                    disabled={selectedPredefinedEquipmentProperty !== "userDefined" ? selectedPredefinedEquipmentProperty === "" : newPropertyName === ""}
                    onClick={createProperty} />
            </div>
            <CustomTable
                cssStyles={[classes.table]}
                data={props.equipmentProperties}
                columns={columns}
                noDataMessage={intl.formatMessage({ id: 'organisations.equipmentProperties.table.noDataText' })}
                showPagination={false}
                stripedRows={true}
                editedRow={selectedProperty}
                editedRowTemplate={editedRowTemplate} />
        </div>
    )
}

export default CustomersEquipmentProperties