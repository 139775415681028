import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomConfirmDeleteProps } from '../../types/custom-confirm-delete'
import CustomButton from './custom-button'
import classNames from 'classnames'

const classes = {
    'modal-wrapper': css`
        background-color: rgba(0, 0, 0, 0.7);
        transition: opacity .3s ease, visibility 0s step-end;
        transition-delay: 0s, .3s;
        &.isVisible {
            transition: opacity .3s ease, visibility 0s step-start;
        }
    `,
    'modal': css`
        transform: translate(-50%, -50%);
    `
}

const CustomConfirmDelete = (props: CustomConfirmDeleteProps): ReactElement => {
    return (
        <React.Fragment>
            <div css={[classes["modal-wrapper"]]} className={classNames({
                'fixed top-0 left-0 w-full h-full z-20': true,
                'invisible opacity-0': !props.visible,
                'visible opacity-100 isVisible': props.visible
            })}>
                <div css={[classes.modal]} className='items flex-col items-center justify-center relative top-1/2 left-1/2 bg-white rounded-lg p-10 min-w-100 w-176 min-h-52'>
                    <h4 className="mb-6 mt-0 text-center text-2xl">{props.header}</h4>
                    <p className="mb-6 mt-0 text-center">{props.message}</p>
                    <div className='flex items-stretch justify-center'>
                        <CustomButton className="bg-red text-white min-w-40 mr-5" label={props.yesText} onClick={props.handleDelete}></CustomButton>
                        <CustomButton className="bg-primary text-white min-w-40" label={props.cancelText} onClick={props.handleCancel}></CustomButton>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

CustomConfirmDelete.defaultProps = {
    header: 'Delete',
    message: 'Do you really want to delete this item? This action can\'t be undone',
    cssStyles: [css``],
    yesText: 'Yes, delete',
    cancelText: 'Cancel',
    visible: false,
    handleDelete: (): void => undefined,
    handleCancel: (): void => undefined
}

export default CustomConfirmDelete