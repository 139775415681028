import React, { useEffect, ReactElement, useState } from 'react'
import MedicalCentresList from './medical-centres-list'
import { SectionProperties } from '../../types/component-properties/section-properties'
import { MedicalCentre } from '../../types/entity/medical-centre'
import { useDispatch, connect } from 'react-redux'
import { Switch, Route } from 'react-router'
import MedicalCentresEditor from './medical-centres-editor'
import { toggleSidemenu, updatePathLabel, setPaths, setCurrentSection, setSidemenuData } from '../../reducers/ui'
import { fetchCreateMedicalCentre, fetchEditMedicalCentre, fetchDeleteMedicalCentre, setMedicalCentres, fetchMedicalCentres } from '../../reducers/medical-centres'
import { State } from '../../types/states/state'
import { RequestQueryParams } from '../../types/request-query-params'
import { TableAction } from '../../types/table-action'
import { push } from 'connected-react-router'
import MedicalCentresCreator from './medical-centres-creator'
import { NewMedicalCentre } from '../../types/new-medical-centre'
import { EditMedicalCentre } from '../../types/edit-medical-centre'
import { LocationDescriptorObject } from 'history'
import { intl } from '../../intl'
import CustomConfirmDelete from '../custom/custom-confim-delete'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { SectionName } from '../../types/section-name'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const MedicalCentres = (props: SectionProperties): ReactElement => {
    
    const dispatch = useDispatch()

    const [newMedicalCentre] = useState<NewMedicalCentre>({ name: '', customerId: '', email: '', phone: '' })
    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})

    function handleCreate(medicalCentre: NewMedicalCentre): void {
        dispatch(fetchCreateMedicalCentre(medicalCentre, props.queryParams, intl.formatMessage({ id: 'medicalCentres.toast.success.medicalCentreCreated' }), props.loggedUser?.role === 'ADMINISTRATOR'))
    }

    function handleEdit(id: string, medicalCentre: EditMedicalCentre): void {
        Promise.all([dispatch(fetchEditMedicalCentre(id, medicalCentre, intl.formatMessage({ id: 'medicalCentres.toast.success.medicalCentreUpdated' })))]).then(() => {
            dispatch(updatePathLabel({ index: props.paths.length - 1, label: medicalCentre.name }))
        })
    }

    function handleDelete(id: string, queryParams: RequestQueryParams): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteMedicalCentre(id, queryParams, intl.formatMessage({ id: 'medicalCentres.toast.success.medicalCentreDeleted' })))
            }
        })
    }

    function handleAction(action: TableAction): void {
        switch (action.type) {
            case 'Edit':
                const editPaths = [
                    {
                        label: (action.row as MedicalCentre).customerName,
                        id: (action.row as MedicalCentre).customerId,
                        destination: { pathname: `/medical-centres` },
                        param: { property: `customerId` as keyof RequestQueryParams, value: (action.row as MedicalCentre).customerId }
                    },
                    {
                        label: (action.row as MedicalCentre).name,
                        id: (action.row as MedicalCentre).id,
                        destination: { pathname: `/medical-centres/medical-centre`,
                        search: `?id=${(action.row as MedicalCentre).id}` },
                        param: null
                    }
                ]
                dispatch(setPaths(props.loggedUser?.role === 'ADMINISTRATOR' ? editPaths : [editPaths[1]]))
                dispatch(push({
                    pathname: `/medical-centres/medical-centre`,
                    search: `?id=${(action.row as MedicalCentre).id}`
                }))

                break
            case 'Equipments':
                // dispatch(setPaths([
                //     // TODO -> indice 0 ci va customerId
                //     { label: medicalCentre.customer, id: medicalCentre.id, destination: '/dashboard', param: { property: 'customerId', value: medicalCentre.customer } },
                //     { label: medicalCentre.name, id: medicalCentre.id, destination: '/dashboard', param: { property: 'medicalCentreId', value: medicalCentre.name } }
                // ]))
                dispatch(setCurrentSection('equipments'))
                dispatch(push(`/equipments`))
                const equipmentsPaths = [
                    {
                        label: (action.row as MedicalCentre).customerName,
                        id: (action.row as MedicalCentre).customerId,
                        destination: {
                            pathname: `/equipments`,
                            search: `?id=${(action.row as MedicalCentre).id}`
                        },
                        param: {
                            property: `customerId` as keyof RequestQueryParams,
                            value: (action.row as MedicalCentre).customerId
                        }
                    },
                    {
                        label: (action.row as MedicalCentre).name,
                        id: (action.row as MedicalCentre).id,
                        destination: {
                            pathname: `/equipments`,
                            search: `?id=${(action.row as MedicalCentre).id}`
                        },
                        param: {
                            property: `medicalCentreId` as keyof RequestQueryParams,
                            value: (action.row as MedicalCentre).id
                        }
                    }
                ]
                dispatch(setPaths(props.loggedUser?.role === 'ADMINISTRATOR' ? equipmentsPaths : [equipmentsPaths[1]]))
                

                break
            case 'Delete':
                // dispatch(deleteMedicalCentre(medicalCentre.id))
                // handleDelete((action.row as MedicalCentre).id, action.queryParams)
                handleDelete((action.row as MedicalCentre).id, action.queryParams)

                break
        }
    }

    useFetching(fetchMedicalCentres, 'medical-centres', [], props.queryParams)

    useEffect(() => {
        dispatch(toggleSidemenu(true))
        return (): void => {
            dispatch(setSidemenuData([]))
            dispatch(setMedicalCentres({ medicalCentres: [], size: 0 }))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/medical-centres'>
                    <MedicalCentresList
                        handleAction={handleAction}
                        handleLimitChange={props.handleLimitChange}
                        handlePageChange={props.handlePageChange}
                        handleSort={props.handleSort}></MedicalCentresList>
                </Route>
                <Route exact path='/medical-centres/new'>
                    <MedicalCentresCreator
                        medicalCentre={newMedicalCentre}
                        handleNavigate={props.handleNavigate}
                        handleCreate={handleCreate}></MedicalCentresCreator>
                </Route>
                <Route exact path='/medical-centres/medical-centre'>
                    <MedicalCentresEditor
                        handleEdit={handleEdit}
                        handleNavigate={props.handleNavigate}
                        handleDelete={handleDelete}></MedicalCentresEditor>
                </Route>
            </Switch>
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { queryParams: RequestQueryParams; location: LocationDescriptorObject; paths: BreadcrumbPath[]; currentSection: SectionName; loggedUser: LoggedUser | null } => ({
    queryParams: state.ui.sectionQueryParams,
    location: state.router.location,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(MedicalCentres)
