import { DashboardState } from '../types/states/dashboard-state'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardEquipment } from '../types/entity/dashboard-equipment'
import { AppThunk } from '../types/app-thunk'
import { call } from '../common/api'
import { RequestQueryParams } from '../types/request-query-params'
import { EquipmentTestObject } from '../types/equipment-test-object'
import { setPage } from './ui'
import { Equipment } from '../types/entity/equipment'
import { setSidemenuData } from '../reducers/ui'

const dashboardInitialState: DashboardState = {
    dashboardEquipments: [],
    dashboardEquipmentsSize: 0,
    currentEquipment: null
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardInitialState,
    reducers: {
        setDashboardEquipments: (state, { payload }: PayloadAction<{ equipments: DashboardEquipment[]; size: number }>): void => {
            state.dashboardEquipments = payload.equipments
            state.dashboardEquipmentsSize = payload.size
        },
        setCurrentDashboardEquipment: (state, { payload }: PayloadAction<Equipment | null>): void => {
            state.currentEquipment = payload
        }
    }
})

export const { setDashboardEquipments, setCurrentDashboardEquipment } = dashboardSlice.actions

export default dashboardSlice.reducer

export const fetchDashboardEquipments = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getDashboardEquipmentsRequest = call({
            method: 'GET',
            url: '/equipmentSummaries',
            params
        }, dispatch, true)
        const res = await getDashboardEquipmentsRequest()

        if (res.status >= 200 && res.status < 300) {
            const equipments = Array.from(res.data.equipments as any[])

            equipments.forEach((equipment) => {
                equipment.name = equipment.testObjects.map((testObject: EquipmentTestObject) => testObject.name)
                equipment.lastAnalysis = equipment.testObjects.map((testObject: EquipmentTestObject) => testObject.lastAnalysis)
                equipment.stabilityInfo = equipment.testObjects.map((testObject: EquipmentTestObject) => testObject.stabilityInfo)

                delete equipment.testObjects
            })

            dispatch(dashboardSlice.actions.setDashboardEquipments({ equipments, size: res.data.size }))
            dispatch(setSidemenuData(res.data.mappedElements))

            if (equipments.length === 0 && res.data.size > 0) {
                dispatch(setPage(0))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDashboardEquipment = (equuipmentId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getDashboardEquipmentRequest = call({
            method: 'GET',
            url: `/equipments/${equuipmentId}`
        }, dispatch, true)
        const res = await getDashboardEquipmentRequest()

        dispatch(dashboardSlice.actions.setCurrentDashboardEquipment(res.data))
    } catch (err) {
        console.log(err)
    }
}
