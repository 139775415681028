import React, { ReactElement, useEffect, useState, SyntheticEvent } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import Switch from "react-switch"
import { CustomSwitchProperties } from '../../types/component-properties/custom/custom-switch-properties'
import classNames from 'classnames'

const classes = {
    'switch': css`
        .react-switch-bg {
            height: 1.25rem !important;
            width: 2.5rem !important;

            div {
                display: none;
            }
        }

        .react-switch-handle {
            height: 1rem !important;
            width: 1rem !important;
            top: 0.125rem !important;
            outline: none !important;
            box-shadow: none !important;
        }
    `,
    'on': css`
        .react-switch-bg {
            background: var(--color-primary) !important;
        }

        .react-switch-handle {
            transform: translateX(1.438rem) !important;
        }
    `,
    'off': css`
        .react-switch-bg {
            background: var(--color-gray-100) !important;
        }
    `,
    'disabled': css`
        .react-switch-bg {
            background: var(--color-gray-100) !important;
        }
    `
}

const CustomSwitch = (props: CustomSwitchProperties): ReactElement => {
    const [checked, setChecked] = useState<boolean>(props.checked)

    function check(newChecked: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>): void {
        event.stopPropagation()
        setChecked(newChecked)
        props.onChange(newChecked)
    }

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    return (
        <React.Fragment>
            <div className="flex items-center">
                <span className={classNames({
                    'mr-2 select-none whitespace-no-wrap': true,
                    'inline-block': props.labelPosition === 'left',
                    'hidden': props.labelPosition !== 'left',
                    'text-gray-100': props.disabled
                })}>{props.label}</span>
                <Switch
                    className={props.className}
                    css={[classes.switch, checked ? classes.on : classes.off, props.cssStyles, props.disabled ? classes.disabled : undefined]}
                    checked={checked}
                    disabled={props.disabled}
                    onChange={check}></Switch>
                <span className={classNames({
                    'ml-2 select-none whitespace-no-wrap': true,
                    'inline-block': props.labelPosition === 'right',
                    'hidden': props.labelPosition !== 'right',
                    'text-gray-100': props.disabled
                })}>{props.label}</span>
            </div>
        </React.Fragment>
    )
}

CustomSwitch.defaultProps = {
    className: '',
    cssStyles: [css``],
    checked: false,
    disabled: false,
    label: '',
    labelPosition: 'right',
    onChange: (): void => undefined
}

export default CustomSwitch