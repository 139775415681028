import React, { ChangeEvent, OptionHTMLAttributes, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import useForm from 'react-hook-form'
import { connect, useDispatch } from 'react-redux'
import CustomSelect from '../custom/custom-select/custom-select'
import CustomTable from '../custom/custom-table/custom-table'
import * as yup from 'yup'
import { TableAction } from '../../types/table-action'
import { IntegrationPropertyValue } from '../../types/integration-property-value'
import { EditableTableRow } from '../../types/editable-table-row'
import ActionsCell from '../custom/custom-table/actions-cell'
import { CellProps } from 'react-table'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import CustomInput from '../custom/custom-input'
import { OptionsType, OptionTypeBase } from 'react-select'
import { State } from '../../types/states/state'
import { IntegrationProperty } from '../../types/integration-property'
import { EquipmentsIntegrationProperties } from '../../types/component-properties/equipments-integration-properties'
import { ActionType } from '../../types/action-type'
import { Equipment } from '../../types/entity/equipment'
import { fetchConfigureIntegrationProperties, fetchEditIntegrationProperty } from '../../reducers/equipments'
import CustomSwitch from '../custom/custom-switch'
import { Locale } from '../../types/locale'
import CustomButton from '../custom/custom-button'
import { intl } from '../../intl'
import { isBoolean } from 'lodash'

const integrationPropertiesSchema = yup.object().shape({
    value: yup.string()
})

const EquipmentsIntegration = (props: EquipmentsIntegrationProperties): ReactElement => {
    const dispatch = useDispatch()

    const [selectedImage, setSelectedImage] = useState('')
    const [propertyName, setPropertyName] = useState<string | null>(null)
    const [propertyValue, setPropertyValue] = useState<string | null>(null)
    const [selectedProperty, setSelectedProperty] = useState<EditableTableRow>({
        active: null,
        value: null,
        id: null
    })
    const [tableActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon }
    ])
    const [editedRowTemplate, setEditedRowTemplate] = useState<ReactElement>()
    const { register, setValue } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        validationSchema: integrationPropertiesSchema
    })

    const handleResetSelectedProperty = useCallback((property: IntegrationPropertyValue | undefined, editRow: string | undefined): void => {
        if (property) {
            // setPropertyName(property.id)
            // setValue('name', property.id)
            setPropertyName(property.name)
            setPropertyValue(property.value)
            setValue('value', property.value)
            setSelectedProperty({
                active: property.active,
                value: property.value,
                id: editRow
            })
        } else {
            setPropertyName(null)
            setPropertyValue(null)
            setValue('value', null)
            setSelectedProperty({
                active: null,
                value: null,
                id: editRow
            })
        }
    }, [setValue])

    const handleAction = useCallback((action: TableAction): void => {
        switch (action.type) {
            case 'Save':
                if (props.equipment && propertyValue) {
                    Promise.all([dispatch(fetchEditIntegrationProperty(props.equipment.id, (action.row as IntegrationPropertyValue).id, { value: propertyValue }, props.locale, intl.formatMessage({ id: 'equipments.toast.success.propertyUpdated' })))]).then(() => {
                        handleResetSelectedProperty(undefined, undefined)
                    })
                }

                break
            case 'Edit':
                setTimeout(() => {
                    handleResetSelectedProperty(action.row as IntegrationPropertyValue, (action.row as IntegrationPropertyValue).id)
                }, 1)

                break
            case 'Delete':

                break
            case 'Cancel':
                handleResetSelectedProperty(undefined, undefined)

                break
        }
    }, [dispatch, handleResetSelectedProperty, propertyValue, props.locale, props.equipment])

    const handleEditIntegrationProperty = useCallback((active: boolean, propertyId: string): void => {
        if (props.equipment) {
            dispatch(fetchEditIntegrationProperty(props.equipment.id, propertyId, { active }, props.locale, intl.formatMessage({ id: 'equipments.toast.success.propertyUpdated' })))
        }
    }, [dispatch, props.equipment, props.locale])

    const columns = useMemo(() => [
        { id: 'name', Header: 'equipments.integration.table.header.name', canGroupBy: false, accessor: 'name', disableSortBy: true, width: '60%' },
        { id: 'value', Header: 'equipments.integration.table.header.value', canGroupBy: false, accessor: 'value', disableSortBy: true, width: '20%' },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 94, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<IntegrationPropertyValue>>): ReactElement {
            return (
                <ActionsCell
                    actions={tableActions}
                    row={rowProps.row.original}
                    canSave={true}
                    handleAction={handleAction}
                    editingRow={(selectedProperty.id ?? '-1') as string} />
            )
        } },
        { id: 'active', Header: 'Active', canGroupBy: false, accessor: 'active', disableSortBy: true, width: 48, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<IntegrationPropertyValue>>): ReactElement {
            return <CustomSwitch className="flex" checked={rowProps.row.original.active} onChange={(checked: boolean): void => handleEditIntegrationProperty(checked, rowProps.row.original.id)} />
        } }
    ], [handleAction, handleEditIntegrationProperty, tableActions, selectedProperty])

    const handleUpdatePropertyValue = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setValue('value', event.target.value)
        setPropertyValue(event.target.value)
        // triggerValidation({ name: 'operand1' })
    }, [setValue])

    function handleChangeSelectedProperty(): void {
        if (propertyValue) {
            setEditedRowTemplate(
                <React.Fragment>
                    <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                        {propertyName}
                    </td>
                    <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                        <CustomInput
                            className="min-w-30 max-w-44 select-none my-2"
                            name="value"
                            formRef={register}
                            hideErrors={true}
                            defaultValue={propertyValue}
                            onChange={handleUpdatePropertyValue} />
                    </td>
                    <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                        <ActionsCell
                            actions={[
                                { type: 'Save', Icon: SaveIcon },
                                { type: 'Cancel', Icon: CancelIcon }
                            ]}
                            row={selectedProperty as IntegrationPropertyValue}
                            canSave={true}
                            handleAction={handleAction}
                            editingRow={selectedProperty.id as string} />
                    </td>
                    <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                        <CustomSwitch
                            className="flex"
                            checked={isBoolean(selectedProperty.active) ? selectedProperty.active : false}
                            onChange={(checked: boolean): void => handleEditIntegrationProperty(checked, selectedProperty.id as string)} />
                    </td>
                </React.Fragment>
            )
        }
    }

    function handleConfigure(): void {
        if (props.equipment) {
            Promise.all([dispatch(fetchConfigureIntegrationProperties(props.equipment.id, selectedImage, props.locale, intl.formatMessage({ id: 'equipments.toast.success.propertyUpdated' })))]).then(() => {
                setSelectedImage('')
            })
        }
    }

    useEffect(handleChangeSelectedProperty, [propertyName, propertyValue, selectedProperty])

    return (
        <div className="mt-6">
            <div className="flex">
                <CustomSelect
                    className="w-60"
                    options={props.imageOptions}
                    value={selectedImage}
                    placeholder={`${intl.formatMessage({ id: 'equipments.integration.select.placeholder' })}...`}
                    onChange={(event): void => setSelectedImage((event as OptionHTMLAttributes<HTMLOptionElement>).value as string)} />
                
                <CustomButton
                    className="font-bold px-5 ml-4 bg-primary text-white"
                    label={intl.formatMessage({ id: 'equipments.integration.configureButton.label' })}
                    onClick={handleConfigure} />
            </div>

            <CustomTable 
                key={selectedProperty.id as string}
                data={props.integrationProperties?.properties ?? []}
                columns={columns}
                editedRow={selectedProperty}
                editedRowTemplate={editedRowTemplate}
                stripedRows={true}
                showPagination={false} />
        </div>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment; imageOptions: OptionsType<OptionTypeBase>; integrationProperties: IntegrationProperty | null; locale: Locale } => ({
    equipment: state.equipments.currentEquipment as Equipment,
    imageOptions: state.equipments.imageOptions,
    integrationProperties: state.equipments.integrationProperties,
    locale: state.ui.locale
})

export default connect(mapStateToProps)(EquipmentsIntegration)
