import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { BreadcrumbProperties } from '../types/component-properties/breadcrumb-properties'
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg'
import classNames from 'classnames'

const classes = {
    'home': css`
        border-top-left-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
    `,
    'side': css`
        border-top: 1.25rem solid transparent;
        border-bottom: 1.25rem solid transparent;
        border-left: 1.25rem solid transparent;
    `,
    'left': css`
        border-bottom: 1.25rem solid var(--color-primary);
        border-top: 1.25rem solid var(--color-primary);
    `,
    'left-last': css`
        border-bottom: 1.25rem solid var(--color-background-first);
        border-top: 1.25rem solid var(--color-background-first);
    `,
    'right': css`
        border-left: 1.25rem solid var(--color-primary);
    `,
    'right-last': css`
        border-left: 1.25rem solid var(--color-background-first);
    `,
    'not-last': css`
        background: var(--color-background-first);
    `,
    'last': css`
        background: var(--color-primary);
        color: var(--color-white);
    `,
    'home-white': css`
        path {
            fill: var(--color-white);
        }
    `,
    'home-gray': css`
        path {
            fill: var(--color-darkgray);
        }
    `
}

const Breadcrumb = (props: BreadcrumbProperties): ReactElement => {
    return (
        <React.Fragment>
            <div
                css={[props.cssStyles]}
                className={`flex w-full h-full text-xs text-gray max-w-full overflow-x-auto overflow-y-hidden py-5 pl-8 z-10 ${props.className}`}
            >
                <div
                    className='flex select-none cursor-pointer min-h-8'
                    onClick={(): void => props.handleNavigate({ pathname: `/${props.currentSection}` }, -1, props.paths.length !== 0)}
                >
                    <div
                        css={[classes.home]}
                        className={classNames({ 'flex items-center justify-center h-full w-10 p-2': true, 'bg-background-first': props.paths.length, 'bg-primary text-white': !props.paths.length })}
                    >
                        <HomeIcon css={props.paths.length ? classes["home-gray"] : classes["home-white"]} />
                    </div>
                    <div css={[classes.side, props.paths.length ? classes["right-last"] : classes.right]}></div>
                </div>

                {
                    props.paths.map((path, index) => {
                        return (
                            <div className='flex select-none cursor-pointer min-h-8' key={path.id ? path.id : index} onClick={(): void => props.handleNavigate(path.destination, index, (props.paths.length - 1) !== index)}>
                                <div css={[classes.side, (props.paths.length - 1) === index ? classes.left : classes["left-last"]]}></div>
                                <div
                                    css={[(props.paths.length - 1) === index ? classes.last : classes["not-last"]]}
                                    className={classNames({ 'flex items-center h-full px-4 py-2 truncate': true, 'bg-background-first': (props.paths.length - 1) !== index, 'bg-primary text-white': (props.paths.length - 1) === index })}
                                >
                                    {path.label}
                                </div>
                                <div css={[classes.side, (props.paths.length - 1) === index ? classes.right : classes["right-last"]]}></div>
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

Breadcrumb.defaultProps = {
    className: '',
    cssStyles: [css``]
}

export default Breadcrumb
