import { useEffect, MutableRefObject, useState, useRef } from 'react'
import { SectionName } from '../types/section-name'
import { useDispatch } from 'react-redux'
import { RGBColor } from 'react-color'
import { Locale } from '../types/locale'

export function deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj))
}


export function useClickOutside(ref: MutableRefObject<HTMLElement | null>): boolean {
    const [menuVisible, setMenuVisible] = useState<boolean>(false)

    function handleClickOutside(event: MouseEvent): void {
        // https://stackoverflow.com/questions/43842057/detect-if-click-was-inside-react-component-or-not-in-typescript
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setMenuVisible(!menuVisible)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return (): void => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    })

    return menuVisible
}

export function useDebounce<Type>(value: Type, section: SectionName, delay: number, dependencies: any[] = []): { value: Type; section: SectionName } {
    const [debouncedValue, setDebouncedValue] = useState({ value, section })

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue({ value, section })
        }, delay)

        return (): void => {
            clearTimeout(handler)
        }
    }, [value, section, delay, ...dependencies])

    return debouncedValue
}

export function useFetching(action: (...params: any) => void, currentSection: SectionName, dependencies: any[] = [], param?: any, ...params: any): void {
    const dispatch = useDispatch()

    const debounce = useDebounce(param, currentSection, 10, dependencies)
    const isFirstRun = useRef(true)

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (debounce.value) {
            dispatch(action(debounce.value, ...params))
        }
    }, [dispatch, action, debounce])
}

export function downloadFile(base64: string, name: string): void {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/octet-stream' })
    const url = window.URL.createObjectURL(blob)
    const element = document.createElement('a')
    element.setAttribute('href', url)
    element.setAttribute('download', name)
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

export function rgbaToHex(r: number, g: number, b: number, a: number): string {
    let red = r.toString(16)
    let green = g.toString(16)
    let blue = b.toString(16)
    let alpha = Math.round(a * 255).toString(16)

    if (red.length === 1) {
        red = "0" + red
    }
        
    if (green.length === 1) {
        green = "0" + green
    }
        
    if (blue.length === 1) {
        blue = "0" + blue
    }
        
    if (alpha.length === 1) {
        alpha = "0" + alpha
    }

    return "#" + red + green + blue + alpha
}

export function getNavigatorLocale(): Locale {
    let navigatorLocale: Locale = 'en'

    switch(navigator.language) {
        case 'de': case 'de-AT': case 'de-LU': case 'de-LI':
            navigatorLocale = 'de'

            break
        case 'it': case 'it-IT': case 'it-CH':
            navigatorLocale = 'it'

            break
        case 'fr': case 'fr-BE': case 'fr-CA': case 'fr-CH': case 'fr-LU':
            navigatorLocale = 'fr'

            break
        case 'es': case 'es-AR': case 'es-GT': case 'es-CR': case 'es-PA': case 'es-DO': case 'es-MX': case 'es-VE': case 'es-CO': case 'es-PE': case 'es-CL': case 'es-UY': case 'es-PY': case 'es-BO': case 'es-SV': case 'es-HN': case 'es-NI': case 'es-PR':
            navigatorLocale = 'es'

            break
    }

    return navigatorLocale
}

export function hexToRgba(hex: string): RGBColor {
    const [r, g, b, a] = hex.match(/\w\w/g)?.map(x => parseInt(x, 16)) ?? []
    return {
        r,
        g,
        b,
        a
    }
}

export function uniqueArray(value: any, index: number, self: any[]): boolean {
    return self.indexOf(value) === index
}
