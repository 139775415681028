import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../types/app-thunk'
import { call, getUser, setUser } from '../common/api'
import { toast } from 'react-toastify'
import { PreferencesState } from '../types/states/preferences-state'
import { User } from '../types/entity/user'
import { EditUser } from '../types/edit-user'
import { setLoggedUserLanguage } from './login'
import { Locale } from '../types/locale'
import { setLocale } from './ui'

const preferencesInitialState: PreferencesState = {
    preferences: null
}

const preferencesSlice = createSlice({
    name: 'preferences',
    initialState: preferencesInitialState,
    reducers: {
        setPreferences: (state: PreferencesState, { payload }: PayloadAction<User | null>): void => {
            state.preferences = payload
        }
    }
})

export const { setPreferences } = preferencesSlice.actions

export default preferencesSlice.reducer

export const fetchPreferences = (userId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getPreferencesRequest = call({
            method: 'GET',
            url: `/users/${userId}`
        }, dispatch, true)
        const res = await getPreferencesRequest()
        
        if (res.status >= 200 && res.status < 300) {
            dispatch(preferencesSlice.actions.setPreferences(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditPreferences = (userId: string | undefined, data: EditUser, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        if (userId) {
            const editPreferencesRequest = call({
                method: 'PUT',
                url: `/users/${userId}`,
                data
            }, dispatch, true)
            const res = await editPreferencesRequest()
            if (res.status >= 200 && res.status < 300) {
                // const localUser = JSON.parse(localStorage.getItem('user') ?? '{}')
                const localUser = getUser()
                if (localUser && localUser.hasOwnProperty('language')) {
                    localUser.language = res.data.languageId
                    dispatch(setLoggedUserLanguage(res.data.languageId))
                    dispatch(setLocale(res.data.languageId as Locale))
                    // localStorage.setItem('user', JSON.stringify(localUser))
                    setUser(localUser)
                }
                dispatch(preferencesSlice.actions.setPreferences(res.data))
                toast.success(message)
            }
        }
    } catch (err) {
        console.log(err)
    }
}