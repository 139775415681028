import React, { ReactElement, useEffect, useState, MouseEvent } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { intl } from '../../../../intl'
import { DateTime } from 'luxon'
import CustomSwitch from '../../../custom/custom-switch'
import { TimelineEventProperties } from '../../../../types/component-properties/timeline-event-properties'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../types/states/state'
import { Locale } from '../../../../types/locale'
import { LoggedUser } from '../../../../types/entity/logged-user'
import classNames from 'classnames'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/column-delete.svg'
import { ReactComponent as BaselineIcon } from '../../../../assets/icons/baseline-icon.svg'
import { ReactComponent as Baselines } from '../../../../assets/icons/column-baseline.svg'
import { ReactComponent as ProtocolIcon } from '../../../../assets/icons/protocols.svg'

// import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg'
import { fetchFile } from '../../../../reducers/ui'
import { Equipment } from '../../../../types/entity/equipment'

const classes = {
    'icon': css`
        g {
            fill: var(--color-primary);
        }
    `
}

const AnalysedImageEvent = (props: TimelineEventProperties): ReactElement => {
    const dispatch = useDispatch()

    const [imageBase64, setImageBase64] = useState('')

    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    function goToResults(): void {
        if (props.equipment) {
            props.timelineNavigate(props.eventDate ? `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=0&type=ANALYSED_IMAGE&id=${props.id}&equipmentId=${props.equipment.id}&page=${timelineFilter.page}` })
        }
    }

    function goToSymptoms(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void {
        event.stopPropagation()
        if (props.symptoms?.severityId && props.equipment) {
            props.timelineNavigate(props.eventDate ? `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=2&type=ANALYSED_IMAGE&id=${props.id}&equipmentId=${props.equipment.id}&page=${timelineFilter.page}` })
        }
    }

    function goToProtocol(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void {
        event.stopPropagation()
        if (props.violations?.severityId && props.equipment) {
            props.timelineNavigate(props.eventDate ? `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=3&type=ANALYSED_IMAGE&id=${props.id}&equipmentId=${props.equipment.id}&page=${timelineFilter.page}` })
        }
    }

    function goToComments(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void {
        event.stopPropagation()
        if (props.equipment) {
            props.timelineNavigate(props.eventDate ? `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=4&type=ANALYSED_IMAGE&id=${props.id}&equipmentId=${props.equipment.id}&page=${timelineFilter.page}` })
        }
    }

    function goToBaseline(event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>): void {
        event.stopPropagation()

        if (props.equipment && props.baseline) {
            props.timelineNavigate(props.baseline.name, { pathname: `/dashboard/equipment-detail/baseline`, search: `?tab=3&&equipmentId=${props.equipment.id}&baselineId=${props.baseline.id}&page=${timelineFilter.page}` })
        }
    }

    function goToProtocols(event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>): void {
        event.stopPropagation()

        if (props.equipment && props.protocol) {
            props.timelineNavigate(props.protocol.name, { pathname: `/dashboard/equipment-detail/requirements`, search: `?tab=4&&equipmentId=${props.equipment.id}&protocolId=${props.protocol.id}&page=${timelineFilter.page}` })
        }
    }

    function deleteEvent(event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>): void {
        event.stopPropagation()
        props.handleDeleteTimelineEvent(props.id)
    }

    useEffect(() => {
        if (props.thumbnail) {
            Promise.all([dispatch(fetchFile(props.thumbnail, 0, 'BASE64', false))]).then((res: any[]) => {
                if (res && res[0]) {
                    setImageBase64("data:image/png;base64," + res[0].data)
                }
            })
        }
    }, [dispatch, props.thumbnail])

    return (
        <React.Fragment>
            <div className="flex items-center item-row h-40 border-0 border-b border-solid border-gray-100 py-4 last:border-0">
                <div className="flex items-center justify-center min-w-32 h-32 relative">
                    <img src={imageBase64} className="max-w-full max-h-full" alt="" />
                    {/* {
                        props.file &&
                        <div
                            className="flex items-center justify-center w-5 h-5 rounded-full border-0 bg-primary text-white outline-none absolute top-2 right-2 bg-no-repeat bg-center bg-60% cursor-pointer right"
                            data-tip={intl.formatMessage({ id: 'Download' })}
                        >
                            <ArrowDownIcon width="0.75rem" height="0.75rem" fill="var(--color-white)" />
                        </div>
                    } */}
                </div>

                <div className="flex items-center px-6 h-full truncate flex-grow hover:opacity-75 cursor-pointer" onClick={goToResults}>
                    <div className="w-full truncate">
                        <div className="flex flex-col flex-grow">
                            <div className="flex-grow mb-1 font-bold">
                                {props.eventDate && `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}`}
                            </div>
                            <div className="flex flex-wrap flex-grow mb-1 mr-2">
                                {props.title}
                            </div>
                            <div className="flex flex-wrap flex-grow mb-1 text-gray-100 text-sm">
                            {intl.formatMessage({ id: 'dashboard.timeline.eventsList.event.lastModified' })}: {props.author} {intl.formatMessage({ id: 'dashboard.timeline.eventsList.event.on' }).toLowerCase()} {props.entryDate && `${DateTime.fromMillis(props.entryDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}`}
                            </div>
                        </div>
                        <div className="mt-2 max-h-18 overflow-y-auto">
                            {
                                props.imageTags &&
                                props.imageTags.map((tag, tagIndex) => {
                                    return <div key={tagIndex} className='inline-block text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase select-none mr-2 mb-1 py-1 px-4'>{tag.name}</div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="flex">
                    {
                        props.protocol &&
                        <ProtocolIcon
                            css={[classes.icon]}
                            className="w-8 h-8 mr-4 cursor-pointer hover:opacity-50"
                            data-tip={intl.formatMessage({ id: 'tooltip.protocol' })}
                            onClick={goToProtocols} />
                    }
                    {
                        props.baseline &&
                        // <div
                        //     className="flex justify-center items-center border-2 xl:border-3 border-solid border-primary bg-white mr-4 text-xl font-bold w-8 h-8 rounded-full text-black no-underline"
                        // >
                        //     <span className="select-none">B</span>
                        // </div>
                        <Baselines
                            className="w-8 h-8 mr-4 cursor-pointer hover:opacity-50"
                            data-tip={intl.formatMessage({ id: 'tooltip.baseline' })}
                            onClick={goToBaseline} />
                    }
                    {
                        !!props.definingBaselinesCount && props.definingBaselinesCount > 0 &&
                        // <div
                        //     className="flex justify-center items-center border-2 xl:border-3 border-solid border-primary bg-white mr-4 text-xl font-bold w-8 h-8 rounded-full text-black no-underline"
                        // >
                        //     <span className="select-none">B</span>
                        // </div>
                        <BaselineIcon className="w-8 h-8 mr-4" />
                    }
                    <div
                        className={classNames({
                            [`flex justify-center items-center mx-1 text-sm w-8 h-8 rounded-full text-white no-underline ${props.symptoms?.severityId.toLowerCase()}`]: true,
                            'cursor-pointer hover:opacity-50': /*props.symptoms && props.symptoms.count > 0*/ true,
                            'opacity-25 cursor-not-allowed': /*props.symptoms?.count === 0*/ false
                        })}
                        data-tip={intl.formatMessage({ id: 'tooltip.symptoms' })}
                        onClick={goToSymptoms}
                    >
                        <span className="select-none">{props.symptoms?.count}</span>
                    </div>
                    <div
                        className={classNames({
                            [`flex justify-center items-center mx-1 text-sm w-8 h-8 rounded-full text-white no-underline ${props.violations?.severityId.toLowerCase()}`]: true,
                            'cursor-pointer hover:opacity-50': props.violations && props.violations.count > 0,
                            'opacity-25 cursor-not-allowed': props.violations?.count === 0
                        })}
                        data-tip={intl.formatMessage({ id: 'tooltip.protocol' })}
                        onClick={goToProtocol}
                    >
                        <span className="select-none">{props.violations?.count}</span>
                    </div>
                    <div
                        className={classNames({
                            'flex justify-center items-center ml-4 text-sm rounded-full text-white no-underline w-8 h-7 relative bg-primary cursor-pointer hover:opacity-50': true,
                            'opacity-25': props.totalComments === 0
                        })}
                        data-tip={intl.formatMessage({ id: 'tooltip.comments' })}
                        onClick={goToComments}
                    >
                        <span className="select-none comments-arrow">{props.totalComments}</span>
                    </div>
                </div>
                {
                    props.loggedUser?.acl.includes('LOGEVENTS_HIDE') && props.hideable &&
                    <CustomSwitch
                        className="ml-6 pl-4"
                        checked={!props.hidden}
                        onChange={(): void => props.handleToggleTImelineEventVisibility(props.id, !props.hidden)}></CustomSwitch>
                }
                {
                    props.loggedUser?.acl.includes('LOGEVENTS_DELETE') && props.removable &&
                    <DeleteIcon 
                        className="min-w-4 w-4 mx-4 cursor-pointer hover:opacity-50"
                        data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                        onClick={deleteEvent} />
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; locale: Locale; loggedUser: LoggedUser | null; search: string }  => ({
    equipment: state.dashboard.currentEquipment,
    locale: state.ui.locale,
    loggedUser: state.login.user,
    search: state.router.location.search
})

export default connect(mapStateToProps)(AnalysedImageEvent)