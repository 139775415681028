import React, { ReactElement, MouseEvent } from 'react'
import { intl } from '../../../../intl'
import { DateTime } from 'luxon'
import CustomSwitch from '../../../custom/custom-switch'
import { TimelineEventProperties } from '../../../../types/component-properties/timeline-event-properties'
import { connect, useSelector } from 'react-redux'
import { State } from '../../../../types/states/state'
import { Locale } from '../../../../types/locale'
import { LoggedUser } from '../../../../types/entity/logged-user'
import classNames from 'classnames'
import { ReactComponent as FileIcon } from '../../../../assets/icons/file.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/column-delete.svg'
import { Equipment } from '../../../../types/entity/equipment'

const AnalysedDocumentEvent = (props: TimelineEventProperties): ReactElement => {
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    function goToResults(): void {
        if (props.equipment) {
            props.timelineNavigate(props.eventDate ? `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=0&type=ANALYSED_DOCUMENT&id=${props.id}&equipmentId=${props.equipment.id}&page=${timelineFilter.page}` })
        }
    }

    function goToComments(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void {
        event.stopPropagation()
        if (props.equipment) {
            props.timelineNavigate(props.eventDate ? `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=1&type=ANALYSED_DOCUMENT&id=${props.id}&equipmentId=${props.equipment.id}&page=${timelineFilter.page}` })
        }
    }

    function deleteEvent(event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>): void {
        event.stopPropagation()
        props.handleDeleteTimelineEvent(props.id)
    }

    return (
        <React.Fragment>
            <div className="flex items-center item-row h-40 border-0 border-b last:border-0 border-solid border-gray-100 py-4">
                <div className="min-w-32 h-32 relative">
                    <FileIcon className="min-w-32 max-w-32 min-h-32 max-h-32 p-6 object-contain" />
                    {/* {
                        props.file &&
                        <div
                            className="flex items-center justify-center w-5 h-5 rounded-full border-0 bg-primary text-white outline-none absolute top-2 right-2 bg-no-repeat bg-center bg-60% cursor-pointer right"
                            data-tip={intl.formatMessage({ id: 'Download' })}
                        >
                            <ArrowDownIcon width="0.75rem" height="0.75rem" fill="var(--color-white)" />
                        </div>
                    } */}
                </div>

                <div className="flex items-center px-6 h-full truncate flex-grow hover:opacity-75 cursor-pointer" onClick={goToResults}>
                    <div>
                        <div className="flex flex-col flex-grow">
                            <div className="flex-grow mb-1 font-bold">
                                {props.eventDate && `${DateTime.fromMillis(props.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}`}
                            </div>
                            <div className="flex flex-wrap flex-grow mb-1 mr-2">
                                {props.title}
                            </div>
                            <div className="flex flex-wrap flex-grow mb-1 text-gray-100 text-sm">
                                {intl.formatMessage({ id: 'dashboard.timeline.eventsList.event.lastModified' })}: {props.author} {intl.formatMessage({ id: 'dashboard.timeline.eventsList.event.on' }).toLowerCase()} {props.entryDate && `${DateTime.fromMillis(props.entryDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}`}
                            </div>
                        </div>
                        <div className="max-h-18 overflow-y-auto mt-2">
                            {props.imageTags && props.imageTags.map((tag, tagIndex) => {
                                return <div key={tagIndex} className='inline-block text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase select-none mr-2 mb-1 py-1 px-4'>{tag}</div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div
                        className={classNames({
                            'flex justify-center items-center rounded-full text-white ml-4 text-sm w-8 h-7 relative bg-primary cursor-pointer hover:opacity-50': true,
                            'opacity-25': props.totalComments === 0
                        })}
                        data-tip={intl.formatMessage({ id: 'tooltip.comments' })}
                        onClick={goToComments}
                    >
                        <span className="select-none comments-arrow">{props.totalComments}</span>
                    </div>
                </div>
                {
                    props.loggedUser?.acl.includes('LOGEVENTS_HIDE') &&
                    <CustomSwitch
                        className="ml-6 pl-4"
                        checked={!props.hidden}
                        onChange={(): void => props.handleToggleTImelineEventVisibility(props.id, !props.hidden)}></CustomSwitch>
                }
                {
                    props.loggedUser?.acl.includes('LOGEVENTS_DELETE') &&
                    <DeleteIcon 
                        className="min-w-4 w-4 mx-4 cursor-pointer hover:opacity-50"
                        data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                        onClick={deleteEvent} />
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; locale: Locale; loggedUser: LoggedUser | null }  => ({
    equipment: state.dashboard.currentEquipment,
    locale: state.ui.locale,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(AnalysedDocumentEvent)