import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProtocolsState } from '../types/states/protocols-state'
import { Protocol } from '../types/entity/protocol'
import { RequestQueryParams } from '../types/request-query-params'
import { AppThunk } from '../types/app-thunk'
import { ProtocolDetail } from '../types/entity/protocol-detail'
import { call } from '../common/api'
import { setSidemenuData, splicePath, setPaths } from './ui'
import { TestObjectProtocol } from '../types/test-object-protocol'
import { NewProtocolRequirement } from '../types/new-protocol-requirement'
import { toast } from 'react-toastify'
import { EditProtocol } from '../types/edit-protocol'
import { push } from 'connected-react-router'
import { Constraints } from '../types/constraints'
import { BreadcrumbPath } from '../types/entity/breadcrumb-path'
import { deepCopy } from '../common/utils'

const protocolsInitialState: ProtocolsState = {
    protocols: [],
    protocolsSize: 0,
    currentProtocol: null,
    testObjectsProtocolModels: [],
    constraints: null
}

const protocolsSlice = createSlice({
    name: 'protocols',
    initialState: protocolsInitialState,
    reducers: {
        setProtocols: (state: ProtocolsState, { payload }: PayloadAction<{ protocols: Protocol[]; size: number }>): void => {
            state.protocols = payload.protocols
            state.protocolsSize = payload.size
        },
        setCurrentProtocol: (state: ProtocolsState, { payload }: PayloadAction<ProtocolDetail | null>): void => {
            state.currentProtocol = payload
        },
        setProtocolsByTestObject: (state: ProtocolsState, { payload }: PayloadAction<TestObjectProtocol[]>): void => {
            state.testObjectsProtocolModels = payload
        },
        setContraints: (state: ProtocolsState, { payload }: PayloadAction<Constraints | null>): void => {
            state.constraints = payload
        }
    }
})

export const { setProtocols, setCurrentProtocol, setProtocolsByTestObject, setContraints } = protocolsSlice.actions

export default protocolsSlice.reducer

export const fetchProtocols = (queryParams: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const params: RequestQueryParams = deepCopy(queryParams)
        delete params.limit
        delete params.page
        const getProtocolsRequest = call({
            method: 'GET',
            url: '/protocol-models',
            params
        }, dispatch, true)
        const res = await getProtocolsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(setSidemenuData(res.data.mappedElements))
            dispatch(protocolsSlice.actions.setProtocolsByTestObject(res.data.testObjectsProtocolModels))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateProtocol = (data: FormData, params: RequestQueryParams, message: string, administrator: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createProtocolRequest = call({
            method: 'POST',
            url: `/protocol-models`,
            data
        }, dispatch, true)
        const res = await createProtocolRequest()
        if (res.status >= 200 && res.status < 300) {
            const paths: BreadcrumbPath[] = [
                {
                    label: res.data.customerName,
                    id: res.data.customerId,
                    destination: {
                        pathname: '/protocols'
                    },
                    param: {
                        property: 'customerId',
                        value: res.data.customerId
                    }
                },
                {
                    label: res.data.name,
                    id: res.data.id,
                    destination: {
                        pathname: `/protocols/detail`,
                        search: `?id=${res.data.id}`  
                    },
                    param: null
                }
            ]
            dispatch(fetchProtocols(params))
            dispatch(protocolsSlice.actions.setCurrentProtocol(res.data))
            dispatch(setPaths(administrator ? paths : [paths[1]]))
            dispatch(push({
                pathname: '/protocols/detail',
                search: `?id=${res.data.id}`
            }))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditProtocol = (protocolId: string, data: EditProtocol, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editProtocolRequest = call({
            method: 'PUT',
            url: `/protocol-models/${protocolId}`,
            data
        }, dispatch, true)
        const res = await editProtocolRequest()

        // dispatch(protocolsSlice.actions.setCurrentProtocol(res.data))
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchProtocols(params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchProtocol = (customerId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getProtocolRequest = call({
            method: 'GET',
            url: `/protocol-models/${customerId}`
        }, dispatch, true)
        const res = await getProtocolRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(protocolsSlice.actions.setCurrentProtocol(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteProtocol = (protocolId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteProtocolRequest = call({
            method: 'DELETE',
            url: `/protocol-models/${protocolId}`
        }, dispatch, true)
        const res = await deleteProtocolRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchProtocols(params))
            dispatch(splicePath(1))
            dispatch(push('/protocols'))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDuplicateProtocol = (protocolId: string, params: RequestQueryParams, message: string, administrator: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteProtocolRequest = call({
            method: 'POST',
            url: `/protocol-models/${protocolId}`,
            params
        }, dispatch, true)
        const res = await deleteProtocolRequest()
        
        if (res.status >= 200 && res.status < 300) {
            const paths: BreadcrumbPath[] = [
                {
                    label: res.data.customerName,
                    id: res.data.customerId,
                    destination: {
                        pathname: '/protocols'
                    },
                    param: {
                        property: 'customerId',
                        value: res.data.customerId
                    }
                },
                {
                    label: res.data.name,
                    id: res.data.id,
                    destination: {
                        pathname: `/protocols/detail`,
                        search: `?id=${res.data.id}`  
                    },
                    param: null
                }
            ]
            dispatch(fetchProtocols(params))
            dispatch(protocolsSlice.actions.setCurrentProtocol(res.data))
            dispatch(setPaths(administrator ? paths : [paths[1]]))
            dispatch(push({
                pathname: '/protocols/detail',
                search: `?id=${res.data.id}`
            }))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateProtocolRequirement = (protocolId: string, data: NewProtocolRequirement, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createProtocolRequirementRequest = call({
            method: 'POST',
            url: `/protocol-models/requirements`,
            data: {
                protocolId,
                ...data
            }
        }, dispatch, true)
        const res = await createProtocolRequirementRequest()

        // dispatch(setPaths([{ label: res.data.name, id: res.data.id, destination: { pathname: `/medical-centres/medical-centre`, search: `?id=${res.data.id}` }, param: null }]))
        // dispatch(push({
        //     pathname: `/medical-centres/medical-centre`,
        //     search: `?id=${res.data.id}`
        // }))
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchProtocol(protocolId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditProtocolRequirement = (protocolId: string, requirementId: string, data: NewProtocolRequirement, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editProtocolRequirementRequest = call({
            method: 'PUT',
            url: `/protocol-models/requirements/${requirementId}`,
            data
        }, dispatch, true)
       const res = await editProtocolRequirementRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchProtocol(protocolId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteProtocolRequirement = (protocolId: string, requirementId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteProtocolRequirementRequest = call({
            method: 'DELETE',
            url: `/protocol-models/requirements/${requirementId}`
        }, dispatch, true)
        const res = await deleteProtocolRequirementRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchProtocol(protocolId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchConstraints = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCostraintsRequest = call({
            method: 'GET',
            url: `/protocol-models/constraints`,
            params
        }, dispatch, false)
        const res = await getCostraintsRequest()

        dispatch(protocolsSlice.actions.setContraints(res.data))
    } catch (err) {
        console.log(err)
    }
}
