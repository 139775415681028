import React, { ReactElement, useEffect, PropsWithChildren, useState, OptionHTMLAttributes, ChangeEvent, useCallback, useMemo } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomButton from '../custom/custom-button'
import { intl } from '../../intl'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { ProtocolsDetailProperties } from '../../types/component-properties/protocols-detail-properties'
import { ProtocolDetail } from '../../types/entity/protocol-detail'
import CustomTable from '../custom/custom-table/custom-table'
import ActionsCell from '../custom/custom-table/actions-cell'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/column-delete.svg'
import { ReactComponent as DeleteRedIcon } from '../../assets/icons/column-delete-red.svg'
import { ReactComponent as DuplicateIcon } from '../../assets/icons/column-duplicate.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { CellProps } from 'react-table'
import { ProtocolRequirement } from '../../types/protocol-requirement'
import { ActionType } from '../../types/action-type'
import SeverityCell from '../custom/custom-table/severity-cell'
import { TableAction } from '../../types/table-action'
import useForm from 'react-hook-form'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import * as yup from 'yup'
import CustomInput from '../custom/custom-input'
import { NewProtocolRequirement } from '../../types/new-protocol-requirement'
import { Constraints } from '../../types/constraints'
import { fetchCreateProtocolRequirement, fetchEditProtocolRequirement, fetchProtocol, setContraints } from '../../reducers/protocols'
import { LoggedUser } from '../../types/entity/logged-user'
import { useFetching } from '../../common/utils'
import queryString from 'query-string'
import { deepCopy } from '../../common/utils'
import { DicomAttribute } from '../../types/dicom-attribute'
import { EditableTableRow } from '../../types/editable-table-row'

const ruleSchema = yup.object().shape({
    severityId: yup.string().required(),
    dicomAttributeId: yup.string().required(),
    relationId: yup.string().required(),
    operand1: yup.number()
        .when('relationId', {
            is: (val) => val === 'EXISTS_N' || val === 'NOT_EXISTS_N',
            then: yup.number().notRequired(),
            otherwise: yup.number().required()
        }),
    operand2: yup.number()
        .when('relationId', {
            is: (val) => val === 'INSIDE_N' || val === 'OUTSIDE_N',
            then: yup.number().required(),
            otherwise: yup.number().notRequired()
        }),
    actionId: yup.string().required()
})

const classes = {
    'button': css`
        svg {
            width: 1rem;
            height: 1rem;
            margin-left: 1rem;
            margin-top: 0 !important;
        }
    `,
    'table': css`
        th, td {
            padding: 0.25rem 0.5rem;
        }
    `,
    'tag-select': css`
        .select__control {
            min-width: 8.25rem;
            max-width: 12.5rem;
            max-height: 2.5rem;
            overflow: hidden;
        }

        .select__placeholder {
            font-size: 0.8rem;
        }

        .select__value-container {
            flex-wrap: nowrap;
        }
        .select__multi-value {
            max-width: 2.5rem;
        }

        .select__clear-indicator, .select__multi-value__remove {
            display: none;
        }

        .select__value-container--is-multi {
            padding-right: 0;
        }
    `
}

const ProtocolsDetail = ({ handleDeleteProtocolRequirement, ...props }: ProtocolsDetailProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [editedRowTemplate, setEditedRowTemplate] = useState<ReactElement>(<tr></tr>)
    const [selectedRequirement, setSelectedRequirement] = useState<EditableTableRow>({
        severityId: null,
        relationId: null,
        operand1: null,
        operand2: null,
        dicomAttributeId: null,
        actionId: null,
        id: null
    })
    const [operation, setOperation] = useState<string | null>(null)
    const [dicomAttribute, setDicomAttribute] = useState<string | null>(null)
    const [severity, setSeverity] = useState<string | null>(null)
    const [action, setAction] = useState<string | null>(null)
    const [operand1, setOperand1] = useState<number | string | null>(null)
    const [operand2, setOperand2] = useState<number | null>(null)
    const [dicomRelations, setDicomRelations] = useState<DicomAttribute[]>([])
    const [dicomAttributes, setDicomAttributes] = useState<OptionsType<OptionTypeBase>>([])
    const [severities, setSeverities] = useState<OptionsType<OptionTypeBase>>([])
    const [actions, setActions] = useState<OptionsType<OptionTypeBase>>([])
    const [orderedRequirements, setOrderedRequirements] = useState<ProtocolRequirement[]>([])
    const [isValid, setIsValid] = useState<boolean>(false)
    const [tableActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])

    const { register, unregister, getValues, setValue, triggerValidation } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        validationSchema: ruleSchema
    })

    function capitalize(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    }

    const checkForm = useCallback((): void => {
        const form = deepCopy(getValues())
        const relation = form.relationId
        const valid = form.severityId && form.relationId && form.dicomAttributeId && form.actionId
        switch (relation) {
            case 'EXISTS_N': case 'NOT_EXISTS_N':
                setIsValid(valid)

                break
            case 'INSIDE_N': case 'OUTSIDE_N':
                setIsValid(valid && form.operand1 !== null && form.operand2 !== null && form.operand1 < form.operand2)

                break
            default:
                setIsValid(valid && form.operand1 !== null)

                break
        }
    }, [getValues])

    const handleUpdateSeverityId = useCallback((value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void => {
        setValue('severityId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setSeverity((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'severityId' })
        checkForm()
    }, [checkForm, setValue, triggerValidation])

    const handleUpdateDicomAttribute = useCallback((value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void => {
        setValue('dicomAttributeId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setValue('relationId', null)
        setValue('operand1', null)
        setValue('operand2', null)
        setDicomAttribute((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        setOperation(null)
        setOperand1(null)
        setOperand2(null)
        triggerValidation({ name: 'dicomAttributeId' })
        checkForm()
    }, [checkForm, setValue, triggerValidation])

    const handleUpdateOperation = useCallback((value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void => {
        setValue('relationId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setValue('operand1', null)
        setValue('operand2', null)
        setOperation((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        setOperand1(null)
        setOperand2(null)
        triggerValidation({ name: 'relationId' })
        checkForm()
    }, [checkForm, setValue, triggerValidation])

    const handleUpdateAction = useCallback((value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void => {
        setValue('actionId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setAction((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'actionId' })
        checkForm()
    }, [checkForm, setValue, triggerValidation])

    const handleUpdateValue = useCallback((event: ChangeEvent<HTMLInputElement>, index: number, string: boolean): void => {
        if (string) {
            setValue('operand1', event.target.value)
            setOperand1(event.target.value)
            triggerValidation({ name: 'operand1' })
        } else {
            if (!isNaN(parseFloat(event.target.value))) {
                switch (index) {
                    case -1:
                        setValue('operand1', parseFloat(event.target.value))
                        setOperand1(parseFloat(event.target.value))
                        triggerValidation({ name: 'operand1' })

                        break
                    case 0:
                        setValue(`operand1`, parseFloat(event.target.value))
                        setOperand1(parseFloat(event.target.value))
                        triggerValidation({ name: 'operand1' })

                        break
                    case 1:
                        setValue(`operand2`, parseFloat(event.target.value))
                        setOperand2(parseFloat(event.target.value))
                        triggerValidation({ name: 'operand2' })

                        break
                }
            }
        }
        checkForm()
    }, [checkForm, setValue, triggerValidation])

    const handleResetSelectedRequirement = useCallback((requirement: NewProtocolRequirement | undefined, editRow: string | undefined): void => {
        if (requirement) {
            setSeverity(requirement.severityId)
            setValue('severityId', requirement.severityId)
            setOperation(requirement.relationId)
            setValue('relationId', requirement.relationId)
            setOperand1(requirement.operand1)
            setValue('operand1', requirement.operand1)
            setOperand2(requirement.operand2)
            setValue('operand2', requirement.operand2)
            setDicomAttribute(requirement.dicomAttributeId)
            setValue('dicomAttributeId', requirement.dicomAttributeId)
            setAction(requirement.actionId)
            setValue('actionId', requirement.actionId)
            setSelectedRequirement({
                severityId: requirement.severityId,
                relationId: requirement.relationId,
                operand1: requirement.operand1,
                operand2: requirement.operand2,
                dicomAttributeId: requirement.dicomAttributeId,
                actionId: requirement.actionId,
                id: editRow
            })
        } else {
            setSeverity(null)
            setValue('severityId', null)
            setOperation(null)
            setValue('relationId', null)
            setOperand1(null)
            setValue('operand1', null)
            setOperand2(null)
            setValue('operand2', null)
            setDicomAttribute(null)
            setValue('dicomAttributeId', null)
            setAction(null)
            setValue('actionId', null)
            setSelectedRequirement({
                severityId: null,
                relationId: null,
                operand1: null,
                operand2: null,
                dicomAttributeId: null,
                actionId: null,
                id: editRow
            })
        }
    }, [setValue])

    const handleAction = useCallback((action: TableAction): void => {
        switch (action.type) {
            case 'Save':
                if (props.protocol) {
                    if (selectedRequirement.id === '-1') {
                        Promise.all([dispatch(fetchCreateProtocolRequirement(props.protocol.id, getValues() as NewProtocolRequirement, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolRequirementCreated' })))]).then(() => {
                            handleResetSelectedRequirement(undefined, undefined)
                        })
                    } else {
                        Promise.all([dispatch(fetchEditProtocolRequirement(props.protocol.id, (action.row as ProtocolRequirement).id, getValues() as NewProtocolRequirement, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolRequirementUpdated' })))]).then(() => {
                            handleResetSelectedRequirement(undefined, undefined)
                        })
                    }
                }

                break
            case 'Edit':
                setTimeout(() => {
                    handleResetSelectedRequirement(action.row as NewProtocolRequirement, (action.row as ProtocolRequirement).id)
                    setIsValid(true)
                }, 1)

                break
            case 'Delete':
                if (props.protocol) {
                    handleDeleteProtocolRequirement(props.protocol.id, (action.row as ProtocolRequirement).id)
                }

                break
            case 'Cancel':
                handleResetSelectedRequirement(undefined, undefined)
                setIsValid(false)

                break
            default:
                break
        }
    }, [dispatch, getValues, handleDeleteProtocolRequirement, handleResetSelectedRequirement, selectedRequirement.id, props.protocol])

    const columns = useMemo(() => [
        {
            id: 'definition',
            Header: 'protocolsVault.detail.table.header.ruleDefinition',
            columns: [{
                id: 'dicomAttributeName', Header: intl.formatMessage({ id: 'protocolsVault.detail.table.header.dicomHeader' }), canGroupBy: false, accessor: 'dicomAttributeName', Cell: function setColumn(rowProps: PropsWithChildren<CellProps<ProtocolRequirement>>): ReactElement {
                    return <div className="min-w-30 max-w-44">{rowProps.row.original.dicomAttributeName}</div>
                }
            },
            {
                id: 'relationName', Header: intl.formatMessage({ id: 'protocolsVault.detail.table.header.operation' }), canGroupBy: false, accessor: 'relationName', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<ProtocolRequirement>>): ReactElement {
                    return <div className="min-w-30 max-w-44">{rowProps.row.original.relationName}</div>
                }
            },
            {
                id: 'operand1', Header: intl.formatMessage({ id: 'protocolsVault.detail.table.header.parameters' }), canGroupBy: false, accessor: 'operand1', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<ProtocolRequirement>>): ReactElement {
                    return (
                        <div className="min-w-30 max-w-44">
                            {rowProps.row.original.operand1}
                            <span className={`text-gray-100 mx-1 ${rowProps.row.original.operand2 === null ? 'hidden' : 'd-inline-block'}`}>|</span>
                            {rowProps.row.original.operand2}
                        </div>
                    )
                }
            }],
        },
        {
            id: 'violated',
            Header: 'protocolsVault.detail.table.header.ifViolated',
            columns: [{
                id: 'actionName', Header: intl.formatMessage({ id: 'protocolsVault.detail.table.header.action' }), canGroupBy: false, accessor: 'actionName', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<ProtocolRequirement>>): ReactElement {
                    return <div className="min-w-30 max-w-44">{rowProps.row.original.actionName}</div>
                }
            },
            {
                id: 'severity', Header: intl.formatMessage({ id: 'protocolsVault.detail.table.header.severity' }), canGroupBy: false, accessor: 'severity', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<ProtocolRequirement>>): ReactElement {
                    return (
                        <div className="min-w-30 max-w-44">
                            <SeverityCell severity={rowProps.row.original.severityId} label={capitalize(rowProps.row.original.severityId)}></SeverityCell>
                        </div>
                    )
                }
            }],
        },
        {
            id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 94, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<ProtocolRequirement>>): ReactElement {
                return <ActionsCell actions={tableActions} row={rowProps.row.original} canSave={isValid} handleAction={handleAction} editingRow={(selectedRequirement.id ?? '-1') as string}></ActionsCell>
            }
        }
    ], [handleAction, tableActions, isValid, selectedRequirement.id])

    // function handleNavigate(): void {
    //     // props.handleNavigate(props.paths.length - 1)
    // }

    function handleEdit(): void {
        props.handleFilter({
            label: props.protocol?.name as string,
            id: props.protocol?.id as string,
            destination: {
                pathname: `/protocols/edit`,
                search: `?id=${props.protocol?.id}`
            },
            param: null
        })
    }

    function handleDelete(): void {
        props.handleDeleteProtocol()
    }

    function handleDuplicate(): void {
        props.handleDuplicateProtocol()
    }

    function handleNewRequirement(): void {
        if (!orderedRequirements.find(requirement => requirement.id === '-1')) {
            handleResetSelectedRequirement(undefined, '-1')
        }
    }

    const handleSort = useCallback((newOrderBy: string | null, newOrderType: string | null): void => {
        if (props.protocol) {
            if (!newOrderBy) {
                setOrderedRequirements(props.protocol.requirements)
            } else {
                const newOrderedRequirements: ProtocolRequirement[] = deepCopy(props.protocol.requirements)
                if (newOrderType === 'asc') {
                    newOrderedRequirements.sort((a, b) => a.dicomAttributeName.localeCompare(b.dicomAttributeName))
                } else {
                    newOrderedRequirements.sort((a, b) => b.dicomAttributeName.localeCompare(a.dicomAttributeName))
                }
                setOrderedRequirements(newOrderedRequirements)
            }
        }
    }, [props.protocol])

    function getOperandComponent(string: boolean): ReactElement {
        switch (operation) {
            case 'EQUALS_S': case 'DIFFERENT_S': case 'ELEMENT_OF_S': case 'NOT_ELEMENT_OF_S': case 'CONTAINS_S': case 'NOT_CONTAINS_S':
                return <CustomInput
                    className="min-w-30 max-w-44 select-none"
                    name="operand1"
                    formRef={register}
                    hideErrors={true}
                    defaultValue={operand1 ?? undefined}
                    onChange={(event): void => handleUpdateValue(event, -1, string)} />
            case 'LESS_N': case 'LESS_OR_EQUAL_N': case 'EQUALS_N': case 'DIFFERENT_N': case 'GREATER_OR_EQUAL_N': case 'GREATER_N':
                return <CustomInput
                    className="min-w-30 max-w-44 select-none"
                    name="operand1"
                    type="number"
                    formRef={register}
                    hideErrors={true}
                    defaultValue={operand1 as number}
                    onChange={(event): void => handleUpdateValue(event, -1, string)} />
            case 'INSIDE_N': case 'OUTSIDE_N':
                return (
                    <div className="flex">
                        <CustomInput
                            className="min-w-13 max-w-20 mr-2 select-none"
                            name="operand1"
                            type="number"
                            formRef={register}
                            hideErrors={true}
                            defaultValue={(operand1 as number)}
                            onChange={(event): void => handleUpdateValue(event, 0, string)} />
                        <CustomInput
                            className="min-w-13 max-w-20 ml-2 select-none"
                            name="operand2"
                            type="number"
                            formRef={register}
                            hideErrors={true}
                            defaultValue={(operand2 as number)}
                            onChange={(event): void => handleUpdateValue(event, 1, string)} />
                    </div>
                )
            default:
                return <div></div>
        }
    }

    function handleChangeSelectedRequirement(): void {
        const operations: OptionsType<OptionTypeBase> = deepCopy(dicomRelations.find(dicomRelation => dicomRelation.id === dicomAttribute)?.relations.map(relation => ({ value: relation.id, label: relation.name })) ?? [])
        const string = operation ? operation.split('_')[operation.split('_').length - 1] === 'S' : false
        setEditedRowTemplate(
            <React.Fragment>
                <td>
                    <CustomSelect
                        cssStyles={[classes['tag-select']]}
                        className="w-full"
                        options={dicomAttributes}
                        selectRef={register({ name: `dicomAttributeId` })}
                        placeholder={intl.formatMessage({ id: 'protocolsVault.detail.select.dicomAttribute.placeholder' })}
                        defaultValue={dicomAttribute ?? undefined}
                        onChange={handleUpdateDicomAttribute} />
                </td>
                <td>
                    <CustomSelect
                        cssStyles={[classes['tag-select']]}
                        options={operations}
                        selectRef={register({ name: `relationId` })}
                        placeholder={intl.formatMessage({ id: 'protocolsVault.detail.select.operation.placeholder' })}
                        defaultValue={operation ?? undefined}
                        onChange={handleUpdateOperation} />
                </td>
                <td className="border-0 border-r border-solid border-gray">
                    {getOperandComponent(string)}
                </td>
                <td>
                    <CustomSelect
                        cssStyles={[classes['tag-select']]}
                        options={actions}
                        selectRef={register({ name: `actionId` })}
                        placeholder={intl.formatMessage({ id: 'protocolsVault.detail.select.action.placeholder' })}
                        defaultValue={action ?? undefined}
                        onChange={handleUpdateAction} />
                </td>
                <td>
                    <CustomSelect
                        cssStyles={[classes["tag-select"]]}
                        className="w-full"
                        options={severities}
                        selectRef={register({ name: `severityId` })}
                        placeholder={intl.formatMessage({ id: 'protocolsVault.detail.select.severity.placeholder' })}
                        defaultValue={severity ?? undefined}
                        onChange={handleUpdateSeverityId} />
                </td>
                <td>
                    <ActionsCell
                        actions={[
                            { type: 'Save', Icon: SaveIcon },
                            { type: 'Edit', Icon: EditIcon },
                            { type: 'Cancel', Icon: CancelIcon }
                        ]}
                        row={selectedRequirement as NewProtocolRequirement}
                        canSave={isValid}
                        handleAction={handleAction}
                        editingRow={selectedRequirement.id as string}></ActionsCell>
                </td>
            </React.Fragment>
        )
    }

    useFetching(fetchProtocol, 'protocols', [], queryParams.id as string)

    useEffect(handleChangeSelectedRequirement, [dicomAttribute, operation, isValid, selectedRequirement])

    useEffect(() => {
        if (props.constraints) {
            setDicomRelations(props.constraints.dicomAttributesRelations)
            setDicomAttributes(props.constraints.dicomAttributesRelations.map(dicomAttribute => {
                return {
                    value: dicomAttribute.id,
                    label: dicomAttribute.name
                }
            }))
            setActions(props.constraints.actions.map(action => {
                return {
                    value: action.id,
                    label: action.name
                }
            }))
            setSeverities(props.constraints.severities.map(severity => {
                return {
                    value: severity.id,
                    label: severity.name
                }
            }))
        }
    }, [props.constraints])

    useEffect(() => {
        register({ name: 'severityId' }, { required: true })
        register({ name: 'relationId' }, { required: true })
        register({ name: 'dicomAttributeId' })
        register({ name: 'operand1' })
        register({ name: 'operand2' })
        register({ name: 'actionId' })

        return (): void => {
            setContraints(null)
            unregister('severityId')
            unregister('relationId')
            unregister('dicomAttributeId')
            unregister('operand1')
            unregister('operand2')
            unregister('actionId')
        }
    }, [register, unregister])

    return (
        <React.Fragment>
            {
                props.protocol &&
                <div className="pt-4 px-8">
                    <div>
                        <div className="flex">
                            <h3 className="font-bold my-0">{props.protocol.name}</h3>
                            {
                                props.loggedUser?.acl.includes('PROTOCOL_MODELS_UPDATE') &&
                                <CustomButton
                                    cssStyles={[classes.button]}
                                    className="bg-transparent text-primary pr-0 flex items-baseline ml-auto"
                                    label={intl.formatMessage({ id: 'protocolsVault.detail.editProtocolButton.label' })}
                                    iconPosition="right"
                                    Icon={EditIcon}
                                    onClick={handleEdit}></CustomButton>
                            }
                            {
                                props.loggedUser?.acl.includes('PROTOCOL_MODELS_CREATE') &&
                                <CustomButton
                                    cssStyles={[classes.button]}
                                    className="bg-transparent text-primary pr-0 flex items-baseline ml-4"
                                    label={intl.formatMessage({ id: 'protocolsVault.detail.duplicateProtocolButton.label' })}
                                    iconPosition="right"
                                    Icon={DuplicateIcon}
                                    onClick={handleDuplicate}></CustomButton>
                            }
                            {
                                props.loggedUser?.acl.includes('PROTOCOL_MODELS_DELETE') &&
                                <CustomButton
                                    cssStyles={[classes.button]}
                                    className="bg-transparent text-red pr-0 flex items-baseline ml-4"
                                    label={intl.formatMessage({ id: 'protocolsVault.detail.deleteProtocolButton.label' })}
                                    iconPosition="right"
                                    Icon={DeleteRedIcon}
                                    onClick={handleDelete}></CustomButton>
                            }
                        </div>

                        <div className="flex items-end mt-4">
                            <div className="text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center uppercase leading-6 px-4 py-1 mr-4 select-none">{props.protocol.name}</div>
                            <div className="text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center uppercase leading-6 px-4 py-1 mr-4 select-none">{props.protocol.customerName}</div>
                            {
                                props.loggedUser?.acl.includes('PROTOCOL_MODELS_UPDATE') &&
                                <CustomButton
                                    className="bg-primary text-white px-6 ml-auto"
                                    label={intl.formatMessage({ id: 'protocolsVault.detail.addRuleButton.label' })}
                                    onClick={handleNewRequirement}></CustomButton>
                            }
                        </div>

                        <form>
                            <CustomTable
                                key="test"
                                className="mt-6"
                                cssStyles={[classes.table]}
                                data={orderedRequirements}
                                noDataMessage={intl.formatMessage({ id: 'protocolsVault.detail.table.noDataText' })}
                                stripedRows={true}
                                columns={columns}
                                hiddenColumns={!props.loggedUser?.acl.includes('PROTOCOL_MODELS_UPDATE') ? ['actions'] : []}
                                showPagination={false}
                                showRightBorderForColumns={['definition', 'operand1']}
                                editedRow={selectedRequirement}
                                editedRowTemplate={editedRowTemplate}
                                handleSort={handleSort}></CustomTable>
                        </form>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; protocol: ProtocolDetail | null; page: number | null; limit: number | null; constraints: Constraints | null; search: string; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    protocol: state.protocols.currentProtocol,
    page: state.ui.page,
    limit: state.ui.limit,
    constraints: state.protocols.constraints,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(ProtocolsDetail)
