import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { fetchConstraints, fetchCopyOptions, fetchDeleteEquipmentProtocolRequirement, fetchEquipmentProtocols, setContraints, setCopyOptions, setCurrentProtocol, setProtocols } from '../../../reducers/equipment-detail'
import { DashboardProtocolsProperties } from '../../../types/component-properties/dashboard-protocols-properties'
import { State } from '../../../types/states/state'
import CustomConfirmDelete from '../../custom/custom-confim-delete'
import DashboardProtocolsList from './dashboard-protocols-list'
import DashboardProtocolsCreator from './dashboard-protocols-creator'
import DashboardProtocolsRequirements from './dashboard-protocols-requirements'
import { intl } from '../../../intl'
import { NewProtocolInstance } from '../../../types/new-protocol-instance'

const DashboardProtocols = ({ setOrderBy, setOrderType, ...props }: DashboardProtocolsProperties): ReactElement => {
    const dispatch = useDispatch()

    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})
    const [customerId, setCustomerId] = useState<string>()
    const [newProtocol] = useState<NewProtocolInstance>({ name: '', equipmentId: '', testObjectId: '' })

    const equipment = useSelector((state: State) => state.dashboard.currentEquipment)

    const loadProtocols = useCallback(() => {
        if (equipment) {
            dispatch(fetchEquipmentProtocols({ equipmentId: equipment.id, orderBy: props.orderBy, orderType: props.orderType, ext: 'true' }))
        }
    }, [dispatch, equipment, props.orderBy, props.orderType])

    const loadCopyOptions = useCallback(() => {
        if (equipment) {
            dispatch(fetchCopyOptions({ equipmentId: equipment.id }))
        }
    }, [dispatch, equipment])

    function handleDeleteProtocolRequirement(protocolId: string, requirementId: string): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete'}),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteEquipmentProtocolRequirement(protocolId, requirementId, intl.formatMessage({ id: 'dashboard.toast.success.protocolRequirementDeleted' })))
            }
        })
    }

    useEffect(() => {
        loadProtocols()
    }, [loadProtocols])

    useEffect(() => {
        loadCopyOptions()
    }, [loadCopyOptions])

    useEffect(() => {
        if (equipment && !customerId) {
            setCustomerId(equipment.customerId)
            dispatch(fetchConstraints({ customerId: equipment.customerId }))
        }
     }, [dispatch, equipment, customerId])

    useEffect(() => {
        return (): void => {
            dispatch(setProtocols([]))
            dispatch(setCopyOptions([]))
            dispatch(setCurrentProtocol(null))
            dispatch(setContraints(null))
            setOrderBy(undefined)
            setOrderType(undefined)
        }
    }, [dispatch, setOrderBy, setOrderType])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/dashboard/equipment-detail/new-protocol'>
                    <DashboardProtocolsCreator
                        protocol={newProtocol}
                        handleCreate={props.handleCreateProtocol}></DashboardProtocolsCreator>
                </Route>
                <Route exact path='/dashboard/equipment-detail'>
                    <DashboardProtocolsList
                        orderBy={props.orderBy}
                        orderType={props.orderType}
                        setOrderBy={setOrderBy}
                        setOrderType={setOrderType}
                        handleEditProtocol={props.handleEditProtocol}
                        handleDeleteProtocol={props.handleDeleteProtocol}></DashboardProtocolsList>
                </Route>
                <Route exact path='/dashboard/equipment-detail/requirements'>
                    <DashboardProtocolsRequirements
                        setModal={setModal}
                        setModalInfo={setModalInfo}
                        handleFilter={props.handleFilter}
                        handleDeleteProtocolRequirement={handleDeleteProtocolRequirement}></DashboardProtocolsRequirements>
                </Route>
            </Switch>
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

export default DashboardProtocols