import React, { ReactElement, BaseSyntheticEvent, OptionHTMLAttributes, useEffect, useState, useRef } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import * as yup from 'yup'
import Footer from '../footer'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import { fetchCustomerOptions, setCustomerOptions } from '../../reducers/customers'
import { ProtocolsCreatorProperties } from '../../types/component-properties/protocols-creator-properties'
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import { Constraints } from '../../types/constraints'
import { fetchConstraints } from '../../reducers/protocols'
import { useDebounce } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const protocolSchema = yup.object().shape({
    name: yup.string().required('protocolsVault.creator.form.input.errorMessage.required.name'),
    testObjectId: yup.string().required('protocolsVault.creator.form.select.errorMessage.required.testObject'),
    customerId: yup.string().required('protocolsVault.creator.form.select.errorMessage.required.organisation'),
    // images: yup.array()
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const ProtocolsCreator = (props: ProtocolsCreatorProperties): ReactElement => {
    const dispatch = useDispatch()
    const debounce = useDebounce('', 'protocols', 10, [])
    const isFirstRun = useRef(true)

    const [customerId, setCustomerId] = useState<string | undefined>()
    const [testObjectId, setTestObjectId] = useState<string | undefined>()
    // const [images, setImages] = useState<File[]>()
    const { register, unregister, errors, setValue, triggerValidation, handleSubmit } = useForm({
        validationSchema: protocolSchema
    })

    function submit(protocol: Record<string, any>): void {
        const data = new FormData()
        data.append('name', protocol.name)
        data.append('customerId', protocol.customerId)
        data.append('testObjectId', protocol.testObjectId)
        // protocol.images && protocol.images.forEach((image: File) => {
        //     data.append('images[', image)
        // })

        props.handleCreateProtocol(data)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleUpdateTestObject(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('testObjectId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setTestObjectId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'testObjectId' })
    }

    function handleUpdateCustomer(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('customerId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setCustomerId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'customerId' })
    }

    // function handleUpdateImages(files: File[]): void {
    //     if (files) {
    //         setImages(files)
    //         setValue('images', files)
    //         triggerValidation({ name: 'images' })
    //     }
    // }

    function handleNavigate(): void {
        props.handleNavigate(props.paths.length - 1)
    }

    function resetProtocol(): void {
        setValue('name', null)
        setValue('testObjectId', null)
        setTestObjectId(undefined)
        setValue('customerId', null)
        setCustomerId(undefined)
        setValue('images', null)
        // setImages([])
    }

    useEffect(() => {
        if (customerId) {
            dispatch(fetchConstraints({ customerId }))
            setValue('testObjectId', '')
            setTestObjectId(undefined)
        }
    }, [dispatch, setValue, customerId])

    useEffect(() => {
        // register({ name: 'images' })

        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            register({ name: 'customerId' })
            setValue('customerId', props.loggedUser?.customerId)
            setCustomerId(props.loggedUser?.customerId)
        }

        return (): void => {
            // unregister('images')
            dispatch(setCustomerOptions([]))
        }
    }, [dispatch, register, unregister, setValue, props.loggedUser])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (props.loggedUser?.role === 'ADMINISTRATOR') {
            dispatch(fetchCustomerOptions())
        }
    }, [dispatch, props.loggedUser, debounce])

    return (
        <React.Fragment>
            <div key={props.protocol.name} className="pt-4 px-8">
                <CustomButton
                    Icon={BackArrowIcon}
                    cssStyles={[classes["back-button"]]}
                    className="bg-lightblue text-primary min-w-40 font-bold px-6 mb-5"
                    label={intl.formatMessage({ id: 'protocolsVault.creator.backButton.label' })}
                    onClick={handleNavigate} />

                <form className="min-w-60" autoComplete="off">
                    <CustomInput
                        className="max-w-60"
                        name="name"
                        label={intl.formatMessage({ id: 'protocolsVault.creator.form.input.label.name' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'protocolsVault.creator.form.input.placeholder.name' })}...`}
                        defaultValue={props.protocol.name} />
                    {
                        props.loggedUser?.role === 'ADMINISTRATOR' &&
                        <CustomSelect
                            className="max-w-60"
                            options={props.customerOptions}
                            name="customerId"
                            label={intl.formatMessage({ id: 'protocolsVault.creator.form.select.label.organisation' }).toUpperCase()}
                            selectRef={register({ name: `customerId` })}
                            errorMessage={errors.customerId ? intl.formatMessage({ id: errors.customerId.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'protocolsVault.creator.form.select.placeholder.organisation' })}...`}
                            value={customerId}
                            onChange={handleUpdateCustomer} />
                    }
                    <CustomSelect
                        className="max-w-60"
                        options={props.constraints?.testObjects.map(testObject => {
                            return {
                                value: testObject.id,
                                label: testObject.name
                            }
                        })}
                        name="testObjectId"
                        label={intl.formatMessage({ id: 'protocolsVault.creator.form.select.label.testObject' }).toUpperCase()}
                        selectRef={register({ name: `testObjectId` })}
                        errorMessage={errors.testObjectId ? intl.formatMessage({ id: errors.testObjectId.message }) : undefined}
                        required={true}
                        disabled={!(customerId || props.loggedUser?.role !== 'ADMINISTRATOR')}
                        placeholder={`${intl.formatMessage({ id: 'protocolsVault.creator.form.select.placeholder.testObject' })}...`}
                        value={testObjectId}
                        onChange={handleUpdateTestObject} />
                    {/* <CustomFile
                        className="max-w-100"
                        name="images"
                        files={images}
                        label={intl.formatMessage({ id: 'protocolsVault.creator.form.inputFile.label.images' }).toUpperCase()}
                        errorMessage={errors.images ? intl.formatMessage({ id: errors.images.message }) : undefined}
                        multiple={true}
                        onChange={handleUpdateImages} /> */}
                </form>
            </div>
            <Footer
                item={props.protocol}
                editing={false}
                handleCancel={resetProtocol}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}


const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; constraints: Constraints | null; customerOptions: OptionsType<OptionTypeBase>; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    constraints: state.protocols.constraints,
    customerOptions: state.customers.customerOptions,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(ProtocolsCreator)
