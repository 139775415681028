import React, { ReactElement, BaseSyntheticEvent, OptionHTMLAttributes, useEffect, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import * as yup from 'yup'
import Footer from '../footer'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType } from 'react-select'
import { ProtocolsEditorProperties } from '../../types/component-properties/protocols-editor-properties'

import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import { ProtocolDetail } from '../../types/entity/protocol-detail'
import { Constraints } from '../../types/constraints'
import { EditProtocol } from '../../types/edit-protocol'
import { LoggedUser } from '../../types/entity/logged-user'
import { LocationDescriptorObject } from 'history'

const protocolSchema = yup.object().shape({
    name: yup.string().required('protocolsVault.editor.form.input.errorMessage.required.name'),
    testObjectId: yup.string().required('protocolsVault.editor.form.select.errorMessage.required.testObject')
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 8px;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const ProtocolsEditor = (props: ProtocolsEditorProperties): ReactElement => {
    const [testObjectId, setTestObjectId] = useState<string | undefined>()
    const { register, unregister, errors, setValue, triggerValidation, handleSubmit } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        validationSchema: protocolSchema
    })

    function submit(protocol: Record<string, any>): void {
        props.handleEditProtocol(protocol as EditProtocol)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleUpdateTestObject(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('testObjectId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        setTestObjectId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'testObjectId' })
    }

    function handleNavigate(): void {
        props.handleFilter({
            label: props.protocol?.name as string,
            id: props.protocol?.id as string,
            destination: {
                pathname: `/protocols/detail`,
                search: `?id=${props.protocol?.id}`
            },
            param: null
        })
    }

    function resetProtocol(): void {
        if (props.protocol) {
            setValue('name', props.protocol.name)
            setValue('medicalCentreId', props.protocol.testObjectId)
            setTestObjectId(props.protocol.testObjectId)
        }
    }

    useEffect(() => {
        register({ name: 'testObjectId' })

        if (props.protocol) {
            setValue('testObjectId', props.protocol.testObjectId)
            setTestObjectId(props.protocol.testObjectId)
        }

        return (): void => {
            unregister('testObjectId')
        }
    }, [register, unregister, setValue, props.protocol])

    return (
        <React.Fragment>
            <div key={props.protocol?.name} className="pt-4 px-8">
                <CustomButton
                    Icon={BackArrowIcon}
                    cssStyles={[classes["back-button"]]}
                    className="bg-lightblue text-primary min-w-40 font-bold px-6 mb-5"
                    label={intl.formatMessage({ id: 'protocolsVault.editor.backButton.label' })}
                    onClick={handleNavigate} />

                {
                    props.protocol && props.constraints?.testObjects &&
                    <form key={`form-${props.protocol?.name}`} className="min-w-60 max-w-60">
                        <CustomInput 
                            name="name"
                            label={intl.formatMessage({ id: 'protocolsVault.editor.form.input.label.name' })}
                            formRef={register}
                            errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'protocolsVault.editor.form.input.placeholder.name' })}...`}
                            defaultValue={props.protocol.name} />
                        <CustomSelect
                            options={props.constraints?.testObjects.map(testObject => {
                                return {
                                    value: testObject.id,
                                    label: testObject.name
                                }
                            })}
                            name="testObjectId"
                            label={intl.formatMessage({ id: 'protocolsVault.editor.form.select.label.testObject' }).toUpperCase()}
                            selectRef={register({ name: `testObjectId` })}
                            placeholder={`${intl.formatMessage({ id: 'protocolsVault.editor.form.select.placeholder.testObject' })}...`}
                            errorMessage={errors.testObjectId ? intl.formatMessage({ id: errors.testObjectId.message }) : undefined}
                            required={true}
                            value={testObjectId}
                            onChange={handleUpdateTestObject} />
                    </form>
                }
            </div>
            <Footer
                item={props.protocol as ProtocolDetail}
                editing={true}
                canDelete={props.loggedUser?.acl.includes('PROTOCOL_MODELS_DELETE')}
                handleDelete={props.handleDelete}
                handleCancel={resetProtocol}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { protocol: ProtocolDetail | null; location: LocationDescriptorObject; paths: BreadcrumbPath[]; constraints: Constraints | null; loggedUser: LoggedUser | null } => ({
    location: state.router.location,
    paths: state.ui.breadcrumbPaths,
    constraints: state.protocols.constraints,
    protocol: state.protocols.currentProtocol,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(ProtocolsEditor)