import React, { ReactElement, useEffect, BaseSyntheticEvent, OptionHTMLAttributes, useState, useRef } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { useDispatch, connect } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import * as yup from 'yup'
import Footer from '../footer'
import { fetchMedicalCentreOptions, setMedicalCentreOptions } from '../../reducers/medical-centres'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import { EquipmentsCreatorProperties } from '../../types/component-properties/equipments-creator-properties'

import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import { useDebounce } from '../../common/utils'

const equipmentSchema = yup.object().shape({
    name: yup.string().required('equipments.creator.form.input.errorMessage.required.name'),
    medicalCentreId: yup.string().required('equipments.creator.form.select.errorMessage.required.medicalCentre'),
    email: yup.string(),
    phone: yup.string(),
    // photo: yup.string()
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const EquipmentsCreator = (props: EquipmentsCreatorProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const [medicalCentreId, setMedicalCentreId] = useState('')
    const debounce = useDebounce('', 'equipments', 10, [])
    const isFirstRun = useRef(true)
    // const [file, setFile] = useState<File>()

    const { register, setValue, errors, triggerValidation, handleSubmit } = useForm({
        validationSchema: equipmentSchema
    })

    function submit(equipment: Record<string, any>): void {
        const data = new FormData()
        data.append('name', equipment.name)
        data.append('medicalCentreId', equipment.medicalCentreId)
        if (equipment.email) {
            data.append('email', equipment.email)
        }
        if (equipment.phone) {
            data.append('phone', equipment.phone)
        }
        // if (equipment.photo && equipment.photo !== '') {
        //     data.append('photo', file as Blob)
        // }
        props.handleCreate(data)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleUpdateMedicalCentre(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('medicalCentreId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'medicalCentreId' })
        setMedicalCentreId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleNavigate(): void {
        props.handleNavigate(0)
    }

    function resetEquipment(): void {
        setValue('name', '')
        setValue('medicalCentreId', '')
        setMedicalCentreId('')
        setValue('email', '')
        setValue('phone', '')
    }

    useEffect(() => {
        return (): void => {
            dispatch(setMedicalCentreOptions([]))
        }
    }, [dispatch])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        dispatch(fetchMedicalCentreOptions())
    }, [dispatch, debounce])

    return (
        <React.Fragment>
            <div key={props.equipment.name} className="pt-4 px-8">
                <CustomButton
                    Icon={BackArrowIcon}
                    cssStyles={[classes["back-button"]]}
                    className="bg-lightblue text-primary min-w-40 font-bold px-6 mb-5"
                    label={intl.formatMessage({ id: 'equipments.creator.backButton.label' })}
                    onClick={handleNavigate} />
                
                <form className="min-w-60" autoComplete="off">
                    <CustomInput
                        className="max-w-60"
                        name="name"
                        label={intl.formatMessage({ id: 'equipments.creator.form.input.label.name' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'equipments.creator.form.input.placeholder.name' })}...`}
                        defaultValue={props.equipment.name} />
                    <CustomSelect
                        className="max-w-60"
                        options={props.medicalCentreOptions}
                        name="medicalCentreId"
                        label={intl.formatMessage({ id: 'equipments.creator.form.select.label.medicalCentre' }).toUpperCase()}
                        selectRef={register({ name: `medicalCentreId` })}
                        errorMessage={errors.medicalCentreId ? intl.formatMessage({ id: errors.medicalCentreId.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'equipments.creator.form.select.placeholder.medicalCentre' })}...`}
                        value={medicalCentreId}
                        onChange={handleUpdateMedicalCentre} />
                    <CustomInput
                        className="max-w-60"
                        name="email"
                        label={intl.formatMessage({ id: 'equipments.creator.form.input.label.email' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'equipments.creator.form.input.placeholder.email' })}...`}
                        defaultValue={props.equipment.email} />
                    <CustomInput
                        className="max-w-60"
                        name="phone"
                        label={intl.formatMessage({ id: 'equipments.creator.form.input.label.phone' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'equipments.creator.form.input.placeholder.phone' })}...`}
                        defaultValue={props.equipment.phone} />
                </form>
            </div>
            <Footer
                item={props.equipment}
                editing={false}
                handleCancel={resetEquipment}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; medicalCentreOptions: OptionsType<OptionTypeBase> } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    medicalCentreOptions: state.medicalCentres.medicalCentreOptions
})

export default connect(mapStateToProps)(EquipmentsCreator)
