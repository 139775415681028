import React, { useState, ReactElement, PropsWithChildren, useEffect, useMemo } from 'react'
import CustomTable from '../custom/custom-table/custom-table'
import PropTypes from 'prop-types'
import { MedicalCentresListProperties } from '../../types/component-properties/medical-centres-list-properties'
import { CellProps } from 'react-table'
import { MedicalCentre } from '../../types/entity/medical-centre'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'

import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as EquipmentsIcon } from '../../assets/icons/equipments.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/column-delete.svg'
import ActionsCell from '../custom/custom-table/actions-cell'
import { ActionType } from '../../types/action-type'
import { intl } from '../../intl'
import { RequestQueryParams } from '../../types/request-query-params'
import { SectionName } from '../../types/section-name'
import { LoggedUser } from '../../types/entity/logged-user'
import MultiRow from '../custom/custom-table/multi-row'

const MedicalCentresList = ({ handleAction, ...props}: MedicalCentresListProperties): ReactElement => {
    const [actions] = useState<ActionType[]>([
        { type: 'Edit', Icon: EditIcon },
        { type: 'Equipments', Icon: EquipmentsIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])
    const [filteredActions, setFilteredActions] = useState<ActionType[]>([
        { type: 'Edit', Icon: EditIcon },
        { type: 'Equipments', Icon: EquipmentsIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])

    const columns = useMemo(() => [
        { id: 'name', Header: 'medicalCentres.list.table.header.name', canGroupBy: false, accessor: 'name', width: "" },
        { id: 'customerName', Header: 'medicalCentres.list.table.header.organisation', canGroupBy: false, accessor: 'customerName', width: "" },
        { id: 'totalEquipments', Header: 'medicalCentres.list.table.header.equipmentsActiveTotal', canGroupBy: false, accessor: 'totalEquipments', width: "", disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<MedicalCentre>>): ReactElement {
            return <span>{rowProps.row.original.activeEquipments} / {rowProps.row.original.totalEquipments}</span>
        } },
        { id: 'usagesTestObject', Header: 'medicalCentres.list.table.header.testObject', canGroupBy: false, disableSortBy: true, accessor: 'usages', width: "", Cell: function setColumn(rowProps: PropsWithChildren<CellProps<MedicalCentre>>): ReactElement {
            return <MultiRow row={rowProps.row.original} fieldKey='usagesTestObject'></MultiRow>
        } },
        { id: 'usagesActiveTotal', Header: 'medicalCentres.list.table.header.activeTotal', canGroupBy: false, disableSortBy: true, accessor: 'usages', width: "", Cell: function setColumn(rowProps: PropsWithChildren<CellProps<MedicalCentre>>): ReactElement {
            return <MultiRow row={rowProps.row.original} fieldKey='usagesActiveTotal'></MultiRow>
        } },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 80, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<MedicalCentre>>): ReactElement {
            return <ActionsCell actions={filteredActions} row={rowProps.row.original} handleAction={handleAction}></ActionsCell>
        } },
    ], [handleAction, filteredActions])

    useEffect(() => {
        setFilteredActions((): ActionType[] => {
            return actions.filter(action => {
                switch(action.type) {
                    case 'Delete':
                        return props.loggedUser?.acl.includes('MEDICAL_CENTRES_DELETE')
                    case 'Edit':
                        return props.loggedUser?.acl.includes('MEDICAL_CENTRES_UPDATE')
                    default:
                        return true
                }
            })
        })
    }, [props.loggedUser, actions])

    return (
        <div className="h-full w-full pt-4 px-8">
            <CustomTable
                data={props.medicalCentres}
                totalData={props.size}
                columns={columns}
                hiddenColumns={props.loggedUser?.role !== 'ADMINISTRATOR' ? ['customerName'] : []}
                stripedRows={true}
                currentPage={props.page ?? 0}
                currentPageSize={props.limit ?? 10}
                noDataMessage={intl.formatMessage({ id: 'medicalCentres.list.table.noDataText' })}
                handlePageChange={props.handlePageChange}
                handlePageSizeChange={props.handleLimitChange}
                handleSort={props.handleSort}></CustomTable>
        </div>
    )
}

MedicalCentresList.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    handleAction: PropTypes.func.isRequired
}

const mapStateToProps = (state: State): { medicalCentres: MedicalCentre[]; size: number; page: number | null; limit: number | null; queryParams: RequestQueryParams; currentSection: SectionName; loggedUser: LoggedUser | null }  => ({
    medicalCentres: state.medicalCentres.medicalCentres,
    size: state.medicalCentres.medicalCentresSize,
    page: state.ui.page,
    limit: state.ui.limit,
    queryParams: state.ui.sectionQueryParams,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(MedicalCentresList)