import React, { ReactElement, useEffect, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomCheckboxProperties } from '../../types/component-properties/custom/custom-checkbox-properties'
import classNames from 'classnames'

const classes = {
    'checkbox': css`
        .new-checkbox {
            border: 1px solid var(--color-gray-100) !important;
        }
        
        .new-checkbox:hover:not(.checkbox-disabled) {
            border-color: var(--color-primary) !important;
        }
        
        .container .old-checkbox:checked ~ .new-checkbox {
            background-color: var(--color-primary) !important;
            border-color: var(--color-primary) !important;
        }

        .container .old-checkbox:checked ~ .checkbox-disabled {
            background-color: var(--color-gray) !important;
            border-color: var(--color-gray) !important;
        }
        
        .new-checkbox:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        .container .old-checkbox:checked ~ .new-checkbox:after {
            display: block;
        }
        
        .container .new-checkbox:after {
            left: 0.3rem;
            top: 0.1rem;
            width: 0.19rem;
            height: 0.4rem;
            border: solid white;
            border-width: 0 0.094rem 0.094rem 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    `
}

const CustomCheckbox = (props: CustomCheckboxProperties): ReactElement => {
    const [checked, setChecked] = useState<boolean>(props.checked)

    function check(event: React.MouseEvent): void {
        if (!props.disabled) {
            event.stopPropagation()
            setChecked(!checked)
            props.onClick(!checked)
        }
    }

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    return (
        <React.Fragment>
            <div css={[classes.checkbox, props.cssStyles]} className={`min-h-5 max-h-5 text-sm ${props.className}`}>
                <div className={classNames({
                    "container flex items-center relative pl-6 min-h-5 h-full select-none": true,
                    "text-gray-100": props.disabled
                })}>
                    {props.label}
                    <input className="old-checkbox absolute opacity-0 w-0 h-0" type="checkbox" checked={checked} onChange={(): void => undefined} />
                    <span className={classNames({
                        'new-checkbox absolute left-0 w-4 h-4 bg-transparent rounded': true,
                        'cursor-pointer': !props.disabled,
                        'checkbox-disabled': props.disabled
                    })} onClick={check}></span>
                </div>
            </div>
        </React.Fragment>
    )
}

CustomCheckbox.defaultProps = {
    className: '',
    cssStyles: [css``],
    checked: false,
    disabled: false,
    label: '',
    onClick: (): void => undefined
}

export default CustomCheckbox
