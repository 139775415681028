import React, { ReactElement, BaseSyntheticEvent } from 'react'
import useForm from 'react-hook-form'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'
import * as yup from 'yup'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import Footer from '../footer'
import { NewCustomer } from '../../types/new-customer'
import { CustomersCreatorProperties } from '../../types/component-properties/customers-creator-properties'

const customerSchema = yup.object().shape({
    name: yup.string().required('organisations.creator.form.input.errorMessage.required.name'),
    email: yup.string(),
    phone: yup.string()
})

const CustomersCreator = (props: CustomersCreatorProperties): ReactElement => {
    const { register, errors, handleSubmit, setValue } = useForm({
        validationSchema: customerSchema
    })

    function submit(customer: Record<string, any>): void {
        props.handleCreate(customer as NewCustomer)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function resetCustomer(): void {
        setValue('name', '')
        setValue('email', '')
        setValue('phone', '')
    }

    return (
        <React.Fragment>
            <div className="pt-4 px-8">
                <form className="min-w-60 max-w-60" autoComplete="off">
                    <CustomInput
                        className="w-full"
                        name="name"
                        label={intl.formatMessage({ id: 'organisations.creator.form.input.label.name' })}
                        formRef={register}
                        errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'organisations.creator.form.input.placeholder.name' })}...`}
                        defaultValue={props.customer.name} />
                    <CustomInput
                        className="w-full"
                        name="email"
                        label={intl.formatMessage({ id: 'organisations.creator.form.input.label.email' })}
                        formRef={register}
                        errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'organisations.creator.form.input.placeholder.email' })}...`}
                        defaultValue={props.customer.email ?? ''} />
                    <CustomInput
                        className="w-full"
                        name="phone"
                        label={intl.formatMessage({ id: 'organisations.creator.form.input.label.phone' })}
                        formRef={register}
                        errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'organisations.creator.form.input.placeholder.phone' })}...`}
                        defaultValue={props.customer.phone ?? ''} />
                </form>
            </div>
            <Footer
                item={props.customer}
                editing={false}
                handleCancel={resetCustomer}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[] } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths
})

export default connect(mapStateToProps)(CustomersCreator)