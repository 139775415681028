import React, { ReactElement, useEffect } from 'react'
import { useFetching } from '../../../common/utils'
import queryString from 'query-string'
import { State } from '../../../types/states/state'
import { fetchResultsData, setResultsData } from '../../../reducers/timeline-event-detail'
import { connect, useDispatch } from 'react-redux'
import { DashboardTimelineMessageProperties } from '../../../types/component-properties/dashboard-timeline-message-properties'
import { MessageResultsData } from '../../../types/entity/message-results-data'
import CustomInput from '../../custom/custom-input'
import { intl } from '../../../intl'
import { DateTime } from 'luxon'
import CustomTextarea from '../../custom/custom-textarea'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { EditMessage } from '../../../types/edit-message'
import { Locale } from '../../../types/locale'

const messageSchema = yup.object().shape({
    title: yup.string().required('Insert a title'),
    content: yup.string(),
})

const DashboardTimelineMessage = (props: DashboardTimelineMessageProperties): ReactElement => {
    
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const { register, errors, handleSubmit, setValue, getValues } = useForm({
        validationSchema: messageSchema
    })

    function submit(message: Record<string, any>): void {
        props.setMessage(message as EditMessage)
    }

    function handleTitleChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        props.setMessage({ ...getValues() as EditMessage, title: event.target.value })
    }

    function handleContentChanged(text: string): void {
        props.setMessage({ ...getValues() as EditMessage, content: text })
    }

    useFetching(fetchResultsData, 'dashboard', [], queryParams.id, queryParams.type)

    useEffect(() => {
        setValue('title', props.message.title)
        setValue('content', props.message.content)
    }, [setValue, props.message])

    useEffect(() => {
        return (): void => {
            dispatch(setResultsData(null))
        }
    }, [dispatch])

    return (
        <div className={`min-w-100 pl-8 pt-8 ${props.className}`}>
            {
                props.resultsData &&
                <React.Fragment>
                    <div className="mb-2 text-sm">
                        <span>{intl.formatMessage({ id: 'dashboard.message.lastModified' })}: {props.resultsData.author}</span>,&nbsp;
                        <span className="text-gray">{props.resultsData.lastModified ? DateTime.fromMillis(props.resultsData.lastModified).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  }) : ''}</span>
                    </div>
                    <form onSubmit={handleSubmit(submit)} autoComplete="off">
                        <CustomInput
                            className="my-2"
                            name="title"
                            label={intl.formatMessage({ id: 'dashboard.message.form.input.label.title' })}
                            formRef={register}
                            errorMessage={errors.title ? intl.formatMessage({ id: errors.title.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'dashboard.message.form.input.placeholder.title' })}...`}
                            defaultValue={props.resultsData.title}
                            onChange={handleTitleChanged} />
                        <CustomTextarea
                            className="my-2"
                            name="content"
                            label={intl.formatMessage({ id: 'dashboard.message.form.textarea.label.messageText' })}
                            formRef={register}
                            placeholder={`${intl.formatMessage({ id: 'dashboard.message.form.textarea.label.messageText' })}...`}
                            value={props.resultsData.content}
                            rows={8}
                            onChange={handleContentChanged} />
                    </form>
                </React.Fragment>
            }
        </div>
    )
}

DashboardTimelineMessage.defaultProps = {
    className: ''
}

const mapStateToProps = (state: State): { resultsData: MessageResultsData | null; search: string; locale: Locale } => ({
    resultsData: state.timelineEventDetail.resultsData as MessageResultsData | null,
    search: state.router.location.search,
    locale: state.ui.locale
})

export default connect(mapStateToProps)(DashboardTimelineMessage)