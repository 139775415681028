import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Accordion } from 'react-accessible-accordion'
import { CustomAccordionProperties } from '../../types/component-properties/custom/custom-accordion-properties'

const classes = {
    'accordion': css`
        .accordion__button {
            display: flex;
            align-items: center;
            outline: none;
            background: var(--color-primary);
            color: var(--color-white);
            padding: 0.6rem 1rem;
            font-weight: bold;
            font-size: 0.9rem;
            position: relative;

            ::before {
                position: absolute;
                right: 0.75rem;
                height: 0.625rem;
                width: 0.625rem;
                border-bottom: 2px solid var(--color-white);
                transform: rotate(-45deg);
                transition: transform 0.1s;
            }
        }

        .accordion__button[aria-expanded="true"] {
            ::before {
                transform: rotate(45deg);
            }
        }

        .accordion__panel {
            border: 1px solid var(--color-gray-100);
            border-top: none;
        }
    `
}

const CustomAccordion = (props: CustomAccordionProperties): ReactElement => {
    return (
        <React.Fragment>
            <Accordion
                css={[classes.accordion, props.cssStyles]}
                className={props.className}
                allowZeroExpanded={props.allowZeroExpanded}
                allowMultipleExpanded={props.allowMultipleExpanded}
                preExpanded={props.preExpanded}
            >
                {
                    props.children &&
                    props.children.map(child => child)
                }
            </Accordion>
        </React.Fragment>
    )
}

CustomAccordion.defaultProps = {
    className: '',
    cssStyles: [css``],
    children: [],
    allowZeroExpanded: true,
    allowMultipleExpanded: true,
    preExpanded: []
}

export default CustomAccordion