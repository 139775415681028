import React, { ReactElement, useEffect, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomTabs from '../../custom/custom-tabs'
import { intl } from '../../../intl'
import DashboardTimelineResults from './dashboard-timeline-results'
import DashboardTimelineSymptoms from './dashboard-timeline-symptoms'
import DashboardTimelineProtocol from './dashboard-timeline-protocol'
import DashboardTimelineComments from './dashboard-timeline-comments'
import { useDispatch, connect, useSelector } from 'react-redux'
import CustomButton from '../../custom/custom-button'
import { Tab, TabList, TabPanel } from 'react-tabs'
import { DashboardTimelineDetailProperties } from '../../../types/component-properties/dashboard-timeline-detail-properties'
import { decrementCounter, incrementCounter, toggleSidemenu, updatePathDestination } from '../../../reducers/ui'
import { State } from '../../../types/states/state'
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/back-arrow.svg'
import { TimelineEvent } from '../../../types/entity/timeline-event'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import { setCurrentTimelineEvent, fetchTimelineEvent, setTimelineFilterPage, fetchTimelineEventsData } from '../../../reducers/equipment-detail'
import { LocationDescriptorObject } from 'history'
import { LoggedUser } from '../../../types/entity/logged-user'
import { deepCopy, useFetching } from '../../../common/utils'
import DashboardTimelineStored from './dashboard-timeline-stored'
import DashboardTimelineMessage from './dashboard-timeline-message'
import classNames from 'classnames'
import Footer from '../../footer'
import { EditMessage } from '../../../types/edit-message'
import { TimelineEventResultsData } from '../../../types/entity/timeline-event-results-data'
import { MessageResultsData } from '../../../types/entity/message-results-data'
import { StoredResultsData } from '../../../types/entity/stored-results-data'
import { Equipment } from '../../../types/entity/equipment'
import { StoredData } from '../../../types/stored-data'
import DashboardTimelineDicomAttributes from './dashboard-timeline-dicom-attributes'
import { DateTime } from 'luxon'

const classes = {
    'container': css`
        .back, .react-tabs__tab-list {
            padding: 0 2rem;
        }
    `,
    'tabs': css`
        .react-tabs__tab-list {
            margin-bottom: 0;
        }

        .react-tabs__tab-panel {
            flex-grow: 1;
        }
    `,
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
    `
}

const DashboardTimelineDetail = (props: DashboardTimelineDetailProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)
    
    const [activeTab, setActiveTab] = useState(0)
    const [imageActiveTab, setImageActiveTab] = useState(0)
    const [storedData, setStoredData] = useState<StoredData>({ title: '', description: '', imageTagsIds: [] })
    const [message, setMessage] = useState<EditMessage>({ title: '', content: '' })
    const [isFirstEvent, setIsFirstEvent] = useState(false)
    const [isLastEvent, setIsLastEvent] = useState(false) 
    const [totalPages, setTotalPages] = useState(0)

    const locale = useSelector((state: State) => state.ui.locale)
    const search = useSelector((state: State) => state.router.location.search)
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)
    const timelineEventsSize = useSelector((state: State) => state.equipmentDetail.timelineEventsSize)

    function changeTab(index: number): void {
        const destination: LocationDescriptorObject = {
            pathname: `/dashboard/timeline-detail`,
            search: `?tab=${index}&type=${queryParams.type}&id=${props.timelineEvent?.id ?? ''}&equipmentId=${props.equipment?.id ?? ''}&page=${timelineFilter.page}`
        }

        setActiveTab(index)
        dispatch(push(destination))
        dispatch(updatePathDestination({ index: props.loggedUser?.role === 'ADMINISTRATOR' ? 3 : 2, destination }))
    }

    function changeImageTab(index: number): void {
        const destination: LocationDescriptorObject = {
            pathname: `/dashboard/timeline-detail`,
            search: `?tab=${index}&type=${queryParams.type}&id=${props.timelineEvent?.id ?? ''}&equipmentId=${props.equipment?.id ?? ''}&page=${timelineFilter.page}`
        }

        setImageActiveTab(index)
        dispatch(push(destination))
        dispatch(updatePathDestination({ index: props.loggedUser?.role === 'ADMINISTRATOR' ? 3 : 2, destination }))
    }

    function handleNavigate(): void {
        props.handleNavigate(props.loggedUser?.role === 'ADMINISTRATOR' ? 3 : 2)
    }

    function handleAddTag(imageTagsIds: string[], message: string): void {
        if (props.timelineEvent && props.timelineEvent.typeId === 'ANALYSED_IMAGE') {
            props.handleEditTimelineEvent(props.timelineEvent.id, { imageTagsIds }, '/analysed-images', message)
        }
    }

    function handleSubmit(): void {
        if (props.timelineEvent) {
            switch (props.timelineEvent.typeId) {
                case 'STORED_IMAGE':
                    props.handleEditTimelineEvent(props.timelineEvent.id, storedData, '/stored-images', intl.formatMessage({ id: 'dashboard.toast.success.storedImageUpdated' }))

                    break
                case 'STORED_FILES':
                    props.handleEditTimelineEvent(props.timelineEvent.id, storedData, '/stored-files', intl.formatMessage({ id: 'dashboard.toast.success.storedFileUpdated' }))

                    break
                case 'MESSAGE':
                    props.handleEditTimelineEvent(props.timelineEvent.id, message, '/messages', intl.formatMessage({ id: 'dashboard.toast.success.messageUpdated' }))
                    
                    break
            }
        }
    }

    function handleCancel(): void {
        if (props.resultsData) {
            switch (props.timelineEvent?.typeId) {
                case 'STORED_IMAGE':
                    setStoredData({ title: (props.resultsData as StoredResultsData)?.title, description: (props.resultsData as StoredResultsData).description, imageTagsIds: [] })

                    break
                case 'STORED_FILES':
                    setStoredData({ title: (props.resultsData as StoredResultsData)?.title, description: (props.resultsData as StoredResultsData).description })

                    break
                case 'MESSAGE':
                    setMessage({ title: (props.resultsData as MessageResultsData).title, content: (props.resultsData as MessageResultsData).content })

                    break
            }
        }
    }

    function handleDelete(): void {
        if (props.timelineEvent) {
            props.handleDeleteTimelineEvent(props.timelineEvent.id, true) 
        }
    }

    function handlePreviousEvent(): void {
        let previousIndex = props.timelineEvents.findIndex((timelineEvent) => timelineEvent.id === props.timelineEvent?.id)

        if (previousIndex !== -1) {
            previousIndex -= 1
            dispatch(incrementCounter())
            if (previousIndex === -1) {
                const page = timelineFilter.page
                fetchTimelineEventsData(props.equipment?.id ?? "", { ...timelineFilter, page: page - 1 }).then((res) => {
                    if (res.data.data.length > 0) {
                        const event: TimelineEvent = deepCopy(res.data.data[previousIndex !== -1 ? previousIndex : res.data.data.length - 1])
                        props.timelineNavigate(event && event.eventDate ? `${DateTime.fromMillis(event.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=${queryParams.tab}&type=${event.typeId}&id=${event.id}&equipmentId=${props.equipment?.id}&page=${timelineFilter.page}` }, false)
                    }
                    const splittedSearch = search.split("&page=")
                    dispatch(push({
                        pathname: "/dashboard/timeline-detail",
                        search: `${splittedSearch[0]}&page=${timelineFilter.page - 1}`
                    }))
                    dispatch(setTimelineFilterPage(timelineFilter.page - 1))
                }).finally(() => dispatch(decrementCounter()))
            } else {
                const event: TimelineEvent = deepCopy(props.timelineEvents[previousIndex])
                props.timelineNavigate(event && event.eventDate ? `${DateTime.fromMillis(event.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=${queryParams.tab}&type=${event.typeId}&id=${event.id}&equipmentId=${props.equipment?.id}&page=${timelineFilter.page}` }, false)
                dispatch(decrementCounter())
            }
        }
    }

    function handleNextEvent(): void {
        let nextIndex = props.timelineEvents.findIndex((timelineEvent) => timelineEvent.id === props.timelineEvent?.id)

        if (nextIndex !== -1) {
            nextIndex += 1
            dispatch(incrementCounter())
            if (props.timelineEvents.length <= nextIndex) {
                const page = timelineFilter.page
                fetchTimelineEventsData(props.equipment?.id ?? "", { ...timelineFilter, page: page + 1 }).then((res) => {
                    if (res.data.data.length > 0) {
                        const event: TimelineEvent = deepCopy(res.data.data[0])
                        props.timelineNavigate(event && event.eventDate ? `${DateTime.fromMillis(event.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=${queryParams.tab}&type=${event.typeId}&id=${event.id}&equipmentId=${props.equipment?.id}&page=${timelineFilter.page}` }, false)
                    }
                    const splittedSearch = search.split("&page=")
                    dispatch(push({
                        pathname: "/dashboard/timeline-detail",
                        search: `${splittedSearch[0]}&page=${timelineFilter.page + 1}`
                    }))
                    dispatch(setTimelineFilterPage(timelineFilter.page + 1))
                }).finally(() => dispatch(decrementCounter()))
            } else {
                const event: TimelineEvent = deepCopy(props.timelineEvents[nextIndex])
                props.timelineNavigate(event && event.eventDate ? `${DateTime.fromMillis(event.eventDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale, numberingSystem: undefined  })}` : '', { pathname: `/dashboard/timeline-detail`, search: `?tab=${queryParams.tab}&type=${event.typeId}&id=${event.id}&equipmentId=${props.equipment?.id}&page=${timelineFilter.page}` }, false)
                dispatch(decrementCounter())
            }
        }
    }

    useFetching(fetchTimelineEvent, 'dashboard', [], queryParams.id)

    useEffect(() => {
        if (props.resultsData) {
            switch (props.timelineEvent?.typeId) {
                case 'STORED_IMAGE':
                    setStoredData({ title: (props.resultsData as StoredResultsData)?.title, description: (props.resultsData as StoredResultsData).description, imageTagsIds: [] })
    
                    break
                case 'STORED_FILES':
                    setStoredData({ title: (props.resultsData as StoredResultsData)?.title, description: (props.resultsData as StoredResultsData).description })
    
                    break
                case 'MESSAGE':
                    setMessage({ title: (props.resultsData as MessageResultsData)?.title, content: (props.resultsData as MessageResultsData)?.content })
    
                    break
            }
        }
    }, [props.timelineEvent, props.resultsData])

    useEffect(() => {
        setTotalPages(Math.ceil(timelineEventsSize / timelineFilter.limit))
    }, [timelineEventsSize, timelineFilter])

    useEffect(() => {
        const currentIndex = props.timelineEvents.findIndex((timelineEvent) => timelineEvent.id === props.timelineEvent?.id)
        setIsFirstEvent(timelineFilter.page <= 0 && (!props.timelineEvents[currentIndex - 1]))
        setIsLastEvent(timelineFilter.page >= (totalPages - 1) && (!props.timelineEvents[currentIndex + 1]))
    }, [timelineFilter, totalPages, props.timelineEvents, props.timelineEvent])

    useEffect(() => {
        setActiveTab(parseInt(queryParams.tab as string, 10))
    }, [queryParams.tab])

    useEffect(() => {
        dispatch(toggleSidemenu(false))
        return (): void => {
            dispatch(setCurrentTimelineEvent(null))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            {   
                props.timelineEvent &&
                <div css={[classes.container]} className="flex flex-col h-full">
                    <div className="flex items-center mb-6 mt-2 mx-8">
                        <CustomButton
                            Icon={BackArrowIcon}
                            cssStyles={[classes["back-button"]]}
                            className="bg-lightblue text-primary min-w-40 max-w-52 font-bold px-6 no-underline"
                            label={intl.formatMessage({ id: 'dashboard.timelineDetail.backButton.label' })}
                            onClick={handleNavigate} />

                        <CustomButton
                            className="bg-primary text-white min-w-30 px-6 ml-auto"
                            label={intl.formatMessage({ id: "dashboard.timelineDetail.previousEventButton.label" })}
                            disabled={isFirstEvent}
                            onClick={handlePreviousEvent} />
                        <CustomButton
                            className="bg-primary text-white min-w-30 px-6 ml-4"
                            label={intl.formatMessage({ id: "dashboard.timelineDetail.nextEventButton.label" })}
                            disabled={isLastEvent}
                            onClick={handleNextEvent} />
                    </div>
                    {
                        !(queryParams.type !== 'ANALYSED_IMAGE' && queryParams.type !== 'ANALYSED_DOCUMENT') &&
                        <CustomTabs
                            cssStyles={[classes.tabs]}
                            className="flex flex-col flex-grow"
                            selectedIndex={activeTab}
                            onSelect={changeTab}
                        >
                            <TabList>
                                {
                                    (queryParams.type === 'ANALYSED_IMAGE' || queryParams.type === 'ANALYSED_DOCUMENT') &&
                                    <Tab>
                                        {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.results' })}
                                    </Tab>
                                }
                                {
                                    queryParams.type === 'ANALYSED_IMAGE' &&
                                    <Tab>
                                        {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.dicom' })}
                                    </Tab>
                                }
                                {
                                    queryParams.type === 'ANALYSED_IMAGE' &&
                                    <Tab className={classNames({ 'react-tabs__tab': true, 'hidden' : props.timelineEvent.symptoms?.severityId === 'NONE'})}>
                                        <div className="flex items-center">
                                            {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.symptoms' })}
                                            <div className={`flex justify-center items-center w-8 h-8 rounded-full text-white cursor-pointer no-underline mx-1 text-sm ml-2 ${props.timelineEvent.symptoms?.severityId.toLowerCase()}`}>{props.timelineEvent.symptoms?.count}</div>
                                        </div>
                                    </Tab>
                                }
                                {
                                    queryParams.type === 'ANALYSED_IMAGE' &&
                                    <Tab className={classNames({ 'react-tabs__tab': true, 'hidden' : props.timelineEvent.violations?.severityId === 'NONE'})}>
                                        <div className="flex items-center">
                                            {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.protocol' })}
                                            <div className={`flex justify-center items-center w-8 h-8 rounded-full text-white cursor-pointer no-underline mx-1 text-sm ml-2 ${props.timelineEvent.violations?.severityId.toLowerCase()}`}>{props.timelineEvent.violations?.count}</div>
                                        </div>
                                    </Tab>
                                }
                                {
                                    (queryParams.type === 'ANALYSED_IMAGE' || queryParams.type === 'ANALYSED_DOCUMENT') &&
                                    <Tab>
                                        <div className="flex items-center">
                                            {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.comments' })}
                                            <div className="flex justify-center items-center rounded-full text-white cursor-pointer no-underline ml-4 text-sm ml-2 w-8 h-7 relative bg-primary">
                                                <span className="select-none comments-arrow">{props.timelineEvent.totalComments}</span>
                                            </div>
                                        </div>
                                    </Tab>
                                }
                            </TabList>

                            {
                                (queryParams.type === 'ANALYSED_IMAGE' || queryParams.type === 'ANALYSED_DOCUMENT') &&
                                <TabPanel>
                                    <DashboardTimelineResults
                                        handleAddTag={handleAddTag}></DashboardTimelineResults>
                                </TabPanel>
                            }
                            {
                                queryParams.type === 'ANALYSED_IMAGE' &&
                                <TabPanel>
                                    <DashboardTimelineDicomAttributes url="/analysed-images"></DashboardTimelineDicomAttributes>
                                </TabPanel>
                            }
                            {
                                queryParams.type === 'ANALYSED_IMAGE' &&
                                <TabPanel className={classNames({ 'hidden' : props.timelineEvent.symptoms?.severityId === 'NONE'})}>
                                    <DashboardTimelineSymptoms handleChangeTab={changeTab}></DashboardTimelineSymptoms>
                                </TabPanel>
                            }
                            {
                                queryParams.type === 'ANALYSED_IMAGE' &&
                                <TabPanel className={classNames({ 'hidden' : props.timelineEvent.violations?.severityId === 'NONE'})}>
                                    <DashboardTimelineProtocol></DashboardTimelineProtocol>
                                </TabPanel>
                            }
                            {
                                (queryParams.type === 'ANALYSED_IMAGE' || queryParams.type === 'ANALYSED_DOCUMENT') &&
                                <TabPanel className={classNames({
                                    'h-full': activeTab === 4
                                })}>
                                    <DashboardTimelineComments
                                        className="h-full"
                                        onlyComments={true}
                                        handleEditComment={props.handleEditComment}
                                        handleCreateComment={props.handleCreateComment}
                                        handleDeleteComment={props.handleDeleteComment}></DashboardTimelineComments>
                                </TabPanel>
                            }
                        </CustomTabs>
                    }
                    {
                        queryParams.type === 'STORED_IMAGE' &&
                        <React.Fragment>
                            <CustomTabs
                                cssStyles={[classes.tabs]}
                                className="flex flex-col flex-grow"
                                selectedIndex={imageActiveTab}
                                onSelect={changeImageTab}
                            >
                                <TabList>
                                    <Tab>
                                        {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.image' })}
                                    </Tab>
                                    <Tab>
                                        {intl.formatMessage({ id: 'dashboard.timelineDetail.tabHeader.dicom' })}
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="flex border-0 border-t border-solid border-gray-100 h-full">
                                        <DashboardTimelineStored
                                            className="w-3/5"
                                            storedData={storedData}
                                            setStoredData={setStoredData}></DashboardTimelineStored>
                                        <DashboardTimelineComments
                                            className="ml-8 w-2/5"
                                            handleEditComment={props.handleEditComment}
                                            handleCreateComment={props.handleCreateComment}
                                            handleDeleteComment={props.handleDeleteComment}></DashboardTimelineComments>
                                    </div>
                                    <Footer
                                        className="top-0"
                                        item={props.resultsData as StoredResultsData}
                                        editing={true}
                                        handleDelete={handleDelete}
                                        handleCancel={handleCancel}
                                        handleSave={handleSubmit} />
                                </TabPanel>
                                <TabPanel>
                                    <DashboardTimelineDicomAttributes url="/stored-images"></DashboardTimelineDicomAttributes>
                                </TabPanel>
                            </CustomTabs>
                        </React.Fragment>
                    }
                    {
                        queryParams.type === 'STORED_FILES' &&
                        <React.Fragment>
                            <div className="flex border-0 border-t border-solid border-gray-100 h-full">
                                <DashboardTimelineStored
                                    className="w-3/5"
                                    storedData={storedData}
                                    setStoredData={setStoredData}></DashboardTimelineStored>
                                <DashboardTimelineComments
                                    className="ml-8 w-2/5"
                                    handleEditComment={props.handleEditComment}
                                    handleCreateComment={props.handleCreateComment}
                                    handleDeleteComment={props.handleDeleteComment}></DashboardTimelineComments>
                            </div>
                            <Footer
                                item={props.resultsData as StoredResultsData}
                                editing={true}
                                handleDelete={handleDelete}
                                handleCancel={handleCancel}
                                handleSave={handleSubmit} />
                        </React.Fragment>
                    }
                    {
                        queryParams.type === 'MESSAGE' &&
                        <React.Fragment>
                            <div className="flex border-0 border-t border-solid border-gray-100 h-full">
                                <DashboardTimelineMessage
                                    className="w-3/5"
                                    message={message}
                                    setMessage={setMessage}></DashboardTimelineMessage>
                                <DashboardTimelineComments
                                    className="ml-8 w-2/5"
                                    handleEditComment={props.handleEditComment}
                                    handleCreateComment={props.handleCreateComment}
                                    handleDeleteComment={props.handleDeleteComment}></DashboardTimelineComments>
                            </div>
                            <Footer
                                item={props.resultsData as MessageResultsData}
                                editing={true}
                                handleDelete={handleDelete}
                                handleCancel={handleCancel}
                                handleSave={handleSubmit} />
                        </React.Fragment>
                    }
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { resultsData: TimelineEventResultsData | StoredResultsData | MessageResultsData | null; timelineEvents: TimelineEvent[]; timelineEvent: TimelineEvent | null; equipment: Equipment | null; search: string; loggedUser: LoggedUser | null }  => ({
    resultsData: state.timelineEventDetail.resultsData,
    timelineEvents: state.equipmentDetail.timelineEvents,
    timelineEvent: state.equipmentDetail.currentTimelineEvent,
    equipment: state.dashboard.currentEquipment,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(DashboardTimelineDetail)