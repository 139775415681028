import React, { ReactElement, useState, useEffect, OptionHTMLAttributes } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomCheckbox from '../custom/custom-checkbox'
import CustomChart from '../custom/custom-chart/custom-chart'
import { ChartLine } from '../../types/chart-line'
import CustomAccordion from '../custom/custom-accordion'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import CustomDatePicker from '../custom/custom-date-picker'
import { intl } from '../../intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../types/states/state'
import { AnalysisIndicator } from '../../types/entity/analysis-indicator'
import { DashboardAnalysisProperties } from '../../types/component-properties/dashboard-analysis-properties'
import queryString from 'query-string'
import { fetchAnalysisCharts, fetchAnalysisIndicators, fetchEditAnalysisIndicator, setAnalysisCharts, setAnalysisIndicators, fetchToggleAnalysisChartRepresentative, fetchAnalysisChartTypes, setChartTypes, fetchOrderAnalysisCharts } from '../../reducers/equipment-detail'
import { useFetching } from '../../common/utils'
import { deepCopy } from '../../common/utils'
import { ChartLegend } from '../../types/chart-legend'
import { CustomChartLine } from '../../types/custom-chart-line'
import { CustomChartArea } from '../../types/custom-chart-area'
import { ChartArea } from '../../types/chart-area'
import { AnalysisChart } from '../../types/entity/analysis-chart'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType } from 'react-select'
import CustomIconSelect from '../custom/custom-icon-select'
import CustomHistogramChart from '../custom/custom-chart/custom-histogram-chart'
import CustomDispersionChart from '../custom/custom-chart/custom-dispersion-chart'
import CustomButton from '../custom/custom-button'

const classes = {
    'analysis': css`
        grid-template-columns: 1fr min-content min-content;
    `,
    'date-picker': css`
        input {
            border: 1px solid var(--color-gray-100);
            color: var(--color-darkgray)};
            border-radius: 0.125rem;
            min-height: 2.5rem;
            width: 8.75rem;
            &::placeholder {
                color: var(--color-darkgray);
            }
        }
    `,
    'accordion': css`
        .accordion__item {
            border: none;
            &:not(:last-of-type) {
                .accordion__button[aria-expanded="false"] {
                    border-bottom: none;
                }
            }
        }

        .accordion__button {
            display: flex;
            align-items: center;
            outline: none;
            background: transparent;
            color: var(--color-black);
            padding: 0.6rem 0;
            font-weight: bold;
            font-size: 0.9rem;
            position: relative;
            border-top: 1px solid var(--color-gray-100);
            border-bottom: 1px solid var(--color-gray-100);

            ::before {
                position: absolute;
                right: -0.5rem;
                top: calc(50% - 0.3125rem);
                height: 0.625rem;
                width: 0.625rem;
                border-bottom: 2px solid var(--color-black);
                border-left: 2px solid var(--color-black)
                transform: rotate(45deg);
                transition: transform 0.1s;
            }
        }

        .accordion__button[aria-expanded="true"] {
            border-bottom: 1px solid var(--color-gray-100);
            ::before {
                transform: rotate(-135deg);
            }
        }

        .accordion__panel {
            border: none;
            padding: 0;
        }
    `,
    'select-event': css`
        .select__control {
            background: var(--color-primary);
            border: none;
        }

        .select__placeholder, .select__single-value {
            color: var(--color-white);
        }

        .select__menu {
            min-width: 25rem;
        }
    `
}

const DashboardAnalysis = (props: DashboardAnalysisProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const chartTypes = useSelector((state: State) => state.equipmentDetail.chartTypes)
    const settings = useSelector((state: State) => state.settings.clientSettings)

    function getTime(): Date {
        const time = new Date(new Date().valueOf() - 1000 * 60 * 60)
        const monthsDifference = parseInt(settings?.find((setting) => setting.nameId === "timeIntervalAnalysis")?.value ?? "12", 10)
        time.setMonth(time.getMonth() - monthsDifference)
        return time
    }

    const [indicators, setIndicators] = useState(props.indicators)
    const [chartsIds, setChartsIds] = useState(props.charts.map((chart) => chart.id))
    const [fromDate, setFromDate] = useState<Date>(getTime())
    const [toDate, setToDate] = useState<Date>(new Date(new Date().valueOf() - 1000 * 60 * 60))
    const [indicatorIndex, setIndicatorIndex] = useState(-1)
    const [chartId, setChartId] = useState<string>()
    const [newIndicatorIds, setNewIndicatorIds] = useState<string[]>([])

    function getTotalIndicatorsRange(): boolean {
        let canAdd = false

        switch(props.charts[indicatorIndex].type) {
            case "FREQUENCY":
                canAdd = newIndicatorIds.length === 1

                break
            case "CORRELATION":
                canAdd = newIndicatorIds.length === 2

                break
            default:
                canAdd = newIndicatorIds.length < 8 && newIndicatorIds.length > 0

                break
        }

        return canAdd
    }

    function handleSaveIndicators(): void {
        dispatch(fetchEditAnalysisIndicator(props.charts[indicatorIndex]?.id ?? '', { indicatorIds: newIndicatorIds, name: null, representative: props.charts[indicatorIndex].representative }, queryParams.equipmentId as string, { from: new Date(fromDate).valueOf().toString(), to: new Date(toDate).valueOf().toString() }))
    }

    function handleToggleIndicator(checked: boolean, newIndicatorId: string): void {
        setTimeout(() => {
            if (props.charts[indicatorIndex]) {
                setNewIndicatorIds((oldValue) => {
                    const newValue = deepCopy(oldValue)
                    if (checked) {
                        newValue.push(newIndicatorId)
                    } else {
                        newValue.splice(newValue.indexOf(newIndicatorId), 1)
                    }
                    return newValue
                })
            }
        }, 5)
    }

    function handleCreateChart(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        props.handleCreateAnalysisChart('', (value as OptionHTMLAttributes<HTMLOptionElement>).value as string, { from: fromDate ? new Date(fromDate).valueOf().toString() : undefined, to: toDate ? new Date(toDate).valueOf().toString() : undefined })
    }

    function sortChart(index: number, newIndex: number): void {
        if (newIndex >= 0 && newIndex < props.charts.length) {
            const newChartsIds: string[] = deepCopy(chartsIds)
            const firstId = newChartsIds[index]
            const secondId = newChartsIds[newIndex]
            newChartsIds[index] = secondId
            newChartsIds[newIndex] = firstId
            setChartsIds(newChartsIds)
            dispatch(fetchOrderAnalysisCharts(newChartsIds, queryParams.equipmentId as string, { from: new Date(fromDate).valueOf().toString(), to: new Date(toDate).valueOf().toString() }))
        }
    }

    function getGridTemplateAreas(): string {
        let gridTemplateAreas = ""

        props.charts.forEach((__chart: any, index: number) => {
            gridTemplateAreas += index === 0 ? `"chart-${index + 1} actions-${index + 1} indicators-${index + 1}"` : ` "chart-${index + 1} actions-${index + 1} indicators-${index + 1}"`
        })

        return gridTemplateAreas
    }

    function handleAction(chart: AnalysisChart, index: number, action: string): void {
        switch(action) {
            case "indicators":
                if (chart.id === chartId) {
                    setChartId(undefined)
                    setNewIndicatorIds([])
                } else {
                    setChartId(chart.id)
                    setNewIndicatorIds(chart.indicatorIds)
                }

                break
            case "moveUp":
                sortChart(index, index - 1)
                if (indicatorIndex > -1) {
                    const trueIndex = props.charts.findIndex((sortedChart) => sortedChart.id === chart.id)
                    setChartId(props.charts[trueIndex - 1].id)
                }

                break
            case "moveDown":
                sortChart(index, index + 1)
                if (indicatorIndex > -1) {
                    const trueIndex = props.charts.findIndex((sortedChart) => sortedChart.id === chart.id)
                    setChartId(props.charts[trueIndex + 1].id)
                }

                break
            case "showInTimeline":
                dispatch(fetchToggleAnalysisChartRepresentative(chart.id, !chart.representative, queryParams.equipmentId as string, { from: new Date(fromDate).valueOf().toString(), to: new Date(toDate).valueOf().toString() }, intl.formatMessage({ id: 'dashboard.toast.success.graphVisibilityUpdated' })))

                break
            case "remove":
                props.handleDeleteAnalysisChart(chart.id, queryParams.equipmentId as string, { from: fromDate ? new Date(fromDate).valueOf().toString() : undefined, to: toDate ? new Date(toDate).valueOf().toString() : undefined })

                break
        }
    }

    useFetching(fetchAnalysisCharts, 'dashboard', [fromDate, toDate], queryParams.equipmentId as string, { from: new Date(fromDate).valueOf().toString(), to: new Date(toDate).valueOf().toString() })
    useFetching(fetchAnalysisIndicators, 'dashboard', [], queryParams.equipmentId as string)

    useEffect(() => {
        setChartsIds(props.charts.map((chart) => chart.id))
    }, [props.charts])

    useEffect(() => {
        setIndicatorIndex(props.charts.findIndex((chart) => chart.id === chartId))
    }, [props.charts, chartId])

    useEffect(() => {
        setIndicators(props.indicators)
    }, [props.indicators])

    useEffect(() => {
        dispatch(fetchAnalysisChartTypes())
    }, [dispatch])

    useEffect(() => {
        return (): void => {
            dispatch(setAnalysisCharts([]))
            dispatch(setAnalysisIndicators([]))
            dispatch(setChartTypes([]))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            {
                <React.Fragment>
                    <div className="flex justify-between h-full mb-4 px-8">
                        <div className="flex flex-col flex-grow mt-5">
                            <div className="flex items-start pl-3">
                                {
                                    props.charts.length > 0 &&
                                    <React.Fragment>
                                        <CustomDatePicker
                                            cssStyles={[classes["date-picker"]]}
                                            name="from"
                                            selected={fromDate}
                                            placeholder={`${intl.formatMessage({ id: 'dashboard.graphs.datePicker.from.label' })}...`}
                                            hideErrors={true}
                                            onChange={(event): void => setFromDate(event)} />
                                        <CustomDatePicker
                                            cssStyles={[classes["date-picker"]]}
                                            className="ml-2"
                                            name="to"
                                            selected={toDate}
                                            placeholder={`${intl.formatMessage({ id: 'dashboard.graphs.datePicker.to.label' })}...`}
                                            hideErrors={true}
                                            onChange={(event): void => setToDate(event)} />
                                    </React.Fragment>
                                }
                                <CustomSelect
                                    cssStyles={[classes["select-event"]]}
                                    className="ml-auto min-w-40"
                                    options={chartTypes}
                                    hideIcon={true}
                                    type="event"
                                    noValue={true}
                                    menuPosition="right"
                                    placeholder={`${intl.formatMessage({ id: 'dashboard.graphs.select.addChart.label' })}`}
                                    onChange={handleCreateChart} />
                            </div>
                            {
                                props.charts.length > 0 &&
                                <div className="grid" css={[classes.analysis]} style={{ gridTemplateAreas: getGridTemplateAreas() }}>
                                    {
                                        props.charts.map((chart, index): ReactElement => {
                                            if (chart) {
                                                const lines: CustomChartLine[] = []
                                                const areas: CustomChartArea[] = []
                                                const legend: ChartLegend[] = []
                                                chart.dataSet.groups.forEach((chartData, colorIndex) => {
                                                    if (chartData) {
                                                        const legendLines: string[] = []
                                                        const legendAreas: string[] = []
                                                        chartData.values.forEach((value, stylesIndex) => {
                                                            const chartLines: ChartLine[] = []
                                                            value.data.forEach(line => {
                                                                chartLines.push({
                                                                    x: chart.type === "FREQUENCY" || chart.type === "CORRELATION" ? parseFloat(line.domain ?? "0") ?? "0" : line.timestamp,
                                                                    y: !line.value || line.value === "null" ? null : parseFloat(line.value),
                                                                    id: line.id
                                                                })
                                                            })
                                                            lines.push({
                                                                chart: chartLines,
                                                                colorIndex,
                                                                stylesIndex
                                                            })
                                                            legendLines.push(value.name)
                                                        })
                                                        chartData.ranges.forEach((range, stylesIndex) => {
                                                            const area: ChartArea[] = []
                                                            range.data.forEach(point => {
                                                                area.push({
                                                                    x: point.timestamp,
                                                                    y: point.upper === 'null' ? null : point.upper,
                                                                    y0: point.lower === 'null' ? null : point.lower
                                                                })
                                                            })
                                                            areas.push({
                                                                area,
                                                                colorIndex,
                                                                stylesIndex
                                                            })
                                                            legendAreas.push(range.name)
                                                        })
                                                        legend.push({
                                                            name: chartData.name,
                                                            lines: legendLines,
                                                            areas: legendAreas
                                                        })
                                                    }
                                                })

                                                const histogramTickValues: number[] = []
                                                const correlationTickValues: number[] = []

                                                if (chart.type === "FREQUENCY") {
                                                    chart.dataSet.groups.forEach((group) => {
                                                        group.values.forEach((value) => {
                                                            value.data.forEach((tick) => {
                                                                histogramTickValues.push(parseFloat(tick.domain ?? "0"))
                                                            })
                                                        })
                                                    })
                                                }

                                                if (chart.type === "CORRELATION") {
                                                    chart.dataSet.groups.forEach((group) => {
                                                        group.values.forEach((value) => {
                                                            value.data.forEach((tick) => {
                                                                correlationTickValues.push(parseFloat(tick.domain ?? "0"))
                                                            })
                                                        })
                                                    })
                                                }

                                                return (
                                                    <div key={index} className="flex items-center" style={{ gridArea: `chart-${index + 1}` }}>
                                                        <div className="flex items-center w-full mt-6">
                                                            {
                                                                chart.type === "FREQUENCY" &&
                                                                <CustomHistogramChart
                                                                    xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? histogramTickValues.sort() : []}
                                                                    // xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => parseFloat(tick.value)).sort() : []}
                                                                    // xTickTimestamp={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                                    lines={lines}
                                                                    areas={areas}
                                                                    xAxisProps={{
                                                                        style: {
                                                                            tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                                            axis: { stroke: 'none' },
                                                                            axisLabel: { fontSize: 20, padding: 100 },
                                                                            grid: { stroke: 'none' }
                                                                        }
                                                                    }}
                                                                    yAxisProps={{
                                                                        style: {
                                                                            tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                                            axis: { stroke: 'none' },
                                                                            axisLabel: { fontSize: 20, padding: 30 },
                                                                            grid: { stroke: 'var(--color-gray-100)' }
                                                                        }
                                                                    }}
                                                                    scale={{
                                                                        x: "linear",
                                                                        y: "linear"
                                                                    }}
                                                                    title={chart.dataSet.name}
                                                                    legend={legend}
                                                                    hideXAxis={false} />
                                                            }
                                                            {
                                                                chart.type === "CORRELATION" &&
                                                                <CustomDispersionChart
                                                                    lines={lines}
                                                                    areas={areas}
                                                                    xAxisProps={{
                                                                        style: {
                                                                            tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                                            axis: { stroke: 'none' },
                                                                            axisLabel: { fontSize: 20, padding: 30 },
                                                                            grid: { stroke: 'none' }
                                                                        }
                                                                    }}
                                                                    yAxisProps={{
                                                                        style: {
                                                                            tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                                            axis: { stroke: 'none' },
                                                                            axisLabel: { fontSize: 20, padding: 30 },
                                                                            grid: { stroke: 'var(--color-gray-100)' }
                                                                        }
                                                                    }}
                                                                    scale={{
                                                                        x: "linear",
                                                                        y: "linear"
                                                                    }}
                                                                    title={chart.dataSet.name}
                                                                    legend={legend}
                                                                    axisType="XY"
                                                                    hideXAxis={false}
                                                                    showMonth={true}
                                                                    monthSeparators={true} />
                                                            }
                                                            {
                                                                chart.type !== "FREQUENCY" && chart.type !== "CORRELATION" &&
                                                                <CustomChart
                                                                    xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                                    xTickTimestamp={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                                    lines={lines}
                                                                    areas={areas}
                                                                    xAxisProps={{
                                                                        style: {
                                                                            tickLabels: { fontSize: 0, fill: 'var(--color-black)' },
                                                                            axis: { stroke: 'none' },
                                                                            axisLabel: { fontSize: 20, padding: 30 },
                                                                            grid: { stroke: 'none' }
                                                                        }
                                                                    }}
                                                                    yAxisProps={{
                                                                        style: {
                                                                            tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                                            axis: { stroke: 'none' },
                                                                            axisLabel: { fontSize: 20, padding: 30 },
                                                                            grid: { stroke: 'var(--color-gray-100)' }
                                                                        }
                                                                    }}
                                                                    title={chart.dataSet.name}
                                                                    legend={legend}
                                                                    hideXAxis={true}
                                                                    showMonth={true}
                                                                    monthSeparators={true} />
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return <div key={index}></div>
                                            }
                                        })
                                    }
                                    {
                                        props.charts.length > 0 &&
                                        props.charts.map((chart, index) => {
                                            const style: any = {
                                                "gridArea": `actions-${index + 1}`
                                            }

                                            return (
                                                <CustomIconSelect
                                                    key={index}
                                                    style={style}
                                                    chart={chart}
                                                    index={index}
                                                    handleAction={handleAction} />
                                            )
                                        })
                                    }
                                    {
                                        props.charts.length > 0 &&
                                        props.charts.map((__chart, index) => {
                                            const style: any = {}
                                            if (indicatorIndex !== index) {
                                                style["gridArea"] = `indicators-${index + 1}`
                                            }
                                            return <div key={index} style={style}></div>
                                        })
                                    }
                                    {
                                        indicatorIndex > -1 &&
                                        <div className="w-full min-w-94 max-w-94 ml-4 mt-5 mr-6 relative" style={{ gridArea: `indicators-${indicatorIndex + 1}` }}>
                                            {
                                                indicators.length > 0 && props.charts.length > 0 &&
                                                <div>
                                                    <div className="flex items-center">
                                                        <CustomButton
                                                            className="bg-lightblue text-primary min-w-32 my-4 font-bold"
                                                            label={intl.formatMessage({ id: 'dashboard.graphs.indicators.backButton.label' })}
                                                            onClick={(): void => setChartId(undefined)} />
                                                        <CustomButton
                                                            className="bg-primary text-white min-w-32 px-5 ml-2 font-bold"
                                                            label={intl.formatMessage({ id: 'dashboard.graphs.indicators.saveButton.label' })}
                                                            disabled={!getTotalIndicatorsRange()}
                                                            onClick={handleSaveIndicators} />
                                                    </div>
                                                    <CustomAccordion className="absolute w-full" preExpanded={indicators.map(indicator => indicator.testObjectId)} cssStyles={[classes.accordion]}>
                                                        {
                                                            indicators.map((indicator, index): ReactElement => {
                                                                return (
                                                                    <AccordionItem key={index} uuid={indicator.testObjectId}>
                                                                        <AccordionItemHeading>
                                                                            <AccordionItemButton>
                                                                                <h6 className="font-extra-bold my-0">{indicator.testObjectName}</h6>
                                                                            </AccordionItemButton>
                                                                        </AccordionItemHeading>
                                                                        <AccordionItemPanel>
                                                                            {
                                                                                indicator.indicators.map((testObjectIndicator, testObjectIndex) => {
                                                                                    return <CustomCheckbox
                                                                                        key={testObjectIndex}
                                                                                        className="my-4 py-1"
                                                                                        label={testObjectIndicator.name}
                                                                                        checked={props.charts[indicatorIndex]?.indicatorIds.includes(testObjectIndicator.id)}
                                                                                        // disabled={props.charts[indicatorIndex].type === "FREQUENCY" ? props.charts[indicatorIndex]?.indicatorIds.includes(testObjectIndicator.id) : (props.charts[indicatorIndex]?.indicatorIds.includes(testObjectIndicator.id) && props.charts[indicatorIndex]?.indicatorIds.length === 1) || !props.charts[indicatorIndex]}
                                                                                        onClick={(checked): void => handleToggleIndicator(checked, testObjectIndicator.id)} />
                                                                                })
                                                                            }
                                                                        </AccordionItemPanel>
                                                                    </AccordionItem>
                                                                )
                                                            })
                                                        }
                                                    </CustomAccordion>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { indicators: AnalysisIndicator[]; charts: AnalysisChart[]; search: string } => ({
    indicators: state.equipmentDetail.analysisIndicators,
    charts: state.equipmentDetail.analysisCharts,
    search: state.router.location.search
})

export default connect(mapStateToProps)(DashboardAnalysis)