import React, { ReactElement, useState, PropsWithChildren, useEffect, useRef, useMemo, useCallback, OptionHTMLAttributes } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { intl } from '../../../intl'
import PropTypes from 'prop-types'
import CustomButton from '../../custom/custom-button'
import ReactTooltip from 'react-tooltip'
import CustomChart from '../../custom/custom-chart/custom-chart'
import { DateTime } from 'luxon'
import CustomTabs from '../../custom/custom-tabs'
import { TabList, Tab, TabPanel } from 'react-tabs'
import { useDispatch, connect } from 'react-redux'
import CustomTable from '../../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import CustomCheckbox from '../../custom/custom-checkbox'
import CustomDatePicker from '../../custom/custom-date-picker'
import SeverityCell from '../../custom/custom-table/severity-cell'
import queryString from 'query-string'
// import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
// import { ReactComponent as FullscreenIcon } from '../../../assets/icons/fullscreen.svg'
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/back-arrow.svg'
import Footer from '../../footer'
import { State } from '../../../types/states/state'
import { DashboardBaselineDetailproperties } from '../../../types/component-properties/dashboard-baseline-detail-properties'
import { fetchBaselineDefinition, setCurrentBaselineDefinition, fetchEditBaselineTags } from '../../../reducers/equipment-detail'
import { Equipment } from '../../../types/entity/equipment'
import { setTags } from '../../../reducers/ui'
import { BaselineIndicator } from '../../../types/entity/baseline-indicator'
import { LoggedUser } from '../../../types/entity/logged-user'
import { BaselineDefinition } from '../../../types/entity/baseline-definition'
import { BreadcrumbPath } from '../../../types/entity/breadcrumb-path'
import { BaselineAnalysis } from '../../../types/entity/baseline-analysis'
import { ChartData } from '../../../types/entity/chart-data'
import { useDebounce } from '../../../common/utils'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CornerstoneViewport from 'react-cornerstone-viewport'
import CustomSelect from '../../custom/custom-select/custom-select'
import { ValueType, OptionTypeBase, OptionsType } from 'react-select'
import { Tags } from '../../../types/tags'
import { ToolOption } from '../../../types/tool-option'
import { deepCopy } from '../../../common/utils'
import { ChartLegend } from '../../../types/chart-legend'
import { ChartLine } from '../../../types/chart-line'
import { CustomChartLine } from '../../../types/custom-chart-line'
import { CustomChartArea } from '../../../types/custom-chart-area'
import { ChartArea } from '../../../types/chart-area'
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg'
import { Locale } from '../../../types/locale'
import { EventFile } from '../../../types/event-file'

const classes = {
    'tag': css`
        height: auto !important;
        .react-tabs__tab {
            margin-right: 2rem !important;
            margin-left: 0 !important;
        }
        .react-tabs__tab-list {
            margin-bottom: 0;
            padding: 0;
        }
        .react-tabs__tab-panel {
            padding: 0;
        }
    `,
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
        &:hover {
            text-decoration: none;
        }
    `,
    'table': css`
        th, td {
            height: 2rem !important;
            padding: 0.5rem !important;
        }
    `,
    'date-picker': css`
        input {
            border: 1px solid var(--color-gray-100);
            color: var(--color-darkgray);
            border-radius: 0.125rem;
            min-height: 2.5rem;
            width: 8.75rem;
            &::placeholder {
                color: var(--color-darkgray);
            }
        }
    `,
    'checkbox': css`
        min-height: 1rem !important;
        max-height: 1rem !important;

        .container {
            min-height: 1rem !important;
            max-height: 1rem !important;
        }

        .new-checkbox {
            top: 0;
        }
    `
}

const DashboardBaselineDetail = (props: DashboardBaselineDetailproperties): ReactElement => {
    const dispatch = useDispatch()
    const isFirstRun = useRef(true)
    const queryParams = queryString.parse(props.search)

    const [toolOptions] = useState(deepCopy(props.toolOptions))
    const [defaultTab, setDefaultTab] = useState(0)
    const [loadingTab, setLoadingTab] = useState<boolean>(true)
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [indicatorIds, setIndicatorsIds] = useState<string[]>()
    const [selectedIndicatorIndex, setSelectedIndicatorIndex] = useState<number>()
    const [selectedIndicator, setSelectedIndicator] = useState<string>()
    const [images, setImages] = useState<EventFile[]>()
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
    const [imageTagsOptions, setImageTagsOptions] = useState<OptionsType<OptionTypeBase>>([])
    const [imageTagToAdd, setImageTagToAdd] = useState('')
    const [baselineTagsOptions, setBaselineTagsOptions] = useState<OptionsType<OptionTypeBase>>([])
    const [baselineTagToAdd, setBaselineTagToAdd] = useState('')
    const [viewerImages, setViewerImages] = useState<string[]>([])
    const [payload, setPayload] = useState({ analysisIds: images?.map((image) => image.id), indicatorIds, selectedIndicatorId: (props.baselineDefinition?.indicators && props.baselineDefinition.indicators.length > 0 && selectedIndicatorIndex) ? props.baselineDefinition.indicators[selectedIndicatorIndex]?.id : undefined, from: startDate ? DateTime.fromMillis(startDate.valueOf()).toISO() : undefined, to: endDate ? DateTime.fromMillis(endDate.valueOf()).toISO() : undefined })
    const debouncedValue = useDebounce(payload, 'dashboard', 10)

    const handleIndicatorChange = useCallback((checked: boolean, id: string): void => {
        setIndicatorsIds((oldValue): string[] | undefined => {
            const ids: string[] = oldValue && oldValue.length > 0 ? deepCopy(oldValue) : props.baselineDefinition?.indicators.map(indicator => indicator.id) ?? []
            return checked ? ids.concat([id]) : ids.filter(indicator => indicator !== id)
        })
    }, [props.baselineDefinition])

    const handleImageChange = useCallback((checked: boolean, image: EventFile): void => {
        setImages((oldValue): EventFile[] | undefined => {
            const newImages: EventFile[] = oldValue && oldValue.length > 0 ? deepCopy(oldValue) : props.baselineDefinition?.analysedImages.map((analysedImage) => analysedImage.imageSources) ?? []
            return checked ? newImages.concat([image]) : newImages.filter(newImage => newImage.id !== image.id)
        })
    }, [props.baselineDefinition])

    function handleDotClick(dot: ChartLine): void {
        // const ids: string[] | undefined = imagesIds ? imagesIds : props.baselineDefinition?.analysedImages.map(analysedImage => analysedImage.id)
        const image = props.baselineDefinition?.analysedImages.find(analysedImage => analysedImage.date === dot.x)?.imageSources
        if (props.baselineDefinition && image) {
            handleImageChange(false, image)
        }
    }

    const indicatorsColumns = useMemo(() => [
        { id: 'selected', Header: '', canGroupBy: false, accessor: 'selected', width: 20, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<BaselineIndicator>>): ReactElement {
            return <CustomCheckbox
                cssStyles={[classes.checkbox]}
                className="w-4"
                checked={rowProps.row.original.defining}
                onClick={(checked): void => handleIndicatorChange(checked, rowProps.row.original.id)} />
        } },
        { id: 'name', Header: 'dashboard.baselines.detail.table.header.name', canGroupBy: false, accessor: 'name', disableSortBy: true },
        { id: 'analysedImageDate', Header: 'dashboard.baselines.detail.table.header.date', canGroupBy: false, accessor: 'analysedImageDate', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<BaselineIndicator>>): ReactElement {
            return <React.Fragment>{DateTime.fromMillis(rowProps.row.original.analysedImageDate).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}</React.Fragment>
        } },
        { id: 'actual', Header: 'dashboard.baselines.detail.table.header.value', canGroupBy: false, accessor: 'actual', disableSortBy: true },
        { id: 'expected', Header: 'dashboard.baselines.detail.table.header.expected', canGroupBy: false, accessor: 'expected', disableSortBy: true },
        { id: 'severity', Header: '', canGroupBy: false, accessor: 'severity', width: 20, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<BaselineIndicator>>): ReactElement {
            return <SeverityCell severity={rowProps.row.original.severity?.id ?? 'NONE'} square={true}></SeverityCell>
        } }
    ], [handleIndicatorChange, props.locale])

    const imagesColumns = useMemo(() => [
        { id: 'selected', Header: '', canGroupBy: false, accessor: 'selected', width: 20, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<BaselineAnalysis>>): ReactElement {
            return <CustomCheckbox
                cssStyles={[classes.checkbox]}
                className="w-4"
                checked={rowProps.row.original.defining}
                onClick={(checked): void => handleImageChange(checked, rowProps.row.original.imageSources)} />
        } },
        { id: 'date', Header: 'dashboard.baselines.detail.table.header.name', canGroupBy: false, accessor: 'date', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<BaselineAnalysis>>): ReactElement {
            return <React.Fragment>{DateTime.fromMillis(rowProps.row.original.date).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}</React.Fragment>
        } },
        { id: 'indicatorName', Header: 'dashboard.baselines.detail.table.header.indicator', canGroupBy: false, accessor: 'indicatorName', disableSortBy: true },
        { id: 'actual', Header: 'dashboard.baselines.detail.table.header.value', canGroupBy: false, accessor: 'actual', disableSortBy: true },
        { id: 'expected', Header: 'dashboard.baselines.detail.table.header.expected', canGroupBy: false, accessor: 'expected', disableSortBy: true },
        { id: 'severity', Header: '', canGroupBy: false, accessor: 'severity', width: 20, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<BaselineIndicator>>): ReactElement {
            return <SeverityCell severity={rowProps.row.original.severity?.id ?? 'NONE'} square={true}></SeverityCell>
        } }
    ], [handleImageChange, props.locale])

    // function back(): void {
    //     const destination = {
    //         pathname: `/dashboard/equipment-detail`,
    //         search: `?tab=${queryParams.tab as string}&id=${props.equipment?.id}`
    //     }

    //     dispatch(push(destination))
    //     dispatch(splicePath(props.paths.length - 1))
    // }

    function cancel(): void {
        setIndicatorsIds(props.baselineDefinition?.indicators.map(indicator => indicator.id) ?? [])
        setImages(props.baselineDefinition?.analysedImages.map(analysedImage => analysedImage.imageSources) ?? [])
        // dispatch(fetchBaselineDefinition(queryParams.baselineId as string, debouncedValue.value))
    }

    function updateBaseline(): void {
        if (props.baselineDefinition && images && indicatorIds) {
            props.handleUpdateBaseline(props.baselineDefinition?.id, images.map((image) => image.id), indicatorIds)
        }
    }

    function handleImageTagToAddChange(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setImageTagToAdd((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function addImageTag(): void {
        if (props.baselineDefinition?.analysedImages[selectedImageIndex].id) {
            const imageTagsIds = props.baselineDefinition?.analysedImages[selectedImageIndex].imageTags.map(imageTag => imageTag.id)
            imageTagsIds.push(imageTagToAdd)
            dispatch(fetchEditBaselineTags(props.baselineDefinition?.analysedImages[selectedImageIndex].id, { imageTagsIds }, props.baselineDefinition.id, debouncedValue.value, intl.formatMessage({ id: 'dashboard.toast.success.imageTagAdded' })))
            setImageTagToAdd('')
        }
    }

    function deleteImageTag(index: number): void {
        if (props.baselineDefinition?.analysedImages[selectedImageIndex].id) {
            const imageTagsIds: string[] = props.baselineDefinition?.analysedImages[selectedImageIndex].imageTags.map(imageTag => imageTag.id) ?? []
            imageTagsIds.splice(index, 1)
            dispatch(fetchEditBaselineTags(props.baselineDefinition?.analysedImages[selectedImageIndex].id, { imageTagsIds }, props.baselineDefinition.id, debouncedValue.value, intl.formatMessage({ id: 'dashboard.toast.success.imageTagDeleted' })))
        }
    }

    function handleBaselineTagToAddChange(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setBaselineTagToAdd((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function addBaselineTag(): void {
        if (props.baselineDefinition?.analysedImages[selectedImageIndex].id) {
            const baselineTagsIds = props.baselineDefinition?.analysedImages[selectedImageIndex].baselineTags.map(baselineTag => baselineTag.id)
            baselineTagsIds.push(baselineTagToAdd)
            dispatch(fetchEditBaselineTags(props.baselineDefinition?.analysedImages[selectedImageIndex].id, { baselineTagsIds }, props.baselineDefinition.id, debouncedValue.value, intl.formatMessage({ id: 'dashboard.toast.success.baselineTagAdded' })))
            setBaselineTagToAdd('')
        }
    }

    function deleteBaselineTag(index: number): void {
        if (props.baselineDefinition?.analysedImages[selectedImageIndex].id) {
            const baselineTagsIds: string[] = props.baselineDefinition?.analysedImages[selectedImageIndex].baselineTags.map(baselineTag => baselineTag.id) ?? []
            baselineTagsIds.splice(index, 1)
            dispatch(fetchEditBaselineTags(props.baselineDefinition?.analysedImages[selectedImageIndex].id, { baselineTagsIds }, props.baselineDefinition.id, debouncedValue.value, intl.formatMessage({ id: 'dashboard.toast.success.baselineTagDeleted' })))
        }
    }

    function handleImagesTableRowClick(row: any): void {
        setSelectedImageIndex(row.index)
        if (props.baselineDefinition) {
            const indicatorId = props.baselineDefinition.analysedImages[row.index].indicatorId
            const index = props.baselineDefinition.indicators.findIndex(indicator => indicator.id === indicatorId)

            if (index > -1) {
                setSelectedIndicatorIndex(index)
            }
        } else {
            setSelectedIndicatorIndex(undefined)
        }
    }

    useEffect(() => {
        if (props.baselineDefinition?.analysedImages[selectedImageIndex].imageTags) {
            const imageTagsValues = props.baselineDefinition?.analysedImages[selectedImageIndex].imageTags?.map(imageTag => imageTag.id)
            setImageTagsOptions(props.tags.imageTags.filter(imageTag => !imageTagsValues?.includes(imageTag.value)))
        }

        if (props.baselineDefinition?.analysedImages[selectedImageIndex].baselineTags) {
            const baselineTagsValues = props.baselineDefinition?.analysedImages[selectedImageIndex].baselineTags?.map(baselineTag => baselineTag.id)
            setBaselineTagsOptions(props.tags.baselineTags.filter(baselineTag => !baselineTagsValues?.includes(baselineTag.value)))
        }
    }, [props.baselineDefinition, selectedImageIndex, props.tags])

    useEffect(() => {
        setPayload({
            analysisIds: images?.map((image) => image.id),
            indicatorIds,
            selectedIndicatorId: selectedIndicator,
            from: startDate ? DateTime.fromMillis(startDate.valueOf()).toISO() : undefined,
            to: endDate ? DateTime.fromMillis(endDate.valueOf()).toISO() : undefined
        })
    }, [images, indicatorIds, selectedIndicator, startDate, endDate])

    useEffect(() => {
        if (props.baselineDefinition && props.baselineDefinition.indicators.length > 0 && selectedIndicatorIndex !== undefined) {
            setSelectedIndicator(props.baselineDefinition.indicators[selectedIndicatorIndex]?.id)
        } else {
            setSelectedIndicator(undefined)
        }
    }, [props.baselineDefinition, selectedIndicatorIndex])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (queryParams.baselineId) {
            dispatch(fetchBaselineDefinition(queryParams.baselineId as string, debouncedValue.value))
        }
    }, [dispatch, queryParams.baselineId, debouncedValue])

    useEffect(() => {
        // if (isFirstRun.current) {
        //     isFirstRun.current = false
        //     return
        // }
        if (!indicatorIds && !images && props.baselineDefinition?.indicators && props.baselineDefinition?.analysedImages) {
            const newIndicators: BaselineIndicator[] = deepCopy(props.baselineDefinition.indicators)
            const newImages: BaselineAnalysis[] = deepCopy(props.baselineDefinition.analysedImages)
            const identifiers: string[] = newIndicators.filter(indicator => indicator.defining).map(indicator => indicator.id)
            const analysis: EventFile[] = newImages.filter(image => image.defining).map(image => image.imageSources)
            setIndicatorsIds(identifiers)
            setImages(analysis)
        }
    }, [props.baselineDefinition, indicatorIds, images])

    useEffect(() => {
        setTimeout(() => {
            setLoadingTab(true)
        })
    }, [defaultTab])

    useEffect(() => {
        setImageTagToAdd('')
        setBaselineTagToAdd('')
    }, [selectedImageIndex])

    useEffect(() => {
        if (props.baselineDefinition && selectedIndicatorIndex === undefined) {
            const indicatorId = props.baselineDefinition.analysedImages[selectedImageIndex].indicatorId
            const index = props.baselineDefinition.indicators.findIndex(indicator => indicator.id === indicatorId)

            if (index > -1) {
                setSelectedIndicatorIndex(index)
            }
        }
    }, [props.baselineDefinition, selectedImageIndex, selectedIndicatorIndex])

    useEffect(() => {
        if (images) {
            const newViewerImages: string[] = []
            images.forEach((image) => {
                image.names.forEach((__name, index) => {
                    newViewerImages.push(`wadouri:${process.env.REACT_APP_API_URL}/files/${image.id}?indices=${index}&type=${image.type}`)
                })
            })
            setViewerImages(newViewerImages)
        }
    }, [images])

    useEffect(() => {
        if (props.baselineDefinition) {
            const newViewerImages: string[] = []
            props.baselineDefinition.analysedImages.forEach((analysedImages) => {
                analysedImages.imageSources.names.forEach((__name, index) => {
                    newViewerImages.push(`wadouri:${process.env.REACT_APP_API_URL}/files/${analysedImages.imageSources.id}?indices=${index}&type=${analysedImages.imageSources.type}`)
                })
            })
            setViewerImages(newViewerImages)
        }
    }, [props.baselineDefinition])

    useEffect(() => {
        return (): void => {
            dispatch(setCurrentBaselineDefinition(null))
            dispatch(setTags({ baselineTags: [], imageTags: [] }))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <div className="px-8 h-full overflow-auto">
                <div className="flex items-start justify-between mt-6">
                    <CustomButton
                        Icon={BackArrowIcon}
                        cssStyles={[classes["back-button"]]}
                        className="bg-lightblue text-primary min-w-52 font-bold"
                        label={intl.formatMessage({ id: 'dashboard.baselines.detail.backButton.label' })}
                        onClick={props.handleBack} />
                    {
                        props.baselineDefinition &&
                        <div className="flex">
                            <CustomDatePicker
                                cssStyles={[classes["date-picker"]]}
                                className="ml-4"
                                selected={startDate}
                                placeholder={`${intl.formatMessage({ id: 'dashboard.baselines.detail.datePicker.placeholder.from' })}...`}
                                onChange={(date): void => setStartDate(date)} 
                            />
                            <CustomDatePicker
                                cssStyles={[classes["date-picker"]]}
                                className="ml-2"
                                selected={endDate}
                                placeholder={`${intl.formatMessage({ id: 'dashboard.baselines.detail.datePicker.placeholder.to' })}...`}
                                onChange={(date): void => setEndDate(date)}
                            />
                        </div>
                    }
                </div>
                {
                    props.baselineDefinition &&
                    <div className="flex mt-5 mb-5">
                        <div className="w-120 min-w-120 relative mr-5">
                            {/* <img src="https://i.stack.imgur.com/HdP7Y.png" alt="" className="w-full" /> */}
                            {
                                viewerImages.length > 0 &&
                                <CornerstoneViewport
                                    className="max-w-120 max-h-120"
                                    tools={toolOptions}
                                    imageIds={viewerImages}
                                    imageIdIndex={selectedImageIndex} />
                            }
                            {/* <div className="hidden absolute top-0 right-0 flex my-4 mr-4">
                                <CustomButton
                                    className="bg-primary text-white w-10 mr-4"
                                    tooltip={intl.formatMessage({ id: 'Download' })}
                                    Icon={DownloadIcon} />
                                <CustomButton
                                    className="bg-primary text-white w-10"
                                    tooltip={intl.formatMessage({ id: 'View Fullscreen' })}
                                    Icon={FullscreenIcon} />
                            </div> */}

                            <CustomTabs cssStyles={[classes.tag]} className="pt-2" selectedIndex={defaultTab} onSelect={(tab): void => {
                                setLoadingTab(false)
                                setDefaultTab(tab)
                            }}>
                                <TabList>
                                    <Tab>{intl.formatMessage({ id: 'dashboard.baselines.detail.tabHeader.images' })}</Tab>
                                    <Tab>{intl.formatMessage({ id: 'dashboard.baselines.detail.tabHeader.indicators' })}</Tab>
                                    <Tab>{intl.formatMessage({ id: 'dashboard.baselines.detail.tabHeader.tags' })}</Tab>
                                </TabList>

                                <TabPanel>
                                    {
                                        loadingTab &&
                                        <CustomTable
                                            cssStyles={[classes.table]}
                                            className="mt-2"
                                            data={props.baselineDefinition.analysedImages}
                                            columns={imagesColumns}
                                            showPagination={false}
                                            selectable={true}
                                            selectedRow={selectedImageIndex}
                                            selectedClassName="bg-lightblue"
                                            handleRowClick={handleImagesTableRowClick}></CustomTable>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {
                                        loadingTab &&
                                        <CustomTable
                                            cssStyles={[classes.table]}
                                            className="mt-2"
                                            data={props.baselineDefinition.indicators}
                                            columns={indicatorsColumns}
                                            showPagination={false}
                                            selectable={true}
                                            selectedRow={selectedIndicatorIndex}
                                            selectedClassName="bg-lightblue"
                                            handleRowClick={(row: any): void => setSelectedIndicatorIndex(row.index)}></CustomTable>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    <div>
                                        <div className="py-4">
                                            <p className="tracking-wide font-bold text-2xs text-darkgray mb-1 mt-0">{intl.formatMessage({ id: 'dashboard.baselines.detail.tabBody.baselineTags' }).toUpperCase()}</p>

                                            <div className="flex">
                                                <CustomSelect
                                                    className="min-w-76 flex-grow"
                                                    options={baselineTagsOptions}
                                                    value={baselineTagToAdd}
                                                    placeholder={`${intl.formatMessage({ id: 'dashboard.baselines.detail.tabBody.select.placeholder.tagName' })}...`}
                                                    onChange={handleBaselineTagToAddChange} />

                                                <CustomButton
                                                    className="bg-primary text-white px-6 ml-4"
                                                    label={intl.formatMessage({ id: 'dashboard.baselines.detail.baselineTag.confirmButton.label' })}
                                                    onClick={addBaselineTag} />
                                            </div>
                                        </div>

                                        <div className="py-4">
                                            {
                                                props.baselineDefinition.analysedImages && props.baselineDefinition.analysedImages[selectedImageIndex] &&
                                                props.baselineDefinition.analysedImages[selectedImageIndex].baselineTags.map((baselineTag, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="relative text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase inline-block select-none mr-4 mb-2 py-1 px-4"
                                                        >
                                                            {baselineTag.name}
                                                            <CloseCircleIcon className="w-4 h-4 absolute top-!2 right-!2 cursor-pointer hover:opacity-50" onClick={(): void => deleteImageTag(index)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className="py-4">
                                            <p className="tracking-wide font-bold text-2xs text-darkgray mb-1 mt-0">{intl.formatMessage({ id: 'dashboard.baselines.detail.tabBody.imageTags' }).toUpperCase()}</p>

                                            <div className="flex">
                                                <CustomSelect
                                                    className="min-w-76 flex-grow"
                                                    options={imageTagsOptions}
                                                    value={imageTagToAdd}
                                                    placeholder={`${intl.formatMessage({ id: 'dashboard.baselines.detail.tabBody.select.placeholder.tagName' })}...`}
                                                    onChange={handleImageTagToAddChange} />

                                                <CustomButton
                                                    className="bg-primary text-white px-6 ml-4"
                                                    label={intl.formatMessage({ id: 'dashboard.baselines.detail.imageTag.confirmButton.label' })}
                                                    onClick={addImageTag} />
                                            </div>
                                        </div>

                                        <div className="py-4">
                                            {
                                                props.baselineDefinition.analysedImages && props.baselineDefinition.analysedImages[selectedImageIndex] &&
                                                props.baselineDefinition.analysedImages[selectedImageIndex].imageTags.map((imageTag, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="relative text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase inline-block select-none mr-4 mb-2 py-1 px-4"
                                                        >
                                                            {imageTag.name}
                                                            <CloseCircleIcon className="w-4 h-4 absolute top-!2 right-!2 cursor-pointer hover:opacity-50" onClick={(): void => deleteBaselineTag(index)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                            </CustomTabs>
                        </div>
                        <div className="flex flex-col flex-grow ml-6">
                            {
                                props.baselineDefinition.dataSets.length > 0 &&
                                props.baselineDefinition.dataSets.map((chart, index) => {
                                    const lines: CustomChartLine[] = []
                                    const areas: CustomChartArea[] = []
                                    const legend: ChartLegend[] = []
                                    chart.groups.forEach((chartData, index) => {
                                        const legendLines: string[] = []
                                        const legendAreas: string[] = []
                                        chartData.values.forEach((value, stylesIndex) => {
                                            const chart: ChartLine[] = []
                                            value.data.forEach(line => {
                                                if (line.value && line.value !== 'null') {
                                                    let bigger = false
                                                    switch (defaultTab) {
                                                        case 0:
                                                            bigger = line.value === props.baselineDefinition?.analysedImages[selectedImageIndex].actual && line.timestamp === props.baselineDefinition?.analysedImages[selectedImageIndex].date
                                                        
                                                            break
                                                        case 1:
                                                            bigger = selectedIndicatorIndex !== undefined && line.timestamp === props.baselineDefinition?.indicators[selectedIndicatorIndex].analysedImageDate
                                                            
                                                            break
                                                    }

                                                    chart.push({
                                                        x: line.timestamp,
                                                        y: parseFloat(line.value),
                                                        size: bigger ? 5 : 2.5
                                                    })  
                                                }
                                            })
                                            lines.push({
                                                chart,
                                                colorIndex: index,
                                                stylesIndex
                                            })
                                            legendLines.push(value.name)
                                        })

                                        chartData.ranges.forEach((range, stylesIndex) => {
                                            const area: ChartArea[] = []
                                            range.data.forEach(point => {
                                                area.push({
                                                    x: point.timestamp,
                                                    y: point.upper === 'null' ? null : point.upper,
                                                    y0: point.lower === 'null' ? null : point.lower
                                                })
                                            })
                                            areas.push({
                                                area,
                                                colorIndex: index,
                                                stylesIndex
                                            })
                                            legendAreas.push(range.name)
                                        })
                                        legend.push({
                                            name: chartData.name,
                                            lines: legendLines,
                                            areas: legendAreas
                                        })
                                    })
                                    return (
                                        <React.Fragment key={index}>
                                            <CustomChart
                                                className="mb-4"
                                                // xTickValues={chart.values[0].data.map(tick => DateTime.fromMillis(tick.t).day.toString())}
                                                xTickValues={chart.groups[0].values[0].data.map(tick => tick.timestamp)}
                                                xTickTimestamp={chart.groups[0].values[0].data.map(tick => tick.timestamp)}
                                                showMonth={true}
                                                lines={lines}
                                                areas={areas}
                                                xAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'none' }
                                                    }
                                                }}
                                                yAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                    }
                                                }}
                                                title={chart.name}
                                                hideXAxis={true}
                                                monthSeparators={true}
                                                legend={legend}
                                                handleDotClick={handleDotClick} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
            <ReactTooltip type="dark" effect="solid" />
            <Footer
                item={props.baselineDefinition}
                hideDelete={!(props.loggedUser?.acl.includes('BASELINES_DELETE') && props.baselineDefinition?.updateable)}
                hideSave={!(props.loggedUser?.acl.includes('BASELINES_DEFINE') && props.baselineDefinition?.updateable)}
                handleDelete={props.handleDeleteBaseline}
                handleCancel={cancel}
                handleSave={updateBaseline}></Footer>
        </React.Fragment>
    )
}

DashboardBaselineDetail.propTypes = {
    locale: PropTypes.any
}

const mapStateToProps = (state: State): { equipment: Equipment | null; paths: BreadcrumbPath[]; baselineDefinition: BaselineDefinition | null; baselineCharts: ChartData[] | null; search: string; loggedUser: LoggedUser | null; tags: Tags; toolOptions: ToolOption[]; locale: Locale } => ({
    equipment: state.dashboard.currentEquipment,
    paths: state.ui.breadcrumbPaths,
    baselineDefinition: state.equipmentDetail.currentBaselineDefinition,
    baselineCharts: state.equipmentDetail.currentBaselineCharts,
    search: state.router.location.search,
    loggedUser: state.login.user,
    tags: state.ui.tags,
    toolOptions: state.ui.toolOptions,
    locale: state.ui.locale
})

export default connect(mapStateToProps)(DashboardBaselineDetail)