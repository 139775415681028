import React, { ReactElement, useState, useEffect, OptionHTMLAttributes, MouseEvent } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomAccordion from '../../custom/custom-accordion'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { intl } from '../../../intl'
import CustomChart from '../../custom/custom-chart/custom-chart'
import CustomTable from '../../custom/custom-table/custom-table'
import CustomButton from '../../custom/custom-button'

import { TimelineEventResultsData } from '../../../types/entity/timeline-event-results-data'
import { connect, useDispatch } from 'react-redux'
import { DashboardTimelineResultsProperties } from '../../../types/component-properties/dashboard-timeline-results-properties'
import { setResultsData, fetchResultsData } from '../../../reducers/timeline-event-detail'
import { useFetching, downloadFile } from '../../../common/utils'
import queryString from 'query-string'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CornerstoneViewport from 'react-cornerstone-viewport'
import { ToolOption } from '../../../types/tool-option'
import { deepCopy } from '../../../common/utils'
import CustomSelect from '../../custom/custom-select/custom-select'
import { Tags } from '../../../types/tags'
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg'
import { ValueType, OptionTypeBase, OptionsType } from 'react-select'
import ReactTooltip from 'react-tooltip'
import { State } from '../../../types/states/state'
import { TimelineEvent } from '../../../types/entity/timeline-event'
import { ChartLegend } from '../../../types/chart-legend'
import { CustomChartLine } from '../../../types/custom-chart-line'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
import { ReactComponent as InformationIcon } from '../../../assets/icons/information.svg'
import { fetchFile } from '../../../reducers/ui'

const classes = {
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }
    `,
    'icon': css`
        g {
            stroke: var(--color-primary);
        }
    `
}

const DashboardTimelineResults = (props: DashboardTimelineResultsProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [toolOptions] = useState(deepCopy(props.toolOptions))
    const [imageTagToAdd, setImageTagToAdd] = useState('')
    const [imageTags, setImageTags] = useState<string[]>([])
    const [imageTagsOptions, setImageTagsOptions] = useState<OptionsType<OptionTypeBase>>([])
    const [imageIds, setImageIds] = useState<string[]>([])

    function handleImageTagToAddChange(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setImageTagToAdd((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function addImageTag(): void {
        if (props.timelineEvent?.typeId === 'ANALYSED_IMAGE') {
            props.handleAddTag([...imageTags, imageTagToAdd], intl.formatMessage({ id: '"dashboard.toast.success.imageTagAdded' }))
            setImageTagToAdd('')
        }
    }

    function deleteImageTag(index: number): void {
        if (props.timelineEvent?.typeId === 'ANALYSED_IMAGE') {
            const newTags: string[] = deepCopy(imageTags)
            newTags.splice(index, 1)
            props.handleAddTag(newTags, intl.formatMessage({ id: '"dashboard.toast.success.imageTagDeleted' }))
        }
    }

    function download(name: string, index: number): void {
        if (props.resultsData?.imageSources) {
            Promise.all([dispatch(fetchFile(props.resultsData?.imageSources, index, 'BASE64', true))]).then((res: any[]) => {
                downloadFile(res[0].data, name)
            })
        }
    }

    function openDocumentation(event: MouseEvent<SVGSVGElement, globalThis.MouseEvent>, url: string): void {
        event.stopPropagation()
        const win = window.open(url, '_blank')
        win?.focus()
    }

    useFetching(fetchResultsData, 'dashboard', [], queryParams.id, queryParams.type)

    useEffect(() => {
        if (props.timelineEvent) {
            setImageTags(props.timelineEvent.imageTags?.map(imageTag => imageTag.id) ?? [])
        }
    }, [props.timelineEvent])

    useEffect(() => {
        if (props.timelineEvent?.baselineTags) {
            const imageTagsValues = props.timelineEvent?.imageTags?.map(imageTag => imageTag.id)
            setImageTagsOptions(props.tags.imageTags.filter(imageTag => !imageTagsValues?.includes(imageTag.value)))
        }
    }, [props.timelineEvent, props.tags])

    useEffect(() => {
        if (props.resultsData?.imageSources) {
            setImageIds(props.resultsData.imageSources.names.map((__name, index) => `wadouri:${process.env.REACT_APP_API_URL}/files/${props.resultsData?.imageSources?.id}?indices=${index}&type=${props.resultsData?.imageSources?.type}`))
        } else if (props.resultsData?.documentFile) {
            setImageIds(props.resultsData.documentFile.names.map((__name, index) => `wadouri:${process.env.REACT_APP_API_URL}/files/${props.resultsData?.imageSources?.id}?indices=${index}&type=${props.resultsData?.imageSources?.type}`))
        }
    }, [props.resultsData])

    useEffect(() => {
        return (): void => {
            dispatch(setResultsData(null))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            {
                props.resultsData &&
                <div className="flex mt-8 h-full px-8">
                    <div className="mr-5">
                        <div className="min-w-120 max-w-120 h-120 relative">
                            {
                                imageIds.length > 0 &&
                                <React.Fragment>
                                    <CornerstoneViewport
                                        className="max-w-120 max-h-120"
                                        tools={toolOptions}
                                        imageIds={imageIds} />
                                
                                    {/* <div className="absolute top-0 right-0 flex my-4 mr-4">
                                        <CustomButton
                                            className="bg-primary text-white w-10 mr-4"
                                            tooltip={intl.formatMessage({ id: 'Download' })}
                                            Icon={DownloadIcon} />
                                        <CustomButton
                                            className="bg-primary text-white w-10"
                                            tooltip={intl.formatMessage({ id: 'View Fullscreen' })}
                                            Icon={FullscreenIcon} />
                                    </div> */}
                                </React.Fragment>
                            }
                        </div>
                        <form autoComplete="off">
                            {
                                props.timelineEvent && props.timelineEvent.typeId === 'ANALYSED_IMAGE' &&
                                <React.Fragment>
                                    <div className="flex flex-col mt-5">
                                        <div className="flex justify-between">
                                            <CustomSelect
                                                className="min-w-76 flex-grow mr-6"
                                                options={imageTagsOptions}
                                                label={intl.formatMessage({ id: 'dashboard.results.select.label' })}
                                                value={imageTagToAdd}
                                                placeholder={`${intl.formatMessage({ id: 'dashboard.results.select.placeholder' })}...`}
                                                onChange={handleImageTagToAddChange} />
                                            <CustomButton
                                                className="bg-primary text-white max-h-10 align-self-end mt-auto"
                                                iconClassName="w-3 h-3 ml-2"
                                                label={intl.formatMessage({ id: 'tooltip.add' })}
                                                disabled={imageTagToAdd === ''}
                                                onClick={addImageTag} />
                                        </div>
                                        <div className="flex pt-4">
                                            {
                                                props.timelineEvent.imageTags?.map((imageTag, index) => {
                                                    return (
                                                        <div
                                                            key={`tag-${index}`}
                                                            className="border border-solid border-primary rounded-sm w-auto outline-none leading-5 uppercase font-bold text-center text-2xs text-primary relative inline-block select-none mr-4 mb-2 py-1 px-4"
                                                        >
                                                            {imageTag.name}
                                                            <CloseCircleIcon className="w-4 h-4 absolute top-!2 right-!2 cursor-pointer hover:opacity-50" onClick={(): void => deleteImageTag(index)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    {
                                        props.timelineEvent.baselineTags && props.timelineEvent.baselineTags.length > 0 &&
                                        <div className="py-4">
                                            <p className="tracking-wide font-bold text-2xs text-darkgray mb-1 mt-0">{intl.formatMessage({ id: 'dashboard.results.baselineTags' }).toUpperCase()}</p>
                                            {
                                                props.timelineEvent.baselineTags?.map((baselineTag, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase inline-block select-none mr-2 mb-2 py-1 px-4"
                                                        >
                                                            {baselineTag.name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        props.resultsData.imageSources &&
                                        <React.Fragment>
                                            {
                                                props.resultsData.imageSources.names.map((name, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            css={[classes.icon]}
                                                            className="flex items-center text-primary leading-5 mt-2 cursor-pointer hover:opacity-75"
                                                            onClick={(): void => download(name, index)}
                                                        >
                                                            <DownloadIcon className="w-5 h-5 mr-2" />
                                                            {name}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </form>
                    </div>

                    {
                        props.resultsData && props.resultsData.tests?.length > 0 && props.timelineEvent?.typeId === queryParams.type &&
                        <CustomAccordion
                            className="ml-4 accordion-minus-dicom-viewer flex-grow"
                            preExpanded={props.resultsData.tests.map((test) => test.name)}
                        >
                            {
                                props.resultsData.tests.map((test) => {
                                    const soloIndicators = test.indicators.filter(indicator => indicator.type === 'NUMERIC')
                                    const chartData = test.indicators.filter(indicator => indicator.type === 'FUNCTION')[0]
                                    const table = test.indicators.filter(indicator => indicator.type === 'TABLE')[0]
                                    const lines: CustomChartLine[] = []
                                    if (chartData) {
                                        Object.keys(chartData.values).forEach((key, index) => {
                                            lines.push({
                                                chart: [],
                                                colorIndex: index,
                                                stylesIndex: 0
                                            })
                                        })
                                        Object.keys(chartData.values).forEach((key) => {
                                            chartData.values[key].forEach((value: string, index: number) => {
                                                lines[index].chart.push({
                                                    x: parseFloat(key),
                                                    y: parseFloat(value)
                                                })
                                            })
                                        })
                                    }
                                    const legend: ChartLegend[] = chartData?.quantities?.map((quantity) => ({ name: '', lines: [quantity.name] })) ?? []
                                    const tableKeys: string[] = []

                                    const columns = [{ id: 'name', Header: intl.formatMessage({ id: 'dashboard.results.table.header.name' }), canGroupBy: false, accessor: 'name', disableSortBy: true }].concat(table?.quantities?.map(quantity => {
                                        const keyName = quantity.name.toLowerCase().split(' ').join('').split('.').join('')
                                        tableKeys.push(keyName)
                                        return {
                                            id: keyName,
                                            canGroupBy: false,
                                            Header: `${quantity.name}${quantity.uom !== '' ? ' [' + quantity.uom + ']' : ''}`,
                                            accessor: keyName,
                                            translateHeader: false,
                                            // width: 60,
                                            disableSortBy: true
                                        }
                                    }) ?? [])
                                    const data: any[] = []
                                    Object.keys(table?.values ?? {}).forEach(key => {
                                        const row = {
                                            name: key
                                        }
                                        tableKeys.forEach((tableKey, index) => {
                                            row[tableKey as keyof typeof row] = table.values[key][index]
                                        })
                                        data.push(row)
                                    })

                                    return (
                                        <AccordionItem key={test.id} uuid={test.name}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {test.name}
                                                    {
                                                        test.documentationUrl && test.documentationUrl !== '' &&
                                                        <InformationIcon className="ml-auto mr-10 cursor-pointer hover:opacity-75" onClick={(event): void => openDocumentation(event, test.documentationUrl)} />
                                                    }
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                {
                                                    test.reference && test.reference !== '' &&
                                                    <div className="mb-8">{test.reference}</div>
                                                }
                                                
                                                <div className="w-1/2">
                                                    {
                                                        soloIndicators.length > 0 &&
                                                        soloIndicators.map((indicator, index) => {
                                                            return (
                                                                <div key={`${indicator.name}-${index}`} className="flex justify-between my-2">
                                                                    <div className="min-w-52 font-bold">{indicator.name}</div>
                                                                    {/* <div className="flex-grow-1 min-w-5"></div> */}
                                                                    <div className="min-w-52">{indicator.value} {indicator.uom}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                {
                                                    lines.length > 0 &&
                                                    <CustomChart
                                                        className="ml-2"
                                                        lines={lines}
                                                        xLabel={chartData.domainUom}
                                                        yLabel={chartData.valuesUom}
                                                        xAxisProps={{
                                                            style: {
                                                                tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                                axis: { stroke: 'none' },
                                                                axisLabel: { fontSize: 20, padding: 30 },
                                                                grid: { stroke: 'none' }
                                                            }
                                                        }}
                                                        yAxisProps={{
                                                            style: {
                                                                tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                                axis: { stroke: 'none' },
                                                                axisLabel: { fontSize: 20, padding: 30 },
                                                                grid: { stroke: 'var(--color-gray-100)' }
                                                            }
                                                        }}
                                                        axisType="NUMERIC"
                                                        legend={legend}
                                                        scale={{ x: 'linear', y: 'linear' }}
                                                        ></CustomChart>
                                                }

                                                {
                                                    data.length > 0 &&
                                                    <CustomTable
                                                        cssStyles={[classes.table]}
                                                        data={data}
                                                        columns={columns}
                                                        translateHeader={false}
                                                        stripedRows={true}
                                                        showPagination={false}></CustomTable>
                                                }
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    )
                                })
                            }
                        </CustomAccordion>
                    }
                </div>
            }
            <ReactTooltip type="dark" effect="solid" />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { resultsData: TimelineEventResultsData | null; timelineEvent: TimelineEvent | null; search: string; toolOptions: ToolOption[]; tags: Tags } => ({
    resultsData: state.timelineEventDetail.resultsData as TimelineEventResultsData | null,
    timelineEvent: state.equipmentDetail.currentTimelineEvent,
    search: state.router.location.search,
    toolOptions: state.ui.toolOptions,
    tags: state.ui.tags
})

export default connect(mapStateToProps)(DashboardTimelineResults)