import React, { ReactElement, useState, useRef, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomButton from './custom-button'
import { CustomSortProperties } from '../../types/component-properties/custom/custom-sort-properties'
import { intl } from '../../intl'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg'
import classNames from 'classnames'
import { useClickOutside } from '../../common/utils'

const CustomSort = (props: CustomSortProperties): ReactElement => {
    
    const [menuVisible, setMenuVisible] = useState<boolean>(false)
    const wrapperRef = useRef(null)
    const clickedOutside = useClickOutside(wrapperRef)

    useEffect(() => {
        setMenuVisible(false)
    }, [clickedOutside])

    return (
        <React.Fragment>
            <div ref={wrapperRef} css={[props.cssStyles]} className={`flex flex-col relative ${props.className}`}>
                <CustomButton
                    className="w-full bg-white text-black border border-solid border-gray-100"
                    label={props.label}
                    Icon={ArrowIcon}
                    iconPosition="right"
                    onClick={(): void => setMenuVisible(!menuVisible)}></CustomButton>

                {
                    menuVisible ? 
                        <div className="flex flex-col absolute px-6 py-4 top-10 left-0 z-10 min-w-56 bg-white shadow-sort-list">
                            <div className="text-small mb-2">
                                <span className="font-bold text-2xs text-darkgray-100 tracking-wide">{intl.formatMessage({ id: 'dashboard.timeline.sort.selectValue' }).toUpperCase()}</span>

                                {
                                    props.options.map((option, index) => {
                                        return (
                                            <div key={index} className="flex items-center my-1 cursor-pointer text-sm text-darkgray-100" onClick={(): void => props.onChange(option.value as string, props.orderType)}>
                                                <div className={classNames({
                                                    'w-3 h-3 rounded-full mr-2': true,
                                                    'bg-background-first': props.orderBy !== option.value,
                                                    'bg-primary': props.orderBy === option.value
                                                })}></div>
                                                <span>{intl.formatMessage({ id: option.label ? option.label : '' })}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="text-small mt-2">
                                <span className="font-bold text-2xs text-darkgray-100 tracking-wide">{intl.formatMessage({ id: 'dashboard.timeline.sort.selectOrder' }).toUpperCase()}</span>

                                <div className="flex items-center my-1 cursor-pointer text-sm text-darkgray-100" onClick={(): void => props.onChange(props.orderBy, 'asc')}>
                                    <div className={classNames({
                                        'w-3 h-3 rounded-full mr-2': true,
                                        'bg-background-first': props.orderType !== 'asc',
                                        'bg-primary': props.orderType === 'asc'
                                    })}></div>
                                    <span>{intl.formatMessage({ id: 'dashboard.timeline.sort.ascendant' })}</span>
                                </div>
                                <div className="flex items-center my-1 cursor-pointer text-sm text-darkgray-100" onClick={(): void => props.onChange(props.orderBy, 'desc')}>
                                    <div className={classNames({
                                        'w-3 h-3 rounded-full mr-2': true,
                                        'bg-background-first': props.orderType !== 'desc',
                                        'bg-primary': props.orderType === 'desc'
                                    })}></div>
                                    <span>{intl.formatMessage({ id: 'dashboard.timeline.sort.descendant' })}</span>
                                </div>
                            </div>
                        </div>
                        :
                        undefined
                }
            </div>
        </React.Fragment>
    )
}

CustomSort.defaultProps = {
    className: '',
    cssStyles: [css``],
    label: 'Sort by',
    options: [],
    value: {},
    order: 'none',
    onChange: (): void => undefined
}

export default CustomSort