import React, { ReactElement, ChangeEvent, useState, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomTextareaProperties } from '../../types/component-properties/custom/custom-textarea-properties'
import { CustomFormProperties } from '../../types/component-properties/custom/custom-form-properties'

const CustomTextarea = (props: CustomTextareaProperties & CustomFormProperties): ReactElement => {
    const [value, setValue] = useState(props.value)

    function onChange(event: ChangeEvent<HTMLTextAreaElement>): void {
        setValue(event.target.value)
        props.onChange(event.target.value)
    }

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <React.Fragment>
            <div css={[props.cssStyles]} className={props.className}>
                <div className="mb-1 font-bold text-darkgray text-xs" hidden={props.label === ''}>
                    {props.label} {props.required && props.label !== '' && <span className="text-red">*</span>}
                </div>
                <textarea
                    className="w-full p-4 outline-none rounded border border-solid border-gray-100 resize-y focus:border-primary"
                    disabled={props.disabled}
                    name={props.name}
                    placeholder={props.placeholder}
                    rows={props.rows}
                    ref={props.formRef}
                    value={value ? value : ''}
                    onChange={onChange}></textarea>
            </div>
            <div className="h-6 font-bold text-red text-xs" hidden={props.name === '' || props.hideErrors}>{props.errorMessage}</div>
        </React.Fragment>
    )
}

CustomTextarea.defaultProps = {
    className: '',
    cssStyles: [css``],
    label: '',
    name: '',
    placeholder: '',
    required: false,
    defaultValue: '',
    selectDefaultValue: undefined,
    errorMessage: undefined,
    readonly: false,
    disabled: false,
    hideErrors: false,
    rows: 4,
    value: '',
    formRef: (): void => undefined,
    selectRef: (): void => undefined,
    datePickerRef: (): void => undefined,
    onChange: (): void => undefined
}

export default CustomTextarea