import React, { ReactElement, BaseSyntheticEvent, OptionHTMLAttributes, useEffect, useState, useRef } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import * as yup from 'yup'
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import Footer from '../footer'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import { fetchCustomerOptions, setCustomerOptions } from '../../reducers/customers'
import { UsersCreatorProperties } from '../../types/component-properties/users-creator-properties'
import { NewUser } from '../../types/new-user'
import { useDebounce } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const userSchema = yup.object().shape({
    name: yup.string(),
    customerId: yup.string()
        .when('roleId', {
            is: (val) => val === 'ADMINISTRATOR',
            then: yup.string().nullable(),
            otherwise: yup.string().required('users.creator.form.input.errorMessage.required.organisation')
        }),
    email: yup.string(),
    phone: yup.string(),
    languageId: yup.string(),
    password: yup.string().required('users.creator.form.input.errorMessage.required.password'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'users.creator.form.input.errorMessage.match.confirmPassword'),
    roleId: yup.string().required('users.creator.form.input.errorMessage.required.role'),
    username: yup.string().required('users.creator.form.input.errorMessage.required.username')
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 8px;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const UsersCreator = (props: UsersCreatorProperties): ReactElement => {
    const dispatch = useDispatch()
    const debounce = useDebounce('', 'medical-centres', 10, [])
    const isFirstRun = useRef(true)
    
    const [customerId, setCustomerId] = useState('')
    const [languageId, setLanguageId] = useState('')
    const [roleId, setRoleId] = useState('')
    const [languages] = useState([
        { label: 'select.options.italian', value: 'it' },
        { label: 'select.options.english', value: 'en' }
    ])
    // const [roles] = useState([
    //     { label: 'select.options.administrator', value: 'ADMINISTRATOR' },
    //     { label: 'select.options.manager', value: 'MANAGER' },
    //     { label: 'select.options.supervisor', value: 'SUPERVISOR' },
    //     { label: 'select.options.standard', value: 'STANDARD' }
    // ])
    const { register, unregister, errors, setValue, triggerValidation, handleSubmit } = useForm({
        validationSchema: userSchema
    })

    const userRoles = useSelector((state: State) => state.ui.userRoles)

    function submit(user: Record<string, any>): void {
        delete user.confirmPassword

        props.handleCreate(user as NewUser)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleUpdateCustomer(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('customerId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'customerId' })
        setCustomerId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleUpdateLanguageId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('languageId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'languageId' })
        setLanguageId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleUpdateRoleId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        const val = (value as OptionHTMLAttributes<HTMLOptionElement>).value as string
        setValue('roleId', val)
        triggerValidation({ name: 'roleId' })
        setRoleId(val)
        if (val === 'ADMINISTRATOR') {
            setValue('customerId', null)
            setCustomerId('')
        }
    }

    function handleNavigate(): void {
        props.handleNavigate(0)
    }

    function resetUser(): void {
        setValue('name', '')
        setValue('customerId', '')
        setCustomerId('')
        setValue('email', '')
        setValue('phone', '')
        setValue('username', '')
        setValue('password', '')
        setValue('confirmPassord', '')
        setValue('roleId', '')
        setRoleId('')
        setValue('languageId', '')
        setLanguageId('')
    }

    useEffect(() => {
        register({ name: 'customerId' })
        register({ name: 'languageId' })
        register({ name: 'roleId' })

        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            setValue('customerId', props.loggedUser?.customerId)
        }

        return (): void => {
            dispatch(setCustomerOptions([]))
            unregister('customerId')
            unregister('languageId')
            unregister('roleId')
        }
    }, [dispatch, register, unregister, setValue, props.loggedUser])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (props.loggedUser?.role === 'ADMINISTRATOR') {
            dispatch(fetchCustomerOptions())
        }
    }, [dispatch, props.loggedUser, debounce])

    return (
        <React.Fragment>
            <div key={props.user.name} className="pt-4 px-8">
                <CustomButton
                    Icon={BackArrowIcon}
                    cssStyles={[classes["back-button"]]}
                    className="font-bold px-6 mb-5 bg-lightblue text-primary min-w-40"
                    label={intl.formatMessage({ id: 'users.creator.backButton.label' })}
                    onClick={handleNavigate} />

                <form className="min-w-60 max-w-60">
                    {
                        props.loggedUser?.role === 'ADMINISTRATOR' && roleId !== 'ADMINISTRATOR' &&
                        <CustomSelect
                            options={props.customerOptions}
                            name="customerId"
                            label={intl.formatMessage({ id: 'users.creator.form.select.label.organisation' }).toUpperCase()}
                            selectRef={register({ name: `customerId` })}
                            errorMessage={errors.customerId ? intl.formatMessage({ id: errors.customerId.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'users.creator.form.select.placeholder.organisation' })}...`}
                            value={customerId}
                            onChange={handleUpdateCustomer} />
                    }
                    <CustomInput
                        className="w-full"
                        name="username"
                        label={intl.formatMessage({ id: 'users.creator.form.input.label.username' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.username ? intl.formatMessage({ id: errors.username.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.input.placeholder.username' })}...`}
                        defaultValue={props.user.username} />
                    <CustomInput
                        className="w-full"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        label={intl.formatMessage({ id: 'users.creator.form.input.label.password' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.password ? intl.formatMessage({ id: errors.password.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.input.placeholder.password' })}...`}
                        defaultValue={props.user.password} />
                    <CustomInput
                        className="w-full"
                        name="confirmPassword"
                        type="password"
                        autoComplete="new-password"
                        label={intl.formatMessage({ id: 'users.creator.form.input.label.confirmPassword' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.confirmPassword ? intl.formatMessage({ id: errors.confirmPassword.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.input.placeholder.confirmPassword' })}...`}
                        defaultValue={props.user.password} />
                    <CustomSelect 
                        name="roleId"
                        // options={props.loggedUser?.role === 'ADMINISTRATOR' ? roles : roles.slice(1)}
                        options={userRoles}
                        label={intl.formatMessage({ id: 'users.creator.form.select.label.role' }).toUpperCase()}
                        errorMessage={errors.roleId ? intl.formatMessage({ id: errors.roleId.message }) : undefined}
                        translate={false}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.select.placeholder.role' })}...`}
                        value={roleId}
                        onChange={handleUpdateRoleId} />
                    <CustomSelect 
                        name="languageId"
                        options={languages}
                        label={intl.formatMessage({ id: 'users.creator.form.select.label.language' }).toUpperCase()}
                        errorMessage={errors.languageId ? intl.formatMessage({ id: errors.languageId.message }) : undefined}
                        translate={true}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.select.placeholder.language' })}...`}
                        value={languageId}
                        onChange={handleUpdateLanguageId} />
                    <CustomInput
                        className="w-full"
                        name="name"
                        label={intl.formatMessage({ id: 'users.creator.form.input.label.name' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.input.placeholder.name' })}...`}
                        defaultValue={props.user.name} />
                    <CustomInput
                        className="w-full"
                        name="email"
                        label={intl.formatMessage({ id: 'users.creator.form.input.label.email' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.input.placeholder.email' })}...`}
                        defaultValue={props.user.email} />
                    <CustomInput
                        className="w-full"
                        name="phone"
                        label={intl.formatMessage({ id: 'users.creator.form.input.label.phone' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'users.creator.form.input.placeholder.phone' })}...`}
                        defaultValue={props.user.phone} />
                </form>
            </div>
            <Footer
                item={props.user}
                editing={false}
                handleCancel={resetUser}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}


const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; customerOptions: OptionsType<OptionTypeBase>; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    customerOptions: state.customers.customerOptions,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(UsersCreator)
