import React, { ReactElement, PropsWithChildren, useEffect, useMemo } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomTable from '../../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import { useFetching } from '../../../common/utils'
import queryString from 'query-string'
import { State } from '../../../types/states/state'
import { connect, useDispatch, useSelector } from 'react-redux'
import { DashboardTimelineProtocolProperties } from '../../../types/component-properties/dashboard-timeline-protocol-properties'
import { fetchViolations, setViolationsDetail } from '../../../reducers/timeline-event-detail'
import { ViolationsDetail } from '../../../types/entity/violations-detail'
import StabilityTrend from '../../custom/custom-table/stability-trend'
import { Violation } from '../../../types/entity/violation'

const classes = {
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }
    `
}

const DashboardTimelineProtocol = (props: DashboardTimelineProtocolProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const timelineEvent = useSelector((state: State) => state.equipmentDetail.currentTimelineEvent)

    const protocolColumns = useMemo(() => [
        { id: 'severity', Header: 'dashboard.protocol.table.header.severity', canGroupBy: false, accessor: 'severity', width: 135, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<Violation>>): ReactElement {
            return <StabilityTrend infos={[{ severityId: rowProps.row.original.severity.id, severityName: rowProps.row.original.severity.name, eventId: timelineEvent?.id ?? "" }]} />
        } },
        { id: 'dicomAttributeName', Header: 'dashboard.protocol.table.header.name', canGroupBy: false, accessor: 'dicomAttributeName', disableSortBy: true },
        { id: 'expected', Header: 'dashboard.protocol.table.header.expected', canGroupBy: false, accessor: 'expected', disableSortBy: true }
    ], [timelineEvent])

    useFetching(fetchViolations, 'dashboard', [], queryParams.id, queryParams.type)

    useEffect(() => {
        return (): void => {
            dispatch(setViolationsDetail(null))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <div className="mt-5 p-8 h-full">
                {
                    props.violationsDetail &&
                    <CustomTable
                        cssStyles={[classes.table]}
                        data={props.violationsDetail.violations}
                        columns={protocolColumns}
                        showPagination={false}
                        stripedRows={true} />
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { violationsDetail: ViolationsDetail | null; search: string } => ({
    violationsDetail: state.timelineEventDetail.violationsDetail,
    search: state.router.location.search
})

export default connect(mapStateToProps)(DashboardTimelineProtocol)