import React, { ReactElement, useState } from 'react'
import { SketchPicker, RGBColor, ColorResult } from 'react-color'
import { hexToRgba } from '../../common/utils'
import { CustomColorProperties } from '../../types/component-properties/custom/custom-color-properties'

const CustomColor = (props: CustomColorProperties): ReactElement => {
    const [displayed, setDisplayed] = useState(false)
    const [color, setColor] = useState<RGBColor>(hexToRgba(props.defaultValue))

    function handleChange(newColor: ColorResult): void {
        setColor(newColor.rgb)
        props.handleChange(newColor.rgb)
    }

    return (
        <div>
            <div className="inline-block p-1 bg-white rounded-sm cursor-pointer border border-solid border-gray-100" onClick={(): void => setDisplayed(!displayed)}>
                <div className="w-10 h-4 rounded-sm" style={{ background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` }} />
            </div>
            {
                displayed &&
                <div className="absolute" style={{ zIndex: 2 }}>
                    <div className="fixed top-0 right-0 bottom-0 left-0" onClick={(): void => setDisplayed(false)}/>
                    <SketchPicker color={color} onChange={handleChange} />
                </div>
            }
        </div>
    )
}

CustomColor.defaultProps = {
    defaultValue: "",
    handleChange: (): void => undefined
}

export default CustomColor