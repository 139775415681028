import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useClickOutside } from '../../common/utils'
import { ReactComponent as MoreIcon } from '../../assets/icons/more.svg'
import classNames from 'classnames'
import { CustomIconSelectProperties } from '../../types/component-properties/custom/custom-icon-select-properties'
import { intl } from '../../intl'
import { AnalysisChart } from '../../types/entity/analysis-chart'

const CustomIconSelect = (props: CustomIconSelectProperties): ReactElement => {
    const [showMenu, setShowMenu] = useState(false)

    const wrapperRef = useRef(null)
    const clickedOutside = useClickOutside(wrapperRef)

    function handleAction(chart: AnalysisChart, index: number, action: string): void {
        props.handleAction(chart, index, action)
        setShowMenu(false)
    }

    useEffect(() => {
        setShowMenu(false)
    }, [clickedOutside])

    return (
        <div ref={wrapperRef} className="relative self-start" style={props.style}>
            <MoreIcon className="cursor-pointer w-6 h-6 mt-20 hover:opacity-75" onClick={(): void => setShowMenu(!showMenu)} />
            {
                props.chart &&
                <div className={classNames({
                    "absolute min-w-60 bg-white top-17 right-8 shadow-menu px-3 py-1": true,
                    "hidden": !showMenu
                })}>
                    <div className="flex items-center justify-center bg-lightblue text-primary text-sn font-bold my-2 px-4 cursor-pointer text-center w-full min-h-8 hover:opacity-50" onClick={(): void => handleAction(props.chart, props.index, "indicators")}>{intl.formatMessage({ id: "iconSelect.options.indicators" })}</div>
                    <div className="flex items-center justify-center bg-lightblue text-primary text-sn font-bold my-2 px-4 cursor-pointer text-center w-full min-h-8 hover:opacity-50" onClick={(): void => handleAction(props.chart, props.index, "moveUp")}>{intl.formatMessage({ id: "iconSelect.options.moveUp" })}</div>
                    <div className="flex items-center justify-center bg-lightblue text-primary text-sn font-bold my-2 px-4 cursor-pointer text-center w-full min-h-8 hover:opacity-50" onClick={(): void => handleAction(props.chart, props.index, "moveDown")}>{intl.formatMessage({ id: "iconSelect.options.moveDown" })}</div>
                    <div className="flex items-center justify-center bg-lightblue text-primary text-sn font-bold my-2 px-4 cursor-pointer text-center w-full min-h-8 hover:opacity-50" onClick={(): void => handleAction(props.chart, props.index, "showInTimeline")}>{props.chart.representative ? intl.formatMessage({ id: "iconSelect.options.hideInTimeline" }) : intl.formatMessage({ id: "iconSelect.options.showInTimeline" })}</div>
                    <div className="flex items-center justify-center bg-lightblue text-primary text-sn font-bold my-2 px-4 cursor-pointer text-center w-full min-h-8 hover:opacity-50" onClick={(): void => handleAction(props.chart, props.index, "remove")}>{intl.formatMessage({ id: "iconSelect.options.remove" })}</div>
                </div>
            }
        </div>
    )
}

export default CustomIconSelect