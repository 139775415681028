import React, { useState, ReactElement, PropsWithChildren, useMemo, useEffect} from 'react'
import CustomTable from '../custom/custom-table/custom-table'
import PropTypes from 'prop-types'
import { UsersListProperties } from '../../types/component-properties/users-list-properties'
import { CellProps } from 'react-table'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/column-delete.svg'
import ActionsCell from '../custom/custom-table/actions-cell'
import { ActionType } from '../../types/action-type'
import { User } from '../../types/entity/user'
import CustomSwitch from '../custom/custom-switch'
import { intl } from '../../intl'
import { SectionName } from '../../types/section-name'
import { RequestQueryParams } from '../../types/request-query-params'
import { LoggedUser } from '../../types/entity/logged-user'

const UsersList = ({ handleAction, handleEnableUser, ...props }: UsersListProperties): ReactElement => {
    const [actions] = useState<ActionType[]>([
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])
    const [filteredActions, setFilteredActions] = useState<ActionType[]>([
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])

    const columns = useMemo(() => [
        { id: 'username', Header: 'users.list.table.header.username', canGroupBy: false, accessor: 'username' },
        { id: 'name', Header: 'users.list.table.header.name', canGroupBy: false, accessor: 'name' },
        { id: 'email', Header: 'users.list.table.header.email', canGroupBy: false, accessor: 'email' },
        { id: 'phone', Header: 'users.list.table.header.phone', canGroupBy: false, accessor: 'phone', disableSortBy: true },
        { id: 'customerName', Header: 'users.list.table.header.organisation', canGroupBy: false, accessor: 'customerName' },
        { id: 'roleName', Header: 'users.list.table.header.role', canGroupBy: false, accessor: 'roleName' },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 80, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<User>>): ReactElement {
            return <ActionsCell actions={filteredActions} row={rowProps.row.original} handleAction={handleAction}></ActionsCell>
        } },
        { id: 'switch', Header: '', canGroupBy: false, accessor: '', width: 40, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<User>>): ReactElement {
            // return <CustomSwitch className="flex ml-4" checked={rowProps.row.original.active} onChange={(): void => props.handleArchiveEquipment(rowProps.row.original.id, rowProps.row.index, !rowProps.row.original.active)}></CustomSwitch>
            return <CustomSwitch className="flex ml-4" checked={rowProps.row.original.enabled} onChange={(): void => handleEnableUser(rowProps.row.original.id, rowProps.row.index, !rowProps.row.original.enabled)}></CustomSwitch>
        } }
    ], [handleAction, handleEnableUser, filteredActions])

    function getHiddenColumns(): string[] {
        const hiddenColumns: string[] = []
        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            hiddenColumns.push('customerName')
        }
        if (!props.loggedUser?.acl.includes('USERS_UPDATE_ENABLE')) {
            hiddenColumns.push('switch')
        }
        return hiddenColumns
    }

    useEffect(() => {
        setFilteredActions((): ActionType[] => {
            return actions.filter(action => {
                switch(action.type) {
                    case 'Delete':
                        return props.loggedUser?.acl.includes('USERS_DELETE')
                    case 'Edit':
                        return props.loggedUser?.acl.includes('USERS_UPDATE')
                    default:
                        return true
                }
            })
        })
    }, [props.loggedUser, actions])

    return (
        <div className="h-full w-full pt-4 px-8">
            <CustomTable 
                data={props.users}
                totalData={props.size}
                columns={columns}
                hiddenColumns={getHiddenColumns()}
                stripedRows={true}
                currentPage={props.page ?? 0}
                currentPageSize={props.limit ?? 10}
                noDataMessage={intl.formatMessage({ id: 'users.list.table.noDataText' })}
                handlePageChange={props.handlePageChange}
                handlePageSizeChange={props.handleLimitChange}
                handleSort={props.handleSort}></CustomTable>
        </div>
    )
}

UsersList.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    handleAction: PropTypes.func.isRequired,
    handleEnableUser: PropTypes.func.isRequired
}

const mapStateToProps = (state: State): { users: User[]; size: number; page: number | null; limit: number | null; queryParams: RequestQueryParams; currentSection: SectionName; loggedUser: LoggedUser | null }  => ({
    users: state.users.users,
    size: state.users.usersSize,
    page: state.ui.page,
    limit: state.ui.limit,
    queryParams: state.ui.sectionQueryParams,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(UsersList)