import React, { ReactElement, useEffect, BaseSyntheticEvent, useState, OptionHTMLAttributes, PropsWithChildren, useCallback, useMemo } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import Footer from '../footer'
import * as yup from 'yup'
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import CustomSelect from '../custom/custom-select/custom-select'
import { OptionsType, OptionTypeBase, ValueType } from 'react-select'
import { User } from '../../types/entity/user'
import { EditUser } from '../../types/edit-user'
import { fetchCreateEquipmentNotification, fetchDeleteEquipmentNotification, fetchEditEquipmentNotification, fetchUser, setCurrentUser } from '../../reducers/users'
import { UsersEditorProperties } from '../../types/component-properties/users-editor-properties'
import CustomSwitch from '../custom/custom-switch'
import queryString from 'query-string'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'
import CustomTabs from '../custom/custom-tabs'
import { Tab, TabList, TabPanel } from 'react-tabs'
import CustomTable from '../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import CustomCheckbox from '../custom/custom-checkbox'
import { EquipmentNotification } from '../../types/entity/equipment-notification'
import ActionsCell from '../custom/custom-table/actions-cell'
import { TableAction } from '../../types/table-action'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/column-delete.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { EditableTableRow } from '../../types/editable-table-row'
import { ActionType } from '../../types/action-type'
import { Severity } from '../../types/severity'
import classNames from 'classnames'

const userSchema = yup.object().shape({
    username: yup.string(),
    name: yup.string(),
    email: yup.string(),
    phone: yup.string(),
    enabled: yup.boolean(),
    roleId: yup.string(),
    languageId: yup.string(),
    currentUserPwd: yup.string().nullable(),
    password: yup.string(),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'users.editor.form.input.errorMessage.match.confirmPassword')
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 8px;
        }
        &:hover {
            text-decoration: none;
        }
    `,
    'tabs': css`
        .react-tabs__tab-list {
            margin-bottom: 0;
        }

        .react-tabs__tab-panel {
            flex-grow: 1;
        }

        .react-tabs__tab {
            background: transparent !important;
        }
    `,
    'table': css`
        tbody {
            border: none;
        }

        th, td {
            padding: 0.25rem 0.75rem;
        }

        tbody > tr:nth-of-type(odd) {
            background: var(--color-white);
        }

        tbody > tr:nth-of-type(even) {
            background: var(--color-background-second);
        }
    `,
    'table-disabled': css`
        color: var(--color-gray-100);

        svg {
            opacity: 0.5 !important;
            pointer-events: none;

            g, path {
                fill: var(--color-gray-100) !important;
            }
        }
    `
}

const UsersEditor = (props: UsersEditorProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const queryParams = queryString.parse(props.search)

    const [editedRowTemplate, setEditedRowTemplate] = useState<ReactElement>(<tr></tr>)
    const [tableActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])
    const [languages] = useState([
        { label: 'select.options.italian', value: 'it' },
        { label: 'select.options.english', value: 'en' }
    ])
    const [roles] = useState([
        { label: 'select.options.administrator', value: 'ADMINISTRATOR' },
        { label: 'select.options.manager', value: 'MANAGER' },
        { label: 'select.options.supervisor', value: 'SUPERVISOR' },
        { label: 'select.options.standard', value: 'STANDARD' }
    ])
    const [notifyForAll, setNotifyForAll] = useState(props.user?.notifyForAll)
    const [onlyAnalysisUser, setOnlyAnalysisUser] = useState(props.user?.onlyAnalysis)
    const [notificationSeverityIdForAll, setNotificationSeverityIdForAll] = useState<Severity | null>(props.user?.notificationSeverity.id as Severity)
    const [enabled, setEnabled] = useState(props.user?.enabled)
    const [languageId, setLanguageId] = useState(props.user?.languageId)
    const [roleId, setRoleId] = useState(props.user?.roleId)
    const [activeTab, setActiveTab] = useState(0)
    const [equipmentId, setEquipmentId] = useState<string | null>(null)
    const [notificationSeverityId, setNotificationSeverityId] = useState<Severity | null>(null)
    const [onlyAnalysis, setOnlyAnalysis] = useState<boolean>(false)
    const [selectedNotification, setSelectedNotification] = useState<EditableTableRow>({
        equipmentName: null,
        medicalCentreName: null,
        notificationSeverityId: null,
        onlyAnalysis: null,
        id: null
    })

    const { register, unregister, errors, handleSubmit, triggerValidation, setValue } = useForm({
        validationSchema: userSchema
    })

    const handleResetSelectedNotification = useCallback((notification: EquipmentNotification | undefined, editRow: string | undefined): void => {
        if (notification) {
            setEquipmentId(notification.equipmentId)
            setNotificationSeverityId(notification.notificationSeverityId)
            setOnlyAnalysis(notification.onlyAnalysis)
            setSelectedNotification({
                equipmentName: notification.equipmentName,
                medicalCentreName: notification.medicalCentreName,
                notificationSeverityId: notification.notificationSeverityId,
                onlyAnalysis: notification.onlyAnalysis,
                id: editRow
            })
        } else {
            setEquipmentId(null)
            setNotificationSeverityId(null)
            setOnlyAnalysis(false)
            setSelectedNotification({
                equipmentName: null,
                medicalCentreName: null,
                notificationSeverityId: null,
                onlyAnalysis: null,
                id: editRow
            })
        }
    }, [])

    const handleAction = useCallback((action: TableAction): void => {
        switch (action.type) {
            case 'Save':
                if (selectedNotification.id !== "-1") {
                    if (props.user && notificationSeverityId && onlyAnalysis !== null) {
                        Promise.all([dispatch(fetchEditEquipmentNotification(props.user.id, (action.row as EquipmentNotification).equipmentId, { notificationSeverityId, onlyAnalysis }, intl.formatMessage({ id: 'users.toast.success.equipmentNotificationUpdated' })))]).then(() => {
                            handleResetSelectedNotification(undefined, undefined)
                        })
                    }
                } else {
                    if (props.user && equipmentId && onlyAnalysis !== null && notificationSeverityId) {
                        Promise.all([dispatch(fetchCreateEquipmentNotification(props.user.id, { equipmentId, onlyAnalysis, severityId: notificationSeverityId, userId: props.user.id }, intl.formatMessage({ id: 'users.toast.success.equipmentNotificationCreated' })))]).then(() => {
                            handleResetSelectedNotification(undefined, undefined)
                        })
                    }
                }

                break
            case 'Edit':
                setTimeout(() => {
                    handleResetSelectedNotification(action.row as EquipmentNotification, (action.row as EquipmentNotification).equipmentId)
                }, 1)

                break
            case 'Delete':
                if (props.user) {
                    Promise.all([dispatch(fetchDeleteEquipmentNotification(props.user.id, (action.row as EquipmentNotification).equipmentId, intl.formatMessage({ id: 'users.toast.success.equipmentNotificationDeleted' })))]).then(() => {
                        handleResetSelectedNotification(undefined, undefined)
                    })
                }

                break
            case 'Cancel':
                handleResetSelectedNotification(undefined, undefined)

                break
        }
    }, [dispatch, handleResetSelectedNotification, selectedNotification.id, equipmentId, onlyAnalysis, notificationSeverityId, props.user])

    const columns = useMemo(() => [
        { id: 'equipmentName', Header: 'users.editor.notifications.table.header.equipment', canGroupBy: false, accessor: 'equipmentName', disableSortBy: true, width: "" },
        { id: 'medicalCentreName', Header: 'users.editor.notifications.table.header.medicalCentre', canGroupBy: false, accessor: 'medicalCentreName', disableSortBy: true, width: "" },
        { id: 'notificationSeverityName', Header: 'users.editor.notifications.table.header.severity', canGroupBy: false, accessor: 'notificationSeverityName', disableSortBy: true, width: "140" },
        { id: 'onlyAnalysis', Header: 'users.editor.notifications.table.header.onlyAnalysis', canGroupBy: false, accessor: 'onlyAnalysis', disableSortBy: true, width: "", Cell: function setColumn(rowProps: PropsWithChildren<CellProps<EquipmentNotification>>): ReactElement {
            return (
                <CustomCheckbox
                    checked={rowProps.row.original.onlyAnalysis}
                    disabled={true} />
            )
        } },
        { id: 'actions', Header: '', canGroupBy: false, accessor: 'actions', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<EquipmentNotification>>): ReactElement {
            return <ActionsCell actions={tableActions} row={{ ...rowProps.row.original, id: rowProps.row.original.equipmentId}} handleAction={handleAction} editingRow={(selectedNotification.id ?? '-1') as string}></ActionsCell>
        } }
    ], [tableActions, handleAction, selectedNotification])

    function changeTab(index: number): void {
        setActiveTab(index)
    }

    function submit(user: Record<string, any>): void {
        if (!((user.password && user.password !== '') && (user.confirmPassword && user.confirmPassword !== "") && (user.currentUserPwd && user.currentUserPwd !== ''))) {
            delete user.password
            delete user.currentUserPwd
        }

        delete user.confirmPassword

        if (notifyForAll) {
            user.onlyAnalysis = onlyAnalysisUser
            user.notificationSeverity = notificationSeverityIdForAll
        }
 
        props.handleEdit(props.user?.id ?? '0', user as EditUser, true)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleSwitch(value: boolean): void {
        setEnabled(value)
        setValue('enabled', value)
    }

    function handleUpdateLanguageId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('languageId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'languageId' })
        setLanguageId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleUpdateRoleId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('roleId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'roleId' })
        setRoleId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleNavigate(): void {
        props.handleNavigate(0)
    }

    function createNotification(): void {
        if (props.user && !props.user.userEquipmentNotification.find(notification => notification.equipmentId === '-1')) {
            handleResetSelectedNotification(undefined, '-1')
        }
    }

    function handleChangeNotifyForAll(checked: boolean): void {
        props.handleEdit(props.user?.id ?? '0', { notifyForAll: checked } as EditUser, false)
    }

    function resetUser(): void {
        if (props.user) {
            setValue('username', props.user.username)
            setValue('name', props.user.name)
            setValue('email', props.user.email)
            setValue('phone', props.user.phone)
            setValue('enabled', props.user.enabled)
            setEnabled(props.user.enabled)
            setValue('languageId', props.user.languageId)
            setLanguageId(props.user.languageId)
            setValue('roleId', props.user.roleId)
            setRoleId(props.user.roleId)
        }
    }

    function handleChangeSelectedNotification(): void {
        setEditedRowTemplate(
            <React.Fragment>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    {
                        selectedNotification.id === "-1" &&
                        <CustomSelect
                            className="w-40 mr-2 select-none"
                            defaultValue={equipmentId as string}
                            options={props.user?.equipments.map((equipment) => ({
                                label: equipment.name,
                                value: equipment.id
                            }))}
                            onChange={(event): void => setEquipmentId((event as OptionHTMLAttributes<HTMLOptionElement>).value as string)} />
                    }
                    {
                        selectedNotification.id !== "-1" &&
                        selectedNotification.equipmentName
                    }
                </td>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    {selectedNotification.medicalCentreName}
                </td>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    <CustomSelect
                        className="mr-2 select-none"
                        defaultValue={notificationSeverityId as string}
                        options={props.user?.severities.map((severity) => ({
                            label: severity.name,
                            value: severity.id
                        }))}
                        onChange={(event): void => setNotificationSeverityId((event as OptionHTMLAttributes<HTMLOptionElement>).value as Severity)} />
                </td>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    <CustomCheckbox
                        className="mr-2 select-none"
                        checked={onlyAnalysis}
                        onClick={(event): void => setOnlyAnalysis(event)} />
                </td>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    <ActionsCell
                        actions={[
                            { type: 'Save', Icon: SaveIcon },
                            { type: 'Edit', Icon: EditIcon },
                            { type: 'Cancel', Icon: CancelIcon }
                        ]}
                        row={selectedNotification as EquipmentNotification}
                        canSave={true}
                        handleAction={handleAction}
                        editingRow={selectedNotification.id as string} />
                </td>
            </React.Fragment>
        )
    }

    useEffect(handleChangeSelectedNotification, [selectedNotification, equipmentId, notificationSeverityId, onlyAnalysis, props.user])

    useFetching(fetchUser, 'users', [], queryParams.id as string)

    useEffect(() => {
        if (props.user) {
            setNotifyForAll(props.user.notifyForAll)
            setOnlyAnalysisUser(props.user.onlyAnalysis)
            setNotificationSeverityIdForAll(props.user.notificationSeverity.id as Severity)
        }
    }, [props.user])

    useEffect(() => {
        register({ name: 'enabled' })
        register({ name: 'languageId' })
        register({ name: 'roleId' })

        if (props.user) {
            setValue('enabled', props.user.enabled)
            setEnabled(props.user.enabled)
            setValue('languageId', props.user.languageId)
            setLanguageId(props.user.languageId)
            setValue('roleId', props.user.roleId)
            setRoleId(props.user.roleId)
        }

        return (): void => {
            unregister('enabled')
            unregister('languageId')
            unregister('roleId')
        }
    }, [register, unregister, setValue, props.user])

    useEffect(() => {
        return (): void => {
            dispatch(setCurrentUser(null))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            {
                props.user &&
                <div className="pt-4 px-8">
                    <div className="flex justify-between mb-5">
                        <CustomButton
                            Icon={BackArrowIcon}
                            cssStyles={[classes["back-button"]]}
                            className="font-bold px-6 bg-lightblue text-primary min-w-40"
                            label={intl.formatMessage({ id: 'users.editor.backButton.label' })}
                            onClick={handleNavigate} />

                        <CustomSwitch
                            checked={enabled}
                            disabled={!props.loggedUser?.acl.includes('USERS_UPDATE_ENABLE')}
                            label={intl.formatMessage({ id: 'users.editor.switch.label' })}
                            labelPosition="left"
                            onChange={handleSwitch}></CustomSwitch>
                    </div>

                    <div className="flex">
                        <form className="min-w-60 max-w-60" autoComplete="off">
                            <CustomInput
                                className="w-full"
                                name="username"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.username' }).toUpperCase()}
                                formRef={register}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.username' })}...`}
                                readonly={true}
                                defaultValue={props.user.username} />
                            <CustomInput
                                name="name"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.name' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.name' })}...`}
                                defaultValue={props.user.name} />
                            <CustomInput
                                name="email"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.email' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.email' })}...`}
                                defaultValue={props.user.email} />
                            <CustomInput
                                name="phone"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.phone' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.phone' })}...`}
                                defaultValue={props.user.phone} />
                            <CustomInput
                                className="w-full"
                                name="currentUserPwd"
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.currentPassword' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.currentUserPwd ? intl.formatMessage({ id: errors.currentUserPwd.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.currentPassword' })}...`}
                                defaultValue="" />
                            <CustomInput
                                className="w-full"
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.newPassword' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.password ? intl.formatMessage({ id: errors.password.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.newPassword' })}...`}
                                defaultValue="" />
                            <CustomInput
                                className="w-full"
                                name="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({ id: 'users.editor.form.input.label.confirmPassword' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.confirmPassword ? intl.formatMessage({ id: errors.confirmPassword.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.input.placeholder.confirmPassword' })}...`}
                                defaultValue="" />
                            <CustomSelect 
                                name="languageId"
                                options={languages}
                                label={intl.formatMessage({ id: 'users.editor.form.select.label.language' }).toUpperCase()}
                                errorMessage={errors.languageId ? intl.formatMessage({ id: errors.languageId.message }) : undefined}
                                translate={true}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.select.placeholder.language' })}...`}
                                value={languageId}
                                onChange={handleUpdateLanguageId} />
                            <CustomSelect 
                                name="roleId"
                                options={roles}
                                label={intl.formatMessage({ id: 'users.editor.form.select.label.role' }).toUpperCase()}
                                errorMessage={errors.roleId ? intl.formatMessage({ id: errors.roleId.message }) : undefined}
                                translate={true}
                                disabled={!props.loggedUser?.acl.includes('USERS_UPDATE_ROLE')}
                                placeholder={`${intl.formatMessage({ id: 'users.editor.form.select.placeholder.role' })}...`}
                                value={roleId}
                                onChange={handleUpdateRoleId} />
                        </form>

                        <div className="flex-grow ml-6">
                            <div className="bg-background-first p-8 ">
                                <CustomTabs
                                    cssStyles={[classes.tabs]}
                                    className="flex flex-col flex-grow"
                                    selectedIndex={activeTab}
                                    onSelect={changeTab}
                                >
                                    <TabList>
                                        <Tab>
                                            {intl.formatMessage({ id: 'users.editor.tabHeader.notifications' })}
                                        </Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="flex justify-end items-center mt-6 h-6">
                                            <CustomCheckbox
                                                className="mr-4"
                                                label={intl.formatMessage({ id: "users.editor.notifications.checkbox.notifyForAll" })}
                                                checked={notifyForAll}
                                                onClick={(event): void => handleChangeNotifyForAll(event)} />
                                            <CustomCheckbox
                                                className="mr-4"
                                                label={intl.formatMessage({ id: "users.editor.notifications.checkbox.onlyAnalysis" })}
                                                checked={onlyAnalysisUser}
                                                disabled={!notifyForAll}
                                                onClick={setOnlyAnalysisUser} />
                                            <CustomSelect
                                                className="w-40 mr-auto select-none"
                                                defaultValue={notificationSeverityIdForAll as string}
                                                options={props.user?.severities.map((severity) => ({
                                                    label: severity.name,
                                                    value: severity.id
                                                }))}
                                                disabled={!notifyForAll}
                                                onChange={(event): void => setNotificationSeverityIdForAll((event as OptionHTMLAttributes<HTMLOptionElement>).value as Severity)} />
                                            <CustomButton
                                                className="px-5 bg-primary text-white"
                                                label={intl.formatMessage({ id: "users.editor.notifications.button.addNotification" })}
                                                disabled={notifyForAll}
                                                onClick={createNotification} />
                                        </div>
                                        <CustomTable
                                            cssStyles={[notifyForAll ? classes["table-disabled"] : classes.table]}
                                            className={classNames({
                                                "mt-4": true
                                            })}
                                            data={props.user.userEquipmentNotification}
                                            columns={columns}
                                            editedRow={selectedNotification}
                                            editedRowTemplate={editedRowTemplate}
                                            stripedRows={true}
                                            showPagination={false}
                                            noDataMessage={intl.formatMessage({ id: 'users.editor.notifications.table.noDataText' })}
                                            // handleSort={handleSort}
                                        />
                                    </TabPanel>
                                </CustomTabs>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer
                item={props.user}
                editing={true}
                canDelete={props.loggedUser?.acl.includes('USERS_DELETE')}
                handleDelete={props.handleDelete}
                handleCancel={resetUser}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; user: User | null; customerOptions: OptionsType<OptionTypeBase>; search: string; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    user: state.users.currentUser,
    customerOptions: state.customers.customerOptions,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(UsersEditor)
