import React, { ReactElement, useState, useEffect, PropsWithChildren, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import ReactTooltip from 'react-tooltip'
import CustomTable from '../custom/custom-table/custom-table'
import CustomChart from '../custom/custom-chart/custom-chart'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../types/states/state'
import { setSymptomsDetail, fetchSPC } from '../../reducers/timeline-event-detail'
import queryString from 'query-string'
import { uniqueArray, useFetching } from '../../common/utils'
import { deepCopy } from '../../common/utils'
import { CustomChartLine } from '../../types/custom-chart-line'
import { ChartLine } from '../../types/chart-line'
import { ChartArea } from '../../types/chart-area'
import { CustomChartArea } from '../../types/custom-chart-area'
import { ChartLegend } from '../../types/chart-legend'
import { TimelineEvent } from '../../types/entity/timeline-event'
import { intl } from '../../intl'
import { SPC } from '../../types/spc'
import { DashboardSPCProperties } from '../../types/component-properties/dashboard-spc-properties'
import SeverityCell from '../custom/custom-table/severity-cell'
import { Symptom } from '../../types/entity/symptom'
import { CellProps } from 'react-table'
import CustomAccordion from '../custom/custom-accordion'
import { AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'

const classes = {
    'accordion': css`
        .accordion__item {
            border: none;
            &:not(:last-of-type) {
                .accordion__button[aria-expanded="false"] {
                    border-bottom: none;
                }
            }
        }

        .accordion__button {
            display: flex;
            align-items: center;
            outline: none;
            background: transparent;
            color: var(--color-black);
            padding: 0.6rem 0;
            font-weight: bold;
            font-size: 0.9rem;
            position: relative;
            border-top: 1px solid var(--color-gray-100);
            border-bottom: 1px solid var(--color-gray-100);

            ::before {
                position: absolute;
                right: -0.5rem;
                top: calc(50% - 0.3125rem);
                height: 0.625rem;
                width: 0.625rem;
                border-bottom: 2px solid var(--color-black);
                border-left: 2px solid var(--color-black)
                transform: rotate(45deg);
                transition: transform 0.1s;
            }
        }

        .accordion__button[aria-expanded="true"] {
            border-bottom: 1px solid var(--color-gray-100);
            ::before {
                transform: rotate(-135deg);
            }
        }

        .accordion__panel {
            border: none;
            padding: 0;
        }
    `,
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }
    `,
    'first-row': css`a
        tr:first-of-type {
            font-weight: bold;
        }
    `
}

const DashboardSPC = (props: DashboardSPCProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [rowIndex, setRowIndex] = useState(0)
    const [currentTestObject, setCurrentTestObject] = useState<string>()
    const [spcData, setSpcData] = useState<any>()
    const [orderedSpcData, setOrderedSpcData] = useState<any>()
    const [orderBy, setOrderBy] = useState<string[]>([])
    const [orderType, setOrderType] = useState<string[]>([])
    const [testObjectTypes, setTestObjectTypes] = useState<string[]>([])
    const [columns] = useState([
        { id: 'severity', Header: '', canGroupBy: false, accessor: 'severity', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<Symptom>>): ReactElement {
            return <SeverityCell severity={rowProps.row.original?.severity.id} square={true}></SeverityCell>
        } },
        { id: 'indicatorName', Header: 'dashboard.spc.table.header.indicator', canGroupBy: false, accessor: 'indicatorName', disableSortBy: true },
        { id: 'actualValue', Header: 'dashboard.spc.table.header.value', canGroupBy: false, accessor: 'actualValue', disableSortBy: true },
        { id: 'expected', Header: 'dashboard.spc.table.header.expected', canGroupBy: false, accessor: 'expected', disableSortBy: true },
        { id: 'uom', Header: 'dashboard.spc.table.header.uom', canGroupBy: false, accessor: 'uom', disableSortBy: true }
    ])

    function handleSymptomClick(rowInfo: any, testObject: string): void {
        setRowIndex(rowInfo.index)
        setCurrentTestObject(testObject)
    }

    const handleSort = useCallback((newOrderBy: string | null, newOrderType: string | null): void => {
        const tableIndex = testObjectTypes.indexOf(currentTestObject ?? '')
        setOrderBy((oldValue: string[]) => {
            const newValue: string[] = deepCopy(oldValue)
            newValue[tableIndex] = newOrderBy ? newOrderBy : ''
            return newValue
        })
        setOrderType((oldValue: string[]) => {
            const newValue: string[] = deepCopy(oldValue)
            newValue[tableIndex] = newOrderType ? newOrderType : ''
            return newValue
        })
    }, [testObjectTypes, currentTestObject])

    useFetching(fetchSPC, 'dashboard', [], queryParams.equipmentId)

    useEffect(() => {
        const newSpcData: any = {}
        const newOrderBy: string[] = []
        const newOrderType: string[] = []
        let newTestObjectTypes: string[] = []

        props.spc?.forEach((spcInfo) => newTestObjectTypes.push(spcInfo.indicatorId.analyserId))
        newTestObjectTypes = newTestObjectTypes.filter(uniqueArray)
        newTestObjectTypes.forEach((testObjectType) => {
            newSpcData[testObjectType] = props.spc?.filter((spcInfo) => spcInfo.indicatorId.analyserId === testObjectType) ?? []
            newOrderBy.push('')
            newOrderType.push('')
        })

        setCurrentTestObject(newTestObjectTypes[0] ?? undefined)
        setOrderBy(newOrderBy)
        setOrderType(newOrderType)
        setSpcData(props.spc && props.spc.length > 0 ? newSpcData : undefined)
        setOrderedSpcData(props.spc && props.spc.length > 0 ? newSpcData : undefined)
        setTestObjectTypes(newTestObjectTypes)
    }, [props.spc])

    useEffect((): void => {
        if (spcData) {
            const newSpcData: any = {}
            orderBy.forEach((orderByValue, index) => {
                if (!orderByValue) {
                    newSpcData[testObjectTypes[index]] = spcData[testObjectTypes[index]]
                } else {
                    const newSpc: SPC[] = deepCopy(spcData[testObjectTypes[index]])
                    if (orderType[index] === 'asc') {
                        newSpc.sort((a, b) => a.indicatorName.localeCompare(b.indicatorName))
                    } else {
                        newSpc.sort((a, b) => b.indicatorName.localeCompare(a.indicatorName))
                    }
                    newSpcData[testObjectTypes[index]] = newSpc
                }
            })
            setOrderedSpcData(newSpcData)
        }
    }, [spcData, orderBy, orderType, testObjectTypes])

    useEffect(() => {
        return (): void => {
            dispatch(setSymptomsDetail(null))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            {
                testObjectTypes.length > 0 &&
                <div className="flex mt-8 h-full px-8">
                    <div className="min-w-136 max-w-136 h-120 relative">
                        <CustomAccordion cssStyles={[classes.accordion]}>
                            {
                                testObjectTypes.map((testObjectType, index) => (
                                    <AccordionItem key={index} uuid={testObjectType}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <h6 className="font-extra-bold my-0">{testObjectType}</h6>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <CustomTable
                                                cssStyles={[classes.table]}
                                                data={orderedSpcData[testObjectType]}
                                                columns={columns}
                                                showPagination={false}
                                                stripedRows={true}
                                                selectable={true}
                                                selectedRow={currentTestObject === testObjectType ? rowIndex : undefined}
                                                handleRowClick={(rowInfo): void => handleSymptomClick(rowInfo, testObjectType)}
                                                handleSort={handleSort}></CustomTable>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))
                            }
                        </CustomAccordion>
                        {
                            props.timelineEvent && props.timelineEvent.imageTags && props.timelineEvent.imageTags.length > 0 &&
                            <div className="py-4">
                                <p className="tracking-wide font-bold text-2xs text-darkgray mb-1 mt-0">{intl.formatMessage({ id: 'dashboard.spc.imageTags.label' }).toUpperCase()}</p>
                                {
                                    props.timelineEvent.imageTags?.map((imageTag, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase inline-block select-none mr-2 mb-2 py-1 px-4"
                                            >
                                                {imageTag.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    {
                        props.spc && currentTestObject &&
                        <div className="ml-4 mr-8 flex-grow">
                            {
                                orderedSpcData[currentTestObject][rowIndex] &&
                                (orderedSpcData[currentTestObject][rowIndex] as SPC).features.map((feature, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {
                                                feature.groups.map((chart, index) => {
                                                    const lines: CustomChartLine[] = []
                                                    const areas: CustomChartArea[] = []
                                                    const legendLines: string[] = []
                                                    const legendAreas: string[] = []
                                                    const legend: ChartLegend[] = []
                                                    chart.values.forEach((value, stylesIndex) => {
                                                        const chart: ChartLine[] = []
                                                        value.data.forEach(line => {
                                                            chart.push({
                                                                x: line.timestamp,
                                                                y: !line.value || line.value === "null" ? null : parseFloat(line.value),
                                                                // size: line.timestamp === props.symptomDetail?.date ? 5 : 2.5,
                                                                size: 2.5
                                                            })
                                                        })
                                                        lines.push({
                                                            chart,
                                                            colorIndex: index,
                                                            stylesIndex
                                                        })
                                                        legendLines.push(value.name)
                                                    })
                                                    chart.ranges.forEach((range, stylesIndex) => {
                                                        const area: ChartArea[] = []
                                                        range.data.forEach(point => {
                                                            area.push({
                                                                x: point.timestamp,
                                                                y: point.upper === 'null' ? null : point.upper,
                                                                y0: point.lower === 'null' ? null : point.lower
                                                            })
                                                        })
                                                        areas.push({
                                                            area,
                                                            colorIndex: index,
                                                            stylesIndex
                                                        })
                                                        legendAreas.push(range.name)
                                                    })
                                                    legend.push({
                                                        name: chart.name,
                                                        lines: legendLines,
                                                        areas: legendAreas
                                                    })
                
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <CustomChart
                                                                xTickValues={feature.groups[0].values[0].data.map((tick) => tick.timestamp) ?? []}
                                                                xTickTimestamp={feature.groups[0].values[0].data.map(tick => tick.timestamp) ?? []}
                                                                lines={lines}
                                                                areas={areas}
                                                                xAxisProps={{
                                                                    style: {
                                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                                        axis: { stroke: 'none' },
                                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                                        grid: { stroke: 'none' }
                                                                    }
                                                                }}
                                                                yAxisProps={{
                                                                    style: {
                                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                                        axis: { stroke: 'none' },
                                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                                    }
                                                                }}
                                                                title={feature.name}
                                                                hideXAxis={true}
                                                                showMonth={true}
                                                                monthSeparators={true}
                                                                separatorsColor="#df5096"
                                                                legend={legend}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
            <ReactTooltip type="dark" effect="solid" />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { timelineEvent: TimelineEvent | null; search: string; spc: SPC[] | null } => ({
    timelineEvent: state.equipmentDetail.currentTimelineEvent,
    search: state.router.location.search,
    spc: state.timelineEventDetail.SPCData
})

export default connect(mapStateToProps)(DashboardSPC)