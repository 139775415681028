import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../types/app-thunk'
import { call } from '../common/api'
import { toast } from 'react-toastify'
import { SettingsState } from '../types/states/settings-state'
import { SystemSetting } from '../types/entity/system-setting'
import { NewUserSetting } from '../types/new-user-setting'

const settingsInitialState: SettingsState = {
    defaultSettings: null,
    clientSettings: null,
    clientId: "REACT_FRONTEND"
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState: settingsInitialState,
    reducers: {
        setDefaultSettings: (state: SettingsState, { payload }: PayloadAction<SystemSetting[] | null>): void => {
            state.defaultSettings = payload
        },
        setClientSettings: (state: SettingsState, { payload }: PayloadAction<SystemSetting[] | null>): void => {
            state.clientSettings = payload
        }
    }
})

export const { setDefaultSettings, setClientSettings } = settingsSlice.actions

export default settingsSlice.reducer

export const fetchSystemSettings = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getDefaultSettingsRequest = call({
            method: 'GET',
            url: '/settings/system'
        }, dispatch, true)
        const res = await getDefaultSettingsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(settingsSlice.actions.setDefaultSettings(res.data.map((setting: any) => ({ ...setting, isSystem: true }) )))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchClientSettings = (id: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getClientSettingsRequest = call({
            method: 'GET',
            url: `/settings/client/${id}`
        }, dispatch, true)
        const res = await getClientSettingsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(settingsSlice.actions.setClientSettings(res.data.map((setting: any) => ({ ...setting, isSystem: false }) )))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditClientSetting = (data: NewUserSetting, clientId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editClientSettingRequest = call({
            method: 'PUT',
            url: `/settings/client/${clientId}`,
            data: {
                ...data,
                value: data.value
            }
        }, dispatch, true)
        const res = await editClientSettingRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchClientSettings(clientId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditSystemSetting = (data: NewUserSetting, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editSystemSettingRequest = call({
            method: 'PUT',
            url: `/settings/system`,
            data: {
                ...data,
                value: data.value
            }
        }, dispatch, true)
        const res = await editSystemSettingRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchSystemSettings())
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateClientSetting = (data: NewUserSetting): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createClientSettingRequest = call({
            method: 'POST',
            url: `/settings/client`,
            data
        }, dispatch, false)
        await createClientSettingRequest()

        // if (res.status >= 200 && res.status < 300) {

        // }
    } catch (err) {
        console.log(err)
    }
}