import React, { useEffect, useState, ReactElement, OptionHTMLAttributes, useCallback } from 'react'
import { useDispatch, connect } from 'react-redux'
import { toggleSidemenu } from '../../reducers/ui'
import useForm from 'react-hook-form'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import * as yup from 'yup'
import { OptionsType, OptionTypeBase, ValueType } from 'react-select'
import { State } from '../../types/states/state'
import CustomSelect from '../custom/custom-select/custom-select'
import { PreferencesProperties } from '../../types/component-properties/preferences-properties'
import { User } from '../../types/entity/user'
import { fetchPreferences, setPreferences, fetchEditPreferences } from '../../reducers/preferences'
import Footer from '../footer'
import { EditUser } from '../../types/edit-user'
import { useFetching } from '../../common/utils'
import { getUser } from '../../common/api'

const preferencesSchema = yup.object().shape({
    name: yup.string().required('preferences.form.input.errorMessage.required.name'),
    email: yup.string().nullable(),
    username: yup.string().required('preferences.form.input.errorMessage.required.username'),
    languageId: yup.string(),
    enabled: yup.boolean(),
    phone: yup.mixed().nullable(),
    currentUserPwd: yup.string().nullable(),
    password: yup.string(),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'preferences.form.input.errorMessage.match.confirmPassword')
})

const Preferences = (props: PreferencesProperties): ReactElement => {
    const dispatch = useDispatch()

    const [languageId, setLanguageId] = useState(props.preferences?.languageId)
    const [languages] = useState<OptionsType<OptionTypeBase>>([
        { label: 'select.options.italian', value: 'it' },
        { label: 'select.options.english', value: 'en' }
    ])

    const { register, unregister, handleSubmit, errors, setValue, triggerValidation } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        validationSchema: preferencesSchema
    })

    function submit(newPreferences: Record<string, any>): void {
        delete newPreferences.roleId
        // delete newPreferences.username
        delete newPreferences.enabled
        // delete newPreferences.password

        if (!((newPreferences.password && newPreferences.password !== '') && (newPreferences.confirmPassword && newPreferences.confirmPassword !== "") && (newPreferences.currentUserPwd && newPreferences.currentUserPwd !== ''))) {
            delete newPreferences.password
            delete newPreferences.currentUserPwd
        }

        if (newPreferences.email === '') {
            newPreferences.email = null
        }

        if (newPreferences.phone === '') {
            newPreferences.phone = null
        }

        delete newPreferences.confirmPassword

        dispatch(fetchEditPreferences(props.preferences?.id, newPreferences as EditUser, intl.formatMessage({ id: 'preferences.toast.success.preferencesUpdated' })))
    }

    function handleUpdateLanguageId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('languageId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'languageId' })
        setLanguageId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    const resetUser = useCallback((): void => {
        if (props.preferences && props.preferences.id) {
            setValue('languageId', props.preferences.languageId)
            setLanguageId(props.preferences.languageId)
            setValue('username', props.preferences.username)
            setValue('name', props.preferences.name)
            setValue('email', props.preferences.email)
            setValue('phone', props.preferences.phone)
            setValue('enabled', props.preferences.enabled)
        }
    }, [setValue, props.preferences])

    useFetching(fetchPreferences, 'preferences', [], getUser()?.id)

    useEffect(() => {
        dispatch(toggleSidemenu(false))
        register({ name: 'languageId' })
        register({ name: 'enabled' })

        // if (props.preferences) {
        //     setValue('languageId', props.preferences.languageId)
        //     setLanguageId(props.preferences.languageId)
        //     setValue('phone', props.preferences.phone)
        //     setValue('enabled', props.preferences.enabled)
        // }

        return (): void => {
            dispatch(setPreferences(null))
            unregister('languageId')
            unregister('enabled')
        }
    }, [dispatch, register, unregister, setValue])

    useEffect(() => {
        resetUser()
    }, [resetUser, props.preferences])

    return (
        <React.Fragment>
            <div className="h-full">
                {
                    props.preferences &&
                    <form className="flex flex-col w-full h-full overflow-y-auto">
                        <div className="h-full flex-grow p-8 min-w-60 max-w-120">
                            <CustomInput
                                className="w-full"
                                name="username"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.username' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.username ? intl.formatMessage({ id: errors.username.message }) : undefined}
                                required={true}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.username' })}...`}
                                defaultValue={props.preferences.username} />
                            <CustomInput
                                className="w-full"
                                name="name"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.name' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                                required={true}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.name' })}...`}
                                defaultValue={props.preferences.name} />
                            <CustomInput
                                className="w-full"
                                name="email"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.email' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.email' })}...`}
                                defaultValue={props.preferences.email} />
                            <CustomInput
                                className="w-full"
                                name="phone"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.phone' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.phone' })}...`}
                                defaultValue={props.preferences.phone} />
                            <CustomInput
                                className="w-full"
                                name="currentUserPwd"
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.currentPassword' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.currentUserPwd ? intl.formatMessage({ id: errors.currentUserPwd.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.currentPassword' })}...`}
                                defaultValue="" />
                            <CustomInput
                                className="w-full"
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.newPassword' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.password ? intl.formatMessage({ id: errors.password.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.newPassword' })}...`}
                                defaultValue="" />
                            <CustomInput
                                className="w-full"
                                name="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                label={intl.formatMessage({ id: 'preferences.form.input.label.confirmPassword' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.confirmPassword ? intl.formatMessage({ id: errors.confirmPassword.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.input.placeholder.confirmPassword' })}...`}
                                defaultValue="" />
                            <CustomSelect
                                name="languageId"
                                options={languages}
                                label={intl.formatMessage({ id: 'preferences.form.select.label.language' }).toUpperCase()}
                                errorMessage={errors.languageId ? intl.formatMessage({ id: errors.languageId.message }) : undefined}
                                translate={true}
                                placeholder={`${intl.formatMessage({ id: 'preferences.form.select.placeholder.language' })}...`}
                                value={languageId}
                                onChange={handleUpdateLanguageId} />
                        </div>
                    </form>
                }
            </div>
            <Footer
                hideDelete={true}
                handleCancel={resetUser}
                handleSave={handleSubmit(submit)}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { preferences: User | null } => ({
    preferences: state.preferences.preferences
})

export default connect(mapStateToProps)(Preferences)