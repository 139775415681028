import { UiState } from '../types/states/ui-state'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BreadcrumbPath } from '../types/entity/breadcrumb-path'
import { SectionName } from '../types/section-name'
import { RequestQueryParams } from '../types/request-query-params'
import { LocationDescriptorObject } from 'history'
import { Locale } from '../types/locale'
import { SidemenuData } from '../types/sidemenu-data'
import { AppThunk } from '../types/app-thunk'
import { call, getUser, getBreadcrumbPaths } from '../common/api'
import { AppVersion } from '../types/app-version'
import { EventFile } from '../types/event-file'
import { SimpleItem } from '../types/simple-item'
import { Tags } from '../types/tags'
import { OptionsType, OptionTypeBase } from 'react-select'

const uiInitialState: UiState = {
    loadingCounter: 0,
    displaySidemenu: true,
    breadcrumbPaths: getBreadcrumbPaths() ?? [],
    customerId: null,
    limit: 10,
    page: 0,
    filter: null,
    orderBy: null,
    orderType: null,
    currentSection: (localStorage.getItem('currentSection') ?? 'none') as SectionName,
    locale: getUser()?.language as Locale,
    sectionQueryParams: {
        limit: "10",
        page: "0"
    },
    sidemenuData: [],
    version: null,
    tags: {
        baselineTags: [],
        imageTags: []
    },
    toolOptions: [
        // Mouse
        {
            name: 'Wwwc',
            mode: 'active',
            modeOptions: { mouseButtonMask: 2 },
        },
        {
            // name: 'ZoomMouseWheel',
            name: 'Zoom',
            modeOptions: { mouseButtonMask: 4 },
            mode: 'active',
            props: {
                configuration: {
                    minScale: 0.01
                }
            }
        },
        {
            name: 'Pan',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1 },
        },
        // Scroll
        {
            name: 'StackScrollMouseWheel',
            mode: 'active',
            // modeOptions: { mouseButtonMask: 4 },
        },

        // Touch
        { name: 'PanMultiTouch', mode: 'active' },
        { name: 'ZoomTouchPinch', mode: 'active' },
        { name: 'StackScrollMultiTouch', mode: 'active' }
    ],
    userRoles: []
}

const uiSlice = createSlice({
    name: 'ui',
    initialState: uiInitialState,
    reducers: {
        incrementCounter: (state: UiState): void => {
            state.loadingCounter += 1
        },
        decrementCounter: (state: UiState): void => {
            state.loadingCounter -= 1
        },
        toggleSidemenu: (state: UiState, { payload }: PayloadAction<boolean>): void => {
            state.displaySidemenu = payload
        },
        addPath: (state: UiState, { payload }: PayloadAction<BreadcrumbPath>): void => {
            state.breadcrumbPaths.push(payload)
        },
        setPaths: (state: UiState, { payload }: PayloadAction<BreadcrumbPath[]>): void => {
            state.breadcrumbPaths = payload
        },
        splicePath: (state: UiState, {payload}: PayloadAction<number>): void => {
            state.breadcrumbPaths.splice(payload)
        },
        resetPaths: (state: UiState): void => {
            state.breadcrumbPaths = []
        },
        updatePage: (state: UiState, { payload }: PayloadAction<number>): void => {
            state.breadcrumbPaths.forEach((path, index) => {
                if (path.destination.pathname?.startsWith("/dashboard/equipment-detail") || path.destination.pathname?.startsWith("/dashboard/timeline-detail")) {
                    const oldSearch = state.breadcrumbPaths[index].destination.search
                    const splittedSearch = oldSearch?.split("&page=")
                    state.breadcrumbPaths[index].destination.search = `${splittedSearch?.[0] ?? ""}&page=${payload}`
                }
            })
        },
        updateLastPath: (state: UiState, { payload }: PayloadAction<BreadcrumbPath>): void => {
            state.breadcrumbPaths[state.breadcrumbPaths.length - 1] = payload
        },
        updatePathLabel: (state: UiState, { payload }: PayloadAction<{ index: number; label: string }>): void => {
            state.breadcrumbPaths[payload.index].label = payload.label
        },
        updatePathDestination: (state: UiState, { payload }: PayloadAction<{ index: number; destination: LocationDescriptorObject }>): void => {
            state.breadcrumbPaths[payload.index].destination = payload.destination
        },
        setCustomerId: (state: UiState, { payload }: PayloadAction<string | null>): void => {
            state.customerId = payload
        },
        setLimit: (state: UiState, { payload }: PayloadAction<number | null>): void => {
            state.limit = payload
        },
        setPage: (state: UiState, { payload }: PayloadAction<number | null>): void => {
            state.page = payload
        },
        setFilter: (state: UiState, { payload }: PayloadAction<string | null>): void => {
            state.filter = payload
        },
        setOrderBy: (state: UiState, { payload }: PayloadAction<string | null>): void => {
            state.orderBy = payload
        },
        setOrderType: (state: UiState, { payload }: PayloadAction<string | null>): void => {
            state.orderType = payload
        },
        setCurrentSection: (state: UiState, { payload }: PayloadAction<SectionName>): void => {
            state.currentSection = payload
            state.sectionQueryParams = {}
        },
        setLocale: (state: UiState, { payload }: PayloadAction<Locale>): void => {
            state.locale = payload
        },
        setQueryParams: (state: UiState, { payload }: PayloadAction<RequestQueryParams>): void => {
            state.sectionQueryParams = payload
        },
        setSidemenuData: (state: UiState, { payload }: PayloadAction<SidemenuData[]>): void => {
            state.sidemenuData = payload
        },
        setVersion: (state: UiState, { payload }: PayloadAction<AppVersion>): void => {
            state.version = payload
        },
        setTags: (state: UiState, { payload }: PayloadAction<Tags>): void => {
            state.tags = payload
        },
        setUserRoles: (state: UiState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.userRoles = payload
        }
    }
})

export const { incrementCounter, decrementCounter, toggleSidemenu, addPath, setPaths, splicePath, resetPaths, updatePage, updateLastPath, updatePathLabel, updatePathDestination, setCustomerId, setLimit, setPage, setFilter, setOrderBy, setOrderType, setCurrentSection, setLocale, setQueryParams, setSidemenuData, setTags, setUserRoles } = uiSlice.actions

export default uiSlice.reducer

export const fetchVersion = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getVersionRequest = call({
            method: 'GET',
            url: '/versions'
        }, dispatch, false)
        const res = await getVersionRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(uiSlice.actions.setVersion(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchTags = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getTagsRequest = call({
            method: 'GET',
            url: '/tags'
        }, dispatch, false)
        const res = await getTagsRequest()

        if (res.status >= 200 && res.status < 300) {
            const tags = {
                baselineTags: res.data.baselineTags.map((tag: SimpleItem) => {
                    return {
                        label: tag.name,
                        value: tag.id
                    }
                }),
                imageTags: res.data.imageTags.map((tag: SimpleItem) => {
                    return {
                        label: tag.name,
                        value: tag.id
                    }
                })
            }
            dispatch(uiSlice.actions.setTags(tags))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchFile = (data: EventFile, indices: number, encoding: 'BASE64' | 'BINARY' = 'BINARY', loading = false): AppThunk => async (dispatch): Promise<any> => {
    try {
        const getFileRequest = call({
            method: 'GET',
            url: `/files/${data.id}`,
            params: {
                indices,
                type: data.type,
                encoding
            }
        }, dispatch, loading)
        return getFileRequest()
        // dispatch(uiSlice.actions.setVersion(res.data))
    } catch (err) {
        console.log(err)
    }
}

export const fetchUserRoles = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getUserRolesRequest = call({
            method: 'GET',
            url: '/users/roles'
        }, dispatch, false)
        const res = await getUserRolesRequest()

        if (res.status >= 200 && res.status < 300) {
            const newRoles = res.data.map((role: SimpleItem) => ({
                label: role.name,
                value: role.id
            }))
            dispatch(uiSlice.actions.setUserRoles(newRoles))
        }
    } catch (err) {
        console.log(err)
    }
}