import React, { ReactElement, useEffect, BaseSyntheticEvent } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import { MedicalCentresEditorProperties } from '../../types/component-properties/medical-centres-editor-properties'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../types/states/state'
import { fetchMedicalCentre, setCurrentMedicalCentre } from '../../reducers/medical-centres'
import { MedicalCentre } from '../../types/entity/medical-centre'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import Footer from '../footer'
import * as yup from 'yup'
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import { EditMedicalCentre } from '../../types/edit-medical-centre'
import queryString from 'query-string'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const medicalCentreSchema = yup.object().shape({
    name: yup.string().required('medicalCentres.editor.form.input.errorMessage.required.name'),
    customerId: yup.string(),
    email: yup.string(),
    phone: yup.string()
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const MedicalCentresEditor = (props: MedicalCentresEditorProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const queryParams = queryString.parse(props.search)
        
    const { register, unregister, errors, handleSubmit, setValue } = useForm({
        validationSchema: medicalCentreSchema
    })

    function submit(medicalCentre: Record<string, any>): void {
        delete medicalCentre.customerId

        props.handleEdit(props.medicalCentre?.id ?? '0', medicalCentre as EditMedicalCentre)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleNavigate(): void {
        props.handleNavigate(0)
    }

    function resetMedicalCentre(): void {
        if (props.medicalCentre) {
            setValue('name', props.medicalCentre.name)
            setValue('customerId', props.medicalCentre.customerId)
            setValue('email', props.medicalCentre.email)
            setValue('phone', props.medicalCentre.phone)
        }
    }

    useFetching(fetchMedicalCentre, 'medical-centres', [], queryParams.id as string)

    useEffect(() => {
        return (): void => {
            dispatch(setCurrentMedicalCentre(null))
        }
    }, [dispatch])

    useEffect(() => {
        register({ name: 'customerId' })

        if (props.medicalCentre) {
            setValue('customerId', props.medicalCentre.customerId)
        }

        return (): void => {
            unregister('customerId')
        }
    }, [register, unregister, setValue, props.medicalCentre])

    return (
        <React.Fragment>
            {
                props.medicalCentre &&
                <div className="pt-4 px-8">
                    <CustomButton
                        Icon={BackArrowIcon}
                        cssStyles={[classes["back-button"]]}
                        className="bg-lightblue text-primary min-w-40 font-bold px-6 mb-5"
                        label={intl.formatMessage({ id: 'medicalCentres.editor.backButton.label' })}
                        onClick={handleNavigate} />

                    <form className="min-w-60 max-w-60" autoComplete="off">
                        <CustomInput
                            name="name"
                            label={intl.formatMessage({ id: 'medicalCentres.editor.form.input.label.name' }).toUpperCase()}
                            formRef={register}
                            errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'medicalCentres.editor.form.input.placeholder.name' })}...`}
                            defaultValue={props.medicalCentre.name} />
                        <CustomInput
                            name="email"
                            label={intl.formatMessage({ id: 'medicalCentres.editor.form.input.label.email' }).toUpperCase()}
                            formRef={register}
                            errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                            placeholder={`${intl.formatMessage({ id: 'medicalCentres.editor.form.input.placeholder.email' })}...`}
                            defaultValue={props.medicalCentre.email} />
                        <CustomInput
                            name="phone"
                            label={intl.formatMessage({ id: 'medicalCentres.editor.form.input.label.phone' }).toUpperCase()}
                            formRef={register}
                            errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                            placeholder={`${intl.formatMessage({ id: 'medicalCentres.editor.form.input.placeholder.phone' })}...`}
                            defaultValue={props.medicalCentre.phone} />
                        <CustomInput
                            label={intl.formatMessage({ id: 'medicalCentres.editor.form.input.label.totalEquipments' })}
                            defaultValue={`${props.medicalCentre.activeEquipments}/${props.medicalCentre.totalEquipments}`}
                            readonly={true} />
                    </form>
                </div>
            }
            <Footer
                item={props.medicalCentre}
                editing={true}
                canDelete={props.loggedUser?.acl.includes('MEDICAL_CENTRES_DELETE')}
                handleDelete={props.handleDelete}
                handleCancel={resetMedicalCentre}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}


const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; medicalCentre: MedicalCentre | null; search: string; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    medicalCentre: state.medicalCentres.currentMedicalCentre,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(MedicalCentresEditor)
