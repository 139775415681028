import React, { ReactElement, useState, ChangeEvent, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SidemenuProperties } from '../../types/component-properties/sidemenu-properties'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { ReactComponent as UsersMenuIcon } from '../../assets/icons/users-menu.svg'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { SectionName } from '../../types/section-name'
import { User } from '../../types/entity/user'
import { RequestQueryParams } from '../../types/request-query-params'
import { SidemenuData } from '../../types/sidemenu-data'
import { useDebounce } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'

const classes = {
    'not-allowed': css`
        circle {
            fill: var(--color-gray-100); !important;
        }

        &:hover {
            cursor: not-allowed !important;
            opacity: 1 !important;
        }
    `,
    'icon': css`
        &:before {
            content: '';
            position: absolute;
            height: 0.5rem;
            width: 0.5rem;
            border: none;
            top: unset;
            left: 0.25rem;
            right: 0.5rem;
            border-top: 2px solid var(--color-darkgray);
            border-left: 2px solid var(--color-darkgray);
            transform: rotate(-45deg);
            transition: transform 0.1s;
        }
    `
}

const UsersMenu = ({ handleInputChange, ...props}: SidemenuProperties<User>): ReactElement => {
    

    const [inputFilter, setInputFilter] = useState<string | null>(null)
    const debouncedFilter = useDebounce(inputFilter, props.currentSection, 600)

    function handleCreateItem(): void {
        if (!(props.paths.length && props.paths[props.paths.length - 1].destination.pathname === '/users/new')) {
            props.handleCreateItem()
        }
    }

    function setProperty(property: string): string {
        let newProperty = ''

        switch(property) {
            case 'CUSTOMER':
                newProperty = 'customer'

                break
            case 'MEDICAL_CENTRE':
                newProperty = 'medicalCentre'

                break
            case 'EQUIPMENT':
                newProperty = 'equipment'
        }

        return newProperty
    }

    function inputChanged(event: ChangeEvent<HTMLInputElement>): void {
        setInputFilter(event.target.value === '' ? null : event.target.value)
    }

    useEffect(() => {
        handleInputChange(debouncedFilter.value)
    }, [handleInputChange, debouncedFilter])

    return (
        <React.Fragment>
            <div className="flex flex-col h-full w-full overflow-hidden p-8 pb-0 text-darkgray">
                <div className='flex justify-between items-center font-500 mb-6 mt-0'>
                    <div className="flex items-center">
                        {/* <img src={UsersMenuIcon} className="w-4 h-4 mr-2" alt="" /> */}
                        <UsersMenuIcon className="w-4 h-4 mr-2" />
                        {intl.formatMessage({ id: 'users.sidemenu.title' })}
                    </div>

                    {
                        props.loggedUser?.acl.includes('USERS_CREATE') &&
                        <AddIcon
                            css={[props.paths.length && props.paths[props.paths.length - 1].destination.pathname === '/users/new' ? classes["not-allowed"] : undefined]}
                            className="cursor-pointer hover:opacity-50"
                            data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                            data-for="sidemenu-tooltip" 
                            onClick={handleCreateItem} />
                    }
                </div>
                <CustomInput
                    className="mb-6 h-10 shadow-menu-input"
                    placeholder={`${intl.formatMessage({ id: 'users.sidemenu.input.placeholder' })}...`}
                    onChange={inputChanged} />

                {
                    props.loggedUser?.role === 'ADMINISTRATOR' && props.paths.length > 0 && props.paths[0].id && props.paths[0].id !== '' &&
                    <div className="mb-1">
                        <div className="flex items-center mb-4 cursor-pointer hover:opacity-50" onClick={(): void => props.handleNavigate(0)}>
                            <div css={[classes.icon]} className="flex items-center justify-center w-4 h-4 relative arrow-icon"></div>
                            <p className="my-0 ml-2 font-bold">{props.paths[0].label}</p>
                        </div>
                    </div>
                }
                {
                    props.loggedUser?.role === 'ADMINISTRATOR' && props.paths.length === 0 &&
                    <p className='font-extra-bold text-2xs mb-4 mt-0'>
                        {intl.formatMessage({ id: 'sidemenu.filterBy' }).toUpperCase()}
                    </p>
                }
                {
                    props.loggedUser?.role === 'ADMINISTRATOR' && props.paths.length === 0 &&
                    <div className="mb-4 font-bold">
                        {intl.formatMessage({ id: 'users.sidemenu.filterLabel' })}
                    </div>
                }

                <div className="pl-6 font-500">
                    {
                        props.sidemenuData.length > 0 && props.loggedUser?.role === 'ADMINISTRATOR' && props.paths.length === 0 &&
                        props.sidemenuData[0].elements.map(element => {
                            return (
                                <div
                                    key={element.id}
                                    className="flex justify-between mb-4 text-sm cursor-pointer hover:opacity-50"
                                    onClick={(): void => props.handleFilter({
                                        label: element.name,
                                        id: element.id,
                                        destination: {
                                            pathname: `/${props.currentSection}`
                                        },
                                        param: {
                                            property: setProperty(props.sidemenuData[0].type) + 'Id' as keyof RequestQueryParams,
                                            value: element.id
                                        }
                                    })}
                                >
                                    <span className={props.paths.length > 0 ? (props.paths[0].id === element.id ? 'font-extra-bold' : '') : ''}>{element.name}</span>
                                    <span className="text-primary text-xs">{element.elementsNumber}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { data: User[]; paths: BreadcrumbPath[]; currentSection: SectionName; sidemenuData: SidemenuData[]; loggedUser: LoggedUser | null }  => ({
    data: state.users.users,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    sidemenuData: state.ui.sidemenuData,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(UsersMenu)
