import React, { ReactElement } from 'react'
import { DateIntervalCellProperties } from '../../../types/component-properties/date-interval-cell-properties'
import { DateTime } from 'luxon'
import { State } from '../../../types/states/state'
import { connect } from 'react-redux'
import { Locale } from '../../../types/locale'

const DateIntervalCell = (props: DateIntervalCellProperties): ReactElement => {
    // return <React.Fragment></React.Fragment>
    // return <React.Fragment>{props.date !== null && props.date !== undefined ? DateTime.fromMillis(props.date).toLocaleString({ locale: props.locale }) : ''}</React.Fragment>
    return (
        <React.Fragment>
            <div className="whitespace-no-wrap">
                {
                    props.firstDate && props.secondDate &&
                    <React.Fragment>
                        <span>{DateTime.fromMillis(props.firstDate).toLocaleString({ ...DateTime.DATE_SHORT, locale: props.locale, numberingSystem: undefined })}</span> |&nbsp;
                        <span>{DateTime.fromMillis(props.secondDate).toLocaleString({ ...DateTime.DATE_SHORT, locale: props.locale, numberingSystem: undefined })}</span>
                    </React.Fragment>
                }
                {
                    !(props.firstDate && props.secondDate) &&
                    <React.Fragment>
                        <span className="text-transparent select-none">?</span>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { locale: Locale }  => ({
    locale: state.ui.locale
})

export default connect(mapStateToProps)(DateIntervalCell)

