import React, { ReactElement, useEffect, useState, useRef } from 'react'
import { Switch, Route } from 'react-router'
import { SectionProperties } from '../../types/component-properties/section-properties'
import { State } from '../../types/states/state'
import { RequestQueryParams } from '../../types/request-query-params'
import { connect, useDispatch } from 'react-redux'
import { fetchProtocols, fetchProtocol, setCurrentProtocol, fetchEditProtocol, fetchCreateProtocol, fetchDeleteProtocol, fetchDeleteProtocolRequirement, fetchDuplicateProtocol, setContraints, fetchConstraints } from '../../reducers/protocols'
import ProtocolsCreator from './protocols-creator'
import { NewProtocol } from '../../types/new-protocol'
import ProtocolsEditor from './protocols-editor'
import { toggleSidemenu } from '../../reducers/ui'
import { Protocol } from '../../types/entity/protocol'
import { LocationDescriptorObject } from 'history'
import { ProtocolDetail } from '../../types/entity/protocol-detail'
import ProtocolsDetail from './protocols-detail'
import CustomConfirmDelete from '../custom/custom-confim-delete'
import { intl } from '../../intl'
import { ProtocolsProperties } from '../../types/component-properties/protocols-properties'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import queryString from 'query-string'
import { SectionName } from '../../types/section-name'
import { EditProtocol } from '../../types/edit-protocol'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const Protocols = ({ handleFilter, ...props }: SectionProperties & ProtocolsProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const isFirstRun = useRef(true)
    const queryParams = queryString.parse(props.location.search ?? '')

    const [newProtocol] = useState<NewProtocol>({ name: '', customerId: '', testObjectId: '' })
    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})
    const [customerId, setCustomerId] = useState<string>()

    function handleCreateProtocol(protocol: FormData): void {
        dispatch(fetchCreateProtocol(protocol, props.queryParams, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolCreated'}), props.loggedUser?.role === 'ADMINISTRATOR'))
    }

    function handleEditProtocol(protocol: EditProtocol): void {
        if (props.currentProtocol) {
            dispatch(fetchEditProtocol(props.currentProtocol.id, protocol, props.queryParams, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolUpdated'})))
        }
    }

    function handleDeleteProtocol(): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                if (props.currentProtocol) {
                    dispatch(fetchDeleteProtocol(props.currentProtocol.id, props.queryParams, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolDeleted' })))
                }
            }
        })
    }

    function handleDuplicateProtocol(): void {
        if (props.currentProtocol) {
            dispatch(fetchDuplicateProtocol(props.currentProtocol.id, props.queryParams, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolUpdated'}), props.loggedUser?.role === 'ADMINISTRATOR'))
        }
    }

    function handleDeleteProtocolRequirement(protocolId: string, requirementId: string): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteProtocolRequirement(protocolId, requirementId, intl.formatMessage({ id: 'protocolsVault.toast.success.protocolRequirementDeleted' })))
            }
        })
    }

    useFetching(fetchProtocols, 'protocols', [], props.queryParams)

    useEffect(() => {
        const pathname = props.location.pathname === '/protocols' ? '/protocols/detail' : props.location.pathname
        if (!props.protocols && props.currentProtocol) {
            handleFilter({ label: props.currentProtocol.name, id: props.currentProtocol.id, destination: { pathname: pathname, search: `?id=${props.currentProtocol.id}` }, param: { property: 'name', value: props.currentProtocol.name }})
        } else if (props.protocols && props.protocols.length > 0 && !props.currentProtocol) {
            handleFilter({ label: props.protocols[0].name, id: props.protocols[0].id, destination: { pathname: pathname, search: `?id=${props.protocols[0].id}` }, param: { property: 'name', value: props.protocols[0].name }})
            dispatch(fetchProtocol(props.protocols[0].id))
        }
     }, [dispatch, handleFilter, props.protocols, props.location, props.currentProtocol])

     useEffect(() => {
        if (props.currentProtocol && !customerId) {
            setCustomerId(props.currentProtocol.customerId)
            dispatch(fetchConstraints({ customerId: props.currentProtocol.customerId }))
        }
     }, [dispatch, props.currentProtocol, customerId])

     useEffect(() => {
        if (props.location.pathname === '/protocols' && props.currentProtocol) {
            dispatch(setCurrentProtocol(null))
            setCustomerId(undefined)
            dispatch(setContraints(null))
        }
     }, [dispatch, props.location, props.currentProtocol])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (!props.currentProtocol && queryParams.id && props.location.pathname !== '/protocols/detail') {
            dispatch(fetchProtocol(queryParams.id as string))
        }

        dispatch(toggleSidemenu(true))
    }, [dispatch, queryParams, props.location, props.currentProtocol])

    useEffect(() => {
        return (): void => {
            dispatch(setCurrentProtocol(null))
            dispatch(setContraints(null))
        }
    }, [dispatch])

    return (
        <React.Fragment key={`${props.location.pathname}`}>
            <Switch>
                <Route exact path='/protocols/detail'>
                    <ProtocolsDetail
                        setModal={setModal}
                        setModalInfo={setModalInfo}
                        handleNavigate={props.handleNavigate}
                        handleFilter={handleFilter}
                        handleDeleteProtocol={handleDeleteProtocol}
                        handleDuplicateProtocol={handleDuplicateProtocol}
                        handleDeleteProtocolRequirement={handleDeleteProtocolRequirement}></ProtocolsDetail>
                </Route>
                <Route exact path='/protocols/new'>
                    <ProtocolsCreator
                        protocol={newProtocol}
                        handleNavigate={props.handleNavigate}
                        handleCreateProtocol={handleCreateProtocol}
                        handleFilter={handleFilter}></ProtocolsCreator>
                </Route>
                <Route exact path='/protocols/edit'>
                    <ProtocolsEditor
                        handleNavigate={props.handleNavigate}
                        handleEditProtocol={handleEditProtocol}
                        handleFilter={handleFilter}
                        handleDelete={handleDeleteProtocol}></ProtocolsEditor>
                </Route>
            </Switch>
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { queryParams: RequestQueryParams; location: LocationDescriptorObject; currentProtocol: ProtocolDetail | null; protocols: Protocol[]; paths: BreadcrumbPath[]; currentSection: SectionName; loggedUser: LoggedUser | null }  => ({
    queryParams: state.ui.sectionQueryParams,
    location: state.router.location,
    currentProtocol: state.protocols.currentProtocol,
    protocols: state.protocols.protocols,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(Protocols)
