import React, { ReactElement, BaseSyntheticEvent, OptionHTMLAttributes, useEffect, useState, useRef } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { MedicalCentresCreatorProperties } from '../../types/component-properties/medical-centres-creator-properties'
import * as yup from 'yup'
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import Footer from '../footer'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import { fetchCustomerOptions, setCustomerOptions } from '../../reducers/customers'
import { NewMedicalCentre } from '../../types/new-medical-centre'
import { useDebounce } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const medicalCentreSchema = yup.object().shape({
    name: yup.string().required('medicalCentres.creator.form.input.errorMessage.required.name'),
    customerId: yup.string().required('medicalCentres.creator.form.select.errorMessage.required.organisation'),
    email: yup.string(),
    phone: yup.string()
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const MedicalCentresCreator = (props: MedicalCentresCreatorProperties): ReactElement => {
    const dispatch = useDispatch()
    

    const [customerId, setCustomerId] = useState<string>('')
    const debounce = useDebounce('', 'medical-centres', 10, [])
    const isFirstRun = useRef(true)
    
    const { register, errors, setValue, triggerValidation, handleSubmit } = useForm({
        validationSchema: medicalCentreSchema
    })

    function submit(medicalCentre: Record<string, any>): void {
        if (props.loggedUser?.role !== 'ADMINISTRATOR' || medicalCentre.customerId === 'noId') {
            delete medicalCentre.customerId
        }

        props.handleCreate(medicalCentre as NewMedicalCentre)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleUpdateCustomer(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('customerId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'customerId' })
        setCustomerId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleNavigate(): void {
        props.handleNavigate(0)
    }

    function resetMedicalCentre(): void {
        setValue('name', '')
        setValue('customerId', '')
        setCustomerId('')
        setValue('email', '')
        setValue('phone', '')
    }

    useEffect(() => {
        return (): void => {
            dispatch(setCustomerOptions([]))
        }
    }, [dispatch])

    useEffect(() => {
        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            register({ name: 'customerId' })
            setValue('customerId', props.loggedUser?.customerId)
        }
    }, [register, setValue, props.loggedUser])

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        if (props.loggedUser?.role === 'ADMINISTRATOR') {
            dispatch(fetchCustomerOptions())
        }
    }, [dispatch, props.loggedUser, debounce])

    return (
        <React.Fragment>
            <div key={props.medicalCentre.name} className="pt-4 px-8">
                <CustomButton
                    Icon={BackArrowIcon}
                    cssStyles={[classes["back-button"]]}
                    className="bg-lightblue text-primary min-w-40 font-bold px-6 mb-5"
                    label={intl.formatMessage({ id: 'medicalCentres.creator.backButton.label' })}
                    onClick={handleNavigate} />

                <form className="min-w-60 max-w-60" autoComplete="off">
                    <CustomInput
                        className="w-full"
                        name="name"
                        label={intl.formatMessage({ id: 'medicalCentres.creator.form.input.label.name' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'medicalCentres.creator.form.input.placeholder.name' })}...`}
                        defaultValue={props.medicalCentre.name} />
                    {
                        props.loggedUser?.role === 'ADMINISTRATOR' &&
                        <CustomSelect
                            options={props.customerOptions}
                            name="customerId"
                            label={intl.formatMessage({ id: 'medicalCentres.creator.form.select.label.organisation' }).toUpperCase()}
                            selectRef={register({ name: `customerId` })}
                            errorMessage={errors.customerId ? intl.formatMessage({ id: errors.customerId.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'medicalCentres.creator.form.select.placeholder.organisation' })}...`}
                            value={customerId}
                            onChange={handleUpdateCustomer} />
                    }
                    <CustomInput
                        className="w-full"
                        name="email"
                        label={intl.formatMessage({ id: 'medicalCentres.creator.form.input.label.email' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'medicalCentres.creator.form.input.placeholder.email' })}...`}
                        defaultValue={props.medicalCentre.email} />
                    <CustomInput
                        className="w-full"
                        name="phone"
                        label={intl.formatMessage({ id: 'medicalCentres.creator.form.input.label.phone' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.phone ? intl.formatMessage({ id: errors.phone.message }) : undefined}
                        placeholder={`${intl.formatMessage({ id: 'medicalCentres.creator.form.input.placeholder.phone' })}...`}
                        defaultValue={props.medicalCentre.phone} />
                </form>
            </div>
            <Footer
                item={props.medicalCentre}
                editing={false}
                handleCancel={resetMedicalCentre}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; customerOptions: OptionsType<OptionTypeBase>; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    customerOptions: state.customers.customerOptions,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(MedicalCentresCreator)
