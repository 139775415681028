import React, { ReactElement, useState, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { intl } from '../../../intl'
import CustomTextarea from '../../custom/custom-textarea'
import CustomButton from '../../custom/custom-button'
import { ReactComponent as EditIcon } from '../../../assets/icons/comment-edit.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/comment-delete.svg'
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg'
import { ReactComponent as CommentIcon } from '../../../assets/icons/comment.svg'
import { ReactComponent as UserCommentIcon } from '../../../assets/icons/user-comment.svg'
import ReactTooltip from 'react-tooltip'
import { DateTime } from 'luxon'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../../types/states/state'
import { Locale } from '../../../types/locale'
import { DashboardTimelineCommentsProperties } from '../../../types/component-properties/dashobard-timeline-comments-properties'
import { TimelineEventComment } from '../../../types/entity/timeline-event-comment'
import { setComments, fetchComments } from '../../../reducers/timeline-event-detail'
import queryString from 'query-string'
import { useFetching } from '../../../common/utils'
import classNames from 'classnames'

const classes = {
    'comment': css`
        .comment-block {
            grid-template-rows: 1fr 2.5rem;
            grid-template-columns: 2.5rem 1fr 2.5rem;
            grid-template-areas: 'message-icon comment action-icons'
                                 'author-icon author-date void';
        }

        .message-icon {
            grid-area: message-icon;
        }

        .comment {
            grid-area: comment;
        }

        .action-icons {
            grid-area: action-icons;
        }

        .author-icon {
            grid-area: author-icon;
        }

        .author-date {
            grid-area: author-date;
        }
        
        .void {
            grid-area: void;
        }

        &:nth-of-type(even) > .comment-block {
            background: var(--color-background-first);
        }
    `
}

const DashboardTimelineComments = (props: DashboardTimelineCommentsProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [content, setContent] = useState('')
    const [editingComment, setEditingComment] = useState('')
    const [newContent, setNewContent] = useState('')

    function createComment(): void {
        props.handleCreateComment(queryParams.id as string, content)
    }

    function prepareForEdit(comment: TimelineEventComment): void {
        setEditingComment(comment.id)
        setNewContent(comment.content)
    }

    function resetEditing(): void {
        setEditingComment('')
        setNewContent('')
    }

    useFetching(fetchComments, 'dashboard', [], queryParams.id)

    useEffect(() => {
        setEditingComment('')
        setNewContent('')
        setContent('')
    }, [props.comments])

    useEffect(() => {
        return (): void => {
            dispatch(setComments(null))
        }
    }, [dispatch])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return (
        <React.Fragment>
            {
                props.comments &&
                <div className={props.className}>
                    <div className={classNames({
                        "flex h-full w-full flex-grow": true,
                        "flex-col": !props.onlyComments,
                        "flex-row": props.onlyComments
                    })}>
                        {
                            !props.onlyComments &&
                            <div className="bg-background-first border-0 border-l border-b border-r border-solid border-gray-100 p-8 w-full min-w-1/2">
                                <h6 className="font-bold mt-0">{intl.formatMessage({ id: 'dashboard.comments.newComment.title' })}</h6>

                                <CustomTextarea
                                    className="my-4 w-full"
                                    placeholder={`${intl.formatMessage({ id: 'dashboard.comments.newComment.textarea.placeholder' })}...`}
                                    value={content}
                                    onChange={setContent}></CustomTextarea>

                                <CustomButton
                                    className="bg-primary text-white ml-auto px-6"
                                    label={intl.formatMessage({ id: 'dashboard.comments.newComment.submitButton.label' })}
                                    disabled={!content || content === ''}
                                    onClick={createComment} />
                            </div>
                        }
                        <div className={classNames({
                            "mt-4 flex-grow": true,
                            "pl-8 w-3/5": props.onlyComments,
                            "min-w-1/2": !props.onlyComments
                        })}>
                            {
                                props.comments.map((comment, index) => {
                                    return (
                                        <div key={index} css={[classes.comment]} className="min-h-40 pr-10">
                                            <div className="grid min-h-40 py-6 px-2 comment-block">
                                                <div className="flex justify-center message-icon">
                                                    <CommentIcon className="mt-1" />
                                                </div>
                                                <div className="comment pr-4 ml-1">
                                                    {
                                                        comment.id === editingComment ?
                                                            <CustomTextarea
                                                                className="w-full"
                                                                value={comment.content}
                                                                onChange={setNewContent}
                                                                rows={2}></CustomTextarea>
                                                            :
                                                            comment.content
                                                    }
                                                </div>
                                                <div className="flex flex-col items-center action-icons">
                                                    {
                                                        comment.id === editingComment ?
                                                            <React.Fragment>
                                                                <div
                                                                    className="flex justify-center items-center rounded-full w-6 h-6 cursor-pointer hover:opacity-50 bg-primary mb-2"
                                                                    data-tip={intl.formatMessage({ id: 'tooltip.edit' })}
                                                                    data-for="comments-tooltip"
                                                                    onClick={(): void => props.handleEditComment(queryParams.id as string, comment.id, newContent)}
                                                                >
                                                                    <EditIcon className="w-3 h-3" fill="var(--color-white)" />
                                                                </div>
                                                                <CloseCircleIcon
                                                                    className="w-6 h-6 cursor-pointer hover:opacity-50"
                                                                    data-tip={intl.formatMessage({ id: 'tooltip.cancel' })}
                                                                    data-for="comments-tooltip"
                                                                    onClick={resetEditing} />
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <div
                                                                    className="flex justify-center items-center rounded-full w-6 h-6 cursor-pointer hover:opacity-50 bg-primary mb-2"
                                                                    data-tip={intl.formatMessage({ id: 'tooltip.edit' })}
                                                                    data-for="comments-tooltip"
                                                                    onClick={(): void => prepareForEdit(comment)}
                                                                >
                                                                    <EditIcon className="w-3 h-3" fill="var(--color-white)" />
                                                                </div>
                                                                <div
                                                                    className="flex justify-center items-center rounded-full w-6 h-6 cursor-pointer hover:opacity-50 bg-red"
                                                                    data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                                                                    data-for="comments-tooltip"
                                                                    onClick={(): void => props.handleDeleteComment(queryParams.id as string, comment.id, comment.author)}
                                                                >
                                                                    <DeleteIcon className="w-3 h-3" fill="var(--color-white)" />
                                                                </div>
                                                            </React.Fragment>
                                                    }
                                                </div>
                                                <div className="flex items-center justify-center author-icon">
                                                    <UserCommentIcon />
                                                </div>
                                                <div className="flex items-center author-date ml-1 text-sm">
                                                    <span>{intl.formatMessage({ id: 'dashboard.comments.comment.lastModified' })}: {comment.author}</span>&nbsp;&nbsp;
                                                    <span className="text-gray">{DateTime.fromMillis(comment.lastModified).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  })}</span>
                                                </div>
                                                <div className="void"></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            props.onlyComments &&
                            <div className="bg-background-first border-0 border-l border-b border-r border-solid border-gray-100 p-8 w-2/5">
                                <h6 className="font-bold mt-0">{intl.formatMessage({ id: 'dashboard.comments.newComment.title' })}</h6>

                                <CustomTextarea
                                    className="my-4 w-full"
                                    placeholder={`${intl.formatMessage({ id: 'dashboard.comments.newComment.textarea.placeholder' })}...`}
                                    value={content}
                                    onChange={setContent}></CustomTextarea>

                                <CustomButton
                                    className="bg-primary text-white ml-auto px-6"
                                    label={intl.formatMessage({ id: 'dashboard.comments.newComment.submitButton.label' })}
                                    disabled={!content || content === ''}
                                    onClick={createComment} />
                            </div>
                        }
                    </div>
                </div>
            }
            <ReactTooltip id="comments-tooltip" type="dark" effect="solid" place="right" />
        </React.Fragment>
    )
}

DashboardTimelineComments.defaultProps = {
    className: '',
    onlyComments: false
}

const mapStateToProps = (state: State): { locale: Locale; comments: TimelineEventComment[] | null; search: string }  => ({
    comments: state.timelineEventDetail.comments,
    locale: state.ui.locale,
    search: state.router.location.search
})

export default connect(mapStateToProps)(DashboardTimelineComments)