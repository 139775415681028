import React, { ReactElement, useEffect, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useFetching, downloadFile } from '../../../common/utils'
import queryString from 'query-string'
import { State } from '../../../types/states/state'
import { fetchResultsData, setResultsData } from '../../../reducers/timeline-event-detail'
import { connect, useDispatch } from 'react-redux'
import CustomInput from '../../custom/custom-input'
import { intl } from '../../../intl'
import { DateTime } from 'luxon'
import CustomTextarea from '../../custom/custom-textarea'
import { DashboardTimelineStoredProperties } from '../../../types/component-properties/dashboard-timeline-stored-properties'
import { StoredResultsData } from '../../../types/entity/stored-results-data'

// import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
// import { ReactComponent as FullscreenIcon } from '../../../assets/icons/fullscreen.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CornerstoneViewport from 'react-cornerstone-viewport'
import { ToolOption } from '../../../types/tool-option'
import useForm from 'react-hook-form'
import { StoredData } from '../../../types/stored-data'
import * as yup from 'yup'
import { deepCopy } from '../../../common/utils'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
import { fetchFile } from '../../../reducers/ui'
import { Locale } from '../../../types/locale'

const storedDataSchema = yup.object().shape({
    title: yup.string().required('dashboard.stored.form.textarea.required.title'),
    description: yup.string(),
    imageTagsIds: yup.array()
})

const classes = {
    'icon': css`
        g {
            stroke: var(--color-primary);
        }
    `
}

const DashboardTimelineStored = (props: DashboardTimelineStoredProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const { register, errors, handleSubmit, setValue, getValues } = useForm({
        validationSchema: storedDataSchema
    })

    const [toolOptions] = useState(deepCopy(props.toolOptions))

    function submit(storedData: Record<string, any>): void {
        props.setStoredData(storedData as StoredData)
    }

    function handleTitleChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        props.setStoredData({ ...getValues() as StoredData, title: event.target.value })
    }

    function handleDescriptionContent(text: string): void {
        props.setStoredData({ ...getValues() as StoredData, description: text })
    }

    function download(name: string, index: number): void {
        if (props.resultsData?.imageSources) {
            Promise.all([dispatch(fetchFile(props.resultsData.imageSources, index, 'BASE64', true))]).then((res: any[]) => {
                downloadFile(res[0].data, name)
            })
        } else if (props.resultsData?.storedFiles) {
            Promise.all([dispatch(fetchFile(props.resultsData.storedFiles, index, 'BASE64', true))]).then((res: any[]) => {
                downloadFile(res[0].data, name)
            })
        }
    }

    useFetching(fetchResultsData, 'dashboard', [], queryParams.id, queryParams.type)

    useEffect(() => {
        setValue('title', props.storedData.title)
        setValue('description', props.storedData.description)
        setValue('imageIdsTags', [])
    }, [setValue, props.storedData])

    useEffect(() => {
        return (): void => {
            dispatch(setResultsData(null))
        }
    }, [dispatch])

    return (
        <div className={`min-w-100 pl-8 pt-4 ${props.className}`}>
            {
                props.resultsData &&
                <React.Fragment>
                    {
                        props.resultsData.imageSources && props.resultsData.imageSources.names.length > 0 &&
                        <React.Fragment>
                            <CornerstoneViewport
                                className="max-w-full max-h-120"
                                tools={toolOptions}
                                imageIds={props.resultsData.imageSources.names.map((__name, index) => `wadouri:${process.env.REACT_APP_API_URL}/files/${props.resultsData?.imageSources.id}?indices=${index}&type=${props.resultsData?.imageSources.type}`)} />

                            {/* <div className="absolute top-0 right-0 flex my-4 mr-4">
                                <CustomButton
                                    className="bg-primary text-white w-10 mr-4"
                                    tooltip={intl.formatMessage({ id: 'Download' })}
                                    Icon={DownloadIcon} />
                                <CustomButton
                                    className="bg-primary text-white w-10"
                                    tooltip={intl.formatMessage({ id: 'View Fullscreen' })}
                                    Icon={FullscreenIcon} />
                            </div> */}
                        </React.Fragment>
                    }
                    <div className="my-2 text-sm">
                        <span>{intl.formatMessage({ id: 'dashboard.stored.lastModified' })}: {props.resultsData.author}</span>,&nbsp;
                        <span className="text-gray">{props.resultsData.lastModified ? DateTime.fromMillis(props.resultsData.lastModified).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale: props.locale, numberingSystem: undefined  }) : ''}</span>
                    </div>
                    <form onSubmit={handleSubmit(submit)} autoComplete="off">
                        <CustomInput
                            className="my-2"
                            name="title"
                            label={intl.formatMessage({ id: 'dashboard.stored.form.input.label.title' })}
                            formRef={register}
                            errorMessage={errors.title ? intl.formatMessage({ id: errors.title.message }) : undefined}
                            required={true}
                            placeholder={`${intl.formatMessage({ id: 'dashboard.stored.form.input.placeholder.title' })}...`}
                            defaultValue={props.resultsData.title}
                            onChange={handleTitleChanged} />
                        <CustomTextarea
                            className="my-2"
                            name="description"
                            label={intl.formatMessage({ id: 'dashboard.stored.form.textarea.label.description' })}
                            formRef={register}
                            placeholder={`${intl.formatMessage({ id: 'dashboard.stored.form.textarea.label.description' })}...`}
                            value={props.resultsData.description}
                            rows={8}
                            onChange={handleDescriptionContent} />
                    </form>

                    {
                        (props.resultsData.imageSources || props.resultsData.storedFiles) &&
                        <React.Fragment>
                            {
                                (props.resultsData.imageSources ? props.resultsData.imageSources : props.resultsData.storedFiles)?.names.map((name, index) => {
                                    return (
                                        <div
                                            key={index}
                                            css={[classes.icon]}
                                            className="flex items-center text-primary leading-5 mt-2 cursor-pointer hover:opacity-75"
                                            onClick={(): void => download(name, index)}
                                        >
                                            <DownloadIcon className="w-5 h-5 mr-2" />
                                            {name}
                                        </div>
                                    )
                                })
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = (state: State): { resultsData: StoredResultsData | null; search: string; toolOptions: ToolOption[]; locale: Locale } => ({
    resultsData: state.timelineEventDetail.resultsData as StoredResultsData | null,
    search: state.router.location.search,
    toolOptions: state.ui.toolOptions,
    locale: state.ui.locale
})

export default connect(mapStateToProps)(DashboardTimelineStored)