import React, { ReactElement, useState, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomTableProperties } from '../../../types/component-properties/custom/custom-table-properties'
import { intl } from '../../../intl'
import { useTable, usePagination, useSortBy, useGroupBy, useExpanded } from 'react-table'
import Pagination from './pagination'
import { CustomerLicenses } from '../../../types/entity/customer-licenses'
import { Equipment } from '../../../types/entity/equipment'
import { State } from '../../../types/states/state'
import { LoggedUser } from '../../../types/entity/logged-user'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg'

const classes = {
    'row': css`
        border-bottom: 1px solid var(--color-gray-100);
        transition: color 0.4s, opacity 0.4s;
        &:last-child {
            border-bottom: 0;
        }
    `,
    'cell-striped': css`
        &:nth-of-type(odd) {
            background: var(--color-background-first);
        }

        &:nth-of-type(even) {
            background: var(--color-white);
        }
    `,
    'arrow_down': css`
        width: 0.3125rem;
        border-top: 0.3125rem solid var(--color-gray);
        border-left: 0.3125rem solid transparent;
        border-right: 0.3125rem solid transparent;
        position: relative;
        top: calc(50% - 0.4375rem);
        left: 0;
        margin-left: 0.625rem;
        cursor: pointer;

        @media screen and (max-width: 1500px) {
            border-top: 0.25rem solid var(--color-gray);
            border-left: 0.25rem solid transparent;
            border-right: 0.25rem solid transparent;
            top: calc(50% - 0.4rem)
        }
    `,
    'arrow_up': css`
        width: 0.3125rem;
        border-bottom: 0.3125rem solid var(--color-gray);
        border-left: 0.3125rem solid transparent;
        border-right: 0.3125rem solid transparent;
        position: relative;
        top: calc(-50% + 0.625rem);
        left: 0;
        margin-left: 0.625rem;
        cursor: pointer;

        @media screen and (max-width: 1500px) {
            border-bottom: 0.25rem solid var(--color-gray);
            border-left: 0.25rem solid transparent;
            border-right: 0.25rem solid transparent;
            top: calc(-50% + 0.6rem)
        }
    `,
    'flex': css`
        position: relative;
        margin-left: 0.625rem;
        cursor: pointer;
        span:first-of-type {
            top: 56%;
            transform: translateY(-100%);
            left: auto;
            margin-left: 0;
            position: absolute;
        }
        span:last-of-type {
            top: 44%;
            transform: translateY(100%);
            left: auto;
            margin-left: 0;
            position: absolute;
        }
    `
}

const CustomTable = ({ handleSort, ...props }: CustomTableProperties): ReactElement => {
    const [columns, setColumns] = useState(props.columns)
    const [data, setData] = useState(props.data)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageSize, sortBy }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, hiddenColumns: props.hiddenColumns },

            // disabilita il metodo di default del sort
            manualSortBy: true,

            // disabilita multisort
            disableMultiSort: true
        },
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination
    )

    function getActiveLicenses(): number {
        let licenses = 0;

        (data as CustomerLicenses[]).forEach((row: CustomerLicenses) => {
            licenses = licenses + row.activeLicenses
        })

        return licenses
    }

    function getTotalLicenses(): number {
        let licenses = 0;

        (data as CustomerLicenses[]).forEach((row: CustomerLicenses) => {
            licenses = licenses + row.totalLicenses
        })

        return licenses
    }

    function canPreviousPage(page: number): boolean {
        return page > 0
    }

    function canNextPage(page: number): boolean {
        return (page + 1) < Math.ceil((props.totalData > -1 ? props.totalData : data.length) / pageSize)
    }

    useEffect(() => {
        setPageSize(props.currentPageSize)
    }, [setPageSize, props.currentPageSize])

    useEffect(() => {
        gotoPage(props.currentPage)
    }, [gotoPage, props.currentPage])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        if (sortBy.length > 0) {
            handleSort(sortBy[0].id, sortBy[0].desc ? 'desc' : 'asc')
        } else {
            handleSort(null, null)
        }
    }, [handleSort, sortBy])

    useEffect(() => {
        setColumns(props.columns.map(column => {
            // if (column.Header !== '') {
            //     column.Header = intl.formatMessage({ id: column.Header ? column.Header.toString() : 'Placeholder' })
            // }

            if (column.Header !== '') {
                return {
                    ...column,
                    Header: props.translateHeader ? intl.formatMessage({ id: column.Header ? column.Header.toString() : 'general.missingText' }) : (column.Header ? column.Header.toString() : intl.formatMessage({ id: 'general.missingText' }))
                }
            } else {
                return column
            }
        }))
    }, [props.columns, props.translateHeader])

    return (
        <React.Fragment>
            <table key={`table-${columns.length}`} css={[props.cssStyles]} className={`w-full relative ${props.className}`} {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map((headerGroup, indexTR) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={`tr-${indexTR}-${props.currentPage}-${props.currentPageSize}`}>
                                {
                                    headerGroup.headers.map((column, indexTH) => {
                                        return (
                                            <th
                                                {...column.getHeaderProps({
                                                    ...column.getSortByToggleProps(),
                                                    style: { width: column.width && column.width !== '150px' ? (!column.width?.toString().includes('px') && !column.width?.toString().includes('%') ? `${column.width}px` : column.width) : 'unset' }
                                                })}
                                                key={`th-${indexTH}-${props.currentPage}-${props.currentPageSize}`}
                                                className={classNames({
                                                    'text-left text-gray relative whitespace-no-wrap py-1 px-3 h-12 relative text-sm xl:text-xs truncate': true,
                                                    'border-0 border-r border-solid border-gray': props.showRightBorderForColumns.some(columnName => column.id.includes(columnName))
                                                })}
                                            >
                                                {
                                                    column.canGroupBy ?
                                                        <span {...column.getGroupByToggleProps()}>
                                                            {column.isGrouped ? '🛑 ' : '👊 '}
                                                        </span>
                                                        :
                                                        null
                                                }
                                                <span className={classNames({ 'cursor-pointer hover:opacity-50': column.canSort })}>{column.render('Header')}</span>
                                                {
                                                    column.canSort ?
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ?
                                                                <span css={[classes.arrow_up]}></span>
                                                                :
                                                                <span css={[classes.arrow_down]}></span>
                                                            :
                                                            <span css={[classes.flex]}>
                                                                <span css={[classes.arrow_up]}></span>
                                                                <span css={[classes.arrow_down]}></span>
                                                            </span>
                                                        :
                                                        ''
                                                }
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody
                    className={classNames({ 'flex items-center h-30 border-0': !props.showPagination && (rows.length === 0) && !props.editedRow?.id })}
                    {...getTableBodyProps()}>
                    {
                        props.editedRow && props.editedRow.id === '-1' &&
                        <tr
                            css={[classes.row, props.stripedRows ? classes["cell-striped"] : undefined]}
                            className={classNames({
                                'cursor-pointer hover:opacity-75': props.selectable
                            })}
                            onClick={(): void => props.selectable ? props.handleRowClick(props.editedRow) : undefined}
                        >
                            {props.editedRowTemplate}
                        </tr>
                    }
                    {
                        (props.showPagination ? page.length > 0 : rows.length > 0) || props.editedRow?.id ?
                            (props.showPagination ? page : rows).map(
                                (row, indexBody) => {
                                    const archived = props.data.length && props.showDisabledRows && props.data[indexBody] ? (props.data[indexBody] as Equipment).archived : false

                                    prepareRow(row)
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            key={`body-${indexBody}-${props.currentPage}-${props.currentPageSize}`}
                                            css={[classes.row, props.stripedRows ? classes["cell-striped"] : undefined]}
                                            className={classNames({
                                                'cursor-pointer hover:opacity-75': props.selectable,
                                                [`font-bold ${props.selectedClassName}`]: props.selectedRow === indexBody,
                                                'opacity-50': props.showDisabledRows && archived
                                            })}
                                            onClick={(): void => props.selectable ? props.handleRowClick(row) : undefined}
                                        >
                                            {
                                                props.editedRow && (row.original as any).id === props.editedRow.id ?
                                                    props.editedRowTemplate
                                                    :
                                                    row.cells.map((cell, indexCell) => {
                                                    return <td
                                                        {...cell.getCellProps()}
                                                        key={`cell-${indexCell}-${props.currentPage}-${props.currentPageSize}`}
                                                        className={classNames({
                                                            'py-1 px-3 h-12 relative text-sm xl:text-xs': true,
                                                            'border-0 border-r border-solid border-gray': props.showRightBorderForColumns.includes(cell.column.id)
                                                        })}
                                                    >
                                                        {
                                                            cell.isGrouped ?
                                                                <React.Fragment>
                                                                    <span {...row.getToggleRowExpandedProps()}>
                                                                        <div className="flex items-center truncate">
                                                                            {/* <span className="text-lg">{row.isExpanded ? '👇' : '👉'}</span> */}
                                                                            <ArrowIcon className={classNames({
                                                                                "w-6 h-6": true,
                                                                                "mr-1 mt-1": row.isExpanded,
                                                                                "transform -rotate-90 mr-1 mb-1": !row.isExpanded
                                                                            })} />
                                                                            <div>
                                                                                {cell.render('Cell')} ({row.subRows.length})
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </React.Fragment>
                                                                :
                                                                cell.isAggregated ?
                                                                    cell.render('Aggregated')
                                                                    :
                                                                    cell.isPlaceholder ?
                                                                        null
                                                                        :
                                                                        cell.render('Cell')
                                                        }
                                                    </td>
                                            })}
                                        </tr>
                                    )
                                }
                            )
                            :
                            <tr className="flex items-center justify-center h-30 text-gray text-xl w-full absolute">
                                <td>{props.noDataMessage}</td>
                            </tr>
                    }
                    {
                        props.showTotalLicenses &&
                        <tr css={[classes.row, props.stripedRows ? classes["cell-striped"] : undefined]} className="font-bold">
                            {/* {(props.showPagination ? page : rows)[0].cells.map((cell, indexCell) => {
                                return <td key={`cell-${indexCell}`} className="py-1 px-3 h-12 relative" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })} */}
                            {/* <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">{intl.formatMessage({ id: 'Total' })}</td> */}
                            <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">{getActiveLicenses()}</td>
                            <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">{getTotalLicenses()}</td>
                            {
                                props.loggedUser?.acl.includes('CUSTOMERS_UPDATE_LICENSES') &&
                                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs"></td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
            {
                props.showPagination ?
                    <Pagination
                        dataSize={props.totalData > -1 ? props.totalData : data.length}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageCount={Math.ceil((props.totalData > -1 ? props.totalData : data.length) / pageSize)}
                        pageSize={props.currentPageSize}
                        pageSizeOptions={props.pageSizeOptions}
                        pageIndex={props.currentPage}
                        goToPage={gotoPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                        onPageChange={props.handlePageChange}
                        onPageSizeChange={props.handlePageSizeChange}></Pagination>
                    :
                    undefined
            }
        </React.Fragment>
    )
}

CustomTable.defaultProps = {
    data: [],
    totalData: -1,
    columns: [],
    cssStyles: [css``],
    className: '',
    resizable: false,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
    showPagination: true,
    currentPage: 0,
    currentPageSize: 10,
    stripedRows: false,
    showTotalLicenses: false,
    selectable: false,
    selectedClassName: '',
    noDataMessage: '',
    showDisabledRows: false,
    hiddenColumns: [],
    translateHeader: true,
    showRightBorderForColumns: [],
    editedRow: null,
    editedRowTemplate: undefined,
    handleRowClick: (): void => undefined,
    handlePageChange: (): void => undefined,
    handlePageSizeChange: (): void => undefined,
    handleSort: (): void => undefined
}

const mapStateToProps = (state: State): { loggedUser: LoggedUser | null } => ({
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(CustomTable)

