import { combineReducers, Reducer } from 'redux'
import ui from './ui'
import dashboard from './dashboard'
import equipmentDetail from './equipment-detail'
import timelineEventDetail from './timeline-event-detail'
import protocols from './protocols'
import customers from './customers'
import medicalCentres from './medical-centres'
import equipments from './equipments'
import users from './users'
import login from './login'
import preferences from './preferences'
import settings from './settings'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

const reducers = (history: History): Reducer<any> => combineReducers({
    router: connectRouter(history),
    dashboard,
    equipmentDetail,
    timelineEventDetail,
    protocols,
    customers,
    medicalCentres,
    equipments,
    users,
    login,
    ui,
    preferences,
    settings
})

export default reducers