import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../types/states/state'
import { intl } from '../../intl'
import { TableAction } from '../../types/table-action'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { ActionType } from '../../types/action-type'
import CustomTable from '../custom/custom-table/custom-table'
import ActionsCell from '../custom/custom-table/actions-cell'
import { CellProps } from 'react-table'
import { SystemSetting } from '../../types/entity/system-setting'
import { EditableTableRow } from '../../types/editable-table-row'
import CustomInput from '../custom/custom-input'
import { fetchClientSettings, fetchCreateClientSetting, fetchEditClientSetting, fetchEditSystemSetting } from '../../reducers/settings'
import CustomColor from '../custom/custom-color'
import { SettingValue } from '../../types/setting-value'
import { rgbaToHex, uniqueArray } from '../../common/utils'
import { toggleSidemenu } from '../../reducers/ui'
import CustomAccordion from '../custom/custom-accordion'
import { AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { AppThunk } from '../../types/app-thunk'
import { NewUserSetting } from '../../types/new-user-setting'

const classes = {
    'table': css`
        th, td {
            padding: 0.25rem 0.75rem;
        }
    `,
    'accordion': css`
        .accordion__item {
            border: none;
            &:not(:last-of-type) {
                .accordion__button[aria-expanded="false"] {
                    border-bottom: none;
                }
            }
        }

        .accordion__button {
            display: flex;
            align-items: center;
            outline: none;
            background: transparent;
            color: var(--color-black);
            padding: 0.6rem 0;
            font-weight: bold;
            font-size: 0.9rem;
            position: relative;
            border-top: 1px solid var(--color-gray-100);
            border-bottom: 1px solid var(--color-gray-100);

            ::before {
                position: absolute;
                right: -0.5rem;
                top: calc(50% - 0.3125rem);
                height: 0.625rem;
                width: 0.625rem;
                border-bottom: 2px solid var(--color-black);
                border-left: 2px solid var(--color-black)
                transform: rotate(45deg);
                transition: transform 0.1s;
            }
        }

        .accordion__button[aria-expanded="true"] {
            border-bottom: 1px solid var(--color-gray-100);
            ::before {
                transform: rotate(-135deg);
            }
        }

        .accordion__panel {
            border: none;
            padding: 0;
        }
    `
}

const Information = (): ReactElement => {
    const dispatch = useDispatch()

    const [settings, setSettings] = useState<SystemSetting[]>([])
    const [tableActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon }
    ])
    const [editedRowTemplate, setEditedRowTemplate] = useState<ReactElement>(<tr></tr>)
    const [settingValue, setSettingValue] = useState<SettingValue | null>(null)
    const [selectedSetting, setSelectedSetting] = useState<EditableTableRow>({
        type: null,
        isSystem: null,
        categoryId: null,
        nameId: null,
        categoryString: null,
        nameString: null,
        value: null,
        id: null
    })
    const [categories, setCategories] = useState<string[]>([])

    const defaultSettings = useSelector((state: State) => state.settings.defaultSettings)
    const clientSettings = useSelector((state: State) => state.settings.clientSettings)
    const clientId = useSelector((state: State) => state.settings.clientId)
    const loggedUser = useSelector((state: State) => state.login.user)

    const handleResetSelectedSetting = useCallback((setting: SystemSetting | undefined, editRow: string | undefined): void => {
        if (setting) {
            setSettingValue(setting.value)
            setSelectedSetting({
                type: setting.type as string,
                isSystem: setting.isSystem,
                categoryId: setting.categoryId,
                nameId: setting.nameId,
                categoryString: setting.categoryString,
                nameString: setting.nameString,
                value: setting.value,
                id: editRow
            })
        } else {
            setSettingValue(null)
            setSelectedSetting({
                type: null,
                isSystem: null,
                categoryId: null,
                nameId: null,
                categoryString: null,
                nameString: null,
                value: null,
                id: editRow
            })
        }
    }, [])

    const handleAction = useCallback((action: TableAction): void => {
        switch (action.type) {
            case 'Save':
                if ((action.row as SystemSetting).isSystem) {
                    Promise.all([dispatch(fetchEditSystemSetting({ nameId: (action.row as SystemSetting).nameId ?? "", categoryId: (action.row as SystemSetting).categoryId ?? "", value: settingValue ?? "" }, intl.formatMessage({ id: 'settings.toast.success.clientSettingUpdated' })))]).then(() => {
                        handleResetSelectedSetting(undefined, undefined)
                    })
                } else {
                    Promise.all([dispatch(fetchEditClientSetting({ nameId: (action.row as SystemSetting).nameId ?? "", categoryId: (action.row as SystemSetting).categoryId ?? "", value: settingValue ?? "" }, clientId, intl.formatMessage({ id: 'settings.toast.success.clientSettingUpdated' })))]).then(() => {
                        handleResetSelectedSetting(undefined, undefined)
                    })
                }

                break
            case 'Edit':
                setTimeout(() => {
                    handleResetSelectedSetting(action.row as SystemSetting, (action.row as SystemSetting).id)
                }, 1)

                break
            case 'Cancel':
                handleResetSelectedSetting(undefined, undefined)

                break
        }
    }, [dispatch, handleResetSelectedSetting, settingValue, clientId])

    const columns = useMemo(() => [
        { id: 'nameString', Header: 'settings.table.header.name', canGroupBy: false, disableSortBy: true, accessor: 'nameString', width: "" },
        { id: 'value', Header: 'settings.table.header.value', canGroupBy: false, disableSortBy: true, accessor: 'value', width: 200, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<SystemSetting>>): ReactElement {
            switch(rowProps.row.original.categoryId) {
                case 'chartColor':
                    return <div className="w-4 h-4" style={{ background: rowProps.row.original.value }}>{!rowProps.row.original.value || rowProps.row.original.value === "" ? "?" : ""}</div>

                default:
                    return <React.Fragment>{rowProps.row.original.value}</React.Fragment>
            }
        } },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 80, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<SystemSetting>>): ReactElement {
            return <ActionsCell actions={tableActions} row={rowProps.row.original} handleAction={handleAction} editingRow={(selectedSetting.id ?? '-1') as string}></ActionsCell>
        } }
    ], [handleAction, tableActions, selectedSetting])

    function getValueComponent(value: SettingValue | null, categoryId: string | undefined, type: string | undefined): ReactElement {
        if (categoryId) {
            switch (categoryId) {
                case 'chartColor':
                    return (
                        <CustomColor
                            defaultValue={value as string}
                            handleChange={(color): void => setSettingValue(rgbaToHex(color.r, color.g, color.b, color.a ?? 1))} />
                    )

                case 'timeInetrval':
                    return (
                        <CustomInput
                            className="w-full max-w-20 mr-2 select-none"
                            type="number"
                            min={1}
                            defaultValue={value as string}
                            onChange={(event): void => setSettingValue(event.target.value)} />
                    )

                case 'dashboard':
                    return (
                        <CustomInput
                            className="w-full mr-2 select-none"
                            type="number"
                            min={0}
                            max={9}
                            defaultValue={value as string}
                            onChange={(event): void => setSettingValue(event.target.value)} />
                    )
            }
        }

        if (type) {
            switch (type) {
                case "STRING":
                    return (
                        <CustomInput
                            className="w-full mr-2 select-none"
                            defaultValue={value as string}
                            onChange={(event): void => setSettingValue(event.target.value)} />
                    )

                default:
                    return <div></div>
            }
        }

        return <div></div>
    }

    function getHiddenColumns(): string[] {
        const hiddenColumns: string[] = []
        if (!loggedUser?.acl.includes('SETTINGS_UPDATE')) {
            hiddenColumns.push('actions')
        }
        return hiddenColumns
    }

    function handleChangeSelectedSetting(): void {
        setEditedRowTemplate(
            <React.Fragment>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    {selectedSetting.nameString}
                </td>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    {/* <CustomInput
                        className="min-w-30 max-w-44 select-none"
                        defaultValue={settingValue as string}
                        onChange={(event): void => setSettingValue(event.target.value)} /> */}
                    {selectedSetting.isSystem ? getValueComponent(settingValue, undefined, selectedSetting.type as string) : getValueComponent(settingValue, selectedSetting.categoryId as string, undefined)}
                </td>
                <td className="py-1 px-3 h-12 relative text-sm xl:text-xs">
                    <ActionsCell
                        actions={[
                            { type: 'Save', Icon: SaveIcon },
                            { type: 'Cancel', Icon: CancelIcon }
                        ]}
                        row={selectedSetting as SystemSetting}
                        canSave={true}
                        handleAction={handleAction}
                        editingRow={selectedSetting.id as string} />
                </td>
            </React.Fragment>
        )
    }

    useEffect(handleChangeSelectedSetting, [selectedSetting, settingValue])

    useEffect(() => {
        const formattedClientSettings: SystemSetting[] = clientSettings?.map((setting, index) => ({
            ...setting,
            id: index.toString(),
            categoryString: setting.categoryId ? intl.formatMessage({ id: `settings.category.${setting.categoryId}` }) : "",
            nameString: setting.nameId ? intl.formatMessage({ id: `settings.name.${setting.nameId}` }) : "",
            type: undefined,
            enumValues: {},
            value: setting.categoryId && setting.categoryId === "chartColor" ? (setting.value) : setting.value
        })) ?? []
        setSettings(defaultSettings?.concat(formattedClientSettings) ?? [])
    }, [defaultSettings, clientSettings])

    useEffect(() => {
        setCategories(settings.map((setting) => setting.categoryString).filter(uniqueArray))
    }, [settings])

    useEffect(() => {
        dispatch(toggleSidemenu(false))
    }, [dispatch])

    useEffect(() => {
        if (settings) {
            fetch('/settings.json').then((res) => res.json()).then((res) => {
                const promises: AppThunk[] = []
                res.forEach((setting: NewUserSetting) => {
                    const isPresent = settings.some((clientSetting) => clientSetting.categoryId === setting.categoryId && clientSetting.clientId === setting.clientId && clientSetting.categoryId === setting.categoryId && clientSetting.nameId === setting.nameId && clientSetting.categoryId === setting.categoryId)
                    if (!isPresent) {
                        promises.push(fetchCreateClientSetting(setting))
                    }
                })

                if (promises.length > 0) {
                    Promise.all(promises.map((promise) => dispatch(promise))).then(() => {
                        dispatch(fetchClientSettings(clientId))
                    })
                }
            })
        }
    }, [dispatch, settings, clientId])

    return (
        <div className="pt-4 px-8">
            {
                categories.length > 0 &&
                <CustomAccordion className="" preExpanded={categories} cssStyles={[classes.accordion]}>
                    {
                        categories.map((category, index): ReactElement => {
                            return (
                                <AccordionItem key={index} uuid={category}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <h6 className="font-extra-bold my-0">{category}</h6>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <CustomTable
                                            cssStyles={[classes.table]}
                                            data={settings.filter((setting) => setting.categoryString === category)}
                                            columns={columns}
                                            noDataMessage={intl.formatMessage({ id: 'settings.table.noDataText' })}
                                            showPagination={false}
                                            stripedRows={true}
                                            hiddenColumns={getHiddenColumns()}
                                            editedRow={selectedSetting}
                                            editedRowTemplate={editedRowTemplate} />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )
                        })
                    }
                </CustomAccordion>
            }
        </div>
    )
}

export default Information