import React, { useEffect, ReactElement, useState } from 'react'
import { SectionProperties } from '../../types/component-properties/section-properties'
import { useDispatch, connect } from 'react-redux'
import { Switch, Route } from 'react-router'
import { toggleSidemenu, updatePathLabel, setPaths, setSidemenuData } from '../../reducers/ui'
import { State } from '../../types/states/state'
import { RequestQueryParams } from '../../types/request-query-params'
import { TableAction } from '../../types/table-action'
import { push } from 'connected-react-router'
import { LocationDescriptorObject } from 'history'
import { intl } from '../../intl'
import CustomConfirmDelete from '../custom/custom-confim-delete'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { NewUser } from '../../types/new-user'
import { EditUser } from '../../types/edit-user'
import { User } from '../../types/entity/user'
import { fetchUsers, fetchDeleteUser, fetchCreateUser, fetchEditUser, setUsers, fetchEnableUser } from '../../reducers/users'
import UsersList from './users-list'
import UsersEditor from './users-editor'
import UsersCreator from './users-creator'
import { SectionName } from '../../types/section-name'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const Users = (props: SectionProperties): ReactElement => {
    const dispatch = useDispatch()

    const [newUser] = useState<NewUser>({ name: '', customerId: '', email: '', phone: '', languageId: '', username: '', password: '', roleId: '' })
    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})

    function handleCreate(user: NewUser): void {
        dispatch(fetchCreateUser(user, props.queryParams, intl.formatMessage({ id: 'users.toast.success.userCreated' }), props.loggedUser?.role === 'ADMINISTRATOR'))
    }

    function handleEdit(id: string, user: EditUser, showMessage = true): void {
        Promise.all([dispatch(fetchEditUser(id, user, intl.formatMessage({ id: 'users.toast.success.userUpdated' }), showMessage))]).then(() => {
            dispatch(updatePathLabel({ index: props.paths.length - 1, label: user.username }))
        })
    }

    function handleEnableUser(id: string, index: number, value: boolean): void {
        dispatch(fetchEnableUser(id, index, value))
    }

    function handleDelete(id: string, queryParams: RequestQueryParams): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteUser(id, queryParams, intl.formatMessage({ id: 'users.toast.success.userDeleted' })))
            }
        })
    }

    function handleAction(action: TableAction): void {
        switch (action.type) {
            case 'Edit':
                // dispatch(addPath({ label: (action.row as User).name, id: (action.row as User).id, destination: { pathname: `/users/user`, search: `?id=${(action.row as User).id}` }, param: null }))
                const editPaths = [
                    {
                        label: (action.row as User).customerName ? (action.row as User).customerName : 'N/A',
                        id: (action.row as User).customerId,
                        destination: {
                            pathname: `/users`
                        },
                        param: {
                            property: `customerId` as keyof RequestQueryParams,
                            value: (action.row as User).customerId
                        }
                    },
                    {
                        label: (action.row as User).username,
                        id: (action.row as User).id,
                        destination: {
                            pathname: `/users/user`,
                            search: `?id=${(action.row as User).id}`
                        },
                        param: null
                    }
                ]
                dispatch(setPaths(props.loggedUser?.role === 'ADMINISTRATOR' ? editPaths : [editPaths[1]]))
                dispatch(push({
                    pathname: `/users/user`,
                    search: `?id=${(action.row as User).id}`
                }))

                break
            case 'Delete':
                // dispatch(deleteMedicalCentre(medicalCentre.id))
                // handleDelete((action.row as User).id, action.queryParams)
                handleDelete((action.row as User).id, action.queryParams)

                break
        }
    }

    useFetching(fetchUsers, 'users', [], props.queryParams)

    useEffect(() => {
        dispatch(toggleSidemenu(true))
        return (): void => {
            dispatch(setSidemenuData([]))
            dispatch(setUsers({ users: [], size: 0 }))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/users'>
                    <UsersList
                        handleEnableUser={handleEnableUser}
                        handleAction={handleAction}
                        handleLimitChange={props.handleLimitChange}
                        handlePageChange={props.handlePageChange}
                        handleSort={props.handleSort}></UsersList>
                </Route>
                <Route exact path='/users/new'>
                    <UsersCreator
                        user={newUser}
                        handleNavigate={props.handleNavigate}
                        handleCreate={handleCreate}></UsersCreator>
                </Route>
                <Route exact path='/users/user'>
                    <UsersEditor
                        handleEdit={handleEdit}
                        handleNavigate={props.handleNavigate}
                        handleDelete={handleDelete}></UsersEditor>
                </Route>
            </Switch>
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { queryParams: RequestQueryParams; location: LocationDescriptorObject; paths: BreadcrumbPath[]; currentSection: SectionName; loggedUser: LoggedUser | null } => ({
    queryParams: state.ui.sectionQueryParams,
    location: state.router.location,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(Users)
