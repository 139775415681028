import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DashboardTimelineEventEditorProperties } from '../../../types/component-properties/dashboard-timeline-event-editor-properties'
import DashboardTimelineEventForm from './dashboard-timeline-event-form'
import { intl } from '../../../intl'
import classNames from 'classnames'

const classes = {
    'container': css`
        transition: max-height .5s cubic-bezier(0, 0.25, 0, 0.25);
        &.open {
            transition: max-height 1s ease-in-out;
        }
    `
}

const DashboardTimelineEventEditor = (props: DashboardTimelineEventEditorProperties): ReactElement => {
    function getText(text: string): string {
        return text
    }

    return (
        <React.Fragment>
            <div css={[classes.container]} className={classNames({
                'absolute top-0 left-0 h-full z-20 max-h-0 bg-white flex overflow-hidden width-minus-sidebar': true,
                'max-h-340 open': props.visible
            })}>
                <div className="w-3/5 flex flex-col overflow-y-auto h-full border-0 border-r border-solid border-gray-100">
                    {
                        props.visible &&
                        <DashboardTimelineEventForm {...props}></DashboardTimelineEventForm>
                    }
                </div>
                <div className="bg-background-first h-full w-2/5 p-8 overflow-y-auto">
                    <h4 className="font-bold mb-6 mt-0 pb-2">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.title' })}</h4>
                    <p className="text-darkgray mb-6 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.description.partOne' })}</p>
                    <p className="text-darkgray mb-6 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.description.partTwo' })}</p>

                    <h6 className="font-bold mb-4 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.analysedImages.title' })}</h6>
                    <p className="text-darkgray mb-6 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.analysedImages.description' })}</p>

                    <h6 className="font-bold mb-4 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.images.title' })}</h6>
                    <p className="text-darkgray mb-6 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.images.description' })}</p>

                    <h6 className="font-bold mb-4 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.files.title' })}</h6>
                    <p className="text-darkgray mb-6 mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.files.description' })}</p>

                    <h6 className="font-bold mb-4 mt-0" dangerouslySetInnerHTML={{ __html: getText(intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.messages.title' })) }}></h6>
                    <p className="text-darkgray mb-6 mt-0" dangerouslySetInnerHTML={{ __html: getText(intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.messages.description' })) }}></p>

                    <p className="text-darkgray mb-6 mt-0" dangerouslySetInnerHTML={{ __html: getText(intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.ending.partOne' })) }}></p>
                    <p className="text-darkgray my-0" dangerouslySetInnerHTML={{ __html: getText(intl.formatMessage({ id: 'dashboard.timeline.newEvent.informations.ending.partTwo' })) }}></p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DashboardTimelineEventEditor