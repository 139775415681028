import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { compose } from 'redux'
import thunk from 'redux-thunk'
import './index.css'
import App from './app'
import * as serviceWorker from './serviceWorker'
import reducers from './reducers/index'
import { createBrowserHistory } from 'history'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { configureStore } from '@reduxjs/toolkit'
import { Settings } from 'luxon'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import enGB from 'date-fns/locale/en-GB'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import dicomParser from 'dicom-parser'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import cornerstone from 'cornerstone-core'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import cornerstoneMath from 'cornerstone-math'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import cornerstoneTools from 'cornerstone-tools'
import Hammer from 'hammerjs'
import { getTokens } from './common/api'

// Cornerstone Tools
cornerstoneTools.external.cornerstone = cornerstone
cornerstoneTools.external.Hammer = Hammer
cornerstoneTools.external.cornerstoneMath = cornerstoneMath
cornerstoneTools.init()

// Image Loader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone
cornerstoneWADOImageLoader.external.dicomParser = dicomParser
cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
        decodeTask: {
            initializeCodecsOnStartup: false,
            usePDFJS: false,
            strict: false
        }
    }
})
cornerstoneWADOImageLoader.configure({
    beforeSend: (xhr: any) => {
        const tokens = getTokens()
        if (tokens) {
            xhr.setRequestHeader('Authorization', `Bearer ${tokens.accessToken}`)
        }
    }
})

Settings.defaultLocale = 'en-GB'

registerLocale('it', it)
registerLocale('en-GB', enGB)

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const history = createBrowserHistory()

const store = configureStore({
    reducer: reducers(history),
    middleware: [thunk, routerMiddleware(history)]
})

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
