import React, { ReactElement, ReactNode } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tabs } from 'react-tabs'
import { CustomTabsProperties } from '../../types/component-properties/custom/custom-tabs-properties'

const classes = {
    'tabs': css`
        .react-tabs__tab-list {
            display: flex;
            border: none;
            border-bottom: 1px solid var(--color-gray-100);
            margin-bottom: 0rem;
            height: 2.875rem;

            .react-tabs__tab {
                display: flex;
                border: none;
                box-shadow: none;
                padding: 0.75rem 0;
                margin-right: 2rem;
                height: 2.875rem;
                color: var(--color-darkgray);
            }

            .react-tabs__tab--selected {
                border-bottom: 3px solid var(--color-primary);
                font-weight: bold;
                color: var(--color-black);
                @media screen and (max-width: 1500px) {
                    border-width: 2px;
                }
            }

            .react-tabs__tab--selected::after {
                border: none;
                height: 0;
            }
        }
    `
}

const CustomTabs = (props: CustomTabsProperties): ReactElement => {
    return (
        <React.Fragment>
            <Tabs
                css={[classes.tabs, props.cssStyles]}
                className={`flex flex-col text-base h-full ${props.className}`}
                selectedIndex={props.selectedIndex}
                onSelect={props.onSelect}
            >
                {props.children.map((child: ReactNode) => child)}
            </Tabs>
        </React.Fragment>
    )
}

CustomTabs.defaultProps = {
    className: '',
    cssStyles: [css``],
    children: [],
    defaultIndex: 2,
    onSelect: (): void => undefined
}

export default CustomTabs
