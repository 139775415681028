import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomButtonProperties } from '../../types/component-properties/custom/custom-button-properties'
import classNames from 'classnames'

const CustomButton = (props: CustomButtonProperties): ReactElement => {
    return (
        <React.Fragment>
            {
                !props.hidden &&
                <button 
                    data-tip={props.tooltip}
                    css={[props.cssStyles]}
                    className={classNames({
                        [`flex items-center text-sm border-0 rounded px-3 py-2 min-h-10 shadow-none outline-none cursor-pointer hover:opacity-75 ${props.className}`]: true,
                        'justify-between': props.iconPosition === 'right',
                        'justify-center': props.iconPosition !== 'right',
                        'bg-gray-100 pointer-events-none': props.disabled
                    })}
                    disabled={props.disabled}
                    type={props.type}
                    onClick={props.onClick}
                >
                    {
                        props.iconPosition === 'left' && props.Icon &&
                        <props.Icon className={classNames({
                            'w-4 h-4': props.iconClassName === '',
                            [props.iconClassName]: props.iconClassName !== ''
                        })} />
                    }
                    <div className="leading-6">{props.label}</div>
                    {
                        props.iconPosition === 'right' && props.Icon &&
                        <props.Icon className={classNames({
                            'w-4 h-4': props.iconClassName === '',
                            [props.iconClassName]: props.iconClassName !== ''
                        })} />
                    }
                </button>
            }
        </React.Fragment>
    )
}

CustomButton.defaultProps = {
    children: undefined,
    className: '',
    iconClassName: '',
    cssStyles: [css``],
    disabled: false,
    hidden: false,
    Icon: undefined,
    iconPosition: 'left',
    label: '',
    tooltip: '',
    type: 'button',
    onClick: (): void => undefined
}

export default CustomButton
