import React, { ReactElement } from 'react'
import { PageButtonProperties } from '../../../types/component-properties/page-button-properties'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const PageButton = (props: PageButtonProperties): ReactElement => {
    const { cssStyles, children, ...rest } = props

    return (
        <React.Fragment>
            <button css={cssStyles} {...rest} type='button'>{children}</button>
        </React.Fragment>
    )
}

PageButton.defaultProps = {
    className: '',
    cssStyles: [css``],
    disabled: false,
    onClick: (): void => undefined
}

export default PageButton