import React, { useEffect, ReactElement, useState, BaseSyntheticEvent, PropsWithChildren, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { useDispatch, connect, useSelector } from 'react-redux'
import CustomSwitch from '../custom/custom-switch'
import CustomTable from '../custom/custom-table/custom-table'
import { CustomersEditorProperties } from '../../types/component-properties/customers-editor-properties'
import { State } from '../../types/states/state'
import { EditCustomer } from '../../types/edit-customer'
import { fetchCustomer, fetchCustomerEquipmentProperties, setCurrentCustomer, setCurrentEquipmentProperties } from '../../reducers/customers'
import * as yup from 'yup'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import Footer from '../footer'
import { CellProps } from 'react-table'
import { CustomerLicenses } from '../../types/entity/customer-licenses'
import LicensesActions from '../custom/custom-table/licenses-actions'
import { LocationDescriptorObject } from 'history'
import queryString from 'query-string'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'
import { deepCopy } from '../../common/utils'
import CustomTabs from '../custom/custom-tabs'
import { Tab, TabList, TabPanel } from 'react-tabs'
import CustomersEquipmentProperties from './customers-equipment-properties'
import CustomersIndicator from './customers-indicators'

const customerSchema = yup.object().shape({
    name: yup.string().required('organisations.editor.form.input.errorMessage.required.name'),
    email: yup.string(),
    phone: yup.string(),
    active: yup.boolean(),
    licenses: yup.array()
})

const classes = {
    'table': css`
        tbody {
            border: 1px solid var(--color-gray-100);
        }

        th, td {
            padding: 0.25rem 0.75rem;
        }

        tbody > tr:nth-of-type(odd) {
            background: var(--color-white);
        }

        tbody > tr:nth-of-type(even) {
            background: var(--color-background-second);
        }
    `,
    'dot': css`
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
        line-height: 75%;
    `,
    'tabs': css`
        .react-tabs__tab-list {
            margin-bottom: 0;
        }

        .react-tabs__tab-panel {
            flex-grow: 1;
        }

        .react-tabs__tab {
            background: transparent !important;
        }
    `
}

const CustomersEditor = (props: CustomersEditorProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.location.search ?? '')

    const [usedIndicators, setUsedIndicators] = useState<string[]>([])
    const [active, setActive] = useState(props.customer?.active ?? true)
    const [licenses, setLicenses] = useState(props.customer?.licenses ?? [])
    const [activeTab, setActiveTab] = useState(0)
    const { register, unregister, setValue, errors, handleSubmit } = useForm({
        validationSchema: customerSchema
    })

    const equipmentProperties = useSelector((state: State) => state.customers.currentEquipmentProperties)

    function changeTab(index: number): void {
        setActiveTab(index)
    }

    function addLicense(customerLicenses: CustomerLicenses[], index: number): void {
        const newLicenses = deepCopy(customerLicenses)
        newLicenses[index].totalLicenses = newLicenses[index].totalLicenses + 1

        setLicenses(newLicenses)
        setValue('licenses', newLicenses)
    }

    function removeLicense(customerLicenses: CustomerLicenses[], index: number): void {
        if (customerLicenses[index].totalLicenses > 0 && customerLicenses[index].totalLicenses > customerLicenses[index].activeLicenses) {
            const newLicenses = deepCopy(customerLicenses)
            newLicenses[index].totalLicenses = newLicenses[index].totalLicenses - 1

            setLicenses(newLicenses)
            setValue('licenses', newLicenses)
        }
    }

    const [columns] = useState([
        { id: 'testObject', Header: 'organisations.editor.table.header.testObject', canGroupBy: false, accessor: 'name' },
        { id: 'activeLicenses', Header: 'organisations.editor.table.header.active', canGroupBy: false, accessor: 'activeLicenses', disableSortBy: true, Cell: function setColumn(rowPRops: PropsWithChildren<CellProps<CustomerLicenses>>): ReactElement {
            return <div>{rowPRops.row.original.activeLicenses}</div>
        } },
        { id: 'totalLicenses', Header: 'organisations.editor.table.header.total', canGroupBy: false, accessor: 'totalLicenses', disableSortBy: true },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 80, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<CustomerLicenses>>): ReactElement {
            return <LicensesActions license={rowProps.row.original} addLicense={(): void => addLicense(rowProps.data, rowProps.row.index)} removeLicense={(): void => removeLicense(rowProps.data, rowProps.row.index)}></LicensesActions>
        } }
    ])

    const handleChange = useCallback((id: string, checked: boolean) => {
        setUsedIndicators((oldValue): string[] => {
            const newValue: string[] = deepCopy(oldValue)
            if (checked) {
                newValue.push(id)
            } else {
                newValue.splice(newValue.indexOf(id), 1)
            }
            return newValue
        })
    }, [])

    function submit(customer: Record<string, any>): void {
        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            delete customer.licenses
        }

        customer.indicatorIds = usedIndicators
        props.handleEdit(props.customer?.id ?? '0', customer as EditCustomer)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleSwitch(value: boolean): void {
        setActive(value)
        setValue('active', value)
    }

    const handleSort = useCallback((orderBy: string | null, orderType: string | null): void => {
        if (!orderBy) {
            setLicenses(props.customer?.licenses ?? [])
        } else {
            const newOrderedLicenses: CustomerLicenses[] = deepCopy(props.customer?.licenses ?? [])
            if (orderType === 'asc') {
                newOrderedLicenses.sort((a, b) => a.name.localeCompare(b.name))
            } else {
                newOrderedLicenses.sort((a, b) => b.name.localeCompare(a.name))
            }
           setLicenses(newOrderedLicenses)
        }
    }, [props.customer])

    function resetCustomer(): void {
        if (props.customer) {
            setValue('active', props.customer.active)
            setActive(props.customer.active)
            setValue('licenses', props.customer.licenses)
            setLicenses(props.customer.licenses)
            setValue('name', props.customer.name)
            setValue('email', props.customer.email)
            setValue('phone', props.customer.phone)
        }
    }

    useFetching(fetchCustomer, 'organisations', [], queryParams.id as string)
    useFetching(fetchCustomerEquipmentProperties, 'organisations', [], queryParams.id as string)

    useEffect(() => {
        const newUsedIndicators: string[] = []
        props.customer?.indicators.forEach((indicator) => {
            indicator.indicatorIdentifiers.forEach((indicatorIdentifier) => {
                if (indicatorIdentifier.used) {
                    newUsedIndicators.push(indicatorIdentifier.id)
                }
            })
        })
        setUsedIndicators(newUsedIndicators)
    }, [props.customer])

    useEffect(() => {
        // if (!props.customer) {
        //     dispatch(fetchCustomer(queryParams.id as string))
        // }
        return (): void => {
            dispatch(setCurrentCustomer(null))
            dispatch(setCurrentEquipmentProperties([]))
        }
    }, [dispatch])

    useEffect(() => {
        register({ name: 'active' })
        register({ name: 'licenses' })

        if (props.customer) {
            setValue('active', props.customer.active)
            setActive(props.customer.active)
            setValue('licenses', props.customer.licenses)
            setLicenses(props.customer.licenses)
        }

        return (): void => {
            unregister('active')
            unregister('licenses')
        }
    }, [register, unregister, setValue, props.customer])

    return (
        <React.Fragment>
            {
                props.customer &&
                <form className="flex flex-col justify-between items-end pt-4 px-8" autoComplete="off">
                    {
                        props.loggedUser?.role === 'ADMINISTRATOR' &&
                        <div className="flex justify-end items-center mb-5">
                            <CustomSwitch
                                checked={active}
                                disabled={!props.loggedUser?.acl.includes('CUSTOMERS_UPDATE_ENABLE')}
                                label={intl.formatMessage({ id: 'organisations.editor.switch.label' })}
                                labelPosition="left"
                                onChange={handleSwitch}></CustomSwitch>
                        </div>
                    }

                    <div className="flex justify-between align-self-start w-full">
                        <div key={props.customer.name} className="min-w-52 max-w-60 h-full flex-grow">
                            <CustomInput
                                className="w-full"
                                name="name"
                                label={intl.formatMessage({ id: 'organisations.editor.form.input.label.name' })}
                                formRef={register}
                                errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                                required={true}
                                readonly={!props.loggedUser?.acl.includes('CUSTOMERS_UPDATE')}
                                placeholder={`${intl.formatMessage({ id: 'organisations.editor.form.input.placeholder.name' })}...`}
                                defaultValue={props.customer.name} />
                            <CustomInput
                                className="w-full"
                                name="email"
                                label={intl.formatMessage({ id: 'organisations.editor.form.input.label.email' })}
                                formRef={register}
                                readonly={!props.loggedUser?.acl.includes('CUSTOMERS_UPDATE')}
                                placeholder={`${intl.formatMessage({ id: 'organisations.editor.form.input.placeholder.email' })}...`}
                                defaultValue={props.customer.email} />
                            <CustomInput
                                className="w-full"
                                name="phone"
                                label={intl.formatMessage({ id: 'organisations.editor.form.input.label.phone' })}
                                formRef={register}
                                readonly={!props.loggedUser?.acl.includes('CUSTOMERS_UPDATE')}
                                placeholder={`${intl.formatMessage({ id: 'organisations.editor.form.input.placeholder.phone' })}...`}
                                defaultValue={props.customer.phone} />
                            <CustomInput
                                className="w-full"
                                label={intl.formatMessage({ id: 'organisations.editor.form.input.label.totalEquipments' })}
                                defaultValue={`${props.customer.activeEquipments}/${props.customer.totalEquipments}`}
                                readonly={true} />
                        </div>

                        <div className="bg-background-first p-8 flex-grow ml-6">
                            <CustomTabs
                                cssStyles={[classes.tabs]}
                                className="flex flex-col flex-grow"
                                selectedIndex={activeTab}
                                onSelect={changeTab}
                            >
                                <TabList>
                                    <Tab>
                                        {intl.formatMessage({ id: 'organisations.editor.tabHeader.licences' })}
                                    </Tab>
                                    <Tab>
                                        {intl.formatMessage({ id: 'organisations.editor.tabHeader.indicators' })}
                                    </Tab>
                                    <Tab>
                                        {intl.formatMessage({ id: 'organisations.editor.tabHeader.properties' })}
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <CustomTable
                                        cssStyles={[classes.table]}
                                        className="mt-4"
                                        data={licenses}
                                        columns={columns}
                                        showPagination={false}
                                        handleSort={handleSort}></CustomTable>
                                </TabPanel>
                                <TabPanel>
                                    <CustomersIndicator
                                        usedIndicators={usedIndicators}
                                        handleChange={handleChange} />
                                </TabPanel>
                                <TabPanel>
                                    <CustomersEquipmentProperties
                                        equipmentProperties={equipmentProperties} />
                                </TabPanel>
                            </CustomTabs>
                        </div>
                    </div>
                </form>
            }
            <Footer
                item={props.customer}
                editing={true}
                canDelete={props.loggedUser?.acl.includes('CUSTOMERS_DELETE')}
                canEdit={props.loggedUser?.acl.includes('CUSTOMERS_UPDATE') || props.loggedUser?.acl.includes('CUSTOMERS_UPDATE_LICENSES') || props.loggedUser?.acl.includes('CUSTOMERS_UPDATE_ENABLE')}
                handleDelete={props.handleDelete}
                handleCancel={resetCustomer}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; location: LocationDescriptorObject; loggedUser: LoggedUser | null } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    location: state.router.location,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(CustomersEditor)