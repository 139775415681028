import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { LoadingSpinnerProperties } from '../types/component-properties/loading-spinner-properties'
import { intl } from '../intl'
import classNames from 'classnames'

const classes = {
    'spinner': css`
        .lds-roller {
            display: inline-block;
            position: relative;
            width: 64px;
            height: 64px;
        }
        .lds-roller div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 32px 32px;
        }
        .lds-roller div:after {
            content: " ";
            display: block;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
            margin: -3px 0 0 -3px;
        }
        .lds-roller div:nth-of-type(1) {
            animation-delay: -0.036s;
        }
        .lds-roller div:nth-of-type(1):after {
            top: 50px;
            left: 50px;
        }
        .lds-roller div:nth-of-type(2) {
            animation-delay: -0.072s;
        }
        .lds-roller div:nth-of-type(2):after {
            top: 54px;
            left: 45px;
        }
        .lds-roller div:nth-of-type(3) {
            animation-delay: -0.108s;
        }
        .lds-roller div:nth-of-type(3):after {
            top: 57px;
            left: 39px;
        }
        .lds-roller div:nth-of-type(4) {
            animation-delay: -0.144s;
        }
        .lds-roller div:nth-of-type(4):after {
            top: 58px;
            left: 32px;
        }
        .lds-roller div:nth-of-type(5) {
            animation-delay: -0.18s;
        }
        .lds-roller div:nth-of-type(5):after {
            top: 57px;
            left: 25px;
        }
        .lds-roller div:nth-of-type(6) {
            animation-delay: -0.216s;
        }
        .lds-roller div:nth-of-type(6):after {
            top: 54px;
            left: 19px;
        }
        .lds-roller div:nth-of-type(7) {
            animation-delay: -0.252s;
        }
        .lds-roller div:nth-of-type(7):after {
            top: 50px;
            left: 14px;
        }
        .lds-roller div:nth-of-type(8) {
            animation-delay: -0.288s;
        }
        .lds-roller div:nth-of-type(8):after {
            top: 45px;
            left: 10px;
        }
        @keyframes lds-roller {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `,
    'modal-spinner': css`
        background: rgba(0, 0, 0, 0.5);
    `,
    'normal-spinner': css`
        .lds-roller div:after {
            content: " ";
            display: block;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--color-black);
            margin: -3px 0 0 -3px;
        }
    `
}

const LoadingSpinner = (props: LoadingSpinnerProperties): ReactElement => {
    
 
    return (
        <React.Fragment>
            <div
                css={[classes.spinner, classes["modal-spinner"]]}
                className={classNames({
                    [`flex justify-center items-center flex-col absolute top-0 left-0 w-screen h-screen transition-opacity duration-300 ${props.className}`]: true,
                    'opacity-0 z-!1 invisible delay-500': props.hidden,
                    'opacity-100 z-40 visible': !props.hidden
                })}>
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <div className="text-big text-white select-none mt-1">{`${intl.formatMessage({ id: 'general.loading' })}...`}</div>
            </div>
        </React.Fragment>
    )
}

LoadingSpinner.defaultProps = {
    className: '',
    hidden: true,
    modal: true
}

export default LoadingSpinner