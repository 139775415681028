import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router'
import DashboardBaselineDetail from './dashboard-baseline-detail'
import DashboardBaselineList from './dashboard-baseline-list'
import { DashboardBaselineProperties } from '../../../types/component-properties/dashboard-baseline-properties'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../../types/states/state'
import { setBaselines, fetchBaselines } from '../../../reducers/equipment-detail'
import { Equipment } from '../../../types/entity/equipment'
import { NewBaseline } from '../../../types/new-baseline'
import DashboardBaselineCreator from './dashboard-baseline-creator'
import { BreadcrumbPath } from '../../../types/entity/breadcrumb-path'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import { splicePath } from '../../../reducers/ui'

const DashboardBaseline = ({ setOrderBy, setOrderType, ...props }: DashboardBaselineProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [newBaseline] = useState<NewBaseline>({ name: '', equipmentId: '', testObjectId: '' })

    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    const loadBaselines = useCallback((): void => {
        if (props.equipment) {
            dispatch(fetchBaselines({ equipmentId: props.equipment.id, orderBy: props.orderBy, orderType: props.orderType, ext: 'true' }))
        }
    }, [dispatch, props.equipment, props.orderBy, props.orderType])

    function handleBaselineSort(newOrderBy: string | null, newOrderType: string | null): void {
        setOrderBy(newOrderBy ? newOrderBy : undefined)
        setOrderType(newOrderType ? newOrderType : undefined)
    }

    function handleBack(): void {
        const destination = {
            pathname: `/dashboard/equipment-detail`,
            search: `?tab=${queryParams.tab as string}&equipmentId=${props.equipment?.id}&page=${timelineFilter.page}`
        }

        dispatch(push(destination))
        dispatch(splicePath(props.paths.length - 1))
        loadBaselines()
    }

    useEffect(() => {
        loadBaselines()
    }, [loadBaselines])

    useEffect(() => {
        return (): void => {
            dispatch(setBaselines([]))
            setOrderBy(undefined)
            setOrderType(undefined)
        }
    }, [dispatch, setOrderBy, setOrderType])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/dashboard/equipment-detail/new-baseline'>
                    <DashboardBaselineCreator
                        baseline={newBaseline}
                        handleCreate={props.handleCreateBaseline}></DashboardBaselineCreator>
                </Route>
                <Route exact path='/dashboard/equipment-detail'>
                    <DashboardBaselineList
                        orderBy={props.orderBy}
                        orderType={props.orderType}
                        handleSort={handleBaselineSort}
                        handleEditBaseline={props.handleEditBaseline}
                        handleDeleteBaseline={props.handleDeleteBaseline}></DashboardBaselineList>
                </Route>
                <Route exact path='/dashboard/equipment-detail/baseline'>
                    <DashboardBaselineDetail
                        handleDeleteBaseline={props.handleDeleteBaseline}
                        handleUpdateBaseline={props.handleUpdateBaseline}
                        handleBack={handleBack}></DashboardBaselineDetail>
                </Route>
            </Switch>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; paths: BreadcrumbPath[]; search: string }  => ({
    equipment: state.dashboard.currentEquipment,
    paths: state.ui.breadcrumbPaths,
    search: state.router.location.search
})

export default connect(mapStateToProps)(DashboardBaseline)
