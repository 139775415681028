import React from 'react'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import JwtDecode from 'jwt-decode'
import { decrementCounter, incrementCounter } from '../reducers/ui'
import { fetchLogout } from '../reducers/login'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../types/states/state'
import { Action } from 'redux'
import { toast } from 'react-toastify'
import { Tokens } from '../types/tokens'
import { BreadcrumbPath } from '../types/entity/breadcrumb-path'
import { LoggedUser } from '../types/entity/logged-user'
import { intl } from '../intl'
import classNames from 'classnames'

axios.defaults.headers.common['Accept-Language'] = 'it'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.baseURL = process.env.REACT_APP_API_URL

export const setTokens = (tokens: Tokens): void => {
    localStorage.setItem('tokens', JSON.stringify(tokens))
}

export const getTokens = (): Tokens | null => {
    const tokens = localStorage.getItem('tokens')
    return tokens ? JSON.parse(tokens) : null
}

export const setUser = (user: LoggedUser): void => {
    localStorage.setItem('user', JSON.stringify(user))
}

export const getUser = (): LoggedUser | null => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) : null
}

export const setBreadcrumbPaths = (paths: BreadcrumbPath[]): void => {
    localStorage.setItem('paths', JSON.stringify(paths))
}

export const getBreadcrumbPaths = (): BreadcrumbPath[] | null => {
    const paths = localStorage.getItem('paths')
    return paths ? JSON.parse(paths) : null
}

export const resetStorage = (): void => {
    localStorage.clear()
}

export const verifyToken = (): boolean => {
    const tokens = getTokens()
    if (tokens) {
        const decode: { exp: number } = JwtDecode(tokens.accessToken)
        if (Date.now() <= decode.exp * 1000) {
            return true
        } else {
            resetStorage()
            return false
        }
    } else {
        return false
    }
}

export function call(requestConfig: AxiosRequestConfig, dispatch: ThunkDispatch<State, unknown, Action> | undefined = undefined, load = false): () => Promise<AxiosResponse> {
    if (load && dispatch) {
        dispatch(incrementCounter())
    }

    const tokens = getTokens()
    const axiosInstance = axios.create()
    const request = async (): Promise<AxiosResponse> => axiosInstance.request(requestConfig)
    const decodedToken: any = tokens ? JwtDecode(tokens.accessToken) : null

    if (tokens && tokens.accessToken !== '' && tokens.accessToken !== 'null' && tokens.accessToken !== 'undefined') {
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${tokens.accessToken}`
    }
    // axiosInstance.defaults.headers['accept-language'] = IntlProvider.defaultProps.defaultLocale
    axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
    // axiosInstance.defaults.baseURL = getApiUrl()
    // if (decodedToken?.exp) {
    //     console.log(DateTime.fromMillis(decodedToken?.exp).toFormat("dd/MM/yyyy hh:mm"))
    // }
    
    // console.log(new Date().valueOf() >= decodedToken?.exp)
    // console.log(DateTime.fromMillis(new Date().valueOf() - decodedToken?.exp).toFormat("dd/MM/yyyy hh:mm"))
    // LOADING COUNTER
    axiosInstance.interceptors.request.use((response: AxiosRequestConfig) => {


        return response
    })

    axiosInstance.interceptors.response.use((response: AxiosResponse) => {
        if (load && dispatch) {
            dispatch(decrementCounter())
        }

        return response
    }, (error: AxiosResponse) => {
        // const originalRequest = error.config
        if (load && dispatch) {
            dispatch(decrementCounter())
        }

        if (error.request.status === 401 && new Date().valueOf() >= decodedToken?.exp && dispatch) {
            dispatch(fetchLogout())
        }

        // if (error.request.status === 401 && !localStorage.getItem('refreshToken') && token_diff <= 0 && user) {
        //     if (load) {
        //         dispatch(incrementCounter())
        //     }

        //     axios.request({
        //         method: 'GET',
        //         url: '/token',
        //         headers: { Authorization: 'Bearer ' + user.refreshToken }
        //     }).then(res => {
        //         if (load) {
        //             dispatch(decrementCounter())
        //         }

        //         localStorage.setItem('refreshToken', res.data.token)

        //         originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token

        //         return axios(originalRequest)
        //     }, () => {
        //         if (load) {
        //             dispatch(decrementCounter())
        //         }
        //         // cogoToast.error((messages as any)[IntlProvider.defaultProps.defaultLocale]['token'], {
        //         //     position: 'top-right',
        //         //     hideAfter: 5
        //         // })

        //         localStorage.removeItem('token')
        //         localStorage.removeItem('user')
        //         localStorage.removeItem('logout')
        //         localStorage.removeItem('refreshToken')

        //         // dispatch(setUser(null))

        //         // dispatch(startLogoutTimer(0))
        //     })
        // }
        // // dispatch(callFailed(error.request.status))

        // // if (error.request.status !== 401) {
        // //     cogoToast.error((messages as any)[IntlProvider.defaultProps.defaultLocale][error.request.response && JSON.parse(error.request.response).internal_code ? JSON.parse(error.request.response).internal_code : 'default_error'], {
        // //         position: 'top-right',
        // //         hideAfter: 5
        // //     })
        // // }

        // return error
        if (decodedToken && !(error.request.status === 401 && new Date().valueOf() >= decodedToken.exp)) {
            const errors = JSON.parse(error.request.response).details
            const main = JSON.parse(error.request.response).main
            if ((Array.isArray(errors)) || !!main) {
                toast.error(
                    <div>
                        {
                            main && main !== '' &&
                            <>
                                <div className={classNames({ "font-bold": errors.length > 0 })}>
                                    {main}
                                </div>
                                <br />
                            </>
                        }
                        {
                            errors.map((error: string, index: number) => {
                                return (
                                    <>
                                        <div key={index}>
                                            {error}
                                        </div>
                                        {
                                            errors.length - 1 !== index &&
                                            <br />
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                )
            } else {
                toast.error(
                    <div>
                        <div className="font-bold">
                            {intl.formatMessage({ id: 'request.missingError.title' })}
                        </div>
                        <br />
                        <div>
                            {intl.formatMessage({ id: 'request.missingError.description' })}
                        </div>
                    </div>
                )
            }
        } else if (error.request.status !== 401 || error.request.responseURL.includes("/login")) {
            const resp = JSON.parse(error.request.response)
            if (resp) {
                toast.error(resp.message)
            }
        }
    })

    return request
}