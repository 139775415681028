import React, { ChangeEvent, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CellProps } from 'react-table'
import { ActionType } from '../../../types/action-type'
import MultiRow from '../../custom/custom-table/multi-row'
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/column-edit.svg'
import { ReactComponent as AnalysisIcon } from '../../../assets/icons/column-analysis.svg'
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/column-duplicate.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/column-delete.svg'
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg'
import { TableAction } from '../../../types/table-action'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardProtocolsListProperties } from '../../../types/component-properties/dashboard-protocols-list-properties'
import { State } from '../../../types/states/state'
import CustomTable from '../../custom/custom-table/custom-table'
import ReactTooltip from 'react-tooltip'
import { intl } from '../../../intl'
import { EditEquipmentProtocol } from '../../../types/edit-equipment-protocol'
import { fetchDuplicateEquipmentProtocol, fetchEditEquipmentProtocol } from '../../../reducers/equipment-detail'
import { TableProtocol } from '../../../types/entity/table-protocol'
import { push } from 'connected-react-router'
import { addPath } from '../../../reducers/ui'
import queryString from 'query-string'
import CustomButton from '../../custom/custom-button'
import { LocationDescriptorObject } from 'history'

const classes = {
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }
    `
}

const DashboardProtocolsList = ({ handleEditProtocol, handleDeleteProtocol, ...props }: DashboardProtocolsListProperties): ReactElement => {
    const dispatch = useDispatch()

    const [editRow, setEditRow] = useState<{ name: string }>({ name: '' })
    const [editIndex, setEditIndex] = useState(-1)
    const [editingRow, setEditingRow] = useState('-1')
    const [name, setName] = useState('')
    const [actions, setActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Definition', Icon: AnalysisIcon },
        { type: 'Duplicate', Icon: DuplicateIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])
    const [actionsEditing] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Definition', Icon: AnalysisIcon },
        { type: 'Duplicate', Icon: DuplicateIcon },
        { type: 'Cancel', Icon: CancelIcon }
    ])

    const equipmentProtocols = useSelector((state: State) => state.equipmentDetail.protocols)
    const equipment = useSelector((state: State) => state.dashboard.currentEquipment)
    const loggedUser = useSelector((state: State) => state.login.user)
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)
    const search = useSelector((state: State) => state.router.location.search)

    const queryParams = queryString.parse(search as string)

    const handleBaselineAction = useCallback((action: TableAction, index: number): void => {
        switch(action.type) {
            case 'Save':
                const editProtocol: EditEquipmentProtocol = {
                    name,
                    active: (action.row as TableProtocol).active[index],
                    testObjectId: (action.row as TableProtocol).testObjectId
                }
                const id = (action.row as TableProtocol).id[index]

                Promise.all([dispatch(fetchEditEquipmentProtocol(editProtocol, id, { equipmentId: equipment?.id }, intl.formatMessage({ id: 'dashboard.toast.success.protocolCreated' })))]).then(() => {
                    setEditRow({ name: '' })
                    setEditIndex(-1)
                    setEditingRow('-1')
                    setName('')
                })

                break
            case 'Edit':
                // setTimeout(() => {
                    setEditRow({ name: (action.row as TableProtocol).name[index] })
                    setEditIndex(index)
                    setEditingRow((action.row as TableProtocol).id[index])
                    setName((action.row as TableProtocol).name[index])
                // }, 100)

                break
            case 'Definition':
                const destination = {
                    pathname: `/dashboard/equipment-detail/requirements`,
                    search: `?tab=${queryParams.tab}&equipmentId=${equipment?.id ?? ''}&protocolId=${(action.row as TableProtocol).id[index]}&page=${timelineFilter.page}`
                }
                dispatch(push(destination))
                dispatch(addPath({
                    label: (action.row as TableProtocol).name[index],
                    id: (action.row as TableProtocol).id[index],
                    destination,
                    param: null
                }))

                break
            case 'Duplicate':
                dispatch(fetchDuplicateEquipmentProtocol((action.row as TableProtocol).id[index], { equipmentId: (action.row as TableProtocol).equipmentId[index], orderBy: props.orderBy, orderType: props.orderType }, intl.formatMessage({ id: 'dashboard.toast.success.protocolDuplicated' })))

                break
            case 'Delete':
                handleDeleteProtocol((action.row as TableProtocol).id[index], { equipmentId: (action.row as TableProtocol).equipmentId[index] }, false)

                break
            case 'Cancel':
                setEditRow({ name: '' })
                setEditIndex(-1)
                setEditingRow('-1')
                setName('')

                break
        }
    }, [dispatch, handleDeleteProtocol, equipment, name, queryParams, props.orderBy, props.orderType, timelineFilter.page])

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        // setEditRow({ name: event.target.value })
        setName(event.target.value)
    }, [])

    const setColumnName = useCallback((rowProps: PropsWithChildren<CellProps<TableProtocol>>): ReactElement => {
        return <MultiRow row={rowProps.row.original} fieldKey='name' editRow={editRow} editIndex={editIndex} editingRow={editingRow} onInputChange={onInputChange}></MultiRow>
    }, [editIndex, editingRow, editRow, onInputChange])

    const setColumnFirstUsageDate = useCallback((rowProps: PropsWithChildren<CellProps<TableProtocol>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='firstUsageDate'></MultiRow>
    }, [])

    const setColumnUsageCount = useCallback((rowProps: PropsWithChildren<CellProps<TableProtocol>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='usageCount'></MultiRow>
    }, [])

    const setColumnLastModified = useCallback((rowProps: PropsWithChildren<CellProps<TableProtocol>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='lastModified'></MultiRow>
    }, [])

    const setColumnActions = useCallback((rowProps: PropsWithChildren<CellProps<TableProtocol>>): ReactElement => {
        return <MultiRow row={rowProps.row.original} fieldKey='actions' editIndex={editIndex} editingRow={editingRow} actions={actions} actionsEditing={actionsEditing} handleBaselineAction={handleBaselineAction}></MultiRow>
    }, [editIndex, editingRow, actions, actionsEditing, handleBaselineAction])

    const setColumnActive = useCallback((rowProps: PropsWithChildren<CellProps<TableProtocol>>): ReactElement => {
        function onChange(checked: boolean, index: number): void {
            handleEditProtocol(rowProps.row.original.id[index], { name: rowProps.row.original.name[index], active: !checked })
        }
        
        return <MultiRow row={rowProps.row.original} fieldKey='active' onChange={onChange}></MultiRow>
    }, [handleEditProtocol])

    const protocolColumns = useMemo(() => [
        { id: 'testObject', Header: 'dashboard.protocols.list.table.header.testObject', canGroupBy: false, accessor: 'testObjectName', disableSortBy: true },
        { id: 'name', Header: 'dashboard.protocols.list.table.header.name', canGroupBy: false, accessor: 'name', disableSortBy: true, Cell: setColumnName },
        { id: 'firstUsageDate', Header: 'dashboard.protocols.list.table.header.usageFromTo', canGroupBy: false, accessor: 'firstUsageDate', disableSortBy: true, Cell: setColumnFirstUsageDate },
        { id: 'usageCount', Header: 'dashboard.protocols.list.table.header.usageCount', canGroupBy: false, accessor: 'usageCount', disableSortBy: true, Cell: setColumnUsageCount },
        { id: 'lastModified', Header: 'dashboard.protocols.list.table.header.lastModified', canGroupBy: false, accessor: 'lastModified', disableSortBy: true, Cell: setColumnLastModified },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 68, disableSortBy: true, Cell: setColumnActions },
        { id: 'active', Header: '', canGroupBy: false, accessor: 'active', width: 68, disableSortBy: true, Cell: setColumnActive }
    ], [setColumnName, setColumnFirstUsageDate, setColumnUsageCount, setColumnLastModified, setColumnActions, setColumnActive])

    function getHiddenColumns(): string[] {
        const hiddenColumns: string[] = []
        if (!loggedUser?.acl.includes('PROTOCOL_INSTANCES_ACTIVATE')) {
            hiddenColumns.push('active')
        }
        return hiddenColumns
    }

    
    function handleCreateProtocol(): void {
        const destination: LocationDescriptorObject = {
            pathname: '/dashboard/equipment-detail/new-protocol',
            search
        }
        const path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newProtocol' }), id: "", destination, param: null }
        dispatch(push(destination))
        dispatch(addPath(path))
    }

    useEffect(() => {
        setActions((oldValue): ActionType[] => {
            return oldValue.filter(action => {
                switch(action.type) {
                    case 'Edit':
                        return loggedUser?.acl.includes('PROTOCOL_INSTANCES_UPDATE')
                    case 'Delete':
                        return loggedUser?.acl.includes('PROTOCOL_INSTANCES_DELETE')
                    case 'Duplicate':
                        return loggedUser?.acl.includes('PROTOCOL_INSTANCES_DEFINE')
                    default:
                        return true
                }
            })
        })
    }, [loggedUser])

    return (
        <React.Fragment>
            <div className="px-8 pt-6">
                {
                    loggedUser?.acl.includes('PROTOCOL_INSTANCES_CREATE') &&
                    <CustomButton
                        className="bg-primary text-white px-6 my-6 ml-auto"
                        label={intl.formatMessage({ id: 'dashboard.protocols.list.addButton.label' })}
                        onClick={handleCreateProtocol} />
                }
                <CustomTable
                    cssStyles={[classes.table]}
                    data={equipmentProtocols}
                    columns={protocolColumns}
                    hiddenColumns={getHiddenColumns()}
                    showPagination={false}
                    stripedRows={true}
                    noDataMessage={intl.formatMessage({ id: 'dashboard.protocols.list.table.noDataText' })}
                    // handleSort={props.handleSort}
                />
            </div>
            <ReactTooltip type="dark" effect="solid" />
        </React.Fragment>
    )
}

export default DashboardProtocolsList