import React, { PropsWithChildren, ReactElement, useMemo } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomAccordion from '../custom/custom-accordion'
import { AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { useSelector } from 'react-redux'
import { State } from '../../types/states/state'
import CustomTable from '../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import { IndicatorIdentifier } from '../../types/indicator-identifier'
import CustomCheckbox from '../custom/custom-checkbox'
import PropTypes from 'prop-types'
import { CustomersIndicatorProperties } from '../../types/component-properties/customers-indicator-properties'

const classes = {
    'accordion': css`
        .accordion__item {
            border: none;
            &:not(:last-of-type) {
                .accordion__button[aria-expanded="false"] {
                    border-bottom: none;
                }
            }
        }

        .accordion__button {
            display: flex;
            align-items: center;
            outline: none;
            background: transparent;
            color: var(--color-black);
            padding: 0.6rem 0;
            font-weight: bold;
            font-size: 0.9rem;
            position: relative;
            border-top: 1px solid var(--color-gray-100);
            border-bottom: 1px solid var(--color-gray-100);

            ::before {
                position: absolute;
                right: -0.5rem;
                top: calc(50% - 0.3125rem);
                height: 0.625rem;
                width: 0.625rem;
                border-bottom: 2px solid var(--color-black);
                border-left: 2px solid var(--color-black)
                transform: rotate(45deg);
                transition: transform 0.1s;
            }
        }

        .accordion__button[aria-expanded="true"] {
            border-bottom: 1px solid var(--color-gray-100);
            ::before {
                transform: rotate(-135deg);
            }
        }

        .accordion__panel {
            border: none;
            padding: 0;
        }

        .accordion__heading {
            background: var(--color-white);
        }
    `,
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }

        thead > tr {
            background: var(--color-white);
        }

        tbody > tr:nth-of-type(even) {
            background: var(--color-white);
        }

        tbody > tr:nth-of-type(odd) {
            background: var(--color-background-second);
        }
    `
}

const CustomersIndicator = ({ handleChange, ...props }: CustomersIndicatorProperties): ReactElement => {
    const customer = useSelector((state: State) => state.customers.currentCustomer)

    const columns = useMemo(() => [
        { id: 'used', Header: '', canGroupBy: false, accessor: 'used', disableSortBy: true, width: 32, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<IndicatorIdentifier>>): ReactElement {
            return (
                <CustomCheckbox
                    checked={props.usedIndicators.includes(rowProps.row.original.id)}
                    onClick={(event): void => handleChange(rowProps.row.original.id, event)} />
            )
        } },
        { id: 'name', Header: 'organisations.indicators.table.header.name', canGroupBy: false, accessor: 'name', disableSortBy: true, width: "" }
    ], [handleChange, props.usedIndicators])

    return (
        <React.Fragment>
            <CustomAccordion className="mt-6" cssStyles={[classes.accordion]}>
                {
                    customer?.indicators.map((indicator, index) => (
                        <AccordionItem key={index} uuid={indicator.analyserId}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <h6 className="font-extra-bold my-0 pl-4">{indicator.analyserName}</h6>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <CustomTable
                                    cssStyles={[classes.table]}
                                    className="mt-2"
                                    data={indicator.indicatorIdentifiers}
                                    columns={columns}
                                    showPagination={false}
                                    stripedRows={true} />
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))
                }
            </CustomAccordion>
        </React.Fragment>
    )
}

CustomersIndicator.propTypes = {
    usedIndicators: PropTypes.array,
    handleChange: PropTypes.func
}

export default CustomersIndicator