import React, { ReactElement, useState, useEffect, useMemo, useCallback, PropsWithChildren } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { intl } from '../../intl'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../types/states/state'
import CustomTable from '../custom/custom-table/custom-table'
import ActionsCell from '../custom/custom-table/actions-cell'
import { ActionType } from '../../types/action-type'
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { EditableTableRow } from '../../types/editable-table-row'
import { TableAction } from '../../types/table-action'
import { CustomerEquipmentProperty } from '../../types/entity/customer-equipment-property'
import { CellProps } from 'react-table'
import { useFetching } from '../../common/utils'
import { fetchEditEquipmentProperty, fetchEquipmentProperties } from '../../reducers/equipment-detail'
import queryString from 'query-string'
import CustomInput from '../custom/custom-input'

const classes = {
    'table': css`
        th, td {
            padding: 0.25rem 0.75rem;
        }

        tbody > tr:nth-of-type(odd) {
            background: var(--color-white);
        }

        tbody > tr:nth-of-type(even) {
            background: var(--color-background-second);
        }
    `
}

const DashboardProperties = (): ReactElement => {
    const dispatch = useDispatch()
    
    const location = useSelector((state: State) => state.router.location)
    const equipment = useSelector((state: State) => state.dashboard.currentEquipment)
    const equipmentProperties = useSelector((state: State) => state.equipmentDetail.equipmentProperties)

    const queryParams = queryString.parse(location.search ?? '')

    const [editedRowTemplate, setEditedRowTemplate] = useState<ReactElement>(<tr></tr>)
    const [propertyValue, setPropertyValue] = useState<string | null>(null)
    const [selectedProperty, setSelectedProperty] = useState<EditableTableRow>({
        name: null,
        value: null,
        id: null
    })

    const handleResetSelectedProperty = useCallback((property: CustomerEquipmentProperty | undefined, editRow: string | undefined): void => {
        if (property) {
            setPropertyValue(property.value)
            setSelectedProperty({
                name: property.name,
                value: property.value,
                id: editRow
            })
        } else {
            setPropertyValue(null)
            setSelectedProperty({
                name: null,
                value: null,
                id: editRow
            })
        }
    }, [])

    const [tableActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon }
    ])

    const handleAction = useCallback((action: TableAction): void => {
        switch (action.type) {
            case 'Save':
                if (equipment) {
                    Promise.all([dispatch(fetchEditEquipmentProperty(equipment.id, (action.row as CustomerEquipmentProperty).id, intl.formatMessage({ id: 'dashboard.toast.success.equipmentPropertyUpdated' }), { value: propertyValue ?? "" }))]).then(() => {
                        handleResetSelectedProperty(undefined, undefined)
                    })
                }

                break
            case 'Edit':
                setTimeout(() => {
                    handleResetSelectedProperty(action.row as CustomerEquipmentProperty, (action.row as CustomerEquipmentProperty).id)
                }, 1)

                break
            case 'Cancel':
                handleResetSelectedProperty(undefined, undefined)

                break
        }
    }, [dispatch, handleResetSelectedProperty, propertyValue, equipment])

    const columns = useMemo(() => [
        { id: 'name', Header: 'dashboard.equipmentProperties.table.header.name', canGroupBy: false, disableSortBy: true, accessor: 'name', width: "" },
        { id: 'value', Header: 'dashboard.equipmentProperties.table.header.value', canGroupBy: false, disableSortBy: true, accessor: 'value', width: 200 },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 80, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<CustomerEquipmentProperty>>): ReactElement {
            return <ActionsCell actions={tableActions} row={rowProps.row.original} handleAction={handleAction} editingRow={(selectedProperty.id ?? '-1') as string}></ActionsCell>
        } }
    ], [tableActions, handleAction, selectedProperty.id])

    function handleChangeSelectedRequirement(): void {
        setEditedRowTemplate(
            <React.Fragment>
                <td>
                    {selectedProperty.name}
                </td>
                <td>
                    <CustomInput
                        className="select-none"
                        defaultValue={propertyValue as string}
                        onChange={(event): void => setPropertyValue(event.target.value)} />
                </td>
                <td>
                    <ActionsCell
                        actions={[
                            { type: 'Save', Icon: SaveIcon },
                            { type: 'Cancel', Icon: CancelIcon }
                        ]}
                        row={selectedProperty as CustomerEquipmentProperty}
                        canSave={true}
                        handleAction={handleAction}
                        editingRow={selectedProperty.id as string}></ActionsCell>
                </td>
            </React.Fragment>
        )
    }

    useFetching(fetchEquipmentProperties, 'dashboard', [], queryParams.equipmentId as string)

    useEffect(handleChangeSelectedRequirement, [selectedProperty, propertyValue])

    return (
        <div className="h-full w-full pt-4 px-8">
            <CustomTable
                cssStyles={[classes.table]}
                data={equipmentProperties}
                columns={columns}
                noDataMessage={intl.formatMessage({ id: 'dashboard.equipmentProperties.table.noDataText' })}
                showPagination={false}
                stripedRows={true}
                editedRow={selectedProperty}
                editedRowTemplate={editedRowTemplate} />
        </div>
    )
}

export default DashboardProperties