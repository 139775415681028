import React, { useEffect, ReactElement, useState } from 'react'
import EquipmentsList from './equipments-list'
import { SectionProperties } from '../../types/component-properties/section-properties'
import { useDispatch, connect, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router'
import { Equipment } from '../../types/entity/equipment'
import EquipmentsEditor from './equipments-editor'
import EquipmentsCreator from './equipments-creator'
import { toggleSidemenu, setQueryParams, updatePathLabel, setPaths, setCurrentSection, setSidemenuData } from '../../reducers/ui'
import { fetchEquipments, fetchArchiveEquipment, fetchDeleteEquipment, fetchCreateEquipment, fetchEditEquipment, setEquipments } from '../../reducers/equipments'
import { State } from '../../types/states/state'
import { RequestQueryParams } from '../../types/request-query-params'
import { TableAction } from '../../types/table-action'
import { push } from 'connected-react-router'
import { EditEquipment } from '../../types/edit-equipment'
import { NewEquipment } from '../../types/new-equipment'
import { EquipmentsProperties } from '../../types/component-properties/equipments-properties'
import { LocationDescriptorObject } from 'history'
import { intl } from '../../intl'
import CustomConfirmDelete from '../custom/custom-confim-delete'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { SectionName } from '../../types/section-name'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'
import { deepCopy } from '../../common/utils'

const Equipments = (props: SectionProperties & EquipmentsProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})
    const [newEquipment] = useState<NewEquipment>({ name: '', medicalCentreId: '', email: '', phone: '', photo: '' })
    const [showArchivedEquipments, setShowArchivedEquipments] = useState(true)

    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    function handleCreate(equipment: FormData): void {
        dispatch(fetchCreateEquipment(equipment, props.queryParams, intl.formatMessage({ id: 'equipments.toast.success.equipmentCreated' }), props.loggedUser?.role === 'ADMINISTRATOR'))
    }

    function handleEdit(id: string, equipment: EditEquipment): void {
        Promise.all([dispatch(fetchEditEquipment(id, equipment, props.queryParams, intl.formatMessage({ id: 'equipments.toast.success.equipmentUpdated' })))]).then(() => {
            dispatch(updatePathLabel({ index: props.paths.length - 1, label: equipment.name }))
        })
    }

    function handleArchiveEquipment(id: string, index: number, value: boolean): void {
        dispatch(fetchArchiveEquipment(id, index, value))
    }

    function handleDelete(id: string, queryParams: RequestQueryParams): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteEquipment(id, queryParams, intl.formatMessage({ id: 'equipments.toast.success.equipmentDeleted' })))
            }
        })
    }

    function handleAction(action: TableAction): void {
        let tabIndex = -1

        switch (action.type) {
            case 'Timeline':
                tabIndex = 0

                break
            case 'SPC':
                tabIndex = 1

                break
            case 'Graphs':
                tabIndex = 2

                break
            case 'Baseline':
                tabIndex = 3

                break
            case 'Protocols':
                tabIndex = 4

                break
            case 'Properties':
                tabIndex = 5

                break
        }

        switch (action.type) {
            case 'Timeline': case 'SPC': case 'Graphs': case 'Baseline': case 'Protocols': case 'Properties':
                dispatch(setCurrentSection('dashboard'))
                const dashboardPaths = [
                    {
                        label: (action.row as Equipment).customerName,
                        id: (action.row as Equipment).customerId,
                        destination: {
                            pathname: `/dashboard`
                        },
                        param: {
                            property: `customerId` as keyof RequestQueryParams,
                            value: (action.row as Equipment).customerId
                        }
                    },
                    {
                        label: (action.row as Equipment).medicalCentreName,
                        id: (action.row as Equipment).medicalCentreId,
                        destination: { pathname: `/dashboard` },
                        param: {
                            property: `medicalCentreId` as keyof RequestQueryParams,
                            value: (action.row as Equipment).medicalCentreId
                        }
                    },
                    {
                        label: (action.row as Equipment).name,
                        id: (action.row as Equipment).id,
                        destination: {
                            pathname: `/dashboard/equipment-detail`,
                            search: `?tab=${tabIndex}&equipmentId=${(action.row as Equipment).id}&page=${timelineFilter.page}`
                        },
                        param: {
                            property: `equipmentId` as keyof RequestQueryParams,
                            value: (action.row as Equipment).id
                        }
                    }
                ]
                props.handleFilterDashboard(props.loggedUser?.role === 'ADMINISTRATOR' ? dashboardPaths : [dashboardPaths[1], dashboardPaths[2]])

                break
            // case 'Users':
            //     dispatch(setCurrentSection('users'))
            //     dispatch(push(`/users`))
            //     dispatch(setPaths([
            //         {
            //             label: (action.row as Equipment).customerName,
            //             id: (action.row as Equipment).customerId,
            //             destination: {
            //                 pathname: `/equipments`,
            //                 search: `?id=${(action.row as Equipment).id}`
            //             },
            //             param: {
            //                 property: `customerId` as keyof RequestQueryParams,
            //                 value: (action.row as Equipment).customerId
            //             }
            //         }
            //     ]))

            //     break
            case 'Edit':
                const editPaths = [
                    {
                        label: (action.row as Equipment).customerName,
                        id: (action.row as Equipment).customerId,
                        destination: {
                            pathname: `/equipments`,
                            search: `?id=${(action.row as Equipment).id}`
                        },
                        param: {
                            property: `customerId` as keyof RequestQueryParams,
                            value: (action.row as Equipment).customerId
                        }
                    },
                    {
                        label: (action.row as Equipment).medicalCentreName,
                        id: (action.row as Equipment).medicalCentreId,
                        destination: {
                            pathname: `/equipments`,
                            search: `?id=${(action.row as Equipment).id}`
                        },
                        param: {
                            property: `medicalCentreId` as keyof RequestQueryParams,
                            value: (action.row as Equipment).medicalCentreId
                        }
                    },
                    {
                        label: (action.row as Equipment).name,
                        id: (action.row as Equipment).id,
                        destination: {
                            pathname: `/equipments/equipment`,
                            search: `?id=${(action.row as Equipment).id}`
                        },
                        param: null
                    }
                ]
                dispatch(setPaths(props.loggedUser?.role === 'ADMINISTRATOR' ? editPaths : [editPaths[1], editPaths[2]]))
                dispatch(push({
                    pathname: `/equipments/equipment`,
                    search: `?id=${(action.row as Equipment).id}`
                }))

                break
            case 'Delete':
                // dispatch(deleteEquipment(equipment.id))
                handleDelete((action.row as Equipment).id, action.queryParams)

                break
        }
    }

    function handleToggleArchivedEquipments (hidden: boolean): void {
        const newParams = deepCopy(props.queryParams)
        newParams.showArchived = hidden
        setShowArchivedEquipments(hidden)
        dispatch(setQueryParams(newParams))
    }

    useFetching(fetchEquipments, 'equipments', [], props.queryParams)

    useEffect(() => {
        dispatch(toggleSidemenu(true))

        return (): void => {
            dispatch(setSidemenuData([]))
            dispatch(setEquipments({ equipments: [], size: 0 }))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/equipments'>
                    <EquipmentsList
                        showArchivedEquipments={showArchivedEquipments}
                        handleToggleArchivedEquipments={handleToggleArchivedEquipments}
                        handleAction={handleAction}
                        handleArchiveEquipment={handleArchiveEquipment}
                        handleLimitChange={props.handleLimitChange}
                        handlePageChange={props.handlePageChange}
                        handleSort={props.handleSort}></EquipmentsList>
                </Route>
                <Route exact path='/equipments/new'>
                    <EquipmentsCreator
                        equipment={newEquipment}
                        handleNavigate={props.handleNavigate}
                        handleCreate={handleCreate}></EquipmentsCreator>
                </Route>
                <Route exact path='/equipments/equipment'>
                    <EquipmentsEditor
                        handleEdit={handleEdit}
                        handleNavigate={props.handleNavigate}
                        handleDelete={handleDelete}></EquipmentsEditor>
                </Route>
            </Switch>
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { queryParams: RequestQueryParams; location: LocationDescriptorObject; paths: BreadcrumbPath[]; currentSection: SectionName; loggedUser: LoggedUser | null } => ({
    queryParams: state.ui.sectionQueryParams,
    location: state.router.location,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(Equipments)
