import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../types/app-thunk'
// import { RequestQueryParams } from '../types/request-query-params'
import { TimelineEventDetailState } from '../types/states/timeline-event-detail-state'
import { TimelineEventResultsData } from '../types/entity/timeline-event-results-data'
import { TimelineEventComment } from '../types/entity/timeline-event-comment'
import { TimelineEventSymptomsDetail } from '../types/entity/timeline-event-symptom-detail'
import { call } from '../common/api'
import { toast } from 'react-toastify'
import { TimelineEventType } from '../types/timeline-event-type'
import { MessageResultsData } from '../types/entity/message-results-data'
import { StoredResultsData } from '../types/entity/stored-results-data'
import { ViolationsDetail } from '../types/entity/violations-detail'
import { EditMessage } from '../types/edit-message'
import { StoredData } from '../types/stored-data'
import { setCurrentTimelineEvent } from './equipment-detail'
import { DicomAttributeTimeline } from '../types/dicom-attribute-timeline'
import { SPC } from '../types/spc'

const timelineEventDetailInitialState: TimelineEventDetailState = {
    resultsData: null,
    symptomDetail: null,
    violationsDetail: null,
    comments: null,
    dicomAttributes: null,
    SPCData: null
}

const timelineEventDetailSlice = createSlice({
    name: 'timelineEventDetail',
    initialState: timelineEventDetailInitialState,
    reducers: {
        setResultsData: (state, { payload }: PayloadAction<TimelineEventResultsData | StoredResultsData | MessageResultsData | null>): void => {
            state.resultsData = payload
        },
        setSymptomsDetail: (state, { payload }: PayloadAction<TimelineEventSymptomsDetail | null>): void => {
            state.symptomDetail = payload
        },
        setViolationsDetail: (state, { payload }: PayloadAction<ViolationsDetail | null>): void => {
            state.violationsDetail = payload
        },
        setComments: (state, { payload }: PayloadAction<TimelineEventComment[] | null>): void => {
            state.comments = payload
        },
        setDicomAttributes: (state, { payload }: PayloadAction<DicomAttributeTimeline[] | null>): void => {
            state.dicomAttributes = payload
        },
        setSPCData: (state, { payload }: PayloadAction<SPC[] | null>): void => {
            state.SPCData = payload
        }
    }
})

export const { setResultsData, setSymptomsDetail, setViolationsDetail, setComments, setDicomAttributes } = timelineEventDetailSlice.actions

export default timelineEventDetailSlice.reducer

function getUrl(eventType: TimelineEventType, id: string, entityType: string): string {
    switch(eventType) {
        case 'ANALYSED_IMAGE':
            return `/analysed-images/${id}/${entityType}`
        case 'ANALYSED_DOCUMENT':
            return `/analysed-documents/${id}/${entityType}`
        case 'STORED_IMAGE':
            return `/stored-images/${id}`
        case 'STORED_FILES':
            return `/stored-files/${id}`
        case 'MESSAGE':
            return `/messages/${id}`
    }
}

export const fetchResultsData = (timelineEventId: string, eventType: TimelineEventType): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getResultsDataRequest = call({
            method: 'GET',
            url: `${getUrl(eventType, timelineEventId, 'result')}`
        }, dispatch, true)
        const res = await getResultsDataRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(timelineEventDetailSlice.actions.setResultsData(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}


export const fetchEditTimelineEvent = (timelineEventId: string, data: { imageTagsIds: string[] } | StoredData | EditMessage, url: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editTimelineEventRequest = call({
            method: 'PUT',
            url: `${url}/${timelineEventId}`,
            data
        }, dispatch, true)
        const res = await editTimelineEventRequest()

        if (res.status >= 200 && res.status < 300) {
            if (res.data.typeId && res.data.typeId === 'ANALYSED_IMAGE') {
                dispatch(setCurrentTimelineEvent(res.data))
            } else {
                dispatch(timelineEventDetailSlice.actions.setResultsData(res.data))
            }
            
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchSymptomsDetail = (timelineEventId: string, eventType: TimelineEventType): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getSymptomsDetailRequest = call({
            method: 'GET',
            url: `${getUrl(eventType, timelineEventId, 'symptoms')}`
        }, dispatch, true)
        const res = await getSymptomsDetailRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(timelineEventDetailSlice.actions.setSymptomsDetail(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchViolations = (timelineEventId: string, eventType: TimelineEventType): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getViolationsRequest = call({
            method: 'GET',
            url: `${getUrl(eventType, timelineEventId, 'violations')}`
        }, dispatch, true)
        const res = await getViolationsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(timelineEventDetailSlice.actions.setViolationsDetail(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchComments = (timelineEventId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCommentsRequest = call({
            method: 'GET',
            url: `/timeline-event/${timelineEventId}/comments`
        }, dispatch, true)
        const res = await getCommentsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(timelineEventDetailSlice.actions.setComments(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateComment = (timelineEventId: string, content: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createCommentRequest = call({
            method: 'POST',
            url: `/timeline-event/${timelineEventId}/comments`,
            data: {
                content
            }
        }, dispatch, true)
        const res = await createCommentRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchComments(timelineEventId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditComment = (timelineEventId: string, commentId: string, content: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editCommentRequest = call({
            method: 'PUT',
            url: `/timeline-event/comments/${commentId}`,
            data: {
                content
            }
        }, dispatch, true)
        const res = await editCommentRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchComments(timelineEventId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteComment = (timelineEventId: string, commentId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteCommentRequest = call({
            method: 'DELETE',
            url: `/timeline-event/comments/${commentId}`
        }, dispatch, true)
        const res = await deleteCommentRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchComments(timelineEventId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDicomAttributes = (timelineEventId: string, url: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getDicomAttributes = call({
            method: 'GET',
            url: `${url}/${timelineEventId}/dicom`
        }, dispatch, true)
        const res = await getDicomAttributes()

        if (res.status >= 200 && res.status < 300) {
            dispatch(timelineEventDetailSlice.actions.setDicomAttributes(res.data))
            
            // toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchSPC = (equipmentId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getSPC = call({
            method: 'GET',
            url: `/scp`,
            params: {
                equipmentId
            }
        }, dispatch, true)
        const res = await getSPC()

        if (res.status >= 200 && res.status < 300) {
            dispatch(timelineEventDetailSlice.actions.setSPCData(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}