import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LoginState } from '../types/states/login-state'
import { call, resetStorage, setTokens, setBreadcrumbPaths, setUser } from "../common/api"
import { AppThunk } from "../types/app-thunk"
import { LoginDetail } from "../types/login-detail"
import { setCurrentSection, setCustomerId, setLocale } from "./ui"
import { push } from "connected-react-router"
import { LoggedUser } from "../types/entity/logged-user"
import { Tokens } from "../types/tokens"
import JwtDecode from "jwt-decode"

const getTokens = (): Tokens | null => {
    const tokens = localStorage.getItem('tokens')
    return tokens ? JSON.parse(tokens) : null
}

const verifyToken = (): boolean => {
    const tokens = getTokens()
    if (tokens) {
        const decode: { exp: number } = JwtDecode(tokens.accessToken)
        if (Date.now() <= decode.exp * 1000) {
            return true
        } else {
            localStorage.clear()
            return false
        }
    } else {
        return false
    }
}

const loginInitialState: LoginState = {
    isLogged: verifyToken(),
    user: null
}

const loginSlice = createSlice({
    name: 'login',
    initialState: loginInitialState,
    reducers: {
        login: (state, { payload }: PayloadAction<LoggedUser>): void => {
            state.user = payload
            state.isLogged = true
        },
        logout: (state): void => {
            state.user = null
            state.isLogged = false
        },
        setLoggedUserLanguage: (state, { payload }: PayloadAction<string>): void => {
            if (state.user) {
                state.user.language = payload
            }
        }
    }
})

export const { login, logout, setLoggedUserLanguage } = loginSlice.actions

export default loginSlice.reducer

export const fetchLogin = (user: LoginDetail): AppThunk => async (dispatch): Promise<void> => {
    try {
        const loginRequest = call({
            method: 'POST',
            url: '/login',
            data: user
        }, dispatch, true)
        const res = await loginRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(setLocale(res.data.language ?? 'en'))
            setTokens({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken })
            // localStorage.setItem('user', JSON.stringify(res.data))
            setUser(res.data)
            setBreadcrumbPaths([])
            localStorage.setItem('currentSection', 'dashboard')
            dispatch(setCurrentSection('dashboard'))
            dispatch(push({ pathname: '/dashboard' }))
            // dispatch(fetchDashboardEquipments({ limit: "10", page: "0" }))
            dispatch(loginSlice.actions.login(res.data))
        }
    } catch (err) {
        // toast.error(errorMessage)
    }
}

export const fetchLogout = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        // const logoutRequest = call({
        //     method: 'POST',
        //     url: '/logout'
        // }, dispatch, false)
        // await logoutRequest()

        // localStorage.removeItem('token')
        // localStorage.removeItem('user')
        // localStorage.setItem('currentSection', 'none')

        if (/*res.status >= 200 && res.status < 300*/ true) {
            resetStorage()
            dispatch(setCustomerId(null))
            dispatch(setCurrentSection('none'))
            dispatch(loginSlice.actions.logout())
        }
    } catch (err) {
        console.log(err)
    }
}
