import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SidebarProperties } from '../types/component-properties/sidebar-properties'

import LogoutIcon from '../assets/icons/logout.png'
import ReactTooltip from 'react-tooltip'
import { intl } from '../intl'
import { connect } from 'react-redux'
import { State } from '../types/states/state'
import { SectionName } from '../types/section-name'
import { SidebarOption } from '../types/sidebar-option'
import classNames from 'classnames'

const classes = {
    'sidebar-container': css`
        width: var(--sidebar-width);
    `,
    'sidebar-nav-link': css`
        width: var(--sidebar-icon-size);
        height: var(--sidebar-icon-size);
        &:hover {
            text-decoration: none;
            cursor: pointer;
            color: var(--color-white);
            background: var(--color-primary-100);
        }
    `
}

const Sidebar = (props: SidebarProperties): ReactElement => {
    

    function handleNavigate(option: SidebarOption): void {
        if (option.section !== props.currentSection) {
            props.handleNavigate(option)
        }
    }

    return (
        <React.Fragment>
            <div css={[classes["sidebar-container"], props.cssStyles]} className={`flex flex-col items-center fixed h-screen p-2 top-0 left-0 bg-primary ${props.className}`}>
                {props.options.map(option => {
                    const Icon = option.Icon
                    return (
                        <div
                            key={option.title}
                            css={[classes["sidebar-nav-link"]]}
                            className={classNames({ 'flex justify-center items-center text-white text-lg rounded-sm p-1 mb-2': true, 'bg-primary-100': option.section === props.currentSection })}
                            data-tip={intl.formatMessage({ id: option.title })}
                            data-for="sidebar-tooltip"
                            onClick={(): void => handleNavigate(option)}
                        >
                            {
                                // option.section === 'dashboard' ?
                                //     <img src={option.Icon as string} className="w-7 h-7" alt="" />
                                //     :
                                    <Icon />
                            }
                        </div>
                    )
                })}

                <div className="flex-grow"></div>

                <div css={[classes["sidebar-nav-link"]]} className={"flex justify-center items-center my-1"} data-tip={intl.formatMessage({ id: 'sidebar.logout' })} data-for="sidebar-tooltip" onClick={props.handleLogout}>
                    <img src={LogoutIcon} className="w-7" alt="" />
                </div>
            </div>
            <ReactTooltip id="sidebar-tooltip" type="dark" effect="solid" place="right" />
        </React.Fragment>
    )
}

Sidebar.defaultProps = {
    className: '',
    cssStyles: [css``]
}

const mapStateToProps = (state: State): { currentSection: SectionName } => ({
    currentSection: state.ui.currentSection
})

export default connect(mapStateToProps)(Sidebar)
