import React, { ReactElement, useEffect, useState } from 'react'
import { MultiRowProperties } from '../../../types/component-properties/multi-row-properties'
import StabilityTrend from './stability-trend'
import { DateTime } from 'luxon'
import { State } from '../../../types/states/state'
import { connect } from 'react-redux'
import { Locale } from '../../../types/locale'
import { DashboardEquipment } from '../../../types/entity/dashboard-equipment'
import { TableBaseline } from '../../../types/entity/table-baseline'
import DateIntervalCell from '../../custom/custom-table/date-interval-cell'
import DateCell from '../../custom/custom-table/date-cell'
import ActionsCell from '../../custom/custom-table/actions-cell'
import CustomSwitch from '../custom-switch'
import { TableAction } from '../../../types/table-action'
import CustomInput from '../custom-input'
import { Equipment } from '../../../types/entity/equipment'
import { MedicalCentre } from '../../../types/entity/medical-centre'
import { Usages } from '../../../types/usages'
import { StabilityInfo } from '../../../types/stability-info'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'

const MultiRow = (props: MultiRowProperties): ReactElement => {
    const [tempName, setTempName] = useState('')

    function getRow(): ReactElement[] {
        switch(props.fieldKey) {
            case 'name':
                return (props.row as DashboardEquipment).name.map((value: string, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            {
                                props.editIndex === index && props.row.id[index] === props.editingRow ?
                                    <div className="flex w-full">
                                        <CustomInput
                                            value={tempName ?? ''}
                                            onChange={(event): void => {
                                                setTempName(event.target.value)
                                                props.onInputChange(event)
                                            }} />
                                    </div>
                                    :
                                    <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">{value}</div>
                            }
                        </React.Fragment>
                    )
                })

            case 'stabilityTrend':
                return (props.row as DashboardEquipment).stabilityInfo.map((value: StabilityInfo[], index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            <StabilityTrend
                                infos={(props.row as DashboardEquipment).stabilityInfo[index]}
                                handleGoToSymptoms={(eventId): void => props.handleGoToSymptoms ? props.handleGoToSymptoms(eventId) : undefined} />
                        </div>
                    )
                })

            case 'lastAnalysis':
                return (props.row as DashboardEquipment).lastAnalysis.map((value: number, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            {value !== null && value !== undefined ? DateTime.fromMillis(value).toLocaleString({ ...DateTime.DATE_SHORT, locale: props.locale, numberingSystem: undefined  }) : ''}
                        </div>
                    )
                })

            case 'firstAnalysisDate':
                return (props.row as TableBaseline).firstAnalysisDate.map((_value: number, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            <DateIntervalCell
                                firstDate={(props.row as TableBaseline).firstAnalysisDate[index]}
                                secondDate={(props.row as TableBaseline).lastAnalysisDate[index]} />
                        </div>
                    )
                })

            case 'firstUsageDate':
                return (props.row as TableBaseline).firstUsageDate.map((_value: number, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            <DateIntervalCell
                                firstDate={(props.row as TableBaseline).firstUsageDate[index]}
                                secondDate={(props.row as TableBaseline).lastUsageDate[index]} />
                        </div>
                    )
                })
            
            case 'usageCount':
                return (props.row as TableBaseline).usageCount.map((value: number, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            {value}
                        </div>
                    )
                })

            case 'lastModified':
                return (props.row as TableBaseline).lastModified.map((value: number, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            <DateCell date={value} />
                        </div>
                    )
                })

            case 'testObject':
                return (props.row as Equipment).testObject.map((value: string, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            {value}
                        </div>
                    )
                })

            case 'usagesTestObject':
                return (props.row as MedicalCentre).usages.map((value: Usages, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            {value.name}
                        </div>
                    )
                })

            case 'usagesActiveTotal':
                return (props.row as MedicalCentre).usages.map((value: Usages, index: number) => {
                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            {value.usages}/{(props.row as MedicalCentre).totalEquipments}
                        </div>
                    )
                })

            case 'active':
                return (props.row as TableBaseline).active.map((value: boolean, index: number) => {
                    let hidden = false
                    switch(props.filterType) {
                        case "BASELINE":
                            hidden = !(props.row as TableBaseline).firstAnalysisDate[index] && !(props.row as TableBaseline).lastAnalysisDate[index]

                            break
                    }

                    return (
                        <div key={index} className={classNames({
                            "items-center rt-td w-full min-h-3 py-3 px-0": true,
                            "flex": !hidden,
                            "hidden": hidden
                        })}>
                            <CustomSwitch
                                className="flex ml-4"
                                checked={value}
                                onChange={(checked): void => {
                                    if (props.onChange) {
                                        props.onChange(!checked, index)
                                    }
                                }} />
                        </div>
                    )
                })

            case 'actions':
                return (props.row as TableBaseline).active.map((_value: boolean, index: number) => {
                    let newActions = cloneDeep(props.actions)
                    let newActionsEditing = cloneDeep(props.actionsEditing)
                    // console.log(!(props.row as TableBaseline).firstUsageDate[index], !(props.row as TableBaseline).lastUsageDate[index], (props.row as TableBaseline).usageCount[index] === 0)
                    switch(props.filterType) {
                        case "BASELINE":
                            newActions = newActions?.filter((action) => {
                                const cond = ((!!(props.row as TableBaseline).firstUsageDate[index] && !!(props.row as TableBaseline).lastUsageDate[index]) || (props.row as TableBaseline).usageCount[index] === 0)
                                return action.type === "Delete" ? cond : true
                            })

                            break
                    }

                    switch(props.filterType) {
                        case "BASELINE":
                            newActionsEditing = newActionsEditing?.filter((action) => {
                                const cond = ((!!(props.row as TableBaseline).firstUsageDate[index] && !!(props.row as TableBaseline).lastUsageDate[index]) || (props.row as TableBaseline).usageCount[index] === 0)
                                return action.type === "Delete" ? cond : true
                            })

                            break
                    }

                    return (
                        <div key={index} className="flex items-center rt-td w-full min-h-3 py-3 px-0">
                            <ActionsCell
                                actions={(props.editIndex === -1 || (props.row as TableBaseline).id[index] !== props.editingRow) ? (newActions ?? []) : (newActionsEditing ?? [])}
                                row={props.row as TableBaseline}
                                canSave={true}
                                editingRow={props.editingRow ?? '-1'}
                                isMulti={true}
                                index={index}
                                handleAction={(action: TableAction): void => {
                                    props.onActionClick(action, index)
                                    if (props.handleBaselineAction) {
                                        props.handleBaselineAction(action, index)
                                    }
                                }} />
                        </div>
                    )
                })

            default:
                return [].map((_, index) => <div key={index}></div>)
        }
    }

    useEffect(() => {
        setTempName(props.editRow?.name ?? '')
    }, [props.editRow])

    return (
        <React.Fragment>
            <div className={`flex flex-col justify-around items-center ${props.className}`}>
                {getRow()}
            </div>
        </React.Fragment>
    )
}

MultiRow.defaultProps = {
    className: '',
    editRow: undefined,
    editIndex: -1,
    editingRow: '-1',
    onInputChange: (): void => undefined,
    onActionClick: (): void => undefined
}

const mapStateToProps = (state: State): { locale: Locale }  => ({
    locale: state.ui.locale
})

export default connect(mapStateToProps)(MultiRow)
