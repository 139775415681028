import React, { ReactElement, DragEvent, useRef, ChangeEvent, useState, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomFileProperties } from '../../types/component-properties/custom/custom-file-properties'
import { CustomFormProperties } from '../../types/component-properties/custom/custom-form-properties'
import CustomButton from './custom-button'
import { intl } from '../../intl'
import { ReactComponent as FileIcon } from '../../assets/icons/document.svg'
import classNames from 'classnames'
import _ from 'lodash'

const classes = {
    'file-wrapper': css`
        background-image: linear-gradient(to right, var(--color-gray-100) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--color-gray-100) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--color-gray-100) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--color-gray-100) 50%, rgba(255, 255, 255, 0) 0%);
        background-position: top, right, bottom, left;
        background-repeat: repeat-x, repeat-y;
        background-size: 1rem 1px, 1px 1rem;
        transition: all .3s ease;
        &::after {
            transition: all .3s ease;
        }
        &.isDragging {
            background-image: linear-gradient(to right, var(--color-primary) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--color-primary) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--color-primary) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--color-primary) 50%, rgba(255, 255, 255, 0) 0%);
            background-size: 1rem 2px, 2px 1rem;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0.5rem;
                background-color: var(--color-background-first);
                opacity: 0.75;
            }
        }
    `
}

const CustomFile = (props: CustomFileProperties & CustomFormProperties): ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null)

    const [files, setFiles] = useState<File[]>(props.files)
    const [dragging, setDragging] = useState<boolean>(false)

    function triggerInput(): void {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    function handleDrop(event: DragEvent<HTMLDivElement>): void {
        event.preventDefault()
        event.stopPropagation()
        if (props.multiple) {
            if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                const newFiles = files ? [...files, ...Array.from(event.dataTransfer.files)] : Array.from(event.dataTransfer.files)
                setFiles(newFiles)
                props.onChange(newFiles)
            }
            setDragging(false)
        } else {
            if (event.dataTransfer.files && event.dataTransfer.files.length > 0 && event.dataTransfer.files.length === 1) {
                setFiles(Array.from(event.dataTransfer.files))
                props.onChange(Array.from(event.dataTransfer.files))
            }
            setDragging(false)
        }
    }

    function handleDragEnter(event: DragEvent<HTMLDivElement>): void {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    function handleDragOver(event: DragEvent<HTMLDivElement>): void {
        event.preventDefault()
        event.stopPropagation()
    }

    function handleDragLeave(event: DragEvent<HTMLDivElement>): void {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            setDragging(false)
        }
    }

    function handleDeleteFile(index: number): void {
        if (files && files.length) {
            setFiles(oldValue => {
                const newFiles = _.cloneDeep(oldValue)
                newFiles.splice(index, 1)
                props.onChange(newFiles)
                return newFiles
            })
        }
    }

    function onChange(event: ChangeEvent<HTMLInputElement>): void {
        if (event.target.files && event.target.files.length) {
            if (props.multiple) {
                const newFiles = files ? [...files, ...Array.from(event.target.files)] : Array.from(event.target.files)
                setFiles(newFiles)
                props.onChange(newFiles)
            } else {
                setFiles(Array.from(event.target.files))
                props.onChange(Array.from(event.target.files))
            }
        }
        
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }

    useEffect(() => {
        setFiles(props.files)
    }, [props.files])

    return (
        <React.Fragment>
            <div css={[props.cssStyles]} className={props.className}>
                <div className="mb-1 font-bold text-darkgray text-xs" hidden={props.hidden || props.label === ''}>
                    {props.label} {props.required && props.label !== '' && <span className="text-red">*</span>}
                </div>
                <input
                    ref={inputRef}
                    className="hidden"
                    defaultValue={props.defaultValue as string}
                    disabled={props.disabled}
                    autoComplete="off"
                    name={props.name}
                    multiple={props.multiple}
                    type="file"
                    onChange={onChange} />
                {
                    files && files.length > 0 &&
                    <React.Fragment key={`file-list-${files ? files[0].name : 'no-file'}`}>
                        {
                            Array.from(files).map((file, index) => {
                                return (
                                    <div className="flex items-center mb-2" key={`file-${file.name}`}>
                                        <FileIcon className="w-6" />
                                        <div className="font-bold mx-2">{file.name}</div>
                                        <CustomButton
                                            className="bg-red text-white ml-auto min-w-30"
                                            label={intl.formatMessage({ id: 'inputFile.deleteButton.label' })}
                                            onClick={(): void => handleDeleteFile(index)} />
                                    </div>
                                )
                            })
                        }
                    </React.Fragment>
                }
                {
                    (props.multiple ? true : !(files && files.length > 0)) &&
                    <div
                        css={[classes["file-wrapper"]]}
                        className={classNames({ 'flex flex-col items-center bg-background-first min-h-6 py-16 px-0 relative rounded-lg': true, 'isDragging': dragging })}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDrop={handleDrop}>
                        <div><b>{intl.formatMessage({ id: 'inputFile.dragAndDrop.text' })}</b></div>
                        <div className="my-2"><small>{intl.formatMessage({ id: 'inputFile.or' }).toLowerCase()}</small></div>
                        <CustomButton
                            className="bg-primary text-white py-2 px-6 min-w-30"
                            label={intl.formatMessage({ id: 'inputFile.browseButton.label' })}
                            onClick={triggerInput} />
                    </div>
                }
                <div className="h-6 font-bold text-red text-xs" hidden={props.name === '' || props.hideErrors}>{props.errorMessage}</div>
            </div>
        </React.Fragment>
    )
}

CustomFile.defaultProps = {
    className: '',
    cssStyles: [css``],
    defaultValue: '',
    selectDefaultValue: undefined,
    files: [],
    name: '',
    label: '',
    hidden: false,
    errorMessage: undefined,
    multiple: false,
    required: false,
    type: 'text',
    placeholder: '',
    readonly: false,
    disabled: false,
    hideErrors: false,
    formRef: (): void => undefined,
    selectRef: (): void => undefined,
    datePickerRef: (): void => undefined,
    onChange: (): void => undefined,
    onFileChange: (): void => undefined
}

export default CustomFile
