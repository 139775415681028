import React, { ReactElement, useEffect, ChangeEvent, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SidemenuProperties } from '../../types/component-properties/sidemenu-properties'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { ReactComponent as ProtocolsMenuIcon } from '../../assets/icons/protocols-menu.svg'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { SectionName } from '../../types/section-name'
import { Protocol } from '../../types/entity/protocol'
import { ProtocolDetail } from '../../types/entity/protocol-detail'
import CustomAccordion from '../custom/custom-accordion'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { SidemenuData } from '../../types/sidemenu-data'
import { RequestQueryParams } from '../../types/request-query-params'
import { TestObjectProtocol } from '../../types/test-object-protocol'
import { LoggedUser } from '../../types/entity/logged-user'

import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import { useDebounce } from '../../common/utils'
import classNames from 'classnames'

const classes = {
    'not-allowed': css`
        circle {
            fill: var(--color-gray-100) !important;
        }

        &:hover {
            cursor: not-allowed !important;
            opacity: 1 !important;
        }
    `,
    'accordion': css`
        .accordion__button {
            padding-left: 1.5rem;
            padding-right: 0;
            background: transparent;
            color: var(--color-black);
            margin-bottom: 0.5rem;

            &::before {
                left: 0.25rem;
                width: 0.5rem;
                height: 0.5rem;
                border-bottom: 2px solid var(--color-darkgray);
                border-right: 2px solid var(--color-darkgray);
            }
        }

        .accordion__item {
            border: none;
        }

        .accordion__panel {
            border: none;
            padding: 0;
        }
    `,
    'icon': css`
        &:before {
            content: '';
            position: absolute;
            height: 0.5rem;
            width: 0.5rem;
            border: none;
            top: unset;
            left: 0.25rem;
            right: 0.5rem;
            border-top: 2px solid var(--color-darkgray);
            border-left: 2px solid var(--color-darkgray);
            transform: rotate(-45deg);
            transition: transform 0.1s;
        }
    `
}

const ProtocolsMenu = ({ handleInputChange, ...props }: SidemenuProperties<Protocol> & { currentProtocol: ProtocolDetail | null; testObjectsProtocolModels: TestObjectProtocol[]; customerId: string | undefined }): ReactElement => {
    
    const [inputFilter, setInputFilter] = useState<string | null>(null)
    const debouncedFilter = useDebounce(inputFilter, props.currentSection, 600)

    function handleCreateItem(): void {
        if (!(props.paths.length && props.paths[props.paths.length - 1].destination.pathname === '/protocols/new')) {
            props.handleCreateItem()
        }
    }

    function setProperty(property: string): string {
        let newProperty = ''

        switch(property) {
            case 'CUSTOMER':
                newProperty = 'customer'

                break
            case 'MEDICAL_CENTRE':
                newProperty = 'medicalCentre'

                break
            case 'EQUIPMENT':
                newProperty = 'equipment'
        }

        return newProperty
    }

    function inputChanged(event: ChangeEvent<HTMLInputElement>): void {
        setInputFilter(event.target.value === '' ? null : event.target.value)
    }

    useEffect(() => {
        handleInputChange(debouncedFilter.value)
    }, [handleInputChange, debouncedFilter])

    return (
        <React.Fragment>
            <div className="flex flex-col h-full w-full overflow-hidden p-8 pb-0 text-darkgray">
                <div className='flex justify-between items-center font-500 mb-6 mt-0'>
                    <div className="flex items-center">
                        {/* <img src={ProtocolsMenuIcon} className="w-4 h-4 mr-2" alt="" /> */}
                        <ProtocolsMenuIcon className="w-4 h-4 mr-2" />
                        {intl.formatMessage({ id: 'protocolsVault.sidemenu.title' })}
                    </div>

                    {
                        props.loggedUser?.acl.includes('PROTOCOL_MODELS_CREATE') &&
                        <AddIcon
                            css={[props.paths.length && props.paths[props.paths.length - 1].destination.pathname === '/protocols/new' ? classes["not-allowed"] : undefined]}
                            className="cursor-pointer hover:opacity-50"
                            data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                            data-for="sidemenu-tooltip" 
                            onClick={handleCreateItem} />
                    }
                </div>
                <CustomInput
                    className="mb-6 h-10 shadow-menu-input"
                    placeholder={`${intl.formatMessage({ id: 'protocolsVault.sidemenu.input.placeholder' })}...`}
                    onChange={inputChanged} />

                {
                    props.loggedUser?.role === 'ADMINISTRATOR' && props.paths.length > 0 && props.paths[0].id && props.paths[0].id !== '' &&
                    <div className="mb-1">
                        <div className="flex items-center mb-4 cursor-pointer hover:opacity-50" onClick={(): void => props.handleNavigate(0)}>
                            <div css={[classes.icon]} className="flex items-center justify-center w-4 h-4 relative arrow-icon"></div>
                            <p className="my-0 ml-2 font-bold">{props.paths[0].label}</p>
                        </div>
                    </div>
                }
                {
                    props.loggedUser?.role === 'ADMINISTRATOR' &&
                    <p className='font-extra-bold text-2xs mb-4 mt-0'>
                        {intl.formatMessage({ id: 'sidemenu.filterBy' }).toUpperCase()}
                    </p>
                }
                {
                    props.loggedUser?.role === 'ADMINISTRATOR' && !(props.paths.length > 0 && props.paths[0].id && props.paths[0].id !== '') &&
                    <div className="mb-4 font-bold">
                        {intl.formatMessage({ id: 'protocolsVault.sidemenu.filterLabel' })}
                    </div>
                }

                <div className="font-500">
                    {
                        props.sidemenuData.length > 0 && !props.customerId ?
                            props.sidemenuData[0].elements.map(element => {
                                return (
                                    <div
                                        key={element.id}
                                        className="flex justify-between mb-4 pl-6 text-sm cursor-pointer hover:opacity-50"
                                        onClick={(): void => props.handleFilter({
                                            label: element.name,
                                            id: element.id,
                                            destination: {
                                                pathname: `/${props.currentSection}`
                                            },
                                            param: {
                                                property: setProperty(props.sidemenuData[0].type) + 'Id' as keyof RequestQueryParams,
                                                value: element.id
                                            }
                                        })}
                                    >
                                        <span className={props.paths.length > 0 ? (props.paths[0].id === element.id ? 'font-extra-bold' : '') : ''}>{element.name}</span>
                                        <span className="text-primary text-xs">{element.elementsNumber}</span>
                                    </div>
                                )
                            })
                            :
                            (
                                props.testObjectsProtocolModels.length > 0 &&
                                <CustomAccordion cssStyles={[classes.accordion]} preExpanded={props.testObjectsProtocolModels.map((testObject) => testObject.testObjectName)}>
                                    {
                                        props.testObjectsProtocolModels.map((testObject, index) => {
                                            return (
                                                <AccordionItem key={index} uuid={testObject.testObjectName}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <div className="flex justify-between items-center w-full cursor-pointer hover:opacity-50">
                                                                <span className="font-bold text-darkgray">{testObject.testObjectName}</span>
                                                                <span className="text-primary text-xs">{testObject.protocols.length}</span>
                                                            </div>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        {
                                                            testObject.protocols.map((protocol, protocolIndex) => {
                                                                return (
                                                                    <div
                                                                        key={`protocol-${testObject.testObjectId}-${protocol.id}-${protocolIndex}`}
                                                                        className={classNames({
                                                                            'mb-4 pl-6 text-sm cursor-pointer hover:opacity-50': true,
                                                                            'font-bold text-black': props.currentProtocol && props.currentProtocol.id === protocol.id,
                                                                            'font-500': !(props.currentProtocol && props.currentProtocol.id === protocol.id)
                                                                        })}
                                                                        onClick={(): void => props.handleFilter({
                                                                            label: protocol.name,
                                                                            id: protocol.id,
                                                                            destination: {
                                                                                pathname: `/protocols/detail`,
                                                                                search: `?id=${protocol.id}`  
                                                                            },
                                                                            param: null
                                                                        })}
                                                                    >
                                                                        {protocol.name}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            )
                                        })
                                    }
                                </CustomAccordion>
                            )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { paths: BreadcrumbPath[]; currentSection: SectionName; currentProtocol: ProtocolDetail | null; sidemenuData: SidemenuData[]; testObjectsProtocolModels: TestObjectProtocol[]; customerId: string | undefined; loggedUser: LoggedUser | null } => ({
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    currentProtocol: state.protocols.currentProtocol,
    sidemenuData: state.ui.sidemenuData,
    testObjectsProtocolModels: state.protocols.testObjectsProtocolModels,
    customerId: state.ui.sectionQueryParams.customerId,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(ProtocolsMenu)
