import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { LicensesActionsProperties } from '../../../types/component-properties/licenses-actions-properties'
import { intl } from '../../../intl'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg'
import { ReactComponent as RemoveIcon } from '../../../assets/icons/remove.svg'

const classes = {
    'not-allowed': css`
        circle {
            fill: var(--color-gray-100) !important;
        }

        &:hover {
            cursor: not-allowed !important;
            opacity: 1 !important;
        }
    `
}

const LicensesActions = (props: LicensesActionsProperties): ReactElement => {
    

    return (
        <React.Fragment>
            <div className="flex">
                <RemoveIcon
                    css={[props.license.totalLicenses <= 0 || props.license.totalLicenses <= props.license.activeLicenses ? classes["not-allowed"] : undefined ]}
                    className="mx-1 cursor-pointer hover:opacity-50"
                    data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                    data-for="licenses-tooltip"
                    onClick={(): void => props.removeLicense()} />
                <AddIcon
                    className="mx-1 cursor-pointer hover:opacity-50"
                    data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                    data-for="licenses-tooltip"
                    onClick={(): void => props.addLicense()} />
            </div>
            <ReactTooltip id="licenses-tooltip" type="dark" effect="solid" />
        </React.Fragment>
    )
}

export default LicensesActions