import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../types/app-thunk'
import { call } from '../common/api'
import { RequestQueryParams } from '../types/request-query-params'
import { toast } from 'react-toastify'
import { setPaths, setPage, setSidemenuData } from './ui'
import { push } from 'connected-react-router'
import { UsersState } from '../types/states/users-state'
import { User } from '../types/entity/user'
import { EditUser } from '../types/edit-user'
import { NewUser } from '../types/new-user'
import { EditEquipmentNotification } from '../types/edit-equipment-notification'
import { NewEquipmentNotification } from '../types/new-equipment-notification'

const usersInitialState: UsersState = {
    users: [],
    usersSize: 0,
    currentUser: null
}

const usersSlice = createSlice({
    name: 'users',
    initialState: usersInitialState,
    reducers: {
        setUsers: (state: UsersState, { payload }: PayloadAction<{ users: User[]; size: number }>): void => {
            state.users = payload.users
            state.usersSize = payload.size
        },
        setCurrentUser: (state: UsersState, { payload }: PayloadAction<User | null>): void => {
            state.currentUser = payload
        },
        enableUser: (state, { payload }: PayloadAction<number>): void => {
            state.users[payload].enabled = !state.users[payload].enabled
        }
    }
})

export const { setUsers, setCurrentUser, enableUser } = usersSlice.actions

export default usersSlice.reducer

export const fetchUsers = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getUsersRequest = call({
            method: 'GET',
            url: '/users',
            params
        }, dispatch, true)
        const res = await getUsersRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(usersSlice.actions.setUsers({ users: res.data.users, size: res.data.size }))
            dispatch(setSidemenuData(res.data.mappedElements))

            if (res.data.users.length === 0 && res.data.size > 0) {
                dispatch(setPage(0))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateUser = (data: NewUser, params: RequestQueryParams, message: string, administrator: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createUserRequest = call({
            method: 'POST',
            url: '/users',
            data
        }, dispatch, true)
        const res = await createUserRequest()

        if (res.status >= 200 && res.status < 300) {
            if (!administrator) {
                dispatch(fetchUsers(params))
            }
            const paths = [
                {
                    label: res.data.customerName ? res.data.customerName : 'N/A',
                    id: res.data.customerId,
                    destination: {
                        pathname: `/users`
                    },
                    param: {
                        property: `customerId` as keyof RequestQueryParams,
                        value: res.data.customerId
                    }
                },
                {
                    label: res.data.username,
                    id: res.data.id,
                    destination: {
                        pathname: `/users/user`,
                        search: `?id=${res.data.id}`
                    },
                    param: null
                }
            ]
            dispatch(usersSlice.actions.setCurrentUser(res.data))
            dispatch(setPaths(administrator ? paths : [paths[1]]))
            dispatch(push({
                pathname: `/users/user`,
                search: `?id=${res.data.id}`
            }))
            toast.success(message)
        }

    } catch (err) {
        console.log(err)
    }
}

export const fetchEditUser = (id: string, data: EditUser, message: string, showMessage = true): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editUserRequest = call({
            method: 'PUT',
            url: `/users/${id}`,
            data
        }, dispatch, true)
        const res = await editUserRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(usersSlice.actions.setCurrentUser(res.data))
            if (showMessage) {
                toast.success(message)
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchUser = (id: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getUserRequest = call({
            method: 'GET',
            url: `/users/${id}`
        }, dispatch, true)
        const res = await getUserRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(usersSlice.actions.setCurrentUser(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEnableUser = (id: string, index: number, value: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const enableUserRequest = call({
            method: 'PUT',
            url: `/users/${id}`,
            data: {
                enabled: value
            }
        }, dispatch, false)
        const res = await enableUserRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(usersSlice.actions.enableUser(index))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteUser = (id: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteUserRequest = call({
            method: 'DELETE',
            url: `/users/${id}`
        }, dispatch, true)
        const res = await deleteUserRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchUsers(params))
            dispatch(setPaths([]))
            dispatch(push('/users'))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateEquipmentNotification = (id: string, data: NewEquipmentNotification, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createEquipmentNotificationRequest = call({
            method: 'POST',
            url: `/users/${id}/notifications`,
            data
        }, dispatch, true)
        const res = await createEquipmentNotificationRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(usersSlice.actions.setCurrentUser(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditEquipmentNotification = (id: string, notificationId: string, data: EditEquipmentNotification, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editEquipmentNotificationRequest = call({
            method: 'PUT',
            url: `/users/${id}/notifications/${notificationId}`,
            data
        }, dispatch, true)
        const res = await editEquipmentNotificationRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(usersSlice.actions.setCurrentUser(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteEquipmentNotification = (id: string, notificationId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteEquipmentNotificationRequest = call({
            method: 'DELETE',
            url: `/users/notifications/${notificationId}`
        }, dispatch, true)
        const res = await deleteEquipmentNotificationRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchUser(id))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}