import React, { ReactElement, useRef } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import ReactTooltip from 'react-tooltip'
import { intl } from '../../../intl'
import { ActionsCellProperties } from '../../../types/component-properties/actions-cell-properties'
import { State } from '../../../types/states/state'
import { connect } from 'react-redux'
import { RequestQueryParams } from '../../../types/request-query-params'
import classNames from 'classnames'
import { isArray } from 'lodash'

const classes = {
    'icon': css`
        g {
            fill: var(--color-primary);
        }
    `
}

const ActionsCell = (props: ActionsCellProperties): ReactElement => {
    const icon = useRef<SVGSVGElement>(null)

    return (
        <React.Fragment>
            <div className="flex justify-center">
                {
                    props.actions.map((action, index) => {
                        const Icon = action.Icon
                        let disabled = false
                        if (props.editingRow && props.editingRow !== (isArray(props.row.id) ? props.row.id[props.index ?? 0] : props.row.id)) {
                            disabled = action.type === 'Save'
                        } else if (props.editingRow && props.editingRow === (isArray(props.row.id) ? props.row.id[props.index ?? 0] : props.row.id)) {
                            disabled = !(action.type === 'Save' || action.type === 'Cancel')
                        } else {
                            disabled = false
                        }
                        return (
                            <Icon
                                key={`icon-${index}-${props.queryParams.page}-${props.queryParams.limit}-${props.editingRow}`}
                                css={[classes.icon]}
                                ref={icon}
                                className={classNames({
                                    [`w-4 h-4 mx-2 cursor-pointer hover:opacity-50 ${action.className ?? ""}`]: true,
                                    'opacity-25': disabled || (!props.canSave && action.type === 'Save'),
                                    'transform rotate-45': action.type === 'Cancel'
                                })}
                                data-tip={intl.formatMessage({ id: `tooltip.${action.type.toLowerCase()}` })}
                                data-for="column-tooltip"
                                onClick={(e): void => {
                                    if (!(disabled || (!props.canSave && action.type === 'Save')) && icon.current) {
                                        ReactTooltip.hide(icon.current as Element)
                                        // setTimeout(() => {
                                            props.handleAction({ row: props.row, type: action.type, queryParams: props.queryParams }, e)
                                        // }, 1)
                                    }
                                }} />
                        )
                    })
                }
            </div>
            <ReactTooltip id="column-tooltip" type="dark" effect="solid" />
        </React.Fragment>
    )
}

ActionsCell.defaultProps = {
    canSave: false,
    isMulti: false
}

const mapStateToProps = (state: State): { queryParams: RequestQueryParams } => ({
    queryParams: state.ui.sectionQueryParams
})

export default connect(mapStateToProps)(ActionsCell)