import React, { ReactElement, useEffect, useState } from 'react'
import DashboardEquipments from './dashboard-equipments'
import DashboardEquipmentsDetail from './dashboard-equipments-detail'
import { SectionProperties } from '../../types/component-properties/section-properties'
import { TimelineFilter } from '../../types/timeline-filter'
import { DashboardProperties } from '../../types/component-properties/dashboard-properties'
import { Switch, Route } from 'react-router'
import DashboardTimelineDetail from './timeline/dashboard-timeline-detail'
import { useDispatch, connect, useSelector } from 'react-redux'
import { DashboardEquipment } from '../../types/entity/dashboard-equipment'
import { setDashboardEquipments, fetchDashboardEquipment, setCurrentDashboardEquipment } from '../../reducers/dashboard'
import { State } from '../../types/states/state'
import { RequestQueryParams } from '../../types/request-query-params'
import { TableAction } from '../../types/table-action'
import { push } from 'connected-react-router'
import { setTimelineFilter, fetchCreateBaseline, fetchEditBaseline, fetchDeleteBaseline, fetchBaselines, fetchUpdateBaselineDefinition, fetchCreateTimelineEvent, fetchToggleTimelineEventVisibility, fetchDeleteTimelineEvent, fetchDeleteAnalysisChart, fetchCreateAnalysisChart, setTimeline, fetchTimelineEvents, fetchDeleteEquipmentProtocol, setTimelineCharts, fetchTimelineCharts, fetchEquipmentProtocols, fetchEditEquipmentProtocol, fetchCreateEquipmentProtocol } from '../../reducers/equipment-detail'
import { LocationDescriptorObject } from 'history'
import { setSidemenuData, splicePath } from '../../reducers/ui'
import CustomConfirmDelete from '../custom/custom-confim-delete'
import { intl } from '../../intl'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { useFetching } from '../../common/utils'
import { SectionName } from '../../types/section-name'
import { LoggedUser } from '../../types/entity/logged-user'
import { NewBaseline } from '../../types/new-baseline'
import { Equipment } from '../../types/entity/equipment'
import { EditBaseline } from '../../types/edit-baseline'
import queryString from 'query-string'
import { fetchCreateComment, fetchDeleteComment, fetchEditComment, fetchEditTimelineEvent } from '../../reducers/timeline-event-detail'
import { EditMessage } from '../../types/edit-message'
import { StoredData } from '../../types/stored-data'
import { AnalysisChart } from '../../types/entity/analysis-chart'
import { NewProtocolInstance } from '../../types/new-protocol-instance'

const Dashboard = (props: SectionProperties & DashboardProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.location?.search as string)

    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})
    const [orderBy, setOrderBy] = useState<string>()
    const [orderType, setOrderType] = useState<string>()

    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)
    const settings = useSelector((state: State) => state.settings.clientSettings)

    // DASHBOARD

    function handleAction(action: TableAction): void {
        let tabIndex = 0

        switch (action.type) {
            case 'Timeline':
                tabIndex = 0

                break
            case 'SPC':
                tabIndex = 1

                break
            case 'Graphs':
                tabIndex = 2

                break
            case 'Baseline':
                tabIndex = 3

                break
            case 'Protocols':
                tabIndex = 4

                break
            case 'Properties':
                tabIndex = 5

                break
            case 'Information':
                tabIndex = 6

                break
        }

        const dashboardPaths = [
            {
                label: (action.row as DashboardEquipment).customerName,
                id: (action.row as DashboardEquipment).customerId,
                destination: { pathname: '/dashboard' },
                param: { property: `customerId` as keyof RequestQueryParams, value: (action.row as DashboardEquipment).customerId }
            },
            {
                label: (action.row as DashboardEquipment).medicalCentreName,
                id: (action.row as DashboardEquipment).medicalCentreId,
                destination: { pathname: `/dashboard` },
                param: { property: `medicalCentreId` as keyof RequestQueryParams, value: (action.row as DashboardEquipment).medicalCentreId }
            },
            {
                label: (action.row as DashboardEquipment).equipment,
                id: (action.row as DashboardEquipment).id,
                destination: { pathname: `/dashboard/equipment-detail`, search: `?tab=${tabIndex}&equipmentId=${(action.row as DashboardEquipment).id}&page=${timelineFilter.page}` },
                param: { property: `equipmentId` as keyof RequestQueryParams, value: (action.row as DashboardEquipment).id }
            }
        ]

        props.handleFilterDashboard(props.loggedUser?.role === 'ADMINISTRATOR' ? dashboardPaths : [dashboardPaths[1], dashboardPaths[2]])
    }

    // EQUIPMENT DETAIL

    function handleCreateTimelineEvent(url: string, data: FormData, message: string): void {
        if (props.equipment) {
            dispatch(fetchCreateTimelineEvent(url, data, message, props.equipment?.id, props.timelineFilter))
        }
    }

    function handleDeleteTimelineEvent(timelineEventId: string, splice = false): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                if (props.equipment) {
                    Promise.all([dispatch(fetchDeleteTimelineEvent(timelineEventId, props.equipment?.id, props.timelineFilter, intl.formatMessage({ id: 'dashboard.toast.success.eventDeleted' })))]).then(() => {
                        if (splice) {
                            const destination = {
                                pathname: `/dashboard/equipment-detail`,
                                search: '?tab=0&equipmentId=' + (props.equipment?.id ?? '') + "&page=" + timelineFilter.page
                            }
                            dispatch(push(destination))
                            dispatch(splicePath(props.paths.length - 1))
                        }
                    })
                }
            }
        })
    }

    function handleCreateAnalysisChart(name: string, type: string, params: RequestQueryParams): void {
        if (props.equipment) {
            dispatch(fetchCreateAnalysisChart({ name, equipmentId: props.equipment.id, type }, params, intl.formatMessage({ id: 'dashboard.toast.success.graphCreated' })))
        }
    }

    function handleDeleteAnalysisChart(chartId: string, equipmentId: string, params: RequestQueryParams): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteAnalysisChart(chartId, equipmentId, params, intl.formatMessage({ id: 'dashboard.toast.success.graphDeleted' })))
            }
        })
    }

    function handleCreateBaseline(baseline: NewBaseline): void {
        Promise.all([dispatch(fetchCreateBaseline(baseline, intl.formatMessage({ id: 'dashboard.toast.success.baselineCreated' }), props.loggedUser?.role === 'ADMINISTRATOR'))]).then((): void => {
            dispatch(fetchBaselines({ equipmentId: props.equipment?.id }))
            const destination = {
                pathname: `/dashboard/equipment-detail`,
                search: '?tab=3&equipmentId=' + (props.equipment?.id ?? '') + "&page=" + timelineFilter.page
            }
            dispatch(push(destination))
            dispatch(splicePath(props.paths.length - 1))
        })
    }

    function handleEditBaseline(baselineId: string, baseline: EditBaseline): void {
        dispatch(fetchEditBaseline(baseline, baselineId, { equipmentId: props.equipment?.id }, intl.formatMessage({ id: 'dashboard.toast.success.baselineUpdated' })))
    }

    function handleDeleteBaseline(baselineId: string, params: RequestQueryParams, splice: boolean): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage( {id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                const deleteDestination = {
                    pathname: `/dashboard/equipment-detail`,
                    search: '?tab=3&equipmentId=' + (props.equipment?.id ?? '') + "&page=" + timelineFilter.page
                }
                setModal(false)
                Promise.all([dispatch(fetchDeleteBaseline(baselineId, params, intl.formatMessage({ id: 'dashboard.toast.success.baselineDeleted' })))]).then(() => {
                    dispatch(push(deleteDestination))
                    if (splice) {
                        dispatch(splicePath(props.paths.length - 1))
                    }
                })
            }
        })
    }

    function handleUpdateBaseline(baselineId: string, analysedImagesIds: string[], indicatorIds: string[]): void {
        dispatch(fetchUpdateBaselineDefinition(baselineId, { analysedImagesIds, indicatorIds }, intl.formatMessage({ id: 'dashboard.toast.success.baselineUpdated' })))
    }

    function handleTimelineFilter(timelineFilter: TimelineFilter): void {
        // if (props.currentDashboardEquipment) {
        dispatch(setTimelineFilter(timelineFilter))
        // dispatch(getTimelineEvents(props.currentDashboardEquipment.id, timelineFilter, props.equipmentDetailTabIndex))
        // }
    }

    function navigateToChosenTab(label: string, destination: LocationDescriptorObject, add = true): void {
        props.timelineNavigate({ label, id: '', destination, param: null }, add)
    }

    function handleToggleTImelineEventVisibility(timelineEventId: string, hidden: boolean): void {
        dispatch(fetchToggleTimelineEventVisibility(timelineEventId, hidden, queryParams.equipmentId as string, props.timelineFilter))
    }

    // PROTOCOLS

    function handleCreateProtocol(protocol: NewProtocolInstance): void {
        Promise.all([dispatch(fetchCreateEquipmentProtocol(protocol, intl.formatMessage({ id: 'dashboard.toast.success.protocolCreated' })))]).then((): void => {
            dispatch(fetchEquipmentProtocols({ equipmentId: props.equipment?.id }))
            const destination = {
                pathname: `/dashboard/equipment-detail`,
                search: '?tab=4&equipmentId=' + (props.equipment?.id ?? '') + "&page=" + timelineFilter.page
            }
            dispatch(push(destination))
            dispatch(splicePath(props.paths.length - 1))
        })
    }

    function handleEditProtocol(protocolId: string, protocol: EditBaseline): void {
        dispatch(fetchEditEquipmentProtocol(protocol, protocolId, { equipmentId: props.equipment?.id }, intl.formatMessage({ id: 'dashboard.toast.success.protocolUpdated' })))
    }

    function handleDeleteProtocol(protocolId: string, params: RequestQueryParams, splice: boolean): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage( {id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                const deleteDestination = {
                    pathname: `/dashboard/equipment-detail`,
                    search: '?tab=4&equipmentId=' + (props.equipment?.id ?? '') + "&page=" + timelineFilter.page
                }
                setModal(false)
                Promise.all([dispatch(fetchDeleteEquipmentProtocol(protocolId, params, intl.formatMessage({ id: 'dashboard.toast.success.protocolDeleted' })))]).then(() => {
                    dispatch(push(deleteDestination))
                    if (splice) {
                        dispatch(splicePath(props.paths.length - 1))
                    }
                })
            }
        })
    }

    // TIMELINE EVENT DETAIL

    function handleEditTimelineEvent(timelineEventId: string, data: { imageTagsIds: string[] } | StoredData | EditMessage, url: string, message: string): void {
        dispatch(fetchEditTimelineEvent(timelineEventId, data, url, message))
    }

    function handleCreateComment(timelineEventId: string, content: string): void {
        dispatch(fetchCreateComment(timelineEventId, content, intl.formatMessage({ id: 'dashboard.toast.success.commentCreated' })))
    }

    function handleEditComment(timelineEventId: string, commentId: string, newContent: string): void {
        dispatch(fetchEditComment(timelineEventId, commentId, newContent, intl.formatMessage({ id: 'dashboard.toast.success.commentUpdated' })))
    }

    function handleDeleteComment(timelineEventId: string, commentId: string, author: string): void {
        setModal(true)
        setModalInfo({
            header: `${intl.formatMessage({ id: 'modal.header.deleteCommentBy' })} ${author}`,
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteComment(timelineEventId, commentId, intl.formatMessage({ id: 'dashboard.toast.success.commentDeleted' })))
            }
        })
    }

    useEffect(() => {
        const timelineTimeInterval = settings?.find((setting) => setting.nameId === "timeIntervalTimeline")

        if (timelineTimeInterval && queryParams.equipmentId) {
            const from = new Date(new Date().valueOf() - 1000 * 60 * 60)
            const monthsDifference = parseInt(timelineTimeInterval.value ?? "12", 10)
            from.setMonth(from.getMonth() - monthsDifference)
            const to = new Date(new Date().valueOf() - 1000 * 60 * 60)
            dispatch(fetchTimelineCharts(queryParams.equipmentId as string, { from: from.valueOf().toString(), to: to.valueOf().toString() }))
        }
    }, [dispatch, settings, queryParams.equipmentId])

    useFetching(fetchDashboardEquipment, 'dashboard', [], queryParams.equipmentId as string)
    useFetching(fetchTimelineEvents, 'dashboard', [timelineFilter], queryParams.equipmentId as string, timelineFilter)

    useEffect(() => {
        return (): void => {
            dispatch(setSidemenuData([]))
            dispatch(setDashboardEquipments({ equipments: [], size: 0 }))
            dispatch(setCurrentDashboardEquipment(null))
            dispatch(setTimeline({
                data: [],
                size: 0
            }))
            dispatch(setTimelineCharts([]))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/dashboard'>
                    <DashboardEquipments
                        handleAction={handleAction}
                        handlePageChange={props.handlePageChange}
                        handleLimitChange={props.handleLimitChange}
                        handleSort={props.handleSort} />
                </Route>
                <Route path='/dashboard/equipment-detail'>
                    <DashboardEquipmentsDetail
                        timelineNavigate={navigateToChosenTab}
                        orderBy={orderBy}
                        orderType={orderType}
                        setOrderBy={setOrderBy}
                        setOrderType={setOrderType}
                        handleNavigate={props.handleNavigate}
                        handleTimelineFilter={handleTimelineFilter}
                        handleToggleTImelineEventVisibility={handleToggleTImelineEventVisibility}
                        handleCreateTimelineEvent={handleCreateTimelineEvent}
                        handleDeleteTimelineEvent={handleDeleteTimelineEvent}
                        handleCreateAnalysisChart={handleCreateAnalysisChart}
                        handleDeleteAnalysisChart={handleDeleteAnalysisChart}
                        handleEditBaseline={handleEditBaseline}
                        handleCreateBaseline={handleCreateBaseline}
                        handleUpdateBaseline={handleUpdateBaseline}
                        handleDeleteBaseline={handleDeleteBaseline}
                        handleCreateProtocol={handleCreateProtocol}
                        handleEditProtocol={handleEditProtocol}
                        handleDeleteProtocol={handleDeleteProtocol}
                        handleFilter={props.handleFilter} />
                </Route>
                <Route path='/dashboard/timeline-detail'>
                    <DashboardTimelineDetail
                        timelineNavigate={navigateToChosenTab}
                        handleNavigate={props.handleNavigate}
                        handleDeleteTimelineEvent={handleDeleteTimelineEvent}
                        handleEditTimelineEvent={handleEditTimelineEvent}
                        handleEditComment={handleEditComment}
                        handleCreateComment={handleCreateComment}
                        handleDeleteComment={handleDeleteComment} />
                </Route>
            </Switch>
            
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { analysisCharts: AnalysisChart[]; equipment: Equipment | null; queryParams: RequestQueryParams; timelineFilter: TimelineFilter; location: LocationDescriptorObject; currentSection: SectionName; paths: BreadcrumbPath[]; loggedUser: LoggedUser | null } => ({
    analysisCharts: state.equipmentDetail.analysisCharts,
    equipment: state.dashboard.currentEquipment,
    queryParams: state.ui.sectionQueryParams,
    timelineFilter: state.equipmentDetail.timelineFilter,
    location: state.router.location,
    currentSection: state.ui.currentSection,
    paths: state.ui.breadcrumbPaths,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(Dashboard)
