import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { toggleSidemenu } from '../../reducers/ui'
import { State } from '../../types/states/state'
import { InformationProperties } from '../../types/component-properties/information-properties'
import { AppVersion } from '../../types/app-version'
import { version } from '../../../package.json'

const Information = (props: InformationProperties): ReactElement => {
    const dispatch = useDispatch()

    const [globalVersion, setGlobalVersion] = useState('')

    useEffect(() => {
        dispatch(toggleSidemenu(false))
        fetch('global-version.json').then(res => res.json()).then(res => {
            setGlobalVersion(res.version)
        })
    }, [dispatch])

    return (
        <div className="pt-4 px-8">
            {
                props.appVersion &&
                <React.Fragment>
                    <h4 className="mt-0 mb-4">AutoPIA - v. {globalVersion}</h4>
                    {
                        props.appVersion.subsystems.map((subsystem, index) => {
                            return (
                                <div key={index} className="flex my-2">
                                    <div className="font-bold min-w-40">{subsystem.name}</div>
                                    <div className="text-gray">v. {subsystem.version}</div>
                                </div>
                            )
                        })
                    }
                    <div className="flex my-2">
                        <div className="font-bold min-w-40">Frontend</div>
                        <div className="text-gray">v. {version}</div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = (state: State): { appVersion: AppVersion | null } => ({
    appVersion: state.ui.version
})

export default connect(mapStateToProps)(Information)