import React, { useState, ReactElement, PropsWithChildren, useEffect, useMemo } from 'react'
import CustomTable from '../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import PropTypes from 'prop-types'
import { EquipmentsListProperties } from '../../types/component-properties/equipments-list-properties'
import CustomSwitch from '../custom/custom-switch'
import { Equipment } from '../../types/entity/equipment'
import CustomCheckbox from '../custom/custom-checkbox'
import { intl } from '../../intl'
import { State } from '../../types/states/state'
import { connect } from 'react-redux'

import { ReactComponent as TimelineIcon } from '../../assets/icons/column-timeline.svg'
import { ReactComponent as SPCIcon } from '../../assets/icons/column-spc.svg'
import { ReactComponent as AnalysisIcon } from '../../assets/icons/column-analysis.svg'
import { ReactComponent as BaselineIcon } from '../../assets/icons/column-baseline.svg'
import { ReactComponent as ProtocolsIcon } from '../../assets/icons/protocols.svg'
import { ReactComponent as PropertiesIcon } from '../../assets/icons/column-properties.svg'
// import { ReactComponent as UsersIcon } from '../../assets/icons/column-users.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/column-edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/column-delete.svg'
import ActionsCell from '../custom/custom-table/actions-cell'
import { ActionType } from '../../types/action-type'
import { RequestQueryParams } from '../../types/request-query-params'
import { SectionName } from '../../types/section-name'
import { LoggedUser } from '../../types/entity/logged-user'
import MultiRow from '../custom/custom-table/multi-row'

const EquipmentsList = ({ handleAction, handleArchiveEquipment, ...props }: EquipmentsListProperties): ReactElement => {
    const [actions] = useState<ActionType[]>([
        { type: 'Timeline', Icon: TimelineIcon },
        { type: 'SPC', Icon: SPCIcon, className: "w-8" },
        { type: 'Graphs', Icon: AnalysisIcon },
        { type: 'Baseline', Icon: BaselineIcon },
        { type: 'Protocols', Icon: ProtocolsIcon },
        { type: 'Properties', Icon: PropertiesIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])
    const [filteredActions, setFilteredActions] = useState<ActionType[]>([
        { type: 'Timeline', Icon: TimelineIcon },
        { type: 'SPC', Icon: SPCIcon, className: "w-8" },
        { type: 'Graphs', Icon: AnalysisIcon },
        { type: 'Baseline', Icon: BaselineIcon },
        { type: 'Protocols', Icon: ProtocolsIcon },
        { type: 'Properties', Icon: PropertiesIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])

    const columns = useMemo(() => [
        { id: 'name', Header: 'equipments.list.table.header.name', canGroupBy: false, accessor: 'name', width: "" },
        { id: 'medicalCentreName', Header: 'equipments.list.table.header.medicalCentre', canGroupBy: false, accessor: 'medicalCentreName', width: "" },
        { id: 'customerName', Header: 'equipments.list.table.header.organisation', canGroupBy: false, accessor: 'customerName', width: "" },
        { id: 'testObject', Header: 'equipments.list.table.header.testObject', canGroupBy: false, disableSortBy: true, accessor: 'testObject', width: "", Cell: function setColumn(rowProps: PropsWithChildren<CellProps<Equipment>>): ReactElement {
            return <MultiRow row={rowProps.row.original} fieldKey='testObject'></MultiRow>
        } },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 220, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<Equipment>>): ReactElement {
            return <ActionsCell key={`${props.page}-${props.limit}`} actions={filteredActions} row={rowProps.row.original} handleAction={handleAction}></ActionsCell>
        } },
        { id: 'switch', Header: '', canGroupBy: false, accessor: '', width: 40, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<Equipment>>): ReactElement {
            return <CustomSwitch className="flex ml-4" checked={!rowProps.row.original.archived} onChange={(): void => handleArchiveEquipment(rowProps.row.original.id, rowProps.row.index, !rowProps.row.original.archived)}></CustomSwitch>
        } }
    ], [handleAction, handleArchiveEquipment, props.limit, props.page, filteredActions])

    function getHiddenColumns(): string[] {
        const hiddenColumns: string[] = []
        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            hiddenColumns.push('customerName')
        }
        if (!props.loggedUser?.acl.includes('EQUIPMENTS_UPDATE_ARCHIVED')) {
            hiddenColumns.push('switch')
        }
        if (actions.length === 0) {
            hiddenColumns.push('actions')
        }
        return hiddenColumns
    }

    useEffect(() => {
        setFilteredActions((): ActionType[] => {
            return actions.filter(action => {
                switch(action.type) {
                    case 'Timeline':
                        return props.loggedUser?.acl.includes('LOGEVENTS_LIST')
                    case 'Graphs':
                        return props.loggedUser?.acl.includes('ANALYSIS_READ')
                    case 'Baseline':
                        return props.loggedUser?.acl.includes('BASELINES_LIST')
                    case 'Protocols':
                        return props.loggedUser?.acl.includes('PROTOCOL_INSTANCES_LIST')
                    case 'Information':
                        // return props.loggedUser?.acl.includes('EQUIPMENT_INFO_READ')
                        return false
                    case 'Delete':
                        return props.loggedUser?.acl.includes('EQUIPMENTS_DELETE')
                    case 'Edit':
                        return props.loggedUser?.acl.includes('EQUIPMENTS_UPDATE')
                    default:
                        return true
                }
            })
        })
    }, [props.loggedUser, actions])

    return (
        <div className="h-full w-full pt-4 px-8">
            <div className="flex px-2 ml-1 mb-4">
                <CustomCheckbox
                    checked={props.showArchivedEquipments}
                    label={intl.formatMessage({ id: 'equipments.list.checkbox.label' })}
                    onClick={(): void => props.handleToggleArchivedEquipments(!props.showArchivedEquipments)} />
            </div>
            <CustomTable 
                data={props.equipments}
                totalData={props.size}
                columns={columns}
                hiddenColumns={getHiddenColumns()}
                stripedRows={true}
                currentPage={props.page ?? 0}
                currentPageSize={props.limit ?? 10}
                noDataMessage={intl.formatMessage({ id: 'equipments.list.table.noDataText' })}
                showDisabledRows={true}
                handlePageChange={props.handlePageChange}
                handlePageSizeChange={props.handleLimitChange}
                handleSort={props.handleSort} />
        </div>
    )
}

EquipmentsList.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    handleAction: PropTypes.func.isRequired,
    handleArchiveEquipment: PropTypes.func.isRequired
}

const mapStateToProps = (state: State): { equipments: Equipment[]; size: number; page: number | null; limit: number | null; queryParams: RequestQueryParams; currentSection: SectionName; loggedUser: LoggedUser | null }  => ({
    equipments: state.equipments.equipments,
    size: state.equipments.equipmentsSize,
    page: state.ui.page,
    limit: state.ui.limit,
    queryParams: state.ui.sectionQueryParams,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(EquipmentsList)