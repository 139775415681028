import React, { ReactElement, useState, PropsWithChildren, useEffect, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import ReactTooltip from 'react-tooltip'
import CustomTable from '../../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import CustomChart from '../../custom/custom-chart/custom-chart'
// import CustomBarChart from '../../custom/custom-chart/custom-bar-chart'
// import { CustomChartSeparator } from '../../../types/custom-chart-separator'
import SeverityCell from '../../custom/custom-table/severity-cell'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../../types/states/state'
import { TimelineEventSymptomsDetail } from '../../../types/entity/timeline-event-symptom-detail'
import { DashboardTimelineSymptomsProperties } from '../../../types/component-properties/dashboard-timeline-symptoms-properties'
import { setSymptomsDetail, fetchSymptomsDetail } from '../../../reducers/timeline-event-detail'
import queryString from 'query-string'
import { useFetching } from '../../../common/utils'
import { Symptom } from '../../../types/entity/symptom'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CornerstoneViewport from 'react-cornerstone-viewport'
import { ToolOption } from '../../../types/tool-option'
import { deepCopy } from '../../../common/utils'
import { SymptomFeature } from '../../../types/symptom-feature'
import { CustomChartLine } from '../../../types/custom-chart-line'
import { ChartLine } from '../../../types/chart-line'
import { ChartArea } from '../../../types/chart-area'
import { CustomChartArea } from '../../../types/custom-chart-area'
import { ChartLegend } from '../../../types/chart-legend'
import { TimelineEvent } from '../../../types/entity/timeline-event'
import { intl } from '../../../intl'

const classes = {
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }
    `,
    'first-row': css`
        tr:first-of-type {
            font-weight: bold;
        }
    `
}

const DashboardTimelineSymptoms = ({ handleChangeTab, ...props }: DashboardTimelineSymptomsProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [toolOptions] = useState(deepCopy(props.toolOptions))
    const [row, setRow] = useState(0)
    const [selectedRow, setSelectedRow] = useState<SymptomFeature>()
    const [orderedSymptoms, setOrderedSymptoms] = useState<Symptom[]>([])
    const [orderBy, setOrderBy] = useState<string | null>(null)
    const [orderType, setOrderType] = useState<string | null>(null)
    const [symptomsColumns] = useState([
        { id: 'severity', Header: '', canGroupBy: false, accessor: 'severity', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<Symptom>>): ReactElement {
            return <SeverityCell severity={rowProps.row.original.severity.id} square={true}></SeverityCell>
        } },
        { id: 'indicatorName', Header: 'dashboard.symptoms.table.header.indicator', canGroupBy: false, accessor: 'indicatorName', width: 160 },
        { id: 'actualValue', Header: 'dashboard.symptoms.table.header.value', canGroupBy: false, accessor: 'actualValue', disableSortBy: true },
        { id: 'expected', Header: 'dashboard.symptoms.table.header.expected', canGroupBy: false, accessor: 'expected', disableSortBy: true },
        { id: 'uom', Header: 'dashboard.symptoms.table.header.uom', canGroupBy: false, accessor: 'uom', disableSortBy: true }
    ])
    const [featuresColumns] = useState([
        { id: 'severity', Header: '', canGroupBy: false, accessor: 'severity', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<SymptomFeature>>): ReactElement {
            return <SeverityCell severity={rowProps.row.original.severity?.id ?? 'NONE'} square={true}></SeverityCell>
        } },
        { id: 'indicatorName', Header: 'dashboard.symptoms.table.header.name', canGroupBy: false, accessor: 'indicatorName', disableSortBy: true },
        { id: 'value', Header: 'dashboard.symptoms.table.header.value', canGroupBy: false, accessor: 'value', disableSortBy: true },
        { id: 'expected', Header: 'dashboard.symptoms.table.header.expected', canGroupBy: false, accessor: 'expected', disableSortBy: true },
        { id: 'support', Header: 'dashboard.symptoms.table.header.support', canGroupBy: false, accessor: 'support', disableSortBy: true },
        { id: 'uom', Header: 'dashboard.symptoms.table.header.uom', canGroupBy: false, accessor: 'uom', disableSortBy: true }
    ])

    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    // const [separatorOne] = useState<CustomChartSeparator>({
    //     axis: "x",
    //     position: 2.1,
    //     hatchSize: "50, 50"
    // })
    // const [separatorTwo] = useState<CustomChartSeparator>({
    //     axis: "x",
    //     position: 1.25,
    //     hatchSize: "25, 25"
    // })
    // const [separatorThree] = useState<CustomChartSeparator>({
    //     axis: "x",
    //     position: 0.4,
    //     hatchSize: "10, 10"
    // })

    function handleSymptomClick(rowInfo: any): void {
        setRow(rowInfo.index)
        if (orderedSymptoms.length > 0) {
            setSelectedRow({
                severity: orderedSymptoms[rowInfo.index].severity,
                indicatorId: orderedSymptoms[rowInfo.index].indicatorId,
                indicatorName: orderedSymptoms[rowInfo.index].indicatorName,
                expected: orderedSymptoms[rowInfo.index].expected,
                support: orderedSymptoms[rowInfo.index].support,
                value: orderedSymptoms[rowInfo.index].actualValue,
                uom: orderedSymptoms[rowInfo.index].uom
            })
        }
    }

    function handleSort(newOrderBy: string | null, newOrderType: string | null): void {
        setOrderBy(newOrderBy)
        setOrderType(newOrderType)
    }

    const sortTable = useCallback((newOrderBy: string | null, newOrderType: string | null): void => {
        if (props.symptomDetail) {
            if (!newOrderBy) {
                setOrderedSymptoms(props.symptomDetail.symptoms)
            } else {
                const newSymptoms: Symptom[] = deepCopy(props.symptomDetail.symptoms)
                if (newOrderType === 'asc') {
                    newSymptoms.sort((a, b) => a.indicatorName.localeCompare(b.indicatorName))
                } else {
                    newSymptoms.sort((a, b) => b.indicatorName.localeCompare(a.indicatorName))
                }
                setOrderedSymptoms(newSymptoms)
            }
        }
    }, [props.symptomDetail])

    useFetching(fetchSymptomsDetail, 'dashboard', [], queryParams.id, queryParams.type)

    useEffect(() => {
        orderedSymptoms.some((symptom, index) => {
            if (selectedRow?.indicatorId === symptom.indicatorId && selectedRow.indicatorName === symptom.indicatorName) {
                setRow(index)
                return true
            } else if (!selectedRow) {
                setSelectedRow({
                    severity: orderedSymptoms[index].severity,
                    indicatorId: orderedSymptoms[index].indicatorId,
                    indicatorName: orderedSymptoms[index].indicatorName,
                    expected: orderedSymptoms[index].expected,
                    support: orderedSymptoms[index].support,
                    value: orderedSymptoms[index].actualValue,
                    uom: orderedSymptoms[index].uom
                })
                return true
            }
            return false
        })
    }, [selectedRow, orderedSymptoms])
    
    useEffect(() => {
        if (props.symptomDetail?.symptoms) {
            sortTable(orderBy, orderType)
        }
    }, [sortTable, props.symptomDetail, orderBy, orderType])

    useEffect(() => {
        if ((!props.symptomDetail || props.symptomDetail?.symptoms.length) === 0 && queryParams.redirect === "true") {
            handleChangeTab(0)
        } else if (queryParams?.redirect === "true") {
            // const newParams = cloneDeep(queryParams)
            // delete newParams.redirect
            // const destination = {
            //     pathname: `/dashboard/timeline-detail`,
            //     search: `?tab=0&type=${queryParams.type}&id=${queryParams.id}&equipmentId=${queryParams.equipmentId}&page=${timelineFilter.page}`
            // }
            // dispatch(push(destination))
            // handleChangeTab(0)
        }
    }, [props.symptomDetail, handleChangeTab, timelineFilter, queryParams])

    useEffect(() => {
        return (): void => {
            dispatch(setSymptomsDetail(null))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            {
                props.symptomDetail && !queryParams.redirect &&
                <div className="flex mt-8 h-full px-8">
                    <div className="min-w-120 max-w-120 h-120 relative mr-5">
                        {
                            props.symptomDetail.imageSources && props.symptomDetail.imageSources.names.length > 0 &&
                            <React.Fragment>
                                <CornerstoneViewport
                                    className="max-w-120 max-h-120"
                                    tools={toolOptions}
                                    imageIds={props.symptomDetail.imageSources.names.map((__name, index) => `wadouri:${process.env.REACT_APP_API_URL}/files/${props.symptomDetail?.imageSources.id}?indices=${index}&type=${props.symptomDetail?.imageSources.type}`)} />

                                {/* <div className="absolute top-0 right-0 flex my-4 mr-4">
                                    <CustomButton
                                        className="bg-primary text-white w-10 mr-4"
                                        tooltip={intl.formatMessage({ id: 'Download' })}
                                        Icon={DownloadIcon} />
                                    <CustomButton
                                        className="bg-primary text-white w-10"
                                        tooltip={intl.formatMessage({ id: 'View Fullscreen' })}
                                        Icon={FullscreenIcon} />
                                </div> */}
                            </React.Fragment>
                        }

                        <div className="pt-2">
                            <CustomTable
                                cssStyles={[classes.table]}
                                data={orderedSymptoms}
                                columns={symptomsColumns}
                                showPagination={false}
                                stripedRows={true}
                                selectable={true}
                                selectedRow={row}
                                handleRowClick={handleSymptomClick}
                                handleSort={handleSort}></CustomTable>
                        </div>

                        {
                            props.timelineEvent && props.timelineEvent.imageTags && props.timelineEvent.imageTags.length > 0 &&
                            <div className="py-4">
                                <p className="tracking-wide font-bold text-2xs text-darkgray mb-1 mt-0">{intl.formatMessage({ id: 'dashboard.symptoms.imageTags' }).toUpperCase()}</p>
                                {
                                    props.timelineEvent.imageTags?.map((imageTag, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="text-2xs text-primary border border-solid border-primary rounded-sm font-bold text-center w-auto leading-5 uppercase inline-block select-none mr-2 mb-2 py-1 px-4"
                                            >
                                                {imageTag.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    {
                        props.symptomDetail &&
                        <div className="ml-4 mr-8 flex-grow">
                            <div className="flex mb-4">
                                <CustomTable
                                    cssStyles={[classes.table, classes['first-row']]}
                                    data={orderedSymptoms.length > 0 ? ((selectedRow ? [selectedRow] : []).concat(orderedSymptoms[row].features)) : []}
                                    columns={featuresColumns}
                                    showPagination={false}
                                    stripedRows={true}></CustomTable>
                                {/* 
                                <CustomBarChart
                                    width={420}
                                    height={380}
                                    firstLine={props.symptomDetail.dataFrequency.lineData}
                                    bars={props.symptomDetail.dataFrequency.barData}
                                    domain={{ y: [0, 150], "x": undefined }}
                                    xTickValues={xTickValues2}
                                    yTickValues={yTickValues2}
                                    xAxisProps={{
                                        style: {
                                            tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                            axis: { stroke: 'none' },
                                            axisLabel: { fontSize: 20, padding: 30 },
                                            grid: { stroke: 'none' }
                                        }
                                    }}
                                    yAxisProps={{
                                        style: {
                                            tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                            axis: { stroke: 'none' },
                                            axisLabel: { fontSize: 20, padding: 30 },
                                            grid: { stroke: 'var(--color-gray-10)' }
                                        }
                                    }}
                                    colorFirstLine="#284fef"
                                    colorBars="#eaeefd"
                                    colorSeparators="#df5096"
                                // separators={[{
                                //     data: props.symptomDetail.dataFrequency.separator,
                                //     hatchSize: "10, 5"
                                // }]}
                                ></CustomBarChart> */}
                            </div>

                            {
                                orderedSymptoms.length > 0 &&
                                orderedSymptoms[row].timeSeriesGroup.groups.map((symptomChart, index) => {
                                    const lines: CustomChartLine[] = []
                                    const areas: CustomChartArea[] = []
                                    const legendLines: string[] = []
                                    const legendAreas: string[] = []
                                    const legend: ChartLegend[] = []
                                    symptomChart.values.forEach((value, stylesIndex) => {
                                        const chart: ChartLine[] = []
                                        value.data.forEach(line => {
                                            chart.push({
                                                x: line.timestamp,
                                                y: !line.value || line.value === "null" ? null : parseFloat(line.value),
                                                size: line.timestamp === props.symptomDetail?.date ? 5 : 2.5
                                            })
                                        })
                                        lines.push({
                                            chart,
                                            colorIndex: index,
                                            stylesIndex
                                        })
                                        legendLines.push(value.name)
                                    })
                                    symptomChart.ranges.forEach((range, stylesIndex) => {
                                        const area: ChartArea[] = []
                                        range.data.forEach(point => {
                                            area.push({
                                                x: point.timestamp,
                                                y: point.upper === 'null' ? null : point.upper,
                                                y0: point.lower === 'null' ? null : point.lower
                                            })
                                        })
                                        areas.push({
                                            area,
                                            colorIndex: index,
                                            stylesIndex
                                        })
                                        legendAreas.push(range.name)
                                    })
                                    legend.push({
                                        name: symptomChart.name,
                                        lines: legendLines,
                                        areas: legendAreas
                                    })

                                    return (
                                        <React.Fragment key={index}>
                                            <CustomChart
                                                xTickValues={orderedSymptoms[row].timeSeriesGroup.groups[0].values[0].data.map((tick) => tick.timestamp) ?? []}
                                                xTickTimestamp={orderedSymptoms[row].timeSeriesGroup.groups[0].values[0].data.map(tick => tick.timestamp) ?? []}
                                                lines={lines}
                                                areas={areas}
                                                xAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'none' }
                                                    }
                                                }}
                                                yAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                    }
                                                }}
                                                title={orderedSymptoms[row].timeSeriesGroup.name}
                                                // lineColors={['#8be3fc', '#50a7df']}
                                                hideXAxis={true}
                                                showMonth={true}
                                                monthSeparators={true}
                                                separatorsColor="#df5096"
                                                // separators={[separatorOne, separatorTwo, separatorThree]}
                                                legend={legend}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                            {
                                orderedSymptoms.length > 0 && orderedSymptoms[row].siblings &&
                                orderedSymptoms[row].siblings.groups.map((symptomChart, index) => {
                                    const lines: CustomChartLine[] = []
                                    const legendLines: string[] = []
                                    const legend: ChartLegend[] = []
                                    symptomChart.values.forEach((value, stylesIndex) => {
                                        const chart: ChartLine[] = []
                                        value.data.forEach(line => {
                                            if (line.value && line.value !== 'null') {
                                                chart.push({
                                                    x: line.category,
                                                    y: parseFloat(line.value)
                                                })  
                                            }
                                        })
                                        lines.push({
                                            chart,
                                            colorIndex: index,
                                            stylesIndex
                                        })
                                        legendLines.push(value.name)
                                    })
                                    legend.push({
                                        name: symptomChart.name,
                                        lines: legendLines
                                    })
                                    return (
                                        <React.Fragment key={index}>
                                            <CustomChart
                                                xTickValues={orderedSymptoms[row].siblings.groups[0].values[0].data.map(tick => tick.category)}
                                                lines={lines}
                                                xAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'none' }
                                                    }
                                                }}
                                                yAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                    }
                                                }}
                                                title={orderedSymptoms[row].siblings.name}
                                                legend={legend}
                                                scale={{ x: 'linear', y: 'linear' }}
                                                // tooltipLabels={['VALUE', 'BASELINE']}
                                                // legendLabels={['VALUE', 'BASELINE']}
                                                // lineColors={['#df5096', '#8be3fc']}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            }
            <ReactTooltip type="dark" effect="solid" />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { timelineEvent: TimelineEvent | null; symptomDetail: TimelineEventSymptomsDetail | null; search: string; toolOptions: ToolOption[] } => ({
    timelineEvent: state.equipmentDetail.currentTimelineEvent,
    symptomDetail: state.timelineEventDetail.symptomDetail,
    search: state.router.location.search,
    toolOptions: state.ui.toolOptions
})

export default connect(mapStateToProps)(DashboardTimelineSymptoms)