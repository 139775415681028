import { MedicalCentresState } from '../types/states/medical-centres-state'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MedicalCentre } from '../types/entity/medical-centre'
import { AppThunk } from '../types/app-thunk'
import { call } from '../common/api'
import { RequestQueryParams } from '../types/request-query-params'
import { toast } from 'react-toastify'
import { OptionsType, OptionTypeBase } from 'react-select'
import { NewMedicalCentre } from '../types/new-medical-centre'
import { setPaths, setPage, setSidemenuData } from './ui'
import { push } from 'connected-react-router'
import { EditMedicalCentre } from '../types/edit-medical-centre'
import { SimpleItem } from '../types/simple-item'
import { BreadcrumbPath } from '../types/entity/breadcrumb-path'

const medicalCentresInitialState: MedicalCentresState = {
    medicalCentres: [],
    medicalCentreOptions: [],
    medicalCentresSize: 0,
    currentMedicalCentre: null
}

const medicalCentresSlice = createSlice({
    name: 'medicalCentres',
    initialState: medicalCentresInitialState,
    reducers: {
        setMedicalCentres: (state: MedicalCentresState, { payload }: PayloadAction<{ medicalCentres: MedicalCentre[]; size: number }>): void => {
            state.medicalCentres = payload.medicalCentres
            state.medicalCentresSize = payload.size
        },
        setMedicalCentreOptions: (state: MedicalCentresState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.medicalCentreOptions = payload
        },
        setCurrentMedicalCentre: (state: MedicalCentresState, { payload }: PayloadAction<MedicalCentre | null>): void => {
            state.currentMedicalCentre = payload
        }
    }
})

export const { setMedicalCentres, setMedicalCentreOptions, setCurrentMedicalCentre } = medicalCentresSlice.actions

export default medicalCentresSlice.reducer

export const fetchMedicalCentres = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getMedicalCentresRequest = call({
            method: 'GET',
            url: '/medicalcentres',
            params
        }, dispatch, true)
        const res = await getMedicalCentresRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(medicalCentresSlice.actions.setMedicalCentres({ medicalCentres: res.data.medicalCentres, size: res.data.total }))
            dispatch(setSidemenuData(res.data.mappedElements))
            if (res.data.medicalCentres.length === 0 && res.data.total > 0) {
                dispatch(setPage(0))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchMedicalCentreOptions = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getMedicalCentreOptionsRequest = call({
            method: 'GET',
            url: '/medicalcentres/references'
        }, dispatch, false)
        const res = await getMedicalCentreOptionsRequest()

        if (res.status >= 200 && res.status < 300) {
            const medicalCentreOptions: OptionsType<OptionTypeBase> = res.data.map((medicalCentre: SimpleItem) => {
                return {
                    label: medicalCentre.name,
                    value: medicalCentre.id
                }
            })
            dispatch(medicalCentresSlice.actions.setMedicalCentreOptions(medicalCentreOptions))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateMedicalCentre = (data: NewMedicalCentre, params: RequestQueryParams, message: string, administrator: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createMedicalCentreRequest = call({
            method: 'POST',
            url: '/medicalcentres',
            data
        }, dispatch, true)
        const res = await createMedicalCentreRequest()

        if (res.status >= 200 && res.status < 300) {
            if (!administrator) {
                dispatch(fetchMedicalCentres(params))
            }
            const paths: BreadcrumbPath[] = [
                { 
                    label: res.data.customerName,
                    id: res.data.customerId,
                    destination: {
                        pathname: `/medical-centres`
                    },
                    param: {
                        property: `customerId`,
                        value: res.data.customerId
                    }
                },
                {
                    label: res.data.name,
                    id: res.data.id,
                    destination: {
                        pathname: `/medical-centres/medical-centre`,
                        search: `?id=${res.data.id}`
                    },
                    param: null
                }
            ]
            
            dispatch(medicalCentresSlice.actions.setCurrentMedicalCentre(res.data))
            dispatch(setPaths(administrator ? paths : [paths[1]]))
            dispatch(push({
                pathname: '/medical-centres/medical-centre',
                search: `?id=${res.data.id}`
            }))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditMedicalCentre = (id: string, data: EditMedicalCentre, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editMedicalCentreRequest = call({
            method: 'PUT',
            url: `/medicalcentres/${id}`,
            data
        }, dispatch, true)
        const res = await editMedicalCentreRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(medicalCentresSlice.actions.setCurrentMedicalCentre(res.data))
            toast.success(message)  
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchMedicalCentre = (id: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getMedicalCentreRequest = call({
            method: 'GET',
            url: `/medicalcentres/${id}`
        }, dispatch, true)
        const res = await getMedicalCentreRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(medicalCentresSlice.actions.setCurrentMedicalCentre(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteMedicalCentre = (id: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteMedicalCentreRequest = call({
            method: 'DELETE',
            url: `/medicalcentres/${id}`
        }, dispatch, true)
        const res = await deleteMedicalCentreRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchMedicalCentres(params))
            dispatch(setPaths([]))
            dispatch(push('/medical-centres'))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}