import React, { ReactElement, useEffect, useState, useRef, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomChartProperties } from '../../../types/component-properties/custom/custom-chart-properties'
import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter, VictoryHistogram } from 'victory'
import { DateTime } from 'luxon'
import { intl } from '../../../intl'
import { deepCopy } from '../../../common/utils'
import ReactTooltip from 'react-tooltip'
import { ChartDatum } from '../../../types/chart-datum'
import numeral from 'numeral'
import _ from 'lodash'
import { fetchTimelineEventData } from '../../../reducers/equipment-detail'
import { BreadcrumbPath } from '../../../types/entity/breadcrumb-path'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { setPaths } from '../../../reducers/ui'
import { State } from '../../../types/states/state'
import { toast } from 'react-toastify'

const CustomHistogramChart = (props: CustomChartProperties): ReactElement => {
    const dispatch = useDispatch()

    const chartRef = useRef<HTMLDivElement>(null)
    const tooltipRef = useRef<HTMLDivElement>(null)

    const [groupColors] = useState([
        { line: 'var(--color-bittersweet)', area: 'var(--color-bittersweet-100)' },
        { line: 'var(--color-sunflower)', area: 'var(--color-sunflower-100)' },
        { line: 'var(--color-grass)', area: 'var(--color-grass-100)' },
        { line: 'var(--color-blue-jeans)', area: 'var(--color-blue-jeans-100)' },
        { line: 'var(--color-lavender)', area: 'var(--color-lavender-100)' },
        { line: 'var(--color-pink-rose)', area: 'var(--color-pink-rose-100)' },
        { line: 'var(--color-mint)', area: 'var(--color-mint-100)' },
        { line: 'var(--color-grapefruit)', area: 'var(--color-grapefruit-100)' },
        { line: 'var(--color-aqua)', area: 'var(--color-aqua-100)' }
    ])
    const [defaultColor] = useState({ line: 'var(--color-dark)', area: 'var(--color-dark-100)' })

    const [lineStyles] = useState([
        {}, // Standard line
        { strokeDasharray: "5, 5" }, // Dashed line
        { strokeDasharray: "0, 7", strokeLinecap: 'round', strokeWidth: 4 }, // Dotted line
        {}
    ])

    const [tooltip, setTooltip] = useState<{
        offset: { top: number; left: number };
        datum: ChartDatum;
        index: number;
        colorIndex: number;
        stylesIndex: number;
    }>()
    const [min, setMin] = useState<number>(0)
    const [max, setMax] = useState<number>(1)
    const [difference, setDifference] = useState(0)
    const [minY, setMinY] = useState<number>(0)
    const [maxY, setMaxY] = useState<number>(1)
    const [months] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])
    const [width, setWidth] = useState(chartRef.current?.getBoundingClientRect().width)
    const [displayedLinesIndexes, setDisplayedLinesIndexes] = useState<(number | null)[]>(Array.from(Array(props.lines.length).keys()))

    const locale = useSelector((state: State) => state.ui.locale)
    const loggedUser = useSelector((state: State) => state.login.user)
    const equipment = useSelector((state: State) => state.dashboard.currentEquipment)
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    function updateWidth(): void {
        if (chartRef.current) {
            setWidth(chartRef.current.getBoundingClientRect().width)
        }
        // setWidth(event.target?.innerWidth)
    }

    const getMonthSeparators = useCallback((): (string | number)[] => {
        return (deepCopy(props.xTickValues).filter((tick: string | number, index: number) => {
            try {
                return index > 0 && ((DateTime.fromMillis(props.xTickTimestamp[index - 1]).month < DateTime.fromMillis(props.xTickTimestamp[index]).month) ||
                    (DateTime.fromMillis(props.xTickTimestamp[index - 1]).month === 12 && DateTime.fromMillis(props.xTickTimestamp[index]).month === 1))
            } catch (err) {
                return false
            }
        }).map((tick: string | number) => {
            const firstDay = new Date(new Date(tick).getFullYear(), new Date(tick).getMonth(), 1)
            return DateTime.fromJSDate(firstDay).toMillis()
        }))
    }, [props.xTickValues, props.xTickTimestamp])

    useEffect(() => {
        window.addEventListener('resize', updateWidth)
        updateWidth()

        return (): void => {
            window.removeEventListener('resize', updateWidth)
        }
    }, [])

    useEffect(() => {
        setDisplayedLinesIndexes((oldValue): (number | null)[] => {
            return props.lines.length !== oldValue.length ? Array.from(Array(props.lines.length).keys()) : oldValue
        })
    }, [props.lines])

    useEffect(() => {
        const xValues: number[] = []
        const yValues: number[] = []
        props.lines.filter((_lines, index) => displayedLinesIndexes.includes(index)).forEach(lines => {
            xValues.push(...lines.chart.map((point) => parseFloat(point.x as string)))
            yValues.push(...lines.chart.map((point) => point.y as number))
        })
        const newDifference = xValues.length > 1 ? (numeral(xValues[1]).difference(xValues[0]) / 2) : 0
        setDifference(newDifference)
        setMax(xValues.length > 0 ? Math.max(...xValues) + newDifference : 10)
        setMin(xValues.length > 0 ? Math.min(...xValues) - newDifference : 0)
        setMaxY(yValues.length > 0 ? Math.max(...yValues) : 10)
        setMinY(yValues.length > 0 ? Math.min(...yValues) : 0)
    }, [props.lines, displayedLinesIndexes])

    return (
        <React.Fragment>
            <div
                ref={chartRef}
                className={`min-h-100 w-full relative overflow-hidden ${props.className}`}
            >
                <h4 className="my-0 absolute" style={{ top: 0, left: 30 }}>{props.title}</h4>
                <svg viewBox={`0 0 ${width ? width : 0} ${props.height}`} className="overflow-visible" preserveAspectRatio="none" width="100%">
                    <VictoryChart 
                        key={props.lines.length}
                        scale={props.scale}
                        standalone={false}
                        domain={{
                            x: [min, max],
                            y: [minY, maxY]
                        }}
                        width={width}
                        height={props.height}
                        style={{ parent: { maxHeight: "20rem" } }}
                        padding={{ top: 40, bottom: 40, left: 40, right: 10 }}
                        // domainPadding={{ x: 80 }}
                    >
                        {
                            !props.hideXAxis &&
                            <VictoryAxis
                                key={`victory-independent-axis`}
                                tickValues={_.uniq(props.xTickValues)}
                                tickFormat={(tick, index): string => {
                                    return props.scale.x === 'time' ? DateTime.fromMillis(props.xTickValues[index] as number).day.toString() : tick
                                }}
                                label={props.xLabel}
                                style={{
                                    axis: { stroke: 'var(--color-gray-100)' },
                                    axisLabel: { fontSize: 25, padding: 30 },
                                    grid: { stroke: 'none' },
                                    tickLabels: { fontSize: 15, padding: 5 },
                                }}
                                orientation="bottom"
                                {...props.xAxisProps} />
                        }
                        {
                            props.showMonth && props.xTickTimestamp &&
                            <VictoryAxis
                                key="victory-independent-axis-month"
                                tickValues={props.monthSeparators && getMonthSeparators().length > 0 ? getMonthSeparators() : props.xTickValues}
                                tickFormat={(tick, index, values): string => {
                                    if (props.monthSeparators) {
                                        return typeof tick === 'number' ? intl.formatMessage({ id: months[DateTime.fromMillis(tick).month - 1] }).slice(0, 3) : intl.formatMessage({ id: months[DateTime.fromMillis(DateTime.fromJSDate(tick).toMillis()).month - 1] }).slice(0, 3)
                                    } else {
                                        const first = values.findIndex((value: string) => value.toString() === '1')

                                        if (props.xTickTimestamp.length) {
                                            if (index > 0 && ((DateTime.fromMillis(props.xTickTimestamp[index - 1]).month < DateTime.fromMillis(props.xTickTimestamp[index]).month) ||
                                                (DateTime.fromMillis(props.xTickTimestamp[index - 1]).month === 12 && DateTime.fromMillis(props.xTickTimestamp[index]).month === 1))) {
                                                return intl.formatMessage({ id: months[DateTime.fromMillis(props.xTickTimestamp[index]).month - 1] })
                                            }

                                            if (first > -1 && index === first) {
                                                return intl.formatMessage({ id: months[DateTime.fromMillis(props.xTickTimestamp[index]).month - 1] })
                                            }

                                            if (first > 0 && index === first - 1) {
                                                return intl.formatMessage({ id: months[DateTime.fromMillis(props.xTickTimestamp[index]).month - 1] })
                                            }

                                            if (first === -1 && index === 0) {
                                                return intl.formatMessage({ id: months[DateTime.fromMillis(props.xTickTimestamp[index]).month - 1] })
                                            }
                                        }

                                        return ''
                                    }
                                }}
                                style={{
                                    axis: { stroke: 'var(--color-gray-100)' },
                                    axisLabel: { fontSize: 20, padding: 30 },
                                    grid: { stroke: 'none' },
                                    tickLabels: { fontSize: 15, padding: props.hideXAxis ? 5 : 25, fill: 'var(--color-black)' }
                                }}
                                standalone={true}
                                offsetY={0}
                                orientation="bottom"
                            />
                        }
                        <VictoryAxis
                            key="victory-dependent-axis"
                            dependentAxis
                            tickFormat={(tick): string | number => {
                                return tick === Infinity || isNaN(tick) ? 0 : tick
                            }}
                            label={props.yLabel}
                            crossAxis={false}
                            {...props.yAxisProps} />
                        {
                            props.separators.map((separator, index) => {
                                return <VictoryLine
                                    key={`victory-separators-${index}`}
                                    data={
                                        separator.axis === "x" ?
                                            props.xTickValues.map(tickValue => { return { x: tickValue, y: separator.position } })
                                            :
                                            props.yTickValues.map(tickValue => { return { x: separator.position, y: tickValue } })
                                    }
                                    interpolation='linear'
                                    style={{ data: { stroke: props.separatorsColor ? props.separatorsColor : defaultColor.line, strokeDasharray: separator.hatchSize } }} />
                            })
                        }
                        {
                            props.lines.filter((_line, index) => displayedLinesIndexes.includes(index)).length > 0 &&
                            props.lines.filter((_line, index) => displayedLinesIndexes.includes(index)).map((line, index) => {
                                const newData: any[] = []
                                line.chart.forEach((point) => {
                                    for (let i = 0; i < (point.y as number); i++) {
                                        newData.push({ x: (parseFloat(point.x as string) - difference).toString() })
                                    }
                                })
                                return (
                                    <VictoryHistogram
                                        key={`victory-histogram-${index}`}
                                        data={newData}
                                        binSpacing={0}
                                        bins={[...line.chart.map((point) => parseFloat(point.x as string) - difference), parseFloat(line.chart[line.chart.length - 1].x as string) + (difference * 2)]}
                                        // bins={line.chart.length}
                                        style={{ data: Object.assign({}, { fill: groupColors[line.colorIndex]?.line ?? defaultColor.line, opacity: 0.5 }, lineStyles[line.stylesIndex])}} />
                                )
                            })
                        }
                        {
                            props.lines.filter((_line, index) => displayedLinesIndexes.includes(index)).length > 0 &&
                            props.lines.filter((_line, index) => displayedLinesIndexes.includes(index)).map((line, index) => {
                                const data = line.chart.map((point) => ({
                                    x: parseFloat((point.x) as string),
                                    y: point.y
                                }))
                                return (
                                    <VictoryLine
                                        key={`victory-line-${index}`}
                                        data={data}
                                        interpolation='linear'
                                        style={{ data: Object.assign({}, { stroke: groupColors[line.colorIndex]?.line ?? defaultColor.line }, lineStyles[line.stylesIndex])}} />
                                )
                            })
                        }
                        {
                            props.lines.filter((_line, index) => displayedLinesIndexes.includes(index)).length > 0 &&
                            props.lines.filter((_line, index) => displayedLinesIndexes.includes(index)).map((line, index, array) => {
                                const data = line.chart.map((point) => ({
                                    x: parseFloat(point.x as string),
                                    y: point.y
                                }))
                                return (
                                    <VictoryScatter
                                        key={`victory-scatter-${index}`}
                                        data={data}
                                        bubbleProperty="size"
                                        size={2.5}
                                        style={{
                                            data: { fill: 'white', stroke: groupColors[line.colorIndex]?.line ?? defaultColor.line, strokeWidth: 2 },
                                            labels: { fill: 'white' }
                                        }}
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                                onMouseOver: (event: any): any => {
                                                    return [{
                                                        target: "data",
                                                        mutation: (eventProps: any): any => {
                                                            event.persist()
                                                            setTooltip({
                                                                offset: { top: event.pageY, left: event.pageX },
                                                                datum: eventProps.datum,
                                                                index: eventProps.index,
                                                                colorIndex: line.colorIndex,
                                                                stylesIndex: line.stylesIndex
                                                            })
                                                            ReactTooltip.show(tooltipRef.current as Element)
                                                            return { style: Object.assign({}, eventProps.style, { fill: groupColors[line.colorIndex]?.line ?? defaultColor.line, cursor: 'pointer' }) }
                                                        }
                                                    }, {
                                                        target: "labels",
                                                        mutation: (): any => ({ active: true })
                                                    }]
                                                },
                                                onMouseOut: (): any => {
                                                    return [{
                                                        target: "data",
                                                        mutation: (): any => {
                                                            setTooltip(undefined)
                                                            ReactTooltip.hide(tooltipRef.current as Element)
                                                            return null
                                                        }
                                                    }, {
                                                        target: "labels",
                                                        mutation: (): any => ({ active: false })
                                                    }]
                                                },
                                                onClick: (): any => {
                                                    return [{
                                                        target: "data",
                                                        mutation: (eventProps: any): any => {
                                                            if (props.handleDotClick) {
                                                                props.handleDotClick({ x: eventProps.datum.x, y: eventProps.datum.y }, eventProps.index)
                                                            } else {
                                                                if (equipment && array[index].chart[eventProps.index].id) {
                                                                    fetchTimelineEventData(array[index].chart[eventProps.index].id ?? "").then((res) => {
                                                                        const paths: BreadcrumbPath[] = [
                                                                            {
                                                                                label: equipment.customerName,
                                                                                id: equipment.customerId,
                                                                                destination: { pathname: '/dashboard' },
                                                                                param: { property: `customerId`, value: equipment.customerId }
                                                                            },
                                                                            {
                                                                                label: equipment.medicalCentreName,
                                                                                id: equipment.medicalCentreId,
                                                                                destination: { pathname: `/dashboard` },
                                                                                param: { property: `medicalCentreId`, value: equipment.medicalCentreId }
                                                                            },
                                                                            {
                                                                                label: equipment.name,
                                                                                id: equipment.id,
                                                                                destination: { pathname: `/dashboard/equipment-detail`, search: `?tab=0&equipmentId=${equipment.id}&page=${timelineFilter.page}` },
                                                                                param: { property: `equipmentId`, value: equipment.id }
                                                                            },
                                                                            {
                                                                                label: res.data.date ? `${DateTime.fromMillis(res.data.date).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale, numberingSystem: undefined  })}` : '',
                                                                                id: "",
                                                                                destination: { pathname: `/dashboard/timeline-detail`, search: `?tab=${res.data.typeId === "ANALYSED_DOCUMENT" ? 0 : 2}&type=${res.data.typeId}&id=${res.data.id}&equipmentId=${equipment.id}&redirect=true&page=${timelineFilter.page}` },
                                                                                param: null
                                                                            }
                                                                        ]
                                                            
                                                                        if (loggedUser?.role !== "ADMINISTRATOR") {
                                                                            paths.splice(0, 1)
                                                                        }
                                                            
                                                                        dispatch(setPaths(paths))
                                                                        dispatch(push(paths[paths.length - 1].destination))
                                                                    })
                                                                } else {
                                                                    toast.info(intl.formatMessage({ id: "chart.toast.information.noEventsAssociated" }))
                                                                }
                                                            }
                                                        }
                                                    }]
                                                }
                                            }
                                        }]}
                                    />
                                )
                            })
                        }
                    </VictoryChart>
                </svg>
                {/* <div className={classNames({
                    'w-full pl-14 mb-5': true,
                    'mt-8': props.xLabel && props.xLabel !== ''
                })}>
                    {
                        props.legend.length > 0 && props.lines.length > 0 &&
                        props.legend.map((legend, index) => {
                            return (
                                <div
                                    key={`legend-group-${legend.name}-${index}`}
                                    className="mb-4"
                                >
                                    <div className="cursor-pointer hover:opacity-50" onClick={(): void => toggleGroup(legend, index)}>{legend.lines.length > 0 ? legend.name : undefined}</div>
                                    <div className="flex flex-wrap">
                                        {
                                            legend.lines.length > 0 &&
                                            legend.lines.map((line, lineIndex) => {
                                                const trueIndex = (index * legend.lines.length) + lineIndex
                                                const styles = ['solid', 'dashed', 'dotted', 'solid']
                                                const style = styles[props.lines[trueIndex].stylesIndex]
                                                
                                                return (
                                                    <div
                                                        key={`legend-line-${line}-${trueIndex}`}
                                                        css={[classes.legend, !displayedLinesIndexes.includes(trueIndex) ? classes["hidden-line"] : null]}
                                                        className="relative ml-16 mr-4 py-2 cursor-pointer hover:opacity-50"
                                                        onClick={(): void => hideLine(trueIndex)}
                                                    >
                                                        <span style={{ borderColor: groupColors[props.lines[trueIndex].colorIndex]?.line ?? defaultColor.line, borderStyle: style, borderWidth: style === 'dotted' ? '4px' : '1px', borderTopWidth: style === 'dotted' ? 0 : 2, borderBottomWidth: 0 }}></span>
                                                        <div className="mb-1">{legend.lines.length > 0 ? line : (line && line !== '' ? line : legend.name)}</div>
                                                        <span style={{ borderColor: groupColors[props.lines[trueIndex].colorIndex]?.line ?? defaultColor.line }}></span>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            legend.areas && legend.areas.length > 0 &&
                                            legend.areas.map((area, lineIndex) => {
                                                const trueIndex = (index * (legend.areas ? legend.areas.length : 0)) + lineIndex

                                                return (
                                                    <div
                                                        key={`legend-area-${area}-${trueIndex}`}
                                                        css={[classes.legend, !displayedAreasIndexes.includes(trueIndex) ? classes["hidden-area"] : null]}
                                                        className="flex items-center relative py-2 cursor-pointer hover:opacity-50"
                                                        onClick={(): void => hideArea(trueIndex)}
                                                    >
                                                        <div className="legend-icon w-4 h-4 mr-2" style={{ backgroundColor: groupColors[props.areas[trueIndex].colorIndex]?.area ?? defaultColor.area }}></div>
                                                        <div className="mb-1">{area}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}
                <div
                    ref={tooltipRef}
                    className="fixed w-0 h-0"
                    data-tip=""
                    data-for="chart-tooltip"
                    style={{
                        top: `${tooltip?.offset.top ?? '0'}px`,
                        left: `${tooltip?.offset.left ?? '0'}px`
                    }}
                ></div>
            </div>
            <ReactTooltip id="chart-tooltip" className="pl-0 pr-4 min-w-30" type="dark" effect="solid" data-offset={tooltip?.offset ?? { top: 0, left: 0 }}>
                {
                    tooltip &&
                    <div className="flex">
                        <div className='w-2 h-2 ml-2 mr-1 mt-1 rounded' style={{ background: groupColors[tooltip.colorIndex]?.line }}></div>
                        {
                            props.axisType !== 'TIME' ?
                                <div className="min-h-6">
                                    {/* <div className='opacity-75'>{props.legend[tooltip.colorIndex].lines[0]}: {(isNaN(tooltip.datum.y as number) ? tooltip.datum.y : DateTime.fromMillis(tooltip.datum.y as number).day + ' ' + intl.formatMessage({ id: months[DateTime.fromMillis(tooltip.datum.y as number).month - 1] }))}</div> */}
                                    <div className='opacity-75'>{props.legend[tooltip.colorIndex].lines[tooltip.stylesIndex]}: {tooltip.datum.y}</div>
                                    <div>{tooltip.datum.x}</div>
                                </div>
                                :
                                <div className="min-h-6">
                                    <div className='opacity-75'>{props.xTickTimestamp && /*month*/ true} {(isNaN(tooltip.datum.x as number) ? tooltip.datum.x : DateTime.fromMillis(tooltip.datum.x as number).day + ' ' + intl.formatMessage({ id: months[DateTime.fromMillis(tooltip.datum.x as number).month - 1] }))}</div>
                                    <div>{tooltip.datum.y}</div>
                                </div>
                        }
                    </div>
                }
            </ReactTooltip>
        </React.Fragment>
    )
}

CustomHistogramChart.defaultProps = {
    className: '',
    cssStyles: [css``],
    width: 540,
    height: 320,
    xTickValues: undefined,
    xTickTimestamp: undefined,
    yTickValues: undefined,
    biggerXTicks: [],
    xAxisProps: undefined,
    yAxisProps: undefined,
    lines: [],
    areas: [],
    separators: [],
    domain: {
        x: undefined,
        y: undefined
    },
    showMonth: false,
    separatorsColor: 'var(--color-dark)',
    tooltipLabels: '',
    axisType: 'LINEAR',
    legend: [],
    title: '',
    xLabel: '',
    yLabel: '',
    hideXAxis: false,
    monthSeparators: false,
    scale: {
        x: 'time',
        y: 'linear'
    },
    handleDotClick: (): void => undefined
}

export default CustomHistogramChart