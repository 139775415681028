import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../types/app-thunk'
import { TimelineEvent } from '../types/entity/timeline-event'
import { TimelineFilter } from '../types/timeline-filter'
import { EquipmentDetailState } from '../types/states/equipment-detail-state'
import { Baseline } from '../types/entity/baseline'
import { call } from '../common/api'
import { RequestQueryParams } from '../types/request-query-params'
import { AnalysisIndicator } from '../types/entity/analysis-indicator'
import { BaselineDetail } from '../types/entity/baseline-detail'
import { toast } from 'react-toastify'
import { NewBaseline } from '../types/new-baseline'
import { BaselineDefinition } from '../types/entity/baseline-definition'
import { EditBaseline } from '../types/edit-baseline'
import { deepCopy } from '../common/utils'
import { AnalysisChart } from '../types/entity/analysis-chart'
import { EditAnalysis } from '../types/edit-analysis'
import { NewAnalysisChart } from '../types/new-analysis-chart'
import { AxiosResponse } from 'axios'
import { DefinitionData } from '../types/definition-data'
import { TableBaseline } from '../types/entity/table-baseline'
import { OptionsType, OptionTypeBase } from 'react-select'
import { SimpleItem } from '../types/simple-item'
import { CustomerEquipmentProperty } from '../types/entity/customer-equipment-property'
import { EquipmentProtocol } from '../types/entity/equipment-protocols'
import { TableProtocol } from '../types/entity/table-protocol'
import { ProtocolDetail } from '../types/entity/protocol-detail'
import { NewProtocolRequirement } from '../types/new-protocol-requirement'
import { Constraints } from '../types/constraints'

const equipmentDetailInitialState: EquipmentDetailState = {
    timelineEvents: [],
    timelineEventsSize: 0,
    currentTimelineEvent: null,
    timelineFilter: {
        limit: 10,
        page: 0,
        showHidden: false,
        type: null,
        name: null,
        from: null,
        to: null,
        orderBy: undefined,
        orderType: undefined
    },
    timelineCharts: [],
    timelineReadOnly: false,

    analysisIndicators: [],
    analysisCharts: [],
    chartTypes: [],

    baselines: [],
    baselinesSize: 0,
    currentBaseline: null,
    currentBaselineDefinition: null,
    currentBaselineCharts: null,

    protocols: [],
    copyOptions: [],
    currentProtocol: null,
    constraints: null,
    
    equipmentProperties: []
}

const equipmentDetailSlice = createSlice({
    name: 'equipmentDetail',
    initialState: equipmentDetailInitialState,
    reducers: {
        resetEquipmentDetail: (state: EquipmentDetailState): void => {
            state.timelineEvents = []
            state.timelineEventsSize = 0
            state.timelineFilter = {
                limit: 10,
                page: 0,
                showHidden: false,
                type: null,
                name: null,
                from: null,
                to: null,
                orderBy: undefined,
                orderType: undefined
            }
            state.timelineCharts = []
            state.timelineReadOnly = false

            state.analysisIndicators = []
            state.analysisCharts = []
            state.chartTypes = []

            state.baselines = []
            state.baselinesSize = 0
            state.currentBaseline = null
            state.currentBaselineDefinition = null
            state.currentBaselineCharts = null

            state.protocols = []
            state.copyOptions = []
            state.currentProtocol = null
            state.constraints = null

            state.equipmentProperties = []
        },

        // TIMELINE
        setTimeline: (state: EquipmentDetailState, { payload }: PayloadAction<{ data: TimelineEvent[]; size: number }>): void => {
            state.timelineEvents = payload.data
            state.timelineEventsSize = payload.size
        },
        setTimelineCharts: (state: EquipmentDetailState, { payload }: PayloadAction<AnalysisChart[]>): void => {
            state.timelineCharts = payload
        },
        setCurrentTimelineEvent: (state: EquipmentDetailState, { payload }: PayloadAction<TimelineEvent | null>): void => {
            state.currentTimelineEvent = payload
        },
        setTimelineEventVisibility: (state: EquipmentDetailState, { payload }: PayloadAction<number>): void => {
            state.timelineEvents[payload].hidden = !state.timelineEvents[payload].hidden
        },
        setTimelineFilter: (state: EquipmentDetailState, { payload }: PayloadAction<TimelineFilter>): void => {
            state.timelineFilter = payload
        },
        setTimelineFilterLimit: (state: EquipmentDetailState, { payload }: PayloadAction<number>): void => {
            state.timelineFilter.limit = payload
        },
        setTimelineFilterPage: (state: EquipmentDetailState, { payload }: PayloadAction<number>): void => {
            state.timelineFilter.page = payload
        },
        setTimelineReadOnly: (state: EquipmentDetailState, { payload }: PayloadAction<boolean>): void => {
            state.timelineReadOnly = payload
        },

        // ANALYSIS
        setAnalysisIndicators: (state: EquipmentDetailState, { payload }: PayloadAction<AnalysisIndicator[]>): void => {
            state.analysisIndicators = payload
        },
        setAnalysisCharts: (state: EquipmentDetailState, { payload }: PayloadAction<AnalysisChart[]>): void => {
            state.analysisCharts = payload
        },
        setChartTypes: (state: EquipmentDetailState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.chartTypes = payload
        },

        // BASELINE
        setBaselines: (state: EquipmentDetailState, { payload }: PayloadAction<TableBaseline[]>): void => {
            state.baselines = payload
        },
        setCurrentBaseline: (state: EquipmentDetailState, { payload }: PayloadAction<BaselineDetail | null>): void => {
            state.currentBaseline = payload
        },
        setCurrentBaselineDefinition: (state: EquipmentDetailState, { payload }: PayloadAction<BaselineDefinition | null>): void => {
            state.currentBaselineDefinition = payload
        },

        // PROTOCOL
        setProtocols: (state: EquipmentDetailState, { payload }: PayloadAction<TableProtocol[]>): void => {
            state.protocols = payload
        },
        setCopyOptions: (state: EquipmentDetailState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.copyOptions = payload
        },
        setCurrentProtocol: (state: EquipmentDetailState, { payload }: PayloadAction<ProtocolDetail | null>): void => {
            state.currentProtocol = payload
        },
        setContraints: (state: EquipmentDetailState, { payload }: PayloadAction<Constraints | null>): void => {
            state.constraints = payload
        },

        // INFORMATION
        setEquipmentProperties: (state: EquipmentDetailState, { payload }: PayloadAction<CustomerEquipmentProperty[]>): void => {
            state.equipmentProperties = payload
        }
    }
})

export const { resetEquipmentDetail, setTimeline, setTimelineCharts, setCurrentTimelineEvent, setTimelineEventVisibility, setTimelineFilter, setTimelineFilterLimit, setTimelineFilterPage, setAnalysisIndicators, setAnalysisCharts, setChartTypes, setBaselines, setCurrentBaseline, setCurrentBaselineDefinition, setProtocols, setCopyOptions, setCurrentProtocol, setContraints, setTimelineReadOnly } = equipmentDetailSlice.actions

export default equipmentDetailSlice.reducer

export const fetchTimelineEvents = (equipmentId: string, timelineFilter: TimelineFilter): AppThunk => async (dispatch): Promise<void> => {
    try {
        const params = new URLSearchParams()
        params.append('limit', timelineFilter.limit.toString())
        params.append('page', timelineFilter.page.toString())
        params.append('showHidden', timelineFilter.showHidden.toString())

        if (timelineFilter.type) {
            timelineFilter.type.forEach((type) => {
                params.append('typeId', type)
            })
        }

        if (timelineFilter.name) {
            params.append('name', timelineFilter.name)
        }

        if (timelineFilter.from) {
            params.append('from', timelineFilter.from.toString())
        }

        if (timelineFilter.to) {
            params.append('to', timelineFilter.to.toString())
        }

        if (timelineFilter.orderBy) {
            params.append('orderBy', timelineFilter.orderBy.toString())
        }

        if (timelineFilter.orderType) {
            params.append('orderType', timelineFilter.orderType.toString())
        }

        const getTimelineEventsRequest = call({
            method: 'GET',
            url: `/timelineevents/${equipmentId}`,
            params
        }, dispatch, true)
        const res = await getTimelineEventsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setTimelineReadOnly(res.data.readOnly))
            dispatch(equipmentDetailSlice.actions.setTimeline({
                data: res.data.data,
                size: res.data.size
            }))
            if (res.data.data.length === 0 && res.data.size > 0) {
                dispatch(setTimelineFilterPage(0))
            }
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchTimelineCharts = (equipmentId: string, params: RequestQueryParams): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const getTimelineChartsRequest = call({
            method: 'GET',
            url: `/inspection-data-sets`,
            params: {
                equipmentId,
                representative: true,
                ...params
            }
        }, dispatch, true)
        const res = await getTimelineChartsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setTimelineCharts(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchTimelineEventsData = async (equipmentId: string, timelineFilter: TimelineFilter): Promise<AxiosResponse<any>> => {
    const params = new URLSearchParams()
    params.append('limit', timelineFilter.limit.toString())
    params.append('page', timelineFilter.page.toString())
    params.append('showHidden', timelineFilter.showHidden.toString())

    if (timelineFilter.type) {
        timelineFilter.type.forEach((type) => {
            params.append('typeId', type)
        })
    }

    if (timelineFilter.name) {
        params.append('name', timelineFilter.name)
    }

    if (timelineFilter.from) {
        params.append('from', timelineFilter.from.toString())
    }

    if (timelineFilter.to) {
        params.append('to', timelineFilter.to.toString())
    }

    if (timelineFilter.orderBy) {
        params.append('orderBy', timelineFilter.orderBy.toString())
    }

    if (timelineFilter.orderType) {
        params.append('orderType', timelineFilter.orderType.toString())
    }

    const getTimelineEventsRequest = call({
        method: 'GET',
        url: `/timelineevents/${equipmentId}`,
        params
    })

    return await getTimelineEventsRequest()
}

export const fetchTimelineEvent = (timelineEventId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getTimelineEventRequest = call({
            method: 'GET',
            url: `/timeline-events/${timelineEventId}/summary`
        }, dispatch, true)
        const res = await getTimelineEventRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setCurrentTimelineEvent(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateTimelineEvent = (url: string, data: FormData, message: string, equipmentId: string, timelineFilter: TimelineFilter): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createTimelineEventRequest = call({
            method: 'POST',
            url,
            data
        }, dispatch, true)
        const res = await createTimelineEventRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchTimelineEvents(equipmentId, timelineFilter))
            dispatch(equipmentDetailSlice.actions.setCurrentTimelineEvent(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteTimelineEvent = (timelineEventId: string, equipmentId: string, timelineFilter: TimelineFilter, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteTimelineEventRequest = call({
            method: 'DELETE',
            url: `/timelineevents/${timelineEventId}`
        }, dispatch, true)
        const res = await deleteTimelineEventRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchTimelineEvents(equipmentId, timelineFilter))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchToggleTimelineEventVisibility = (timelineEventId: string, hidden: boolean, equipmentId: string, timelineFilter: TimelineFilter): AppThunk => async (dispatch): Promise<void> => {
    try {
        const toggleTimelineEventVisibilityRequest = call({
            method: 'PUT',
            url: `/timelineevents/${timelineEventId}`,
            data: {
                hidden
            }
        }, dispatch, false)
        const res = await toggleTimelineEventVisibilityRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchTimelineEvents(equipmentId, timelineFilter))
            // dispatch(equipmentDetailSlice.actions.setCurrentTimelineEvent(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchAnalysisCharts = (equipmentId: string, params: RequestQueryParams): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const getAnalysisChartsRequest = call({
            method: 'GET',
            url: `/inspection-data-sets`,
            params: {
                equipmentId,
                ...params
            }
        }, dispatch, true)
        const res = await getAnalysisChartsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setAnalysisCharts(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateAnalysisChart = (data: NewAnalysisChart, params: RequestQueryParams, message: string): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const createAnalysisChartRequest = call({
            method: 'POST',
            url: `/inspection-data-sets`,
            data
        }, dispatch, true)

        const res = await createAnalysisChartRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchAnalysisCharts(data.equipmentId, params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteAnalysisChart = (chartId: string, equipmentId: string, params: RequestQueryParams, message: string): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const getDeleteAnalysisChartRequest = call({
            method: 'DELETE',
            url: `/inspection-data-sets/${chartId}`
        }, dispatch, true)

        const res = await getDeleteAnalysisChartRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchAnalysisCharts(equipmentId, params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchToggleAnalysisChartRepresentative = (chartId: string, representative: boolean, equipmentId: string, params: RequestQueryParams, message: string): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const getDeleteAnalysisChartRequest = call({
            method: 'PUT',
            url: `/inspection-data-sets/${chartId}`,
            data: {
                representative
            }
        }, dispatch, true)

        const res = await getDeleteAnalysisChartRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchAnalysisCharts(equipmentId, params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchOrderAnalysisCharts = (inspectionDataSetsIds: string[], equipmentId: string, params: RequestQueryParams): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const orderAnalysisChartsRequest = call({
            method: 'PUT',
            url: `/inspection-data-sets/order`,
            data: {
                equipmentId,
                inspectionDataSetsIds
            }
        }, dispatch, true)
        const res = await orderAnalysisChartsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchAnalysisCharts(equipmentId, params))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchAnalysisIndicators = (equipmentId: string): AppThunk => async  (dispatch): Promise<void> => {
    try {
        const getAnalysisIndicatorsRequest = call({
            method: 'GET',
            url: `/inspection-data-sets/indicators`,
            params: {
                equipmentId
            }
        }, dispatch, true)
        const res = await getAnalysisIndicatorsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setAnalysisIndicators(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditAnalysisIndicator = (timeSeriesGroupId: string, data: EditAnalysis, equipmentId: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<AxiosResponse<any> | undefined> => {
    try {
        const editAnalysisIndicatorRequest = call({
            method: 'PUT',
            url: `/inspection-data-sets/${timeSeriesGroupId}`,
            data
        }, dispatch, true)
        const res = await editAnalysisIndicatorRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchAnalysisCharts(equipmentId, params))
            // dispatch(equipmentDetailSlice.actions.setAnalysisChart({ chart: res.data, index }))
        }

        return res
    } catch (err) {
        console.log(err)
        return undefined
    }
}

export const fetchAnalysisChartTypes = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getAnalysisChartTypes = call({
            method: 'GET',
            url: `/inspection-data-sets/types `
        }, dispatch, true)
        const res = await getAnalysisChartTypes()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setChartTypes(res.data.map((type: SimpleItem) => ({
                label: type.name,
                value: type.id
            }))))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchBaselines = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getBaselinesRequest = call({
            method: 'GET',
            url: `/baselines`,
            params
        }, dispatch, true)
        const res = await getBaselinesRequest()

        if (res.status >= 200 && res.status < 300) {
            const baselines: any[] = []
            res.data.forEach((baseline: { analyserId: string; analyserName: string; baselines: Baseline[] }) => {
                baselines.push({
                    analyserId: baseline.analyserId,
                    analyserName: baseline.analyserName,
                    id: baseline.baselines.map((baseline) => baseline.id),
                    name: baseline.baselines.map((baseline) => baseline.name),
                    firstAnalysisDate: baseline.baselines.map((baseline) => baseline.firstAnalysisDate),
                    lastAnalysisDate: baseline.baselines.map((baseline) => baseline.lastAnalysisDate),
                    firstUsageDate: baseline.baselines.map((baseline) => baseline.firstUsageDate),
                    usageCount: baseline.baselines.map((baseline) => baseline.usageCount),
                    lastUsageDate: baseline.baselines.map((baseline) => baseline.lastUsageDate),
                    lastModified: baseline.baselines.map((baseline) => baseline.lastModified),
                    active: baseline.baselines.map((baseline) => baseline.active),
                    testObjectId: baseline.baselines.map((baseline) => baseline.testObjectId),
                    testObjectName: baseline.baselines.map((baseline) => baseline.testObjectName),
                    equipmentId: baseline.baselines.map((baseline) => baseline.equipmentId),
                    equipmentName: baseline.baselines.map((baseline) => baseline.equipmentName),
                })
            })
            dispatch(equipmentDetailSlice.actions.setBaselines(baselines))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateBaseline = (data: NewBaseline, message: string, administrator: boolean): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createBaselineRequest = call({
            method: 'POST',
            url: '/baselines',
            data
        }, dispatch, true)
        const res = await createBaselineRequest()

        if (res.status >= 200 && res.status < 300) {
            console.log(administrator)
            // const paths = [
            //     {
            //         label: res.data.customerName,
            //         id: res.data.customerId,
            //         destination: {
            //             pathname: `/equipments`,
            //             search: `?id=${res.data.id}`
            //         },
            //         param: {
            //             property: `customerId` as keyof RequestQueryParams,
            //             value: res.data.customerId
            //         }
            //     },
            //     {
            //         label: res.data.medicalCentreName,
            //         id: res.data.medicalCentreId,
            //         destination: {
            //             pathname: `/equipments`,
            //             search: `?id=${res.data.id}`
            //         },
            //         param: {
            //             property: `medicalCentreId` as keyof RequestQueryParams,
            //             value: res.data.medicalCentreId
            //         }
            //     },
            //     {
            //         label: res.data.name,
            //         id: res.data.id,
            //         destination: {
            //             pathname: `/equipments/equipment`,
            //             search: `?id=${res.data.id}`
            //         },
            //         param: null
            //     }
            // ]
            // dispatch(equipmentsSlice.actions.setCurrentEquipment(res.data))
            // dispatch(setPaths(administrator ? paths : [paths[1], paths[2]]))
            // dispatch(push({
            //     pathname: `/equipments/equipment`,
            //     search: `?id=${res.data.id}`
            // }))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditBaseline = (data: EditBaseline, baselineId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEditBaselineRequest = call({
            method: 'PUT',
            url: `/baselines/${baselineId}`,
            data
        }, dispatch, true)
        const res = await getEditBaselineRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchBaselines(params))
            dispatch(equipmentDetailSlice.actions.setCurrentBaseline(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchBaseline = (baselineId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getBaselineRequest = call({
            method: 'GET',
            url: `/baselines/${baselineId}`,
        }, dispatch, true)
        const res = await getBaselineRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setCurrentBaseline(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchBaselineDefinition = (baselineId: string, data: DefinitionData): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getBaselineDefinitionRequest = call({
            method: 'POST',
            url: `/baselines/${baselineId}/indicator-info`,
            data
        }, dispatch, true)
        const res = await getBaselineDefinitionRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setCurrentBaselineDefinition(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditBaselineTags = (imageId: string, data: { baselineTagsIds?: string[]; imageTagsIds?: string[] }, baselineId: string, definitionData: DefinitionData, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEditBaselineTagsRequest = call({
            method: 'PUT',
            url: `/analysed-images/${imageId}`,
            data
        }, dispatch, true)
        const res = await getEditBaselineTagsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchBaselineDefinition(baselineId, definitionData))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchUpdateBaselineDefinition = (baselineId: string, data: { indicatorIds: string[]; analysedImagesIds: string[] }, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const updateBaselineDefinitionRequest = call({
            method: 'PUT',
            url: `/baselines/${baselineId}/definition`,
            data
        }, dispatch, true)
        const res = await updateBaselineDefinitionRequest()

        if (res.status >= 200 && res.status < 300) {
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditBaselineDefinition = (baselineId: string, data: { analysis: string[]; identifiers: string[] }, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editBaselineDefinitionRequest = call({
            method: 'PUT',
            url: `/baselines/${baselineId}/definition`,
            data,
            params
        }, dispatch, true)
        const res = await editBaselineDefinitionRequest()

        if (res.status >= 200 && res.status < 300) {
            const baselineDefinition: BaselineDefinition = deepCopy(res.data)
            // baselineDefinition.indicators.forEach(indicator => {
            //     indicator.selected = true
            // })
            dispatch(equipmentDetailSlice.actions.setCurrentBaselineDefinition(baselineDefinition))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDuplicateBaseline = (protocolId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const duplicateBaselineRequest = call({
            method: 'POST',
            url: `/baselines/${protocolId}`
        }, dispatch, true)
        const res = await duplicateBaselineRequest()
        
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchBaselines(params))
            // dispatch(equipmentDetailSlice.actions.setCurrentBaseline(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteBaseline = (baselineId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteBaselineRequest = call({
            method: 'DELETE',
            url: `/baselines/${baselineId}`
        }, dispatch, true)
        const res = await deleteBaselineRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchBaselines(params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

// export const fetchEditBaselineDetailInterval = (baselineId: string, data: { startDate: number; endDate: number }): AppThunk => async (dispatch): Promise<void> => {
//     try {
//         // const editBaselineDetailIntervalRequest = call({
//         //     method: 'POST',
//         //     url: `/baseline/${baselineId}/editInterval`,
//         //     data
//         // }, dispatch, true)
//         // const res = await editBaselineDetailIntervalRequest()

//         // dispatch(equipmentDetailSlice.actions.setCurrentBaseline(res.data))
//     } catch (err) {
//         console.log(err)
//     }
// }

export const fetchEquipmentProtocols = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEquipmentProtocolsRequest = call({
            method: 'GET',
            url: `/protocol-instances`,
            params
        }, dispatch, true)
        const res = await getEquipmentProtocolsRequest()

        if (res.status >= 200 && res.status < 300) {
            const equipmentProtocols: any[] = []
            res.data.forEach((protocol: { testObjectId: string; testObjectName: string; protocols: EquipmentProtocol[] }) => {
                equipmentProtocols.push({
                    testObjectId: protocol.testObjectId,
                    testObjectName: protocol.testObjectName,
                    id: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.id),
                    name: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.name),
                    firstUsageDate: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.firstUsageDate),
                    usageCount: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.usageCount),
                    lastUsageDate: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.lastUsageDate),
                    lastModified: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.lastModified),
                    active: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.active),
                    equipmentId: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.equipmentId),
                    equipmentName: protocol.protocols.map((equipmentProtocol) => equipmentProtocol.equipmentName),
                })
            })
            dispatch(equipmentDetailSlice.actions.setProtocols(equipmentProtocols))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCopyOptions = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCopyOptionsRequest = call({
            method: 'GET',
            url: `/protocol-instances/models`,
            params
        }, dispatch, true)
        const res = await getCopyOptionsRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setCopyOptions(res.data.map((copyOption: any) => ({
                label: copyOption.name,
                value: copyOption.id
            }))))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateEquipmentProtocol = (params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createEquipmentProtocolRequest = call({
            method: 'POST',
            url: '/protocol-instances',
            params
        }, dispatch, true)
        const res = await createEquipmentProtocolRequest()

        if (res.status >= 200 && res.status < 300) {
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEquipmentProtocol = (customerId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEquipmentProtocolRequest = call({
            method: 'GET',
            url: `/protocol-instances/${customerId}`
        }, dispatch, true)
        const res = await getEquipmentProtocolRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setCurrentProtocol(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditEquipmentProtocol = (data: EditBaseline, protocolId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editEquipmentProtocolRequest = call({
            method: 'PUT',
            url: `/protocol-instances/${protocolId}`,
            data
        }, dispatch, true)
        const res = await editEquipmentProtocolRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProtocols(params))
            dispatch(equipmentDetailSlice.actions.setCurrentProtocol(res.data))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteEquipmentProtocol = (protocolId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteEquipmentProtocolRequest = call({
            method: 'DELETE',
            url: `/protocol-instances/${protocolId}`
        }, dispatch, true)
        const res = await deleteEquipmentProtocolRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProtocols(params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDuplicateEquipmentProtocol = (protocolId: string, params: RequestQueryParams, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const duplicateEquipmentProtocolRequest = call({
            method: 'POST',
            url: `/protocol-instances/${protocolId}`
        }, dispatch, true)
        const res = await duplicateEquipmentProtocolRequest()
        
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProtocols(params))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateEquipmentProtocolRequirement = (protocolId: string, data: NewProtocolRequirement, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createEquipmentProtocolRequirementRequest = call({
            method: 'POST',
            url: `/protocol-instances/requirements`,
            data: {
                protocolId,
                ...data
            }
        }, dispatch, true)
        const res = await createEquipmentProtocolRequirementRequest()

        // dispatch(setPaths([{ label: res.data.name, id: res.data.id, destination: { pathname: `/medical-centres/medical-centre`, search: `?id=${res.data.id}` }, param: null }]))
        // dispatch(push({
        //     pathname: `/medical-centres/medical-centre`,
        //     search: `?id=${res.data.id}`
        // }))
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProtocol(protocolId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditEquipmentProtocolRequirement = (protocolId: string, requirementId: string, data: NewProtocolRequirement, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editEquipmentProtocolRequirementRequest = call({
            method: 'PUT',
            url: `/protocol-instances/requirements/${requirementId}`,
            data
        }, dispatch, true)
       const res = await editEquipmentProtocolRequirementRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProtocol(protocolId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteEquipmentProtocolRequirement = (protocolId: string, requirementId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteEquipmentProtocolRequirementRequest = call({
            method: 'DELETE',
            url: `/protocol-models/requirements/${requirementId}`
        }, dispatch, true)
        const res = await deleteEquipmentProtocolRequirementRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProtocol(protocolId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchConstraints = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCostraintsRequest = call({
            method: 'GET',
            url: `/protocol-instances/constraints`,
            params
        }, dispatch, false)
        const res = await getCostraintsRequest()

        dispatch(equipmentDetailSlice.actions.setContraints(res.data))
    } catch (err) {
        console.log(err)
    }
}

export const fetchEquipmentProperties = (equipmentId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getEquipmentPropertiesRequest = call({
            method: 'GET',
            url: `/equipments/${equipmentId}/properties`
        }, dispatch, true)
        const res = await getEquipmentPropertiesRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(equipmentDetailSlice.actions.setEquipmentProperties(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditEquipmentProperty = (equipmentId: string, propertyId: string, message: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editEquipmentPropertyRequest = call({
            method: 'PUT',
            url: `/equipments/${equipmentId}/properties/${propertyId}`,
            params
        }, dispatch, true)
        const res = await editEquipmentPropertyRequest()
        
        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchEquipmentProperties(equipmentId))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchTimelineEventData = async (id: string): Promise<AxiosResponse<any>> => {
    const getTimelineEventDataRequest = call({
        method: 'GET',
        url: `/timeline-events/${id}/summary`
    })
    return await getTimelineEventDataRequest()
}

export const fetchEquipment = async (id: string): Promise<AxiosResponse<any>> => {
    const getEquipmentRequest = call({
        method: 'GET',
        url: `/equipments/${id}`
    })
    return await getEquipmentRequest()
}

