import React, { useEffect, useState, ReactElement, OptionHTMLAttributes } from 'react'
import { OptionsType, OptionTypeBase, ValueType } from 'react-select'
import useForm from 'react-hook-form'
import { intl } from '../../../intl'
import { connect, useDispatch } from 'react-redux'
import { State } from '../../../types/states/state'
import CustomSelect from '../../custom/custom-select/custom-select'
import CustomInput from '../../custom/custom-input'
import CustomFile from '../../custom/custom-file'
import CustomTextarea from '../../custom/custom-textarea'
import { DashboardTimelineEventFormProperties } from '../../../types/component-properties/dashboard-timeline-event-form-properties'
import * as yup from 'yup'
import { TimelineEventType } from '../../../types/timeline-event-type'
import Footer from '../../footer'
import { LoggedUser } from '../../../types/entity/logged-user'
import { Equipment } from '../../../types/entity/equipment'
import { fetchAnalysersOptions, setAnalysersOptions } from '../../../reducers/equipments'
import { deepCopy } from '../../../common/utils'

const eventSchema = yup.object().shape({
    type: yup.string().required('dashboard.timeline.newEvent.form.select.errorMessage.required.type'),
    testObject: yup.string()
        .when('type', {
            is: (val) => val === 'ANALYSED_IMAGE',
            then: yup.string().required('dashboard.timeline.newEvent.form.select.errorMessage.required.phantom'),
            otherwise: yup.string()
        }),
    template: yup.string()
        .when('type', {
            is: (val) => val === 'ANALYSED_DOCUMENT',
            then: yup.string().required('dashboard.timeline.newEvent.form.select.errorMessage.required.template'),
            otherwise: yup.string()
        }),
    file: yup.array()
        .when('type', {
            is: (val) => val !== 'MESSAGE',
            then: yup.array().required('dashboard.timeline.newEvent.form.inputFile.errorMessage.required.file'),
            otherwise: yup.array()
        }),
    comment: yup.string(),
    message: yup.string()
            .when('type', {
                is: (val) => val !== 'MESSAGE',
                then: yup.string(),
                otherwise: yup.string().required('dashboard.timeline.newEvent.form.textarea.errorMessage.required.message')
            }),
    title: yup.string()
        .when('type', {
            is: (val) => val === 'MESSAGE',
            then: yup.string().required('dashboard.timeline.newEvent.form.input.errorMessage.required.title'),
            otherwise: yup.string()
        })
})

const DashboardTimelineEventForm = (props: DashboardTimelineEventFormProperties): ReactElement => {
    
    const dispatch = useDispatch()

    const [type, setType] = useState<TimelineEventType>(props.type)
    const [files, setFiles] = useState<File[]>()
    const [events] = useState<OptionsType<OptionTypeBase>>([
        { label: 'select.options.analysedImage', value: 'ANALYSED_IMAGE' },
        // { label: 'Analysed Document', value: 'ANALYSED_DOCUMENT' },
        { label: 'select.options.image', value: 'STORED_IMAGE' },
        { label: 'select.options.files', value: 'STORED_FILES' },
        { label: 'select.options.message', value: 'MESSAGE' }
    ])
    const { register, unregister, handleSubmit, setValue, triggerValidation, reset, errors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            type: type,
            file: [] as File[],
            testObject: '',
            title: '',
            template: '',
            message: '',
            comment: ''
        },
        validationSchema: eventSchema
    })

    function submit(values: Record<string, any>): void {
        if (props.equipment) {
            const newValues = deepCopy(values)
            const formData = new FormData()
            let url = ''

            formData.append('equipmentId', props.equipment.id)

            switch(type) {
                case 'ANALYSED_IMAGE':
                    url = '/analysed-images'

                    formData.append('testObjectId', newValues.testObject)
                    files?.forEach(file => {
                        formData.append('imageFile', file)
                    })

                    props.handleCreateTimelineEvent(url, formData, intl.formatMessage({ id: 'ashboard.toast.success.analysedImageCreated' }))

                    break
                case 'ANALYSED_DOCUMENT':
                    url = '/analysed-documents'

                    formData.append('templateId', newValues.template)
                    files?.forEach(file => {
                        formData.append('documentFiles', file)
                    })

                    props.handleCreateTimelineEvent(url, formData, intl.formatMessage({ id: 'dashboard.toast.success.analysedDocumentCreated' }))

                    break
                case 'STORED_IMAGE':
                    url = '/stored-images'

                    files?.forEach(file => {
                        formData.append('imageFile', file)
                    })

                    props.handleCreateTimelineEvent(url, formData, intl.formatMessage({ id: 'dashboard.toast.success.storedImageCreated' }))

                    break
                case 'STORED_FILES':
                    url = '/stored-files'

                    files?.forEach(file => {
                        formData.append('files', file)
                    })

                    props.handleCreateTimelineEvent(url, formData, intl.formatMessage({ id: 'dashboard.toast.success.storedFileCreated' }))

                    break
                case 'MESSAGE':
                    url = '/messages'

                    newValues.equipmentId = props.equipment.id
                    newValues.content = newValues.message

                    delete newValues.type
                    delete newValues.message
                    delete newValues.file

                    props.handleCreateTimelineEvent(url, newValues, intl.formatMessage({ id: 'dashboard.toast.success.messageCreated' }))

                    break
            }

            // props.handleCreateTimelineEvent(url, formData)
            // const config = {
            //     headers: { 'content-type': 'multipart/form-data' }
            // }
        }
    }

    function handleCancel(): void {
        reset()
        props.handleCancel()
    }

    function handleUpdateType(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('type', (value as OptionHTMLAttributes<HTMLOptionElement>).value as TimelineEventType)
        triggerValidation({ name: 'type' })
        setType((value as OptionHTMLAttributes<HTMLOptionElement>).value as TimelineEventType)
        setFiles([])
        setValue('file', [])
        setValue('message', '')
        setValue('comment', '')
        setValue('testObject', '')
    }
    
    function handleUpdateTestObject(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('testObject', (value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'testObject' })
    }

    function handleUpdateTemplate(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('template', (value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
        triggerValidation({ name: 'template' })
    }

    function handleUpdateFile(files: File[]): void {
        if (files) {
            setFiles(files)
            setValue('file', files)
            triggerValidation({ name: 'file' })
        }
    }

    useEffect(() => {
        register({ name: 'file' })
        register({ name: 'type' })

        return (): void => {
            unregister('file')
            unregister('type')
        }
    }, [register, unregister, setValue, props.user])

    useEffect(() => {
        if (props.equipment) {
            dispatch(fetchAnalysersOptions(props.equipment?.id, type === 'ANALYSED_IMAGE' ? 'IMAGE' : 'DOCUMENT'))
        }
    }, [dispatch, props.equipment, type])

    useEffect(() => {
        return (): void => {
            dispatch(setAnalysersOptions([]))
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <div className="p-8 pr-2">
                <h4 className="mt-0">{intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.title' })}</h4>
                <form onSubmit={handleSubmit(submit)} autoComplete="off" key={`event-editor-${props.visible}`} className="py-6">
                    <div className="flex flex-wrap items-center">
                        <CustomSelect
                            className="w-1/4 pr-6"
                            name="type"
                            options={events}
                            defaultValue={type}
                            label={intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.select.label.type' }).toUpperCase()}
                            errorMessage={errors.type ? intl.formatMessage({ id: errors.type.message }) : undefined}
                            placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.select.placeholder.type' })}...`}
                            required={true}
                            translate={true}
                            onChange={handleUpdateType} />
                        {
                            type === 'ANALYSED_IMAGE' &&
                            <CustomSelect
                                className="w-3/4 pr-6"
                                name="testObject"
                                options={props.analysersOptions}
                                label={intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.select.label.phantom' }).toUpperCase()}
                                errorMessage={errors.testObject ? intl.formatMessage({ id: errors.testObject.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.select.placeholder.phantom' })}...`}
                                required={true}
                                selectRef={register({ name: `testObject` })}
                                onChange={handleUpdateTestObject} />
                        }
                        {
                            type === 'ANALYSED_DOCUMENT' &&
                            <CustomSelect
                                className="w-3/4 pr-6"
                                name="template"
                                options={props.analysersOptions}
                                label={intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.select.label.template' }).toUpperCase()}
                                errorMessage={errors.template ? intl.formatMessage({ id: errors.template.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.select.placeholder.template' })}...`}
                                required={true}
                                selectRef={register({ name: `template` })}
                                onChange={handleUpdateTemplate} />
                        }
                        {
                            type === 'MESSAGE' &&
                            <CustomInput
                                className='w-3/4 pr-6'
                                name="title"
                                label={intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.input.label.title' }).toUpperCase()}
                                errorMessage={errors.title ? intl.formatMessage({ id: errors.title.message }) : undefined}
                                formRef={register}
                                required={true}
                                placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.input.placeholder.title' })}...`} />
                        }
                    </div>
                    {
                        type !== 'MESSAGE' &&
                        <CustomFile
                            className="w-1/2 pr-6 mt-6"
                            name="file"
                            files={files}
                            label={intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.inputFile.label.file' }).toUpperCase()}
                            errorMessage={errors.file ? intl.formatMessage({ id: errors.file.message }) : undefined}
                            multiple={true}
                            required={true}
                            onChange={handleUpdateFile} />
                    }
                    {
                        type === 'MESSAGE' &&
                        <CustomTextarea
                            className="w-1/2 pr-6 mt-6"
                            label={intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.textarea.label.message' }).toUpperCase()}
                            errorMessage={errors.message ? intl.formatMessage({ id: errors.message.message }) : undefined}
                            formRef={register({ required: true })}
                            name="message"
                            placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.newEvent.form.textarea.placeholder.message' })}...`}
                            required={true}
                            rows={4} />
                    }
                </form>
            </div>
            <Footer
                editing={false}
                handleCancel={handleCancel}
                handleSave={(e): void | Promise<void> => handleSubmit(submit)(e)}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; user: LoggedUser | null; analysersOptions: OptionsType<OptionTypeBase> } => ({
    equipment: state.dashboard.currentEquipment,
    user: state.login.user,
    analysersOptions: state.equipments.analysersOptions
})

export default connect(mapStateToProps)(DashboardTimelineEventForm)
