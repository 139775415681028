import React, { useState, useEffect, ReactElement, OptionHTMLAttributes, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { PaginationProperties } from '../../../types/component-properties/pagination-properties'
import PageButton from './page-button'
import CustomSelect from '../custom-select/custom-select'
import { intl } from '../../../intl'
import { ValueType } from 'react-select'

const classes = {
    'page-button': css`
        outline: none !important;
        height: 1.875rem;
        color: var(--color-primary);
        font-size: 0.8rem;
        border: none;
        border-radius: 0.25rem;
        background: var(--color-background-first);
        margin-left: 0.5rem;
        cursor: pointer;
        min-width: 1.5rem;
    `,
    'page-button:disabled': css`
        cursor: not-allowed;
        color: var(--color-gray);
        opacity: 0.8;
    `,
    'page-button-active': css`
        color: var(--color-white);
        background: var(--color-primary);
        font-weight: bold;
    `,
    'page-button-change-page': css`
        background: var(--color-background-first);
        position: relative;

        .arrow-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            ::after {
                content: '';
                position: absolute;
                height: 0.5rem;
                width: 0.5rem;
                border: none;
                border-bottom: 0.125rem solid var(--color-primary);
                border-right: 0.125rem solid var(--color-primary);
                
                transition: transform 0.1s;
            }
        }

        .left {
            transform: rotate(135deg);
        }

        .right {
            transform: rotate(-45deg);
        }
    `,
    'disabled': css`
        opacity: 0.25;
        cursor: not-allowed;
    `,
    'select': css`
        min-width: 3.5rem;

        .select__control {
            background: var(--color-background-first);
            color: var(--color-darkgray);
            border: none;
            border-radius: 0.125rem;
            min-height: 1.875rem;
            padding-right: 0.5rem;
        }
        
        .select__single-value {
            font-size: 0.875rem;
            color: var(--color-darkgray);
        }

        .select__value-container {
            padding: 0;
            display: flex;
            justify-content: center;
        }

        .select__indicator {
            color: var(--color-darkgray);
            padding: 0;

            :hover {
                color: var(--color-darkgray);
            }

            svg {
                width: 0.875rem;
            }
        }

        .select__placeholder {
            color: var(--color-darkgray);
        }

        .select__menu {
            .select__menu-list {
                .select__option {
                    display: flex;
                    justify-content: center;

                    &:hover {
                        background: var(--color-gray-100);
                        color: var(--color-darkgray);
                    }
                }

                .select__option--is-selected {
                    background: var(--color-gray-100);
                    color: var(--color-darkgray);
                }

                .select__option--is-focused {
                    background: inherit;
                    color: inherit;
                }
            }
        }
    `
}

const Pagination = (props: PaginationProperties): ReactElement => {
    
    const [visiblePages, setVisiblePages] = useState<number[]>([])
    const [sizeOptions, setSizeOptions] = useState<OptionHTMLAttributes<HTMLOptionElement>[]>([])

    function filterPages(visiblePages: number[], totalPages: number): number[] {
        return visiblePages.filter((page) => page <= totalPages)
    }

    const getVisiblePages = useCallback((page: number, total: number): number[] => {
        if (total < 5) {
            return filterPages([1, 2, 3, 4], total)
        } else {
            if (page % 4 >= 0 && page > 2 && page + 1 < total) {
                return [1, page - 1, page, page + 1, total]
            } else if (page % 4 >= 0 && page > 2 && page + 1 >= total) {
                return [1, 2, total - 2, total - 1, total]
            } else {
                return [1, 2, 3, total - 1, total]
            }
        }
    }, [])

    function changePage(page: number): void {
        if (page === (props.pageIndex + 1)) {
            return
        }
        const visiblePagesLocal = getVisiblePages(page, props.pageCount)
        setVisiblePages(filterPages(visiblePagesLocal, props.pageCount))
        props.goToPage(page - 1)
        // setActivePage(page)
        props.onPageChange(page)
    }

    function changeSizeOption(option: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        const newOption = (option as OptionHTMLAttributes<HTMLOptionElement>)
 
        const newSizeParsed = parseInt((newOption.value as number).toString(), 10) ? parseInt((newOption.value as number).toString(), 10) : 5

        if ((option as OptionHTMLAttributes<HTMLOptionElement>) !== undefined) {

        }

        if ((props.pageSize !== newSizeParsed) && ((props.pageIndex + 1) > Math.ceil(props.dataSize / newSizeParsed))) {
            // setActivePage(1)
            changePage(1)
            // props.onPageChange(1)
        }

        if (!isNaN(newSizeParsed)) {
            props.onPageSizeChange(newSizeParsed)
            props.setPageSize(newSizeParsed)
        }
    }

    useEffect(() => {
        setVisiblePages(getVisiblePages(props.pageIndex + 1, props.pageCount))
    }, [getVisiblePages, props.pageIndex, props.pageCount])

    useEffect(() => {
        let newSizeOptions: OptionHTMLAttributes<HTMLOptionElement>[] = []

        newSizeOptions = props.pageSizeOptions.map((option) => {
            const newOption: OptionHTMLAttributes<HTMLOptionElement> = {
                label: option.toString(),
                value: option.toString(),
                disabled: undefined,
                selected: undefined
            }

            return newOption
        })

        setSizeOptions(newSizeOptions)
    }, [props.pageSizeOptions])

    return (
        <React.Fragment>
            <div className='mt-4 text-gray text-sm' key={`pagination-${props.pageIndex}-${props.pageSize}`}>
                {/* <div className='ml-4 text-gray'>Showing {props ? (pageSize * props.page) + 1 : 0} to {((props.currentPage + 1) * props.rowsPerPage) > props.data.length ? props.data.length : (props.currentPage + 1) * props.rowsPerPage} of {props.data.length} ({props.pages} pages)</div> */}
                <div className="flex justify-between py-4 px-1">
                    <div className="flex items-center text-xs text-gray">
                        {intl.formatMessage({ id: 'table.pagination.showingFrom' })} {(props.pageIndex * props.pageSize) + (!!props.dataSize ? 1 : 0)}&nbsp;
                        {intl.formatMessage({ id: 'table.pagination.to' }).toLowerCase()} {((props.pageIndex + 1) * props.pageSize) > props.dataSize ? props.dataSize : (props.pageIndex + 1) * props.pageSize}&nbsp;
                        {intl.formatMessage({ id: 'table.pagination.of' }).toLowerCase()} {props.dataSize}&nbsp;
                        <div className="flex items-center h-full ml-4">
                            {intl.formatMessage({ id: 'table.pagination.sizeOptions.label' })}
                            {
                                sizeOptions.length > 0 &&
                                <CustomSelect
                                    cssStyles={[classes.select]}
                                    className='ml-4'
                                    options={sizeOptions}
                                    menuPlacement='top'
                                    placeholder={props.pageSize.toString()}
                                    value={props.pageSize.toString()}
                                    onChange={(option): void => changeSizeOption(option)}></CustomSelect>
                            }
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex">
                            {
                                visiblePages.map((page, index, array) => {
                                    return (
                                        !(array[index - 1] + 1 < page) ?
                                            <PageButton
                                                key={page}
                                                cssStyles={
                                                    (props.pageIndex + 1) === page
                                                        ? [classes["page-button"], classes["page-button-active"]]
                                                        : [classes["page-button"]]
                                                }
                                                onClick={(): void => changePage(page)}
                                            >
                                                {page}
                                            </PageButton>
                                            :
                                            <div className="flex" key={page}>
                                                <span className="ml-1 px-1 text-xl text-primary">...</span>
                                                <PageButton
                                                    cssStyles={
                                                        (props.pageIndex + 1) === page
                                                            ? [classes["page-button"], classes["page-button-active"]]
                                                            : [classes["page-button"]]
                                                    }
                                                    onClick={(): void => changePage(page)}
                                                >
                                                    {page}
                                                </PageButton>
                                            </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex">
                            <PageButton
                                cssStyles={[classes["page-button"], classes["page-button-change-page"], (!props.canPreviousPage(props.pageIndex) ? classes["disabled"] : css``)]}
                                onClick={(): void => {
                                    if (!props.canPreviousPage(props.pageIndex)) {
                                        return
                                    }
                                    props.previousPage()
                                    changePage(props.pageIndex)
                                }}
                                disabled={!props.canPreviousPage(props.pageIndex)}
                            >
                                <div className="arrow-icon left"></div>
                            </PageButton>
                        </div>
                        <div className="flex">
                            <PageButton
                                cssStyles={[classes["page-button"], classes["page-button-change-page"], (!props.canNextPage(props.pageIndex) ? classes["disabled"] : css``)]}
                                onClick={(): void => {
                                    if (!props.canNextPage(props.pageIndex)) {
                                        return
                                    }
                                    props.nextPage()
                                    changePage(props.pageIndex + 2)
                                }}
                                disabled={!props.canNextPage(props.pageIndex)}
                            >
                                <div className="arrow-icon right"></div>
                            </PageButton>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

Pagination.defaultProps = {
    className: '',
    cssStyles: [css``],
    dataSize: 0,
    canPreviousPage: (): boolean => false,
    canNextPage: (): boolean => false,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [5, 10, 25, 50, 100],
    pageCount: 0,
    previousPage: (): void => undefined,
    nextPage: (): void => undefined,
    goToPage: (): void => undefined,
    setPageSize: (): void => undefined,
    onPageChange: (): void => undefined,
    onPageSizeChange: (): void => undefined
}

export default Pagination