import React, { useEffect, ReactElement, useState } from 'react'
import { SectionProperties } from '../../types/component-properties/section-properties'
import { Customer } from '../../types/entity/customer'
import { useDispatch, connect } from 'react-redux'
import CustomersEditor from './customers-editor'
import { Switch, Route } from 'react-router'
import { NewCustomer } from '../../types/new-customer'
import { EditCustomer } from '../../types/edit-customer'
import { fetchCreateCustomer, fetchEditCustomer, fetchCustomers, fetchDeleteCustomer, setCustomers, fetchCustomerEquipmentPropertiesPredefined, setEquipmentPropertiesPredefined } from '../../reducers/customers'
import { toggleSidemenu } from '../../reducers/ui'
import { State } from '../../types/states/state'
import { CustomersProperties } from '../../types/component-properties/customers-properties'
import { RequestQueryParams } from '../../types/request-query-params'
import CustomersCreator from './customers-creator'
import { LocationDescriptorObject } from 'history'
import CustomConfirmDelete from '../custom/custom-confim-delete'
import { intl } from '../../intl'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { SectionName } from '../../types/section-name'
import { useFetching } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

const Customers = ({ handleFilter, ...props }: SectionProperties & CustomersProperties): ReactElement => {
    const dispatch = useDispatch()

    const [newCustomer] = useState<NewCustomer>({ name: '', email: null, phone: null })
    const [modal, setModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})

    function handleCreate(customer: NewCustomer): void {
        dispatch(fetchCreateCustomer(customer, intl.formatMessage({ id: 'organisations.toast.success.organisationCreated' }), { filter: props.queryParams.filter }))
    }

    function handleEdit(id: string, customer: EditCustomer): void {
        dispatch(fetchEditCustomer(id, customer, intl.formatMessage({ id: 'organisations.toast.success.organisationUpdated' }), { filter: props.queryParams.filter }))
    }

    function handleDelete(id: string): void {
        setModal(true)
        setModalInfo({
            header: intl.formatMessage({ id: 'modal.header.delete' }),
            yesText: intl.formatMessage({ id: 'modal.confirm.delete' }),
            cancelText: intl.formatMessage({ id: 'modal.cancel.delete' }),
            message: intl.formatMessage({ id: 'modal.description.delete' }),
            handleDelete: () => {
                setModal(false)
                dispatch(fetchDeleteCustomer(id, intl.formatMessage({ id: 'organisations.toast.success.organisationDeleted' }), { filter: props.queryParams.filter }))
            }
        })
    }

    useFetching(fetchCustomers, 'organisations', [], props.queryParams)

    useEffect(() => {
        dispatch(fetchCustomerEquipmentPropertiesPredefined())
        dispatch(toggleSidemenu(true))

        return (): void => {
            dispatch(setCustomers([]))
            dispatch(setEquipmentPropertiesPredefined([]))
        }
    }, [dispatch])

    useEffect(() => {
        if (!props.currentItem && props.data.length && props.loggedUser?.role === 'MANAGER') {
            handleFilter({ label: props.data[0].name, id: props.data[0].id, destination: { pathname: `/organisations/organisation`, search: `?id=${props.data[0].id}` }, param: null })
        }
     }, [handleFilter, props.currentItem, props.loggedUser, props.data])

    useEffect(() => {
        if (!props.currentItem && props.data.length === 1) {
            handleFilter({ label: props.data[0].name, id: props.data[0].id, destination: { pathname: `/organisations/organisation`, search: `?id=${props.data[0].id}` }, param: null })
        }
    }, [handleFilter, props.currentItem, props.data])

    return (
        <React.Fragment>
            <Switch>
                <Route exact path='/organisations/new'>
                    <CustomersCreator
                        customer={newCustomer}
                        handleNavigate={props.handleNavigate}
                        handleCreate={handleCreate}></CustomersCreator>
                </Route>
                <Route exact path='/organisations/organisation'>
                    <CustomersEditor
                        customer={props.currentItem}
                        handleEdit={handleEdit}
                        handleNavigate={props.handleNavigate}
                        handleDelete={handleDelete}></CustomersEditor>
                </Route>
            </Switch>
            <CustomConfirmDelete
                {...modalInfo}
                visible={modal}
                handleCancel={(): void => setModal(false)} />
        </React.Fragment>
    )
}

Customers.defaultProps = {
    handleLimitChange: (): void => undefined,
    handlePageChange: (): void => undefined
}

const mapStateToProps = (state: State): { data: Customer[]; queryParams: RequestQueryParams; currentItem: Customer | null; location: LocationDescriptorObject; paths: BreadcrumbPath[]; currentSection: SectionName; loggedUser: LoggedUser | null }  => ({
    queryParams: state.ui.sectionQueryParams,
    data: state.customers.customers,
    currentItem: state.customers.currentCustomer,
    location: state.router.location,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(Customers)
