import React, { ReactElement, useEffect, BaseSyntheticEvent, OptionHTMLAttributes, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../../custom/custom-button'
import CustomInput from '../../custom/custom-input'
import { intl } from '../../../intl'
import { useDispatch, connect, useSelector } from 'react-redux'
import { State } from '../../../types/states/state'
import { BreadcrumbPath } from '../../../types/entity/breadcrumb-path'
import * as yup from 'yup'
import Footer from '../../footer'
import CustomSelect from '../../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import queryString from 'query-string'
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/back-arrow.svg'
import { Equipment } from '../../../types/entity/equipment'
import { push } from 'connected-react-router'
import { splicePath } from '../../../reducers/ui'
import { LocationDescriptorObject } from 'history'
import { LoggedUser } from '../../../types/entity/logged-user'
import { fetchAnalysersOptions, setAnalysersOptions } from '../../../reducers/equipments'
import { DashboardProtocolsCreatorProperties } from '../../../types/component-properties/dashboard-protocols-creator-properties'
import { NewProtocolInstance } from '../../../types/new-protocol-instance'

const protocolSchema = yup.object().shape({
    name: yup.string().required('dashboard.protocols.creator.form.input.errorMessage.required.name'),
    testObjectId: yup.string().required('dashboard.protocols.creator.form.select.errorMessage.required.testObject'),
    modelId: yup.string(),
    images: yup.array(),
    equipmentId: yup.string().required()
})

const classes = {
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.25rem;
        }
        &:hover {
            text-decoration: none;
        }
    `
}

const DashboardProtocolsCreator = (props: DashboardProtocolsCreatorProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const queryParams = queryString.parse(props.search)

    const [testObjectId, setTestObjectId] = useState('')
    const [modelId, setModelId] = useState('')
    // const [protocolOptions, setProtocolOptions] = useState<OptionsType<OptionTypeBase>>([])

    // const equipmentProtocols = useSelector((state: State) => state.equipmentDetail.protocols)
    const copyOptions = useSelector((state: State) => state.equipmentDetail.copyOptions)
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    const { register, unregister, setValue, errors, triggerValidation, handleSubmit } = useForm({
        validationSchema: protocolSchema
    })

    function submit(protocol: Record<string, any>): void {
        if (!protocol.modelId) {
            delete protocol.modelId
        }

        props.handleCreate(protocol as NewProtocolInstance)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleUpdateTestObjectId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('testObjectId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'testObjectId' })
        setTestObjectId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleUpdateModelId(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('modelId', (value as OptionHTMLAttributes<HTMLOptionElement>)?.value ?? "")
        triggerValidation({ name: 'modelId' })
        setModelId(((value as OptionHTMLAttributes<HTMLOptionElement>)?.value ?? "") as string)
    }

    function handleNavigate(): void {
        const destination: LocationDescriptorObject = {
            pathname: '/dashboard/equipment-detail',
            search: `?tab=${queryParams.tab as string}&id=${props.equipment?.id}&page=${timelineFilter.page}`
        }

        dispatch(push(destination))
        dispatch(splicePath(props.paths.length - 1))
    }

    function resetProtocol(): void {
        setValue('name', '')
        setValue('testObjectId', '')
        setTestObjectId('')
        setValue('equipmentId', props.equipment?.id)
        setValue('modelId', '')
        setValue('images', [])
    }

    // useEffect(() => {
    //     const newProtocolOptions: any[] = []
    //     equipmentProtocols.forEach((protocol) => {
    //         protocol.name.forEach((name, index) => {
    //             newProtocolOptions.push({
    //                 label: name,
    //                 value: protocol.id[index]
    //             })
    //         })
    //     })
    //     setProtocolOptions(newProtocolOptions)
    // }, [equipmentProtocols])

    useEffect(() => {
        register({ name: 'equipmentId' })

        return (): void => {
            dispatch(setAnalysersOptions([]))
            unregister('equipmentId')
        }
    }, [dispatch, register, unregister])

    useEffect(() => {
        if (props.equipment) {
            dispatch(fetchAnalysersOptions(props.equipment.id, 'IMAGE'))
        }

        setValue('equipmentId', props.equipment?.id)
    }, [dispatch, setValue, props.equipment])

    return (
        <React.Fragment>
            <div key={props.protocol.name} className="pt-4 px-8">
                <CustomButton
                    Icon={BackArrowIcon}
                    cssStyles={[classes["back-button"]]}
                    className="bg-lightblue text-primary min-w-52 font-bold px-6 mb-5"
                    label={intl.formatMessage({ id: 'dashboard.protocols.creator.backButton.label' })}
                    onClick={handleNavigate} />

                <form className="min-w-76 max-w-76" autoComplete="off">
                    <CustomInput
                        name="name"
                        label={intl.formatMessage({ id: 'dashboard.protocols.creator.form.input.label.name' }).toUpperCase()}
                        formRef={register}
                        errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'dashboard.protocols.creator.form.input.placeholder.name' })}...`}
                        defaultValue={props.protocol.name} />
                    <CustomSelect
                        options={props.analysersOptions}
                        name="testObjectId"
                        label={intl.formatMessage({ id: 'dashboard.protocols.creator.form.select.label.testObject' }).toUpperCase()}
                        selectRef={register({ name: `testObjectId` })}
                        errorMessage={errors.testObjectId ? intl.formatMessage({ id: errors.testObjectId.message }) : undefined}
                        required={true}
                        placeholder={`${intl.formatMessage({ id: 'dashboard.protocols.creator.form.select.placeholder.testObject' })}...`}
                        value={testObjectId}
                        onChange={handleUpdateTestObjectId} />
                    <CustomSelect
                        options={copyOptions}
                        name="modelId"
                        label={intl.formatMessage({ id: 'dashboard.protocols.creator.form.select.label.protocol' }).toUpperCase()}
                        selectRef={register({ name: `modelId` })}
                        placeholder={`${intl.formatMessage({ id: 'dashboard.protocols.creator.form.select.placeholder.protocol' })}...`}
                        value={modelId}
                        clearable={true}
                        onChange={handleUpdateModelId} />
                </form>
            </div>
            <Footer
                item={props.protocol}
                editing={false}
                handleCancel={resetProtocol}
                handleSave={formSubmit}></Footer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; pathname: string; paths: BreadcrumbPath[]; analysersOptions: OptionsType<OptionTypeBase>; search: string; loggedUser: LoggedUser | null } => ({
    equipment: state.dashboard.currentEquipment,
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    analysersOptions: state.equipments.analysersOptions,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(DashboardProtocolsCreator)
