import React, { ReactElement, useEffect, useState, BaseSyntheticEvent, OptionHTMLAttributes, useRef, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import useForm from 'react-hook-form'
import CustomButton from '../custom/custom-button'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { EquipmentsEditorProperties } from '../../types/component-properties/equipments-editor-properties'
import { useDispatch, connect } from 'react-redux'
import { State } from '../../types/states/state'
import { fetchEquipment, fetchIntegrationProperties, setImageOptions, setCurrentEquipment } from '../../reducers/equipments'
import { Equipment } from '../../types/entity/equipment'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import * as yup from 'yup'

import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg'
import Footer from '../footer'
import CustomSwitch from '../custom/custom-switch'
import { EditEquipment } from '../../types/edit-equipment'
import { fetchMedicalCentreOptions, setMedicalCentreOptions } from '../../reducers/medical-centres'
import CustomSelect from '../custom/custom-select/custom-select'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import queryString from 'query-string'
import { SimpleItem } from '../../types/simple-item'
import ReactTooltip from 'react-tooltip'
import { useFetching } from '../../common/utils'
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg'
import { LoggedUser } from '../../types/entity/logged-user'
import classNames from 'classnames'
import { deepCopy } from '../../common/utils'
import CustomTabs from '../custom/custom-tabs'
import { Tab, TabList, TabPanel } from 'react-tabs'
import { IntegrationProperty } from '../../types/integration-property'
import EquipmentsIntegration from './equipments-integration'
import { Locale } from '../../types/locale'

const equipmentSchema = yup.object().shape({
    name: yup.string().required('equipments.editor.form.input.errorMessage.required.name'),
    medicalCentreId: yup.string().required('equipments.editor.form.select.errorMessage.required.medicalCentre'),
    email: yup.string(),
    phone: yup.string(),
    protocolsIds: yup.array(),
    testObjectsIds: yup.array(),
    usersIds: yup.array(),
    archived: yup.boolean()
})

const classes = {
    'property-block': css`
        min-width: calc(50% - 1rem);
    `,
    'back-button': css`
        svg {
            transform: rotate(90deg);
            margin-right: 0.5rem;
        }
        &:hover {
            text-decoration: none;
        }
    `,
    'not-allowed': css`
        circle {
            fill: var(--color-gray-100) !important;
        }

        &:hover {
            cursor: not-allowed !important;
            opacity: 1 !important;
        }
    `,
    'tabs': css`
        .react-tabs__tab-list {
            margin-bottom: 0;
        }

        .react-tabs__tab-panel {
            flex-grow: 1;
        }

        .react-tabs__tab {
            background: transparent !important;
        }
    `
}

const EquipmentsEditor = (props: EquipmentsEditorProperties): ReactElement => {
    const dispatch = useDispatch()
    
    const dotRef = useRef<SVGSVGElement>(null)
    const queryParams = queryString.parse(props.search)

    const [archived, setArchived] = useState(props.equipment?.archived)
    const [medicalCentreId, setMedicalCentreId] = useState(props.equipment?.medicalCentreId)
    const [selectedContentType, setSelectedContentType] = useState<string>('Test Objects')
    const [associatedEntities, setAssociatedEntities] = useState<SimpleItem[]>()
    const [availableEntities, setAvailableEntities] = useState<SimpleItem[]>()
    const [associatedProtocolsIds, setAssociatedProtocolsIds] = useState<SimpleItem[]>()
    const [associatedTestObjectsIds, setAssociatedTestObjectIds] = useState<SimpleItem[]>()
    const [associatedUsersIds, setAssociatedUsersIds] = useState<SimpleItem[]>()
    const [availableProtocolsIds, setAvailableProtocolsIds] = useState<SimpleItem[]>()
    const [availableTestObjectsIds, setAvailableTestObjectIds] = useState<SimpleItem[]>()
    const [availableUsersIds, setAvailableUsersIds] = useState<SimpleItem[]>()
    const [activeTab, setActiveTab] = useState(0)
    const { register, unregister, setValue, errors, triggerValidation, handleSubmit } = useForm({
        validationSchema: equipmentSchema
    })

    function changeTab(index: number): void {
        switch(index) {
            case 0:
                setSelectedContentType("Test Objects")

                break
            case 1:
                setSelectedContentType("Protocols")
            
                break
            case 2:
                setSelectedContentType("Users")

                break
        }

        setActiveTab(index)
    }

    function submit(equipment: Record<string, any>): void {
        equipment.protocolsIds = equipment.protocolsIds.map((protocol: SimpleItem) => protocol.id)
        equipment.testObjectsIds = equipment.testObjectsIds.map((testObject: SimpleItem) => testObject.id)
        equipment.usersIds = equipment.usersIds.map((user: SimpleItem) => user.id)

        props.handleEdit(props.equipment?.id ?? '0', equipment as EditEquipment)
    }

    function formSubmit(event: BaseSyntheticEvent): void {
        handleSubmit(submit)(event)
    }

    function handleSwitch(value: boolean): void {
        setValue('archived', !value)
        setArchived(!value)
    }

    function handleUpdateMedicalCentre(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        setValue('medicalCentreId', (value as OptionHTMLAttributes<HTMLOptionElement>).value)
        triggerValidation({ name: 'medicalCentreId' })
        setMedicalCentreId((value as OptionHTMLAttributes<HTMLOptionElement>).value as string)
    }

    function handleNavigate(): void {
        props.handleNavigate(0)
    }

    function resetEquipment(): void {
        if (props.equipment) {
            setValue('name', props.equipment.name)
            setValue('email', props.equipment.email)
            setValue('phone', props.equipment.phone)
            setValue('archived', props.equipment.archived)
            setArchived(props.equipment.archived)
            setValue('protocolsIds', props.equipment.associatedProtocols)
            setAssociatedProtocolsIds(props.equipment.associatedProtocols)
            setAvailableProtocolsIds(props.equipment.availableProtocols)
            setValue('testObjectsIds', props.equipment.associatedTestObjects)
            setAssociatedTestObjectIds(props.equipment.associatedTestObjects)
            setAvailableTestObjectIds(props.equipment.availableTestObjects)
            setValue('usersIds', props.equipment.associatedUsers)
            setAssociatedUsersIds(props.equipment.associatedUsers)
            setAvailableUsersIds(props.equipment.availableUsers)
            // setValue('photo', props.equipment.photo)
            setValue('medicalCentreId', props.equipment.medicalCentreId)
            setMedicalCentreId(props.equipment.medicalCentreId)
        }
    }

    function addEntity(item: SimpleItem): void {
        let newAvailableEntities: SimpleItem[] = deepCopy(availableEntities)
        const newAssociatedEntities: SimpleItem[] = deepCopy(associatedEntities)

        newAvailableEntities = newAvailableEntities.filter((entity: SimpleItem) => entity.id !== item.id)
        newAssociatedEntities.push(item)

        switch (selectedContentType) {
            default:
                setAssociatedTestObjectIds(newAssociatedEntities)
                setAvailableTestObjectIds(newAvailableEntities)
                setValue('testObjectsIds', newAssociatedEntities)

                break
            case 'Protocols':
                setAssociatedProtocolsIds(newAssociatedEntities)
                setAvailableProtocolsIds(newAvailableEntities)
                setValue('protocolsIds', newAssociatedEntities)

                break
            case 'Users':
                setAssociatedUsersIds(newAssociatedEntities)
                setAvailableUsersIds(newAvailableEntities)
                setValue('usersIds', newAssociatedEntities)

                break
        }

        if (dotRef.current) {
            ReactTooltip.hide(dotRef.current)
        }

        setAssociatedEntities(newAssociatedEntities.sort((a, b) => a.name.localeCompare(b.name)))
        setAvailableEntities(newAvailableEntities.sort((a, b) => a.name.localeCompare(b.name)))
    }

    function removeEntity(item: SimpleItem): void {
        const newAvailableEntities: SimpleItem[] = deepCopy(availableEntities)
        let newAssociatedEntities: SimpleItem[] = deepCopy(associatedEntities)

        newAvailableEntities.push(item)
        newAssociatedEntities = newAssociatedEntities.filter((entity: SimpleItem) => entity.id !== item.id)

        switch (selectedContentType) {
            default:
                setAssociatedTestObjectIds(newAssociatedEntities)
                setAvailableTestObjectIds(newAvailableEntities)
                setValue('testObjectsIds', newAssociatedEntities)

                break
            case 'Protocols':
                setAssociatedProtocolsIds(newAssociatedEntities)
                setAvailableProtocolsIds(newAvailableEntities)
                setValue('protocolsIds', newAssociatedEntities)

                break
            case 'Users':
                setAssociatedUsersIds(newAssociatedEntities)
                setAvailableUsersIds(newAvailableEntities)
                setValue('usersIds', newAssociatedEntities)

                break
        }

        if (dotRef.current) {
            ReactTooltip.hide(dotRef.current)
        }

        setAssociatedEntities(newAssociatedEntities.sort((a, b) => a.name.localeCompare(b.name)))
        setAvailableEntities(newAvailableEntities.sort((a, b) => a.name.localeCompare(b.name)))
    }

    const setEntities = useCallback(() => {
        switch (selectedContentType) {
            default:
                setAssociatedEntities(associatedTestObjectsIds)
                setAvailableEntities(availableTestObjectsIds)

                break
            case 'Protocols':
                setAssociatedEntities(associatedProtocolsIds)
                setAvailableEntities(availableProtocolsIds)

                break
            case 'Users':
                setAssociatedEntities(associatedUsersIds)
                setAvailableEntities(availableUsersIds)

                break
        }
    }, [associatedTestObjectsIds, availableTestObjectsIds, associatedProtocolsIds, availableProtocolsIds, associatedUsersIds, availableUsersIds, selectedContentType])

    useFetching(fetchEquipment, 'equipments', [], queryParams.id as string)
    useFetching(fetchMedicalCentreOptions, 'equipments', [], queryParams.id as string)
    // useFetching(fetchAttributeOptions, 'equipments', [], queryParams.id as string)
    useFetching(fetchIntegrationProperties, 'equipments', [], queryParams.id as string, props.locale)

    useEffect(() => {
        setEntities()
    }, [setEntities])

    useEffect(() => {
        if (props.equipment) {
            setValue('archived', props.equipment.archived)
            setArchived(props.equipment.archived)
            setValue('protocolsIds', props.equipment.associatedProtocols)
            setAssociatedProtocolsIds(props.equipment.associatedProtocols)
            setAvailableProtocolsIds(props.equipment.availableProtocols)
            setValue('testObjectsIds', props.equipment.associatedTestObjects)
            setAssociatedTestObjectIds(props.equipment.associatedTestObjects)
            setAvailableTestObjectIds(props.equipment.availableTestObjects)
            setValue('usersIds', props.equipment.associatedUsers)
            setAssociatedUsersIds(props.equipment.associatedUsers)
            setAvailableUsersIds(props.equipment.availableUsers)
            // setValue('photo', props.equipment.photo)
            setValue('medicalCentreId', props.equipment.medicalCentreId)
            setMedicalCentreId(props.equipment.medicalCentreId)
        }
    }, [setValue, props.equipment])

    useEffect(() => {
        register({ name: 'archived' })
        register({ name: 'protocolsIds' })
        register({ name: 'testObjectsIds' })
        register({ name: 'usersIds' })
        // register({ name: 'photo' })
        register({ name: 'medicalCentreId' })

        return (): void => {
            unregister('archived')
            unregister('protocolsIds')
            unregister('testObjectsIds')
            unregister('usersIds')
            // unregister('photo')
            unregister('medicalCentreId')
        }
    }, [register, unregister])

    useEffect(() => {
        return (): void => {
            dispatch(setCurrentEquipment(null))
            dispatch(setMedicalCentreOptions([]))
            dispatch(setImageOptions([]))
        }
    }, [dispatch])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return (
        <React.Fragment>
            {
                props.equipment && props.medicalCentreOptions.length > 0 &&
                <form className="flex flex-col pt-4 px-8 h-full" autoComplete="off">
                    <div className="flex justify-between items-center mb-12">
                        <CustomButton
                            Icon={BackArrowIcon}
                            cssStyles={[classes["back-button"]]}
                            className="bg-lightblue text-primary min-w-40 font-bold px-6"
                            label={intl.formatMessage({ id: 'equipments.editor.backButton.label' })}
                            onClick={handleNavigate} />
                        <CustomSwitch
                            checked={!archived}
                            disabled={!props.loggedUser?.acl.includes('EQUIPMENTS_UPDATE_ARCHIVED')}
                            label={intl.formatMessage({ id: 'equipments.editor.switch.label' })}
                            labelPosition="left"
                            onChange={handleSwitch}></CustomSwitch>
                    </div>

                    <div className="flex">
                        <div className="min-w-40 max-w-60">
                            <CustomInput
                                className="w-full"
                                name="name"
                                label={intl.formatMessage({ id: 'equipments.editor.form.input.label.name' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.name ? intl.formatMessage({ id: errors.name.message }) : undefined}
                                required={true}
                                placeholder={`${intl.formatMessage({ id: 'equipments.editor.form.input.placeholder.name' })}...`}
                                defaultValue={props.equipment.name} />
                            <CustomSelect
                                options={props.medicalCentreOptions}
                                name="medicalCentreId"
                                label={intl.formatMessage({ id: 'equipments.editor.form.select.label.medicalCentre' }).toUpperCase()}
                                selectRef={register({ name: `medicalCentreId` })}
                                errorMessage={errors.medicalCentreId ? intl.formatMessage({ id: errors.medicalCentreId.message }) : undefined}
                                required={true}
                                placeholder={`${intl.formatMessage({ id: 'equipments.editor.form.select.placeholder.medicalCentre' })}...`}
                                value={medicalCentreId}
                                onChange={handleUpdateMedicalCentre} />
                            <CustomInput
                                className="w-full"
                                name="email"
                                label={intl.formatMessage({ id: 'equipments.editor.form.input.label.email' }).toUpperCase()}
                                formRef={register}
                                errorMessage={errors.email ? intl.formatMessage({ id: errors.email.message }) : undefined}
                                placeholder={`${intl.formatMessage({ id: 'equipments.editor.form.input.placeholder.email' })}...`}
                                defaultValue={props.equipment.email} />
                            <CustomInput
                                className="w-full"
                                name="phone"
                                label={intl.formatMessage({ id: 'equipments.editor.form.input.label.phone' }).toUpperCase()}
                                formRef={register}
                                placeholder={`${intl.formatMessage({ id: 'equipments.editor.form.input.placeholder.phone' })}...`}
                                defaultValue={props.equipment.phone} />
                        </div>

                        <div className="bg-background-first p-8 ml-6 flex-grow">
                            <CustomTabs
                                cssStyles={[classes.tabs]}
                                className="flex flex-col flex-grow"
                                selectedIndex={activeTab}
                                onSelect={changeTab}
                            >
                                <TabList>
                                    <Tab>
                                        {intl.formatMessage({ id: 'equipments.editor.tabHeader.testObjects' })}
                                    </Tab>
                                    <Tab>
                                        {intl.formatMessage({ id: 'equipments.editor.tabHeader.protocols' })}
                                    </Tab>
                                    <Tab>
                                        {intl.formatMessage({ id: 'equipments.editor.tabHeader.users' })}
                                    </Tab>
                                    <Tab>
                                        {intl.formatMessage({ id: 'equipments.editor.tabHeader.pacsIntegration' })}
                                    </Tab>
                                </TabList>
                                    <TabPanel>
                                            {/* <h6 className="font-bold mb-4 mt-0">{intl.formatMessage({ id: 'Properties' })}</h6> */}

                                            <div className="text-gray my-6">{intl.formatMessage({ id: 'equipments.editor.tabBody.properties.text' })}</div>

                                            <div className="flex flex-grow">
                                                <div css={[classes["property-block"]]} className="mr-6">
                                                    <div className="font-extra-bold text-gray text-2xs mb-2 truncate">{intl.formatMessage({ id: 'equipments.editor.tabBody.blockTitle.available' }).toUpperCase()}</div>
                                                    <div className="border border-solid border-gray-100 rounded-sm bg-background-second h-60 overflow-auto">
                                                        {
                                                            availableEntities &&
                                                            availableEntities.map((content, index) => {
                                                                const name = selectedContentType !== 'Users' ? content.name : (content as any).username
                                                                return (
                                                                    <div key={`${content.id}-${index}`} className={classNames({ 'justify-between items-center px-4 py-2 border-0 border-b border-solid border-gray-100': true, 'flex': name, 'hidden': !name })}>
                                                                        <div className="mr-auto">{name}</div>
                                                                        <AddIcon
                                                                            ref={dotRef}
                                                                            className="ml-4 cursor-pointer hover:opacity-50"
                                                                            data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                                                                            data-for="action-tooltip"
                                                                            onClick={(): void => addEntity(content)} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div css={[classes["property-block"]]}>
                                                    <div className="font-extra-bold text-gray text-2xs mb-2 truncate">{intl.formatMessage({ id: 'equipments.editor.tabBody.blockTitle.selected' }).toUpperCase()}</div>
                                                    <div className="border border-solid border-gray-100 rounded-sm bg-background-second h-60 overflow-auto">
                                                        {
                                                            associatedEntities &&
                                                            associatedEntities.map((content, index) => {
                                                                const name = selectedContentType !== 'Users' ? content.name : (content as any).username
                                                                return (
                                                                    <div key={`${content.id}-${index}`} className={classNames({ 'justify-between items-center px-4 py-2 border-0 border-b border-solid border-gray-100': true, 'flex': name, 'hidden': !name })}>
                                                                        {name}
                                                                        <RemoveIcon
                                                                            ref={dotRef}
                                                                            className="ml-4 cursor-pointer hover:opacity-50"
                                                                            data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                                                                            data-for="action-tooltip"
                                                                            onClick={(): void => removeEntity(content)} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    </TabPanel>
                                    <TabPanel>
                                            {/* <h6 className="font-bold mb-4 mt-0">{intl.formatMessage({ id: 'Properties' })}</h6> */}

                                            <div className="text-gray my-6">{intl.formatMessage({ id: 'equipments.editor.tabBody.properties.text' })}</div>

                                            <div className="flex flex-grow">
                                                <div css={[classes["property-block"]]} className="mr-6">
                                                    <div className="font-extra-bold text-gray text-2xs mb-2 truncate">{intl.formatMessage({ id: 'equipments.editor.tabBody.blockTitle.available' }).toUpperCase()}</div>
                                                    <div className="border border-solid border-gray-100 rounded-sm bg-background-second h-60 overflow-auto">
                                                        {
                                                            availableEntities &&
                                                            availableEntities.map((content, index) => {
                                                                const name = selectedContentType !== 'Users' ? content.name : (content as any).username
                                                                return (
                                                                    <div key={`${content.id}-${index}`} className={classNames({ 'justify-between items-center px-4 py-2 border-0 border-b border-solid border-gray-100': true, 'flex': name, 'hidden': !name })}>
                                                                        <div className="mr-auto">{name}</div>
                                                                        <AddIcon
                                                                            ref={dotRef}
                                                                            className="ml-4 cursor-pointer hover:opacity-50"
                                                                            data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                                                                            data-for="action-tooltip"
                                                                            onClick={(): void => addEntity(content)} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div css={[classes["property-block"]]}>
                                                    <div className="font-extra-bold text-gray text-2xs mb-2 truncate">{intl.formatMessage({ id: 'equipments.editor.tabBody.blockTitle.selected' }).toUpperCase()}</div>
                                                    <div className="border border-solid border-gray-100 rounded-sm bg-background-second h-60 overflow-auto">
                                                        {
                                                            associatedEntities &&
                                                            associatedEntities.map((content, index) => {
                                                                const name = selectedContentType !== 'Users' ? content.name : (content as any).username
                                                                return (
                                                                    <div key={`${content.id}-${index}`} className={classNames({ 'justify-between items-center px-4 py-2 border-0 border-b border-solid border-gray-100': true, 'flex': name, 'hidden': !name })}>
                                                                        {name}
                                                                        <RemoveIcon
                                                                            ref={dotRef}
                                                                            className="ml-4 cursor-pointer hover:opacity-50"
                                                                            data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                                                                            data-for="action-tooltip"
                                                                            onClick={(): void => removeEntity(content)} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    </TabPanel>
                                    <TabPanel>
                                        {/* <h6 className="font-bold mb-4 mt-0">{intl.formatMessage({ id: 'Properties' })}</h6> */}

                                        <div className="text-gray my-6">{intl.formatMessage({ id: 'equipments.editor.tabBody.properties.text' })}</div>

                                        <div className="flex flex-grow">
                                            <div css={[classes["property-block"]]} className="mr-6">
                                                <div className="font-extra-bold text-gray text-2xs mb-2 truncate">{intl.formatMessage({ id: 'equipments.editor.tabBody.blockTitle.available' }).toUpperCase()}</div>
                                                <div className="border border-solid border-gray-100 rounded-sm bg-background-second h-60 overflow-auto">
                                                    {
                                                        availableEntities &&
                                                        availableEntities.map((content, index) => {
                                                            const name = selectedContentType !== 'Users' ? content.name : (content as any).username
                                                            return (
                                                                <div key={`${content.id}-${index}`} className={classNames({ 'justify-between items-center px-4 py-2 border-0 border-b border-solid border-gray-100': true, 'flex': name, 'hidden': !name })}>
                                                                    <div className="mr-auto">{name}</div>
                                                                    <AddIcon
                                                                        ref={dotRef}
                                                                        className="ml-4 cursor-pointer hover:opacity-50"
                                                                        data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                                                                        data-for="action-tooltip"
                                                                        onClick={(): void => addEntity(content)} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <div css={[classes["property-block"]]}>
                                                <div className="font-extra-bold text-gray text-2xs mb-2 truncate">{intl.formatMessage({ id: 'equipments.editor.tabBody.blockTitle.selected' }).toUpperCase()}</div>
                                                <div className="border border-solid border-gray-100 rounded-sm bg-background-second h-60 overflow-auto">
                                                    {
                                                        associatedEntities &&
                                                        associatedEntities.map((content, index) => {
                                                            const name = selectedContentType !== 'Users' ? content.name : (content as any).username
                                                            return (
                                                                <div key={`${content.id}-${index}`} className={classNames({ 'justify-between items-center px-4 py-2 border-0 border-b border-solid border-gray-100': true, 'flex': name, 'hidden': !name })}>
                                                                    {name}
                                                                    <RemoveIcon
                                                                        ref={dotRef}
                                                                        className="ml-4 cursor-pointer hover:opacity-50"
                                                                        data-tip={intl.formatMessage({ id: 'tooltip.delete' })}
                                                                        data-for="action-tooltip"
                                                                        onClick={(): void => removeEntity(content)} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                </TabPanel>
                                <TabPanel>
                                    <EquipmentsIntegration />
                                </TabPanel>
                            </CustomTabs>
                        </div>
                    </div>
                </form>
            }
            <Footer
                item={props.equipment}
                editing={true}
                canDelete={props.loggedUser?.acl.includes('EQUIPMENTS_DELETE')}
                handleDelete={props.handleDelete}
                handleCancel={resetEquipment}
                handleSave={formSubmit}></Footer>
            <ReactTooltip id="action-tooltip" type="dark" effect="solid" />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { pathname: string; paths: BreadcrumbPath[]; equipment: Equipment; medicalCentreOptions: OptionsType<OptionTypeBase>; integrationProperties: IntegrationProperty | null; search: string; loggedUser: LoggedUser | null; locale: Locale } => ({
    pathname: state.router.location.pathname,
    paths: state.ui.breadcrumbPaths,
    equipment: state.equipments.currentEquipment as Equipment,
    medicalCentreOptions: state.medicalCentres.medicalCentreOptions,
    integrationProperties: state.equipments.integrationProperties,
    search: state.router.location.search,
    loggedUser: state.login.user,
    locale: state.ui.locale
})

export default connect(mapStateToProps)(EquipmentsEditor)
