import React, { ReactElement, useEffect, useState } from 'react'
import DatePicker from "react-datepicker"
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomDatePickerProperties } from '../../types/component-properties/custom/custom-date-picker-properties'
import { CustomFormProperties } from '../../types/component-properties/custom/custom-form-properties'
import { intl } from '../../intl'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'

const classes = {
    'date-picker': css`
        input {
            border: 1px solid var(--color-gray-100);
            color: var(--color-darkgray);
            border-radius: 0.25rem;
            min-height: 2.5rem;
            outline: none;
            padding: 0 0.5rem;
            font-size: 0.9rem;
            width: 100%;

            &::placeholder {
                color: var(--color-darkgray);
            }

            :focus {
                border: 1px solid var(--color-primary);
            }
        }

        .react-datepicker {
            border-radius: 0.25rem;
            border-color: var(--color-gray-100);
        }

        .react-datepicker-wrapper {
            width: 100%;
        }

        .react-datepicker__header {
            background: white;
            border: none;
        }

        .react-datepicker-popper {
            margin-top: 0.25rem;
            left: 0.125rem;
        }

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__month-container {
            box-shadow: 0 10px 40px -30px rgba(57,57,57,0.60), 0 0 20px 0 rgba(57,57,57,0.25);
        }

        .react-datepicker__current-month {
            text-transform: capitalize;
            font-size: 0.85rem;
            color: var(--color-darkgray);
            padding: 0.5rem 0;
        }

        .react-datepicker__day-name {
            text-transform: capitalize;
            font-weight: 700;
            color: var(--color-gray-100);
            font-size: 0.75rem;
        }

        .react-datepicker__day--outside-month {
            opacity: 0;
            pointer-events: none;
        }

        .react-datepicker__day {
            outline: none !important;
        }

        .react-datepicker__day--keyboard-selected {
            background: unset;
            color: black;
        }
        
        .react-datepicker__day--selected {
            border-radius: 50%;
            background: var(--color-primary);
            color: var(--color-white);
        }

        .react-datepicker__navigation {
            margin: 0.5rem 0;
            outline: none;
            box-shadow: none;
        }
    `,
    'icon': `
        top: 0.9rem;
    `
}

const CustomDatePicker = (props: CustomDatePickerProperties & CustomFormProperties): ReactElement => {
    
    const [value, setValue] = useState<string>('')

    useEffect(() => {
        if (props.selected) {
            setValue(intl.formatDate(props.selected, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }))
        } else {
            setValue('')
        }
    }, [props.selected])

    return (
        <React.Fragment>
            <div css={[classes["date-picker"], props.cssStyles]} className={`${props.className}`}>
                <div className="mb-1 font-bold text-darkgray text-xs" hidden={props.hidden || props.label === ''}>
                    {props.label} {props.required && props.label !== '' && <span className="text-red">*</span>}
                </div>
                <div className="flex items-center relative">
                    <CalendarIcon css={[classes.icon]} className="absolute right-2 w-3 h-3 z-10" />
                </div>
                <DatePicker
                    selected={props.selected}
                    value={value}
                    placeholderText={props.placeholder}
                    disabled={props.disabled}
                    ref={props.datePickerRef}
                    autoComplete="off"
                    name={props.name}
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true,
                        }
                    }}
                    onChange={props.onChange}></DatePicker>
                <div className="h-6 font-bold text-red text-xs" hidden={props.name === '' || props.hideErrors}>{props.errorMessage}</div>
            </div>
        </React.Fragment>
    )
}

CustomDatePicker.defaultProps = {
    className: '',
    cssStyles: [css``],
    defaultValue: '',
    selectDefaultValue: undefined,
    name: '',
    label: '',
    hidden: false,
    errorMessage: undefined,
    required: false,
    selected: undefined,
    placeholder: '',
    disabled: false,
    hideErrors: false,
    formRef: (): void => undefined,
    selectRef: (): void => undefined,
    datePickerRef: (): void => undefined,
    onChange: (): void => undefined
}

export default CustomDatePicker