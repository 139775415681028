import { CustomersState } from '../types/states/customers-state'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Customer } from '../types/entity/customer'
import { RequestQueryParams } from '../types/request-query-params'
import { AppThunk } from '../types/app-thunk'
import { call } from '../common/api'
import { NewCustomer } from '../types/new-customer'
import { EditCustomer } from '../types/edit-customer'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'
import { setPaths } from './ui'
import { OptionsType, OptionTypeBase } from 'react-select'
import { SimpleItem } from '../types/simple-item'
import { CustomerEquipmentProperty } from '../types/entity/customer-equipment-property'


const customerInitialState: CustomersState = {
    customers: [],
    customerOptions: [],
    currentCustomer: null,
    currentEquipmentProperties: [],
    equipmentPropertiesPredefined: []
}

const customersSlice = createSlice({
    name: 'customers',
    initialState: customerInitialState,
    reducers: {
        setCustomers: (state: CustomersState, { payload }: PayloadAction<Customer[]>): void => {
            state.customers = payload
        },
        setCustomerOptions: (state: CustomersState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.customerOptions = payload
        },
        addCustomer: (state: CustomersState, { payload }: PayloadAction<Customer>): void => {
            state.customers.push(payload)
        },
        setCurrentCustomer: (state: CustomersState, { payload }: PayloadAction<Customer | null>): void => {
            state.currentCustomer = payload
        },
        setCurrentEquipmentProperties: (state: CustomersState, { payload }: PayloadAction<CustomerEquipmentProperty[]>): void => {
            state.currentEquipmentProperties = payload
        },
        setEquipmentPropertiesPredefined: (state: CustomersState, { payload }: PayloadAction<OptionsType<OptionTypeBase>>): void => {
            state.equipmentPropertiesPredefined = payload
        }
    }
})

export const { setCustomers, setCustomerOptions, addCustomer, setCurrentCustomer, setCurrentEquipmentProperties, setEquipmentPropertiesPredefined } = customersSlice.actions

export default customersSlice.reducer

export const fetchCustomers = (params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCustomersRequest = call({
            method: 'GET',
            url: '/customers',
            params: {
                name: params.filter
            }
        }, dispatch, true)
        const res = await getCustomersRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(customersSlice.actions.setCustomers(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCustomerOptions = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCustomerOptionsRequest = call({
            method: 'GET',
            url: '/customers/references'
        }, dispatch, false)
        const res = await getCustomerOptionsRequest()

        if (res.status >= 200 && res.status < 300) {
            const customerOptions: OptionsType<OptionTypeBase> = res.data.map((customer: SimpleItem) => {
                return {
                    label: customer.name,
                    value: customer.id
                }
            })

            dispatch(customersSlice.actions.setCustomerOptions(customerOptions))
        }

    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateCustomer = (data: NewCustomer, message: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createCustomerRequest = call({
            method: 'POST',
            url: '/customers',
            data
        }, dispatch, true)
        const res = await createCustomerRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(customersSlice.actions.addCustomer(res.data))
            dispatch(fetchCustomers(params))
            dispatch(setPaths([{ label: res.data.name, id: res.data.id, destination: { pathname: `/organisations/organisation`, search: `?id=${res.data.id}` }, param: null }]))
            dispatch(push({ 
                pathname: `/organisations/organisation`,
                search: `?id=${res.data.id}`
            }))
            toast.success(message)
        }

    } catch (err) {
        console.log(err)
    }
}

export const fetchEditCustomer = (id: string, data: EditCustomer, message: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editCustomerRequest = call({
            method: 'PUT',
            url: `/customers/${id}`,
            data
        }, dispatch, true)
        const res = await editCustomerRequest()
        
        if (res.status >= 200 && res.status < 300) {
            dispatch(customersSlice.actions.setCurrentCustomer(res.data))
            dispatch(fetchCustomers(params))
            toast.success(message)
        }

    } catch (err) {
        console.log(err)
    }
}

export const fetchCustomer = (id: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCustomerRequest = call({
            method: 'GET',
            url: `/customers/${id}`
        }, dispatch, true)
        const res = await getCustomerRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(customersSlice.actions.setCurrentCustomer(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteCustomer = (id: string, message: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteCustomerRequest = call({
            method: 'DELETE',
            url: `/customers/${id}`
        }, dispatch, true)
        const res = await deleteCustomerRequest()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchCustomers(params))
            dispatch(setPaths([]))
            dispatch(push('/organisations'))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCustomerEquipmentProperties = (id: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCustomerEquipmentProperties = call({
            method: 'GET',
            url: `/customers/${id}/equipment-properties`
        }, dispatch, true)
        const res = await getCustomerEquipmentProperties()

        if (res.status >= 200 && res.status < 300) {
            dispatch(customersSlice.actions.setCurrentEquipmentProperties(res.data))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCreateCustomerEquipmentProperty = (id: string, message: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const createCustomerEquipmentProperty = call({
            method: 'POST',
            url: `/customers/${id}/equipment-properties`,
            params
        }, dispatch, true)
        const res = await createCustomerEquipmentProperty()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchCustomerEquipmentProperties(id))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchEditCustomerEquipmentProperty = (id: string, propertyId: string, message: string, params: RequestQueryParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        const editCustomerEquipmentProperty = call({
            method: 'PUT',
            url: `/customers/equipment-properties/${propertyId}`,
            params
        }, dispatch, true)
        const res = await editCustomerEquipmentProperty()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchCustomerEquipmentProperties(id))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchCustomerEquipmentPropertiesPredefined = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const getCustomerEquipmentProperties = call({
            method: 'GET',
            url: `/customers/equipment-properties/predefined`
        }, dispatch, true)
        const res = await getCustomerEquipmentProperties()

        if (res.status >= 200 && res.status < 300) {
            const equipmentPropertiesPredefined: OptionsType<OptionTypeBase> = res.data.map((equipmentProperty: CustomerEquipmentProperty) => {
                return {
                    label: equipmentProperty.name,
                    value: equipmentProperty.nameId
                }
            })
            dispatch(customersSlice.actions.setEquipmentPropertiesPredefined(equipmentPropertiesPredefined))
        }
    } catch (err) {
        console.log(err)
    }
}

export const fetchDeleteCustomerEquipmentProperty = (id: string, propertyId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const deleteCustomerEquipmentProperty = call({
            method: 'DELETE',
            url: `/customers/equipment-properties/${propertyId}`
        }, dispatch, true)
        const res = await deleteCustomerEquipmentProperty()

        if (res.status >= 200 && res.status < 300) {
            dispatch(fetchCustomerEquipmentProperties(id))
            toast.success(message)
        }
    } catch (err) {
        console.log(err)
    }
}