import React, { ReactElement } from 'react'
import { DateCellProperties } from '../../../types/component-properties/date-cell-properties'
import { DateTime } from 'luxon'
import { State } from '../../../types/states/state'
import { connect } from 'react-redux'
import { Locale } from '../../../types/locale'

const DateCell = (props: DateCellProperties): ReactElement => {
    return <React.Fragment>{props.date !== null && props.date !== undefined ? DateTime.fromMillis(props.date).toLocaleString({ ...DateTime.DATE_SHORT, locale: props.locale, numberingSystem: undefined  }) : ''}</React.Fragment>
}

const mapStateToProps = (state: State): { locale: Locale }  => ({
    locale: state.ui.locale
})

export default connect(mapStateToProps)(DateCell)

