import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CustomButton from './custom/custom-button'
import { intl } from '../intl'
import { FooterProperties } from '../types/component-properties/footer-properties'
import { Customer } from '../types/entity/customer'
import { Equipment } from '../types/entity/equipment'
import { State } from '../types/states/state'
import { RequestQueryParams } from '../types/request-query-params'
import { connect } from 'react-redux'
import { BaselineDetail } from '../types/entity/baseline-detail'

const classes = {
    'container': css`
        top: calc(100vh - var(--breadcrumb-height));
        height: var(--breadcrumb-height);
    `
}

const Footer = (props: FooterProperties): ReactElement => {
    function handleDelete(): void {
        if (props.item && (props.item as Customer | Equipment | BaselineDetail).id) {
            props.handleDelete((props.item as Customer | Equipment | BaselineDetail)?.id ?? '', props.queryParams, true)
        }
    }

    return (
        <React.Fragment>
            <div css={[classes.container, props.cssStlyes]} className={`flex items-center justify-between py-6 w-full bg-white py-0 px-8 bottom-0 sticky shadow-footer ${props.className}`}>
                {
                    !props.hideDelete && props.canDelete &&
                    <CustomButton
                        className="font-bold px-5 bg-red text-white"
                        label={intl.formatMessage({ id: 'footer.deleteButton.label' })} 
                        hidden={!props.editing}
                        onClick={handleDelete} />
                }
                {
                    props.canEdit &&
                    <React.Fragment>
                        <CustomButton
                            className="font-bold px-5 ml-auto bg-lightblue text-primary"
                            label={intl.formatMessage({ id: 'footer.cancelButton.label' })}
                            onClick={props.handleCancel} />
                        {
                            !props.hideSave &&
                            <CustomButton
                                className="font-bold px-5 ml-2 bg-primary text-white"
                                label={intl.formatMessage({ id: props.editing ? 'footer.submitButton.label.save' : 'footer.submitButton.label.create' })}
                                onClick={props.handleSave} />
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment >
    )
}

Footer.defaultProps = {
    item: null,
    cssStlyes: [],
    className: '',
    editing: true,
    hideDelete: false,
    hideSave: false,
    canDelete: true,
    canEdit: true,
    handleDelete: (): void => undefined,
    handleCancel: (): void => undefined,
    handleSave: (): void => undefined
}

const mapStateToProps = (state: State): { queryParams: RequestQueryParams }  => ({
    queryParams: state.ui.sectionQueryParams
})

export default connect(mapStateToProps)(Footer)