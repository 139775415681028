import React, { ReactElement, useState, PropsWithChildren, useEffect, useMemo, ChangeEvent, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import CustomTable from '../../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import { ActionType } from '../../../types/action-type'
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/column-edit.svg'
import { ReactComponent as AnalysisIcon } from '../../../assets/icons/column-analysis.svg'
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/column-duplicate.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/column-delete.svg'
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg'
import { DashboardBaselineListProperties } from '../../../types/component-properties/dashboard-baseline-list-properties'
import CustomButton from '../../custom/custom-button'
import { intl } from '../../../intl'
import { State } from '../../../types/states/state'
import { connect, useDispatch } from 'react-redux'
import { Locale } from '../../../types/locale'
import { push } from 'connected-react-router'
import { LocationDescriptorObject } from 'history'
import { LoggedUser } from '../../../types/entity/logged-user'
import { addPath } from '../../../reducers/ui'
import MultiRow from '../../custom/custom-table/multi-row'
import { TableBaseline } from '../../../types/entity/table-baseline'
import { TableAction } from '../../../types/table-action'
import { fetchDuplicateBaseline, fetchEditBaseline } from '../../../reducers/equipment-detail'
import { EditBaseline } from '../../../types/edit-baseline'
import { Equipment } from '../../../types/entity/equipment'

const classes = {
    'table': css`
        .ReactTable {
            .rt-tr-group {
                border: none;
            }

            .rt-td {
                padding: 0.35rem 0.25rem;
            }
        }
    `
}

const DashboardBaselineList = ({ handleEditBaseline, handleDeleteBaseline, ...props }: DashboardBaselineListProperties): ReactElement => {
    const dispatch = useDispatch()

    const [editRow, setEditRow] = useState<{ name: string }>({ name: '' })
    const [editIndex, setEditIndex] = useState(-1)
    const [editingRow, setEditingRow] = useState('-1')
    const [name, setName] = useState('')
    const [actions, setActions] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Definition', Icon: AnalysisIcon },
        { type: 'Duplicate', Icon: DuplicateIcon },
        { type: 'Delete', Icon: DeleteIcon }
    ])
    const [actionsEditing] = useState<ActionType[]>([
        { type: 'Save', Icon: SaveIcon },
        { type: 'Edit', Icon: EditIcon },
        { type: 'Definition', Icon: AnalysisIcon },
        { type: 'Duplicate', Icon: DuplicateIcon },
        { type: 'Cancel', Icon: CancelIcon }
    ])

    const handleBaselineAction = useCallback((action: TableAction, index: number): void => {
        switch(action.type) {
            case 'Save':
                const editBaseline: EditBaseline = {
                    name,
                    active: (action.row as TableBaseline).active[index]
                }
                const id = (action.row as TableBaseline).id[index]

                Promise.all([dispatch(fetchEditBaseline(editBaseline, id, { equipmentId: props.equipment?.id }, intl.formatMessage({ id: 'dashboard.toast.success.baselineUpdated' })))]).then(() => {
                    setEditRow({ name: '' })
                    setEditIndex(-1)
                    setEditingRow('-1')
                    setName('')
                })

                break
            case 'Edit':
                // setTimeout(() => {
                    setEditRow({ name: (action.row as TableBaseline).name[index] })
                    setEditIndex(index)
                    setEditingRow((action.row as TableBaseline).id[index])
                    setName((action.row as TableBaseline).name[index])
                // }, 1)

                break
            case 'Definition':
                const destination = {
                    pathname: `/dashboard/equipment-detail/baseline`,
                    search: props.search + '&baselineId=' + (action.row as TableBaseline).id[index]
                }
                const path = { label: (action.row as TableBaseline).name[index], id: (action.row as TableBaseline).id[index], destination, param: null }

                dispatch(push(destination))
                dispatch(addPath(path))

                break
            case 'Duplicate':
                dispatch(fetchDuplicateBaseline((action.row as TableBaseline).id[index], { equipmentId: (action.row as TableBaseline).equipmentId[index], orderBy: props.orderBy, orderType: props.orderType }, intl.formatMessage({ id: 'dashboard.toast.success.baselineDuplicated' })))

                break
            case 'Delete':
                handleDeleteBaseline((action.row as TableBaseline).id[index], { equipmentId: (action.row as TableBaseline).equipmentId[index] }, false)

                break
            case 'Cancel':
                setEditRow({ name: '' })
                setEditIndex(-1)
                setEditingRow('-1')
                setName('')

                break
        }
    }, [dispatch, handleDeleteBaseline, props.equipment, name, props.orderBy, props.orderType, props.search])

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        // setEditRow({ name: event.target.value })
        setName(event.target.value)
    }, [])

    const setColumnName = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        return <MultiRow row={rowProps.row.original} fieldKey='name' editRow={editRow} editIndex={editIndex} editingRow={editingRow} onInputChange={onInputChange}></MultiRow>
    }, [editIndex, editingRow, editRow, onInputChange])

    const setColumnfirstAnalysisDate = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='firstAnalysisDate'></MultiRow>
    }, [])

    const setColumnFirstUsageDate = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='firstUsageDate'></MultiRow>
    }, [])

    const setColumnUsageCount = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='usageCount'></MultiRow>
    }, [])

    const setColumnLastModified = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='lastModified'></MultiRow>
    }, [])

    const setColumnActions = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        return <MultiRow row={rowProps.row.original} filterType="BASELINE" fieldKey='actions' editIndex={editIndex} editingRow={editingRow} actions={actions} actionsEditing={actionsEditing} handleBaselineAction={handleBaselineAction}></MultiRow>
    }, [editIndex, editingRow, actions, actionsEditing, handleBaselineAction])

    const setColumnActive = useCallback((rowProps: PropsWithChildren<CellProps<TableBaseline>>): ReactElement => {
        function onChange(checked: boolean, index: number): void {
            handleEditBaseline(rowProps.row.original.id[index], { name: rowProps.row.original.name[index], active: !checked })
        }
        
        return <MultiRow row={rowProps.row.original} filterType="BASELINE" fieldKey='active' onChange={onChange}></MultiRow>
    }, [handleEditBaseline])

    const baselineColumns = useMemo(() => [
        { id: 'analyser', Header: 'dashboard.baselines.list.table.header.testObject', canGroupBy: false, accessor: 'analyserName', disableSortBy: true },
        { id: 'name', Header: 'dashboard.baselines.list.table.header.name', canGroupBy: false, accessor: 'name', disableSortBy: true, Cell: setColumnName },
        { id: 'firstAnalysisDate', Header: 'dashboard.baselines.list.table.header.definedFromTo', canGroupBy: false, accessor: 'firstAnalysisDate', disableSortBy: true, Cell: setColumnfirstAnalysisDate },
        { id: 'firstUsageDate', Header: 'dashboard.baselines.list.table.header.usageFromTo', canGroupBy: false, accessor: 'firstUsageDate', disableSortBy: true, Cell: setColumnFirstUsageDate },
        { id: 'usageCount', Header: 'dashboard.baselines.list.table.header.usageCount', canGroupBy: false, accessor: 'usageCount', disableSortBy: true, Cell: setColumnUsageCount },
        { id: 'lastModified', Header: 'dashboard.baselines.list.table.header.lastModified', canGroupBy: false, accessor: 'lastModified', disableSortBy: true, Cell: setColumnLastModified },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 68, disableSortBy: true, Cell: setColumnActions },
        { id: 'active', Header: '', canGroupBy: false, accessor: 'active', width: 68, disableSortBy: true, Cell: setColumnActive }
    ], [setColumnName, setColumnfirstAnalysisDate, setColumnFirstUsageDate, setColumnUsageCount, setColumnLastModified, setColumnActions, setColumnActive])

    function handleCreateBaseline(): void {
        const destination: LocationDescriptorObject = {
            pathname: '/dashboard/equipment-detail/new-baseline',
            search: props.search
        }
        const path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newBaseline' }), id: "", destination, param: null }
        dispatch(push(destination))
        dispatch(addPath(path))
    }

    function getHiddenColumns(): string[] {
        const hiddenColumns: string[] = []
        if (!props.loggedUser?.acl.includes('BASELINES_ACTIVATE')) {
            hiddenColumns.push('active')
        }
        return hiddenColumns
    }

    useEffect(() => {
        setActions((oldValue): ActionType[] => {
            return oldValue.filter(action => {
                switch(action.type) {
                    case 'Edit':
                        return props.loggedUser?.acl.includes('BASELINES_UPDATE')
                    case 'Delete':
                        return props.loggedUser?.acl.includes('BASELINES_DELETE')
                    case 'Duplicate':
                        return props.loggedUser?.acl.includes('BASELINES_DEFINE')
                    default:
                        return true
                }
            })
        })
    }, [props.loggedUser])

    return (
        <React.Fragment>
            <div className="px-8">
                {
                    props.loggedUser?.acl.includes('BASELINES_CREATE') &&
                    <CustomButton
                        className="bg-primary text-white px-6 my-6 ml-auto"
                        label={intl.formatMessage({ id: 'dashboard.baselines.list.addButton.label' })}
                        onClick={handleCreateBaseline} />
                }

                <CustomTable
                    cssStyles={[classes.table]}
                    data={props.baselines}
                    columns={baselineColumns}
                    hiddenColumns={getHiddenColumns()}
                    showPagination={false}
                    stripedRows={true}
                    noDataMessage={intl.formatMessage({ id: 'dashboard.baselines.list.table.noDataText' })}
                    // editedRow={selectedRequirement}
                    handleSort={props.handleSort} />
            </div>
            <ReactTooltip type="dark" effect="solid" />
        </React.Fragment>
    )
}

DashboardBaselineList.propTypes = {
    handleEditBaseline: PropTypes.func.isRequired
}

const mapStateToProps = (state: State): { equipment: Equipment | null; baselines: TableBaseline[]; locale: Locale; search: string; loggedUser: LoggedUser | null }  => ({
    equipment: state.dashboard.currentEquipment,
    baselines: state.equipmentDetail.baselines,
    locale: state.ui.locale,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(DashboardBaselineList)