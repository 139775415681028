import React, { useEffect, ReactElement, useState } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './styles/app.css'
import Inner from './components/inner'
import Login from './components/login'
import { State } from './types/states/state'
import { PrivateRouteProperties } from './types/component-properties/private-route-properties'
import LoadingSpinner from './components/loading-spinner'
import { ToastContainer } from 'react-toastify'
import { IntlShape, RawIntlProvider } from 'react-intl'
import { setLocale, fetchVersion } from './reducers/ui'
import { Locale } from './types/locale'
import { setDefaultLocale } from 'react-datepicker'
import { verifyToken, getUser } from './common/api'
import { login } from './reducers/login'
import { generateIntl } from './intl'
import { getNavigatorLocale } from './common/utils'

const languages: Locale[] = ['it', 'en', 'es', 'fr', 'de']

const App = (): ReactElement => {
    const dispatch = useDispatch()

    const location = useSelector((state: State) => state.router.location)
    const loadingCounter = useSelector((state: State) => state.ui.loadingCounter)
    const locale = useSelector((state: State) => state.ui.locale)
    const user = useSelector((state: State) => state.login.user)
    const isLogged = useSelector((state: State) => state.login.isLogged)

    const [messages, setMessages] = useState<any>()
    // const [value, setValue] = useState(generateIntl({ locale: locale ?? 'en', messages: props.messages[locale ?? 'en'] }))
    const [value, setValue] = useState<IntlShape>()

    useEffect(() => {
        Promise.all(languages.map((language) => fetch(`/translations/${language}.json`))).then((responses) => {
            Promise.all(responses.map((response) => response.json())).then((res) => {
                const newMessages: any = {}
                languages.forEach((language, index) => {
                    newMessages[language] = res[index]
                })
                setMessages(newMessages)
            })
        })
    }, [])

    useEffect(() => {
        if (!user) {
            dispatch(setLocale(getNavigatorLocale()))
        }
    }, [dispatch, user])

    useEffect(() => {
        const loggedUser = getUser()
        let newLocale: Locale = 'en'

        if (verifyToken() && loggedUser) {
            dispatch(login(loggedUser))
        }

        if (loggedUser) {
            newLocale = loggedUser.language as Locale
            setDefaultLocale(loggedUser.language)
        } else {
            newLocale = getNavigatorLocale()
        }

        dispatch(setLocale(newLocale))
        dispatch(fetchVersion())
    }, [dispatch])

    useEffect(() => {
        if (messages) {
            setValue(generateIntl({ locale: locale ?? 'en', messages: messages[locale ?? 'en'] }))
        }
    }, [locale, messages])

    return (
        <React.Fragment>
            {
                value &&
                <RawIntlProvider
                    value={value}
                >
                    {
                        locale && messages[locale] &&
                        <div className="w-full h-screen overflow-x-hidden mx-auto">
                            <Router>
                                <Route exact path='/login' component={Login} />
                                <PrivateRoute path='/' component={Inner} url={location.pathname + location.search} isLogged={isLogged} />
                            </Router>

                            <LoadingSpinner hidden={loadingCounter <= 0}></LoadingSpinner>
                            <ToastContainer />
                        </div>
                    }
                </RawIntlProvider>
            }
        </React.Fragment>
    )
}

const PrivateRoute = ({ isLogged, component: Component, url, path }: PrivateRouteProperties): ReactElement => {
    return (
        <Route
            path={path}
            render={(props): JSX.Element => isLogged ? (
                <React.Fragment>
                    <Component {...props} />
                    <Redirect to={url} />
                </React.Fragment>
            ) : (
                    <Redirect to='/login' />
                )}
        ></Route>
    )
}

export default App
