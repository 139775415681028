import React, { ReactElement } from 'react'
import { StabilityTrendProperties } from '../../../types/component-properties/stability-trend-properties'
import { ReactComponent as CircleIcon } from '../../../assets/icons/circle.svg'
import { Severity } from '../../../types/severity'
import { StabilityInfo } from '../../../types/stability-info'

const StabilityTrend = (props: StabilityTrendProperties): ReactElement => {
    function getFill(severity: Severity): string {
        switch(severity) {
            case 'CRITICAL':
                return 'var(--color-red)'
            case 'ERROR':
                return 'var(--color-orange)'
            case 'INFORMATION':
                return 'var(--color-teal)'
            case 'NORMAL':
                return 'var(--color-green)'
            case 'WARNING':
                return 'var(--color-yellow)'
            default:
                return 'none'
        }
    }

    function goToSymptoms(info: StabilityInfo): void {
        props.handleGoToSymptoms(info.eventId)
    }

    return (
        <React.Fragment>
            {
                props.infos.map((info, index) => {
                    const fill = getFill(info.severityId)
                    const stroke = fill === 'none' ? 'var(--color-darkgray-100)' : 'none'
                    // return <div key={index} className={`mr-1 rounded-full w-2.5 h-2.5 first:w-2 first:h-2 last:w-3.5 last:h-3.5 ${severity.toLowerCase()}`}></div>
                    return  <CircleIcon
                                key={index}
                                className="w-2.5 h-2.5 first:w-2 first:h-2 last:w-3.5 last:h-3.5 mr-1 last:mr-0 cursor-pointer hover:opacity-50"
                                fill={fill}
                                stroke={stroke}
                                onClick={(): void => goToSymptoms(props.infos[index])} />
                }).reverse()
            }
        </React.Fragment>
    )
}

StabilityTrend.defaultProps = {
    equipmentId: "",
    infos: [],
    handleGoToSymptoms: (): void => undefined
}

export default StabilityTrend
