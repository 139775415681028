import React, { ReactElement, useEffect, useState, ChangeEvent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { deepCopy, useDebounce, useFetching } from '../../../common/utils'
import { fetchDicomAttributes, setDicomAttributes } from '../../../reducers/timeline-event-detail'
import { DashboardTimelineDicomAttributesProperties } from '../../../types/component-properties/dashboard-timeline-dicom-attributes-properties'
import { State } from '../../../types/states/state'
import CustomTable from '../../custom/custom-table/custom-table'
import queryString from 'query-string'
import { DicomAttributeTimeline } from '../../../types/dicom-attribute-timeline'
import CustomInput from '../../custom/custom-input'
import { intl } from '../../../intl'

const DashboardTimelineDicomAttributes = (props: DashboardTimelineDicomAttributesProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)
    const [inputFilter, setInputFilter] = useState<string | undefined>('')
    const debouncedFilter = useDebounce(inputFilter, 'dashboard', 600)

    const [allDicomAttributes, setAllDicomAttributes] = useState<DicomAttributeTimeline[]>([])
    const [filteredDicomAttributes, setFilteredDicomAttributes] = useState<DicomAttributeTimeline[]>([])
    const [columns] = useState([
        { id: 'tag', Header: 'dashboard.dicom.table.header.code', canGroupBy: false, accessor: 'tag', disableSortBy: true },
        { id: 'name', Header: 'dashboard.dicom.table.header.name', canGroupBy: false, accessor: 'name', disableSortBy: true },
        { id: 'value', Header: 'dashboard.dicom.table.header.value', canGroupBy: false, accessor: 'value', disableSortBy: true }
    ])

    function inputChanged(event: ChangeEvent<HTMLInputElement>): void {
        setInputFilter(event.target.value)
    }

    useFetching(fetchDicomAttributes, 'dashboard', [], queryParams.id, props.url)

    useEffect(() => {
        let newDicomAttributes: DicomAttributeTimeline[] = deepCopy(allDicomAttributes)
        newDicomAttributes = newDicomAttributes.filter((dicomAttribute) => {
            return debouncedFilter.value && (debouncedFilter.value === '' || dicomAttribute.name?.toLowerCase().includes(debouncedFilter.value.toLowerCase()) || dicomAttribute.tag?.toLowerCase().includes(debouncedFilter.value.toLowerCase()) || dicomAttribute.value?.toLowerCase().includes(debouncedFilter.value.toLowerCase()))
        })
        setFilteredDicomAttributes(debouncedFilter.value === '' ? allDicomAttributes : newDicomAttributes)
    }, [debouncedFilter, allDicomAttributes])

    useEffect(() => {
        if (props.dicomAttributes) {
            setAllDicomAttributes(props.dicomAttributes)
        } else {
            setAllDicomAttributes([])
        }
    }, [props.dicomAttributes])

    useEffect(() => {
        return (): void => {
            dispatch(setDicomAttributes(null))
        }
    }, [dispatch])

    return (
        <div className="pt-4 px-8">
            <CustomInput className="w-84" value={inputFilter} placeholder={`${intl.formatMessage({ id: 'dashboard.dicom.input.label' })}...`} onChange={inputChanged} />
            <CustomTable
                data={filteredDicomAttributes}
                columns={columns}
                showPagination={false}
                stripedRows={true}></CustomTable>
        </div>
    )
}

const mapStateToProps = (state: State): { dicomAttributes: DicomAttributeTimeline[] | null; search: string }  => ({
    dicomAttributes: state.timelineEventDetail.dicomAttributes,
    search: state.router.location.search
})

export default connect(mapStateToProps)(DashboardTimelineDicomAttributes)