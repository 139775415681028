import React, { ReactElement } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SeverityCellProperties } from '../../../types/component-properties/severity-cell-properties'

const classes = {
    'dot': css`
        border-radius: 50%;
        background: transparent;
        width: 0.5625rem;
        height: 0.5625rem;
    `
}

const SeverityCell = (props: SeverityCellProperties): ReactElement => {
    return (
        <React.Fragment>
            <div className="flex items-center">
                <div css={[classes.dot]} className={props.severity?.toLowerCase() + (props.square ? ' rounded-none' : '')}></div>
                {
                    props.label !== '' &&
                    <span className="ml-2">{props.label}</span>
                }
            </div>
        </React.Fragment>
    )
}

SeverityCell.defaultProps = {
    label: '',
    square: false
}

export default SeverityCell
