import React, { useEffect, ReactElement, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import DashboardTimeline from './timeline/dashboard-timeline'
import DashboardAnalysis from './dashboard-analysis'
import DashboardProperties from './dashboard-properties'
import { useDispatch, connect, useSelector } from 'react-redux'
import CustomTabs from '../custom/custom-tabs'
import { intl } from '../../intl'
import { Tab, TabList, TabPanel } from 'react-tabs'
import { DashboardEquipmentsDetailProperties } from '../../types/component-properties/dashboard-equipments-detail-properties'
import { toggleSidemenu, splicePath } from '../../reducers/ui'
import { State } from '../../types/states/state'
import DashboardBaseline from './baseline/dashboard-baseline'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { LocationDescriptorObject } from 'history'
import { updatePathDestination } from '../../reducers/ui'
import { Equipment } from '../../types/entity/equipment'
import { LoggedUser } from '../../types/entity/logged-user'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import classNames from 'classnames'
import DashboardSPC from './dashboard-spc'
import DashboardProtocols from './protocols/dashboard-protocols'

const classes = {
    'container': css`
        .react-tabs__tab-list {
            padding: 0 2rem;
        }

        .react-tabs__tab-panel--selected {
            &.baseline-tab {
                height: calc(100vh - 3rem - var(--breadcrumb-height));
            }
        }
    `
}

const DashboardEquipmentsDetail = (props: DashboardEquipmentsDetailProperties): ReactElement => {
    const dispatch = useDispatch()
    const queryParams = queryString.parse(props.search)

    const [activeTab, setActiveTab] = useState(0)

    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)

    function changeTab(tab: number): void {
        const destination: LocationDescriptorObject = {
            pathname: `/dashboard/equipment-detail`,
            search: `?tab=${tab}&equipmentId=${props.equipment?.id ?? ''}&page=${timelineFilter.page}`
        }
        const index = props.loggedUser?.role === 'ADMINISTRATOR' ? 2 : 1
        setActiveTab(tab)
        dispatch(push(destination))
        dispatch(updatePathDestination({ index, destination }))
        if (props.paths.length > index + 1) {
            dispatch(splicePath(props.paths.length - 1))
        }
    }

    useEffect(() => {
        const tab = parseInt(queryParams.tab as string, 10)

        dispatch(toggleSidemenu(false))
        setActiveTab(tab)
    }, [dispatch, queryParams])

    return (
        <React.Fragment>
            <div css={[classes.container]} className="h-full">
                <CustomTabs selectedIndex={activeTab} onSelect={changeTab}>
                    <TabList>
                        {
                            props.loggedUser?.acl.includes('LOGEVENTS_LIST') &&
                            <Tab>{intl.formatMessage({ id: 'dashboard.equipmentDetail.tabHeader.timeline' })}</Tab>
                        }
                        {
                            true &&
                            <Tab>
                                {intl.formatMessage({ id: 'dashboard.equipmentDetail.tabHeader.spc' })}
                            </Tab>
                        }
                        {
                            props.loggedUser?.acl.includes('ANALYSIS_READ') &&
                            <Tab>{intl.formatMessage({ id: 'dashboard.equipmentDetail.tabHeader.graphs' })}</Tab>
                        }
                        {
                            props.loggedUser?.acl.includes('BASELINES_LIST') &&
                            <Tab>{intl.formatMessage({ id: 'dashboard.equipmentDetail.tabHeader.baseline' })}</Tab>
                        }
                        {
                            props.loggedUser?.acl.includes('PROTOCOL_INSTANCES_LIST') &&
                            <Tab>{intl.formatMessage({ id: 'dashboard.equipmentDetail.tabHeader.protocols' })}</Tab>
                        }
                        {
                            props.loggedUser?.acl.includes('EQUIPMENT_INFO_READ') &&
                            <Tab>{intl.formatMessage({ id: 'dashboard.equipmentDetail.tabHeader.properties' })}</Tab>
                        }
                    </TabList>

                    {
                        props.loggedUser?.acl.includes('LOGEVENTS_LIST') &&
                        <TabPanel className={classNames({
                            'relative': true,
                            'h-full': activeTab === 0
                        })}>
                            <DashboardTimeline
                                timelineNavigate={props.timelineNavigate}
                                handleTimelineFilter={props.handleTimelineFilter}
                                handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                                handleCreateTimelineEvent={props.handleCreateTimelineEvent}
                                handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></DashboardTimeline>
                        </TabPanel>
                    }
                    {
                        true &&
                        <TabPanel>
                            <DashboardSPC></DashboardSPC>
                        </TabPanel>
                    }
                    {
                        props.loggedUser?.acl.includes('ANALYSIS_READ') &&
                        <TabPanel>
                            <DashboardAnalysis
                                handleCreateAnalysisChart={props.handleCreateAnalysisChart}
                                handleDeleteAnalysisChart={props.handleDeleteAnalysisChart}></DashboardAnalysis>
                        </TabPanel>
                    }
                    {
                        props.loggedUser?.acl.includes('BASELINES_LIST') &&
                        <TabPanel className="flex flex-col baseline-tab">
                            <DashboardBaseline
                                orderBy={props.orderBy}
                                orderType={props.orderType}
                                setOrderBy={props.setOrderBy}
                                setOrderType={props.setOrderType}
                                handleEditBaseline={props.handleEditBaseline}
                                handleCreateBaseline={props.handleCreateBaseline}
                                handleUpdateBaseline={props.handleUpdateBaseline}
                                handleDeleteBaseline={props.handleDeleteBaseline}></DashboardBaseline>
                        </TabPanel>
                    }
                    {
                        props.loggedUser?.acl.includes('PROTOCOL_INSTANCES_LIST') &&
                        <TabPanel className="flex flex-col baseline-tab">
                            <DashboardProtocols
                                orderBy={props.orderBy}
                                orderType={props.orderType}
                                setOrderBy={props.setOrderBy}
                                setOrderType={props.setOrderType}
                                handleCreateProtocol={props.handleCreateProtocol}
                                handleEditProtocol={props.handleEditProtocol}
                                handleDeleteProtocol={props.handleDeleteProtocol}
                                handleFilter={props.handleFilter}></DashboardProtocols>
                        </TabPanel>
                    }
                    {
                        props.loggedUser?.acl.includes('EQUIPMENT_INFO_READ') &&
                        <TabPanel>
                            <DashboardProperties></DashboardProperties>
                        </TabPanel>
                    }
                </CustomTabs>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; paths: BreadcrumbPath[]; search: string; loggedUser: LoggedUser | null } => ({
    equipment: state.dashboard.currentEquipment,
    paths: state.ui.breadcrumbPaths,
    search: state.router.location.search,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(DashboardEquipmentsDetail)
