import React, { ReactElement, ChangeEvent, useEffect, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SidemenuProperties } from '../../types/component-properties/sidemenu-properties'
import { Customer } from '../../types/entity/customer'
import CustomInput from '../custom/custom-input'
import { intl } from '../../intl'
import { ReactComponent as CustomersMenuIcon } from '../../assets/icons/customers-menu.svg'
import { connect } from 'react-redux'
import { State } from '../../types/states/state'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { SectionName } from '../../types/section-name'
import { useDebounce } from '../../common/utils'
import { LoggedUser } from '../../types/entity/logged-user'

import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import classNames from 'classnames'

const classes = {
    'not-allowed': css`
        circle {
            fill: var(--color-gray-100) !important;
        }

        &:hover {
            cursor: not-allowed !important;
            opacity: 1 !important;
        }
    `
}

const CustomersMenu = ({ handleInputChange, ...props }: SidemenuProperties<Customer> & { currentCustomer: Customer | null }): ReactElement => {
    const [inputFilter, setInputFilter] = useState<string | null>(null)
    const debouncedFilter = useDebounce(inputFilter, props.currentSection, 600)

    function handleCreateItem(): void {
        if (!(props.paths.length && props.paths[props.paths.length - 1].destination.pathname === '/organisations/new')) {
            props.handleCreateItem()
        }
    }

    function handleFilter(customer: Customer): void {
        props.handleFilter({ label: customer.name, id: customer.id, destination: { pathname: `/organisations/organisation`, search: `?id=${customer.id}` }, param: null })
    }

    function inputChanged(event: ChangeEvent<HTMLInputElement>): void {
        setInputFilter(event.target.value === '' ? null : event.target.value)
    }

    useEffect(() => {
        handleInputChange(debouncedFilter.value)
    }, [handleInputChange, debouncedFilter])

    return (
        <React.Fragment>
            <div className="flex flex-col h-full w-full overflow-hidden p-8 pb-0 text-darkgray">
                <div className='flex justify-between items-center font-500 mb-6 mt-0'>
                    <div className="flex items-center">
                        {/* <img src={CustomersMenuIcon} className="w-4 h-4 mr-2" alt="" /> */}
                        <CustomersMenuIcon className="w-4 h-4 mr-2" />
                        {intl.formatMessage({ id: 'organisations.sidemenu.title' })}
                    </div>

                    {
                        props.loggedUser?.acl.includes('CUSTOMERS_CREATE') &&
                        <AddIcon
                            css={[props.paths.length && props.paths[props.paths.length - 1].destination.pathname === '/organisations/new' ? classes["not-allowed"] : undefined]}
                            className="cursor-pointer hover:opacity-50"
                            data-tip={intl.formatMessage({ id: 'tooltip.add' })}
                            data-for="sidemenu-tooltip" 
                            onClick={handleCreateItem} />
                    }
                </div>
                <CustomInput 
                    className="mb-6 h-10 shadow-menu-input"
                    placeholder={`${intl.formatMessage({ id: 'organisations.sidemenu.input.placeholder' })}...`}
                    onChange={inputChanged} />

                {
                    props.loggedUser?.role === 'ADMINISTRATOR' &&
                    <div className="font-500">
                        {
                            props.data.length > 0 &&
                            Array.from(new Set(props.data.map((customer) => customer.name.charAt(0)))).map((letter, letterIndex) => {
                                return (
                                    <div className="flex flex-col mt-4 text-sm" key={`first-${letter}-${letterIndex}`}>
                                        <span className="text-primary font-bold">{letter.toUpperCase()}</span>
                                        {
                                            props.data.filter((customer) => customer.name.charAt(0) === letter).map((customer, customerIndex) => {
                                                return (
                                                    <div
                                                        key={`${letter}-${customerIndex}`}
                                                        className={classNames({
                                                            'mt-2 cursor-pointer hover:opacity-50': true,
                                                            'font-bold': props.currentCustomer?.name === customer.name,
                                                            'font-500': props.currentCustomer?.name !== customer.name
                                                        })}
                                                        onClick={(): void => handleFilter(customer)}
                                                    >
                                                        {customer.name}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { data: Customer[]; currentCustomer: Customer | null; paths: BreadcrumbPath[]; currentSection: SectionName; loggedUser: LoggedUser | null }  => ({
    data: state.customers.customers,
    currentCustomer: state.customers.currentCustomer,
    paths: state.ui.breadcrumbPaths,
    currentSection: state.ui.currentSection,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(CustomersMenu)
