import React, { ReactElement } from 'react'
import { OptionTypeBase } from 'react-select'

const SortOption = (data: OptionTypeBase): ReactElement => {
    return (
        <React.Fragment>
            <div className="flex items-center">
                <div className="w-3 h-3 rounded-full mr-2 dot"></div>
                <div>{data.label}</div>
            </div>
        </React.Fragment>
    )
}

export default SortOption