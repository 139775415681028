import React, { useState, ReactElement, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useDispatch, connect } from 'react-redux'
import useForm from 'react-hook-form'
import { LoginDetail } from '../types/login-detail'
import CustomButton from './custom/custom-button'
import CustomInput from './custom/custom-input'
import { fetchLogin } from '../reducers/login'
import { setCurrentSection } from '../reducers/ui'
import CustomCheckbox from './custom/custom-checkbox'
import * as yup from 'yup'
import LoginPattern from '../assets/images/cyberqual-pattern.png'
import { State } from '../types/states/state'
import { LoginProperties } from '../types/component-properties/login-properties'
import { AppVersion } from '../types/app-version'
import { intl } from '../intl'
import { Locale } from '../types/locale'
import { resetStorage, getUser } from '../common/api'
import { push } from 'connected-react-router'

const loginSchema = yup.object().shape({
    username: yup.string().required('login.form.input.errorMessage.required.username'),
    password: yup.string().required('login.form.input.errorMessage.required.password')
})

const classes = {
    'container': css`
        background-image: url(${LoginPattern});
    `
}

const Login = (props: LoginProperties): ReactElement => {
    const dispatch = useDispatch()

    const [globalVersion, setGlobalVersion] = useState('')
    const [user] = useState({ username: '', password: '' })
    const [terms, setTerms] = useState(true)
    const { register, handleSubmit, errors } = useForm({
        validationSchema: loginSchema
    })

    function submit(loginInfo: Record<string, any>): void {
        dispatch(fetchLogin(loginInfo as LoginDetail))
        // dispatch({ type: 'LOGIN_SUCCESS', payload: { username: 'test' } })
    }

    function handleChangeTerms(value: boolean): void {
        setTerms(value)
    }

    function getLanguage(): string {
        let lang = ''

        switch(props.locale) {
            case 'it':
                lang = 'IT'

                break
            default:
                lang = 'EN'

                break
        }

        return lang
    }

    useEffect(() => {
        const user = getUser()
        fetch('global-version.json').then(res => res.json()).then(res => {
            setGlobalVersion(res.version)
        })

        if (user) {
            dispatch(push(`/dashboard`))
        } else {
            dispatch(setCurrentSection('none'))
            resetStorage()
            localStorage.setItem('currentSection', 'none')
        }
    }, [dispatch])

    return (
        <React.Fragment>
            <div css={[classes.container]} className="flex flex-col justify-center items-center w-full h-full">
                <div key={user.username} className="min-w-100 max-w-100 max-h-150 bg-white rounded-md shadow-login" >
                    <form className='flex flex-col justify-between py-8 px-12' onSubmit={handleSubmit(submit)}>
                        <h2 className='font-bold text-black text-center mt-0 mb-2'>{intl.formatMessage({ id: 'login.title' })}</h2>

                        <p className="mt-4 text-darkgray">{intl.formatMessage({ id: 'login.description' })}.</p>

                        <CustomInput 
                            className='w-full mt-4'
                            name="username"
                            label={intl.formatMessage({ id: 'login.form.input.label.username' })}
                            placeholder={`${intl.formatMessage({ id: 'login.form.input.placeholder.username' })}...`}
                            errorMessage={errors.username ? intl.formatMessage({ id: errors.username.message }) : undefined}
                            required={true}
                            defaultValue={user.username}
                            formRef={register({ required: true })} />
                        <CustomInput
                            type="password"
                            className='w-full'
                            name="password"
                            label={intl.formatMessage({ id: 'login.form.input.label.password' })}
                            placeholder={`${intl.formatMessage({ id: 'login.form.input.placeholder.password' })}...`}
                            errorMessage={errors.password ? intl.formatMessage({ id: errors.password.message }) : undefined}
                            required={true}
                            defaultValue={user.password}
                            formRef={register({ required: true })} />

                        {/* <a className="mb-4" href="/">Need help?</a> */}

                        <div className="flex items-center">
                            <CustomCheckbox
                                className="pl-0 w-6"
                                checked={terms}
                                onClick={handleChangeTerms} />
                            <a className="text-darkgray-100 text-sm no-underline" href={`http://cyberqual.it/autopia/cloud/TermsOfService${getLanguage()}.html`} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: 'login.tos' })}</a>
                        </div>

                        <CustomButton
                            className='bg-primary text-white mt-6'
                            label={intl.formatMessage({ id: 'login.form.submitButton.label' })}
                            disabled={!terms}
                            type='submit' />
                    </form>
                </div>

                <div className="text-white text-center bottom-12 absolute w-full">{intl.formatMessage({ id: 'login.version' })} - {globalVersion} - {intl.formatMessage({ id: 'login.copyright' })}</div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { appVersion: AppVersion | null; locale: Locale }  => ({
    appVersion: state.ui.version,
    locale: state.ui.locale
})

export default connect(mapStateToProps)(Login)