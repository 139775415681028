import React, { ReactElement, useState, OptionHTMLAttributes, useEffect, ChangeEvent } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { intl } from '../../../intl'
import CustomInput from '../../custom/custom-input'
import CustomSelect from '../../custom/custom-select/custom-select'
import CustomButton from '../../custom/custom-button'
import useForm from 'react-hook-form'
import { ValueType, OptionsType, OptionTypeBase } from 'react-select'
import CustomDatePicker from '../../custom/custom-date-picker'
import CustomCheckbox from '../../custom/custom-checkbox'
// import CustomSort from '../custom/custom-sort'
import { DashboardTimelineEventsListProperties } from '../../../types/component-properties/dashboard-timeline-events-list-properties'
import Pagination from '../../custom/custom-table/pagination'
import { TimelineEvent } from '../../../types/entity/timeline-event'
import ReactTooltip from 'react-tooltip'
import AnalysedImagedEvent from './events/analysed-image-event'
import AnalysedDocumentEvent from './events/analysed-document-event'
import MessageEvent from './events/message-event'
import StoredFiles from './events/stored-files'
import ImageEvent from './events/image-event'
import { TimelineFilter } from '../../../types/timeline-filter'
import { DateTime } from 'luxon'
import { connect, useDispatch, useSelector } from 'react-redux'
import { State } from '../../../types/states/state'
import { setTimelineFilterLimit, setTimelineFilterPage } from '../../../reducers/equipment-detail'
import { BreadcrumbPath } from '../../../types/entity/breadcrumb-path'
import { TimelineEventType } from '../../../types/timeline-event-type'
import { Locale } from '../../../types/locale'
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg'
import { LoggedUser } from '../../../types/entity/logged-user'
import CustomSort from '../../custom/custom-sort'
import { deepCopy } from '../../../common/utils'
import { push } from 'connected-react-router'

const classes = {
    'select-event': css`
        .select__control {
            background: var(--color-primary);
            border: none;
        }

        .select__placeholder, .select__single-value {
            color: var(--color-white);
        }
    `
}

const DashboardTimelineEventsList = (props: DashboardTimelineEventsListProperties): ReactElement => {
    const dispatch = useDispatch()
    const [limit, setLimit] = useState<number>(props.timelineFilter.limit)
    const [page, setPage] = useState<number>(props.timelineFilter.page)
    const [showHiddenElements, setShowHiddenElements] = useState<boolean>(props.timelineFilter.showHidden)
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [types, setTypes] = useState(props.timelineFilter.type)
    const [name, setName] = useState('')
    const [fromDate, setFromDate] = useState(props.timelineFilter.from)
    const [toDate, setToDate] = useState(props.timelineFilter.to)
    const [events] = useState<OptionsType<OptionTypeBase>>([
        { label: 'select.options.analysedImage', value: 'ANALYSED_IMAGE' },
        // { label: 'Analysed Document', value: 'ANALYSED_DOCUMENT' },
        { label: 'select.options.image', value: 'STORED_IMAGE' },
        { label: 'select.options.files', value: 'STORED_FILES' },
        { label: 'select.options.message', value: 'MESSAGE' }
    ])
    const [sortOptions] = useState<OptionHTMLAttributes<HTMLOptionElement>[]>([
        { label: 'dashboard.timeline.sort.options.eventDate', value: 'eventDate' },
        { label: 'dashboard.timeline.sort.options.entryDate', value: 'entryDate' },
        { label: 'dashboard.timeline.sort.options.author', value: 'author' },
        { label: 'dashboard.timeline.sort.options.title', value: 'title' }
    ])

    const location = useSelector((state: State) => state.router.location)
    const readOnly = useSelector((state: State) => state.equipmentDetail.timelineReadOnly)

    const { register, unregister, handleSubmit, setValue, getValues } = useForm({
        defaultValues: props.timelineFilter
    })

    function canPreviousPage(page: number): boolean {
        return page > 0
    }

    function canNextPage(page: number): boolean {
        return (page + 1) < Math.ceil(props.timelineEventsSize / limit)
    }

    function toggleFilter(): void {
        setShowFilters(!showFilters)
    }

    function handleUpdateType(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        props.setSelectedType((value as OptionHTMLAttributes<HTMLOptionElement>).value as TimelineEventType)
    }

    function handleUpdateLimit(value: number): void {
        setValue('limit', value)
        setLimit(value)
        dispatch(setTimelineFilterLimit(value))
    }

    function handleUpdatePage(value: number): void {
        setValue('page', value - 1)
        setPage(value - 1)
        dispatch(setTimelineFilterPage(value - 1))
        let search = location.search
        search = search.split("&page=")[0] + "&page=" + (value - 1)
        dispatch(push({
            pathname: location.pathname,
            search
        }))
    }

    function handleUpdateShowHidden(checked: boolean): void {
        setValue('showHidden', checked)
        setShowHiddenElements(checked)
        props.handleTimelineFilter(getValues() as TimelineFilter)
    }

    function handleUpdateTypes(value: ValueType<OptionHTMLAttributes<HTMLOptionElement>> | ValueType<OptionHTMLAttributes<HTMLOptionElement>>[]): void {
        const types = (value as OptionHTMLAttributes<HTMLOptionElement>[]).map((option: OptionHTMLAttributes<HTMLOptionElement>) => option ? option.value as string : '')
        setValue('type', types)
        setTypes(types)
    }

    function handleUpdateName(event: ChangeEvent<HTMLInputElement>): void {
        setValue('name', event.target.value)
        setName(event.target.value)
    }

    function handleUpdateFromDate(value: Date): void {
        setFromDate(DateTime.fromJSDate(value).toMillis())
        setValue('from', DateTime.fromJSDate(value).toMillis())
    }

    function handleUpdateToDate(value: Date): void {
        setToDate(DateTime.fromJSDate(value).toMillis())
        setValue('to', DateTime.fromJSDate(value).toMillis())
    }

    function handleDeleteFilter(type: string, index: number | null = null): void {
        const newTimelineFilter: TimelineFilter = deepCopy(props.timelineFilter)

        switch (type) {
            case 'type':
                if (newTimelineFilter.type && index !== null) {
                    newTimelineFilter.type.splice(index, 1)

                    newTimelineFilter.type = newTimelineFilter.type.length === 0 ? null : newTimelineFilter.type
                }
                setValue('type', newTimelineFilter.type)
                setTypes(newTimelineFilter.type)

                break
            case 'name':
                newTimelineFilter.name = null
                setValue('name', newTimelineFilter.name)
                setName('')

                break
            case 'from':
                newTimelineFilter.from = null
                setValue('from', newTimelineFilter.from)
                setFromDate(null)

                break
            case 'to':
                newTimelineFilter.to = null
                setValue('to', newTimelineFilter.to)
                setToDate(null)

                break
        }

        props.handleTimelineFilter(newTimelineFilter)
    }

    function handleSort(orderBy: string | undefined, orderType: string | undefined): void {
        const newTimelineFilter: TimelineFilter = deepCopy(props.timelineFilter)
        newTimelineFilter.orderBy = orderBy
        newTimelineFilter.orderType = orderType
        props.handleTimelineFilter(newTimelineFilter)
    }

    useEffect(() => {
        setLimit(props.timelineFilter.limit)
        setPage(props.timelineFilter.page)
    }, [props.timelineFilter])

    useEffect(() => {
        register({ name: 'limit' })
        register({ name: 'page' })
        register({ name: 'showHidden' })
        register({ name: 'name' })

        setValue('limit', limit)
        setValue('showHidden', false)
        setValue('page', page)
        setValue('name', null)

        return (): void => {
            unregister('limit')
            unregister('showHidden')
            unregister('page')
            unregister('name')
        }
    }, [register, unregister, setValue, limit, page])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return (
        <React.Fragment>
            {/* <DashboardTimelineEventEditor
                visible={!!selectedType}
                type={selectedType as TimelineEventType}
                handleCancel={(): void => setSelectedType(undefined)}
                handleCreateTimelineEvent={props.handleCreateTimelineEvent} /> */}
            <div className="h-full min-h-150">
                <div className='flex justify-between items-center mb-4'>
                    <div className="flex items-center">
                        <CustomSort
                            className="min-w-40 mr-4"
                            options={sortOptions}
                            orderBy={props.timelineFilter.orderBy}
                            orderType={props.timelineFilter.orderType}
                            label={intl.formatMessage({ id: 'dashboard.timeline.sort.label' })}
                            onChange={handleSort} />
                        <CustomCheckbox
                            checked={showHiddenElements}
                            label={intl.formatMessage({ id: 'dashboard.timeline.checkbox.label' })}
                            onClick={handleUpdateShowHidden} />
                    </div>

                    <div className="flex items-center">
                        <CustomButton
                            className="min-w-40 bg-lightblue text-primary"
                            label={`${intl.formatMessage({ id: 'dashboard.timeline.addFilterButton.label' })}`}
                            onClick={(): void => setShowFilters(!showFilters)} />
                        {
                            props.loggedUser?.acl.includes('LOGEVENTS_CREATE') && !readOnly &&
                            <CustomSelect
                                cssStyles={[classes["select-event"]]}
                                className="min-w-40 ml-4"
                                options={events}
                                hideIcon={true}
                                type="event"
                                noValue={true}
                                translate={true}
                                placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.select.label' })}`}
                                onChange={handleUpdateType} />
                        }
                    </div>
                </div>
                <div key={'timeline-filter-form'} hidden={!showFilters}>
                    <form onSubmit={handleSubmit((event): void => props.handleTimelineFilter(event as TimelineFilter))} autoComplete="off">
                        <div className="flex items-center relative p-4 min-h-18 bg-background-first">
                            <div className="flex justify-between flex-grow">
                                <div className='flex items-center'>
                                    <CustomSelect
                                        options={events}
                                        className="min-w-33 max-w-45 w-full"
                                        name="type"
                                        value={types ? types : undefined}
                                        hideErrors={true}
                                        placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.filter.form.select.placeholder.type' })}...`}
                                        selectRef={register({ name: `type` })}
                                        isMulti={true}
                                        translate={true}
                                        onChange={handleUpdateTypes} />
                                    <CustomInput
                                        className="min-w-33 min-w-45 ml-4"
                                        value={name as string}
                                        hideErrors={true}
                                        placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.filter.form.input.placeholder.name' })}...`}
                                        onChange={handleUpdateName} />
                                    <CustomDatePicker
                                        className='min-w-33 min-w-45 ml-4'
                                        name="from"
                                        selected={fromDate ? DateTime.fromMillis(fromDate as number).toJSDate() : undefined}
                                        hideErrors={true}
                                        placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.filter.form.datePicker.placeholder.from' })}...`}
                                        datePickerRef={register({ name: `from` })}
                                        onChange={handleUpdateFromDate} />
                                    <CustomDatePicker
                                        className='min-w-33 min-w-45 ml-4'
                                        name="to"
                                        selected={toDate ? DateTime.fromMillis(toDate as number).toJSDate() : undefined}
                                        hideErrors={true}
                                        placeholder={`${intl.formatMessage({ id: 'dashboard.timeline.filter.form.datePicker.placeholder.to' })}...`}
                                        datePickerRef={register({ name: `to` })}
                                        onChange={handleUpdateToDate} />
                                </div>

                                <CustomButton
                                    className="bg-primary text-white min-w-30 ml-4"
                                    label={intl.formatMessage({ id: 'dashboard.timeline.filter.form.submitButton.label' })}
                                    type='submit'
                                    onClick={toggleFilter} />

                                <CloseCircleIcon className="w-6 h-6 absolute top-!3 right-!3 cursor-pointer hover:opacity-50" onClick={(): void => setShowFilters(false)} />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="flex items-center">
                    {
                        (props.timelineFilter.type || props.timelineFilter.name || props.timelineFilter.from || props.timelineFilter.to) &&
                        <span className="font-bold mr-4">{intl.formatMessage({ id: 'dashboard.timeline.filter.form.filteredBy' })}:</span>
                    }

                    {
                        Object.keys(props.timelineFilter).map((key, index) => {
                            if (props.timelineFilter[key as keyof TimelineFilter] !== null) {
                                let value = ''

                                switch (key) {
                                    case 'type':
                                        return props.timelineFilter && props.timelineFilter.type && (props.timelineFilter.type as string[]).map((option, index) => {
                                            value = `TYPE: ${option}`

                                            return (
                                                <div className="text-2xs text-primary border border-solid border-primary roundged-sm font-bold text-center uppercase leading-5 py-1 px-4 mx-2 relative" key={index}>
                                                    {value}
                                                    <CloseCircleIcon className="w-4 h-4 absolute top-!2 right-!2 cursor-pointer hover:opacity-50" onClick={(): void => handleDeleteFilter(key, index)} />
                                                </div>
                                            )
                                        })
                                    case 'name':
                                        value = `NAME: ${props.timelineFilter.name as string}`

                                        return (
                                            <div className="text-2xs text-primary border border-solid border-primary roundged-sm font-bold text-center uppercase leading-5 py-1 px-4 mx-2 relative" key={index}>
                                                {value}
                                                <CloseCircleIcon className="w-4 h-4 absolute top-!2 right-!2 cursor-pointer hover:opacity-50" onClick={(): void => handleDeleteFilter(key, index)} />
                                            </div>
                                        )
                                    case 'from': case 'to':
                                        value = `${key.toUpperCase()}: ${DateTime.fromMillis(props.timelineFilter[key as keyof TimelineFilter] as number).toLocaleString({ locale: props.locale })}`

                                        return (
                                            <div className="text-2xs text-primary border border-solid border-primary roundged-sm font-bold text-center uppercase leading-5 py-1 px-4 mx-2 relative" key={index}>
                                                {value}
                                                <CloseCircleIcon className="w-4 h-4 absolute top-!2 right-!2 cursor-pointer hover:opacity-50" onClick={(): void => handleDeleteFilter(key, index)} />
                                            </div>
                                        )
                                }
                            }

                            return undefined
                        })
                    }
                </div>

                <div>
                    {
                        props.timelineEvents.length ? 
                            props.timelineEvents.map(timelineEvent => {
                                switch (timelineEvent.typeId) {
                                    case 'ANALYSED_IMAGE':
                                        return <AnalysedImagedEvent
                                            {...timelineEvent}
                                            key={timelineEvent.id}
                                            timelineNavigate={props.timelineNavigate}
                                            handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                                            handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></AnalysedImagedEvent>

                                    case 'ANALYSED_DOCUMENT':
                                        return <AnalysedDocumentEvent
                                            {...timelineEvent}
                                            key={timelineEvent.id}
                                            timelineNavigate={props.timelineNavigate}
                                            handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                                            handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></AnalysedDocumentEvent>

                                    case 'STORED_IMAGE':
                                        return <ImageEvent
                                            {...timelineEvent}
                                            key={timelineEvent.id}
                                            timelineNavigate={props.timelineNavigate}
                                            handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                                            handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></ImageEvent>

                                    case 'STORED_FILES':
                                        return <StoredFiles
                                            {...timelineEvent}
                                            key={timelineEvent.id}
                                            timelineNavigate={props.timelineNavigate}
                                            handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                                            handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></StoredFiles>

                                    case 'MESSAGE':
                                        return <MessageEvent
                                            {...timelineEvent}
                                            key={timelineEvent.id}
                                            timelineNavigate={props.timelineNavigate}
                                            handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                                            handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></MessageEvent>
                                    default:
                                        return <div></div>
                                }
                            })
                            :
                            <div className="flex justify-center text-gray text-xl py-6 w-full">{intl.formatMessage({ id: 'dashboard.timeline.eventsList.noDataText' })}</div>
                    }
                </div>

                <Pagination
                    dataSize={props.timelineEventsSize}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageIndex={page}
                    pageSize={limit}
                    pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                    pageCount={Math.ceil(props.timelineEventsSize / limit)}
                    onPageChange={handleUpdatePage}
                    onPageSizeChange={handleUpdateLimit}></Pagination>
            </div>
            <ReactTooltip type="dark" effect="solid" />
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { timelineEvents: TimelineEvent[]; timelineEventsSize: number; timelineFilter: TimelineFilter; paths: BreadcrumbPath[]; locale: Locale; loggedUser: LoggedUser | null } => ({
    timelineEvents: state.equipmentDetail.timelineEvents,
    timelineEventsSize: state.equipmentDetail.timelineEventsSize,
    timelineFilter: state.equipmentDetail.timelineFilter,
    paths: state.ui.breadcrumbPaths,
    locale: state.ui.locale,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(DashboardTimelineEventsList)
