// 'disableSortBy: true' da aggiungere su tutte le colonne dove non si vuole il sort!!!

import React, { useState, ReactElement, useEffect, PropsWithChildren, useMemo, useCallback } from 'react'
import CustomTable from '../custom/custom-table/custom-table'
import { CellProps } from 'react-table'
import PropTypes from 'prop-types'
import { DashboardEquipmentsProperties } from '../../types/component-properties/dashboard-equipments-properties'
import { useDispatch, connect, useSelector } from 'react-redux'
import MultiRow from '../custom/custom-table/multi-row'
import { DashboardEquipment } from '../../types/entity/dashboard-equipment'
import { setPaths, toggleSidemenu } from '../../reducers/ui'
import { State } from '../../types/states/state'
import ActionsCell from '../custom/custom-table/actions-cell'
import { ActionType } from '../../types/action-type'

import { ReactComponent as TimelineIcon } from '../../assets/icons/column-timeline.svg'
import { ReactComponent as SPCIcon } from '../../assets/icons/column-spc.svg'
import { ReactComponent as AnalysisIcon } from '../../assets/icons/column-analysis.svg'
import { ReactComponent as BaselineIcon } from '../../assets/icons/column-baseline.svg'
import { ReactComponent as ProtocolsIcon } from '../../assets/icons/protocols.svg'
import { ReactComponent as PropertiesIcon } from '../../assets/icons/column-properties.svg'
import { ReactComponent as InformationIcon } from '../../assets/icons/column-information.svg'
import DateCell from '../custom/custom-table/date-cell'
import { intl } from '../../intl'
import { LoggedUser } from '../../types/entity/logged-user'
import { fetchTimelineEventData, setTimelineFilterPage } from '../../reducers/equipment-detail'
import { push } from 'connected-react-router'
import { BreadcrumbPath } from '../../types/entity/breadcrumb-path'
import { RequestQueryParams } from '../../types/request-query-params'
import { DateTime } from 'luxon'
import { useFetching } from '../../common/utils'
import { fetchDashboardEquipments } from '../../reducers/dashboard'

const DashboardEquipments = ({ handleAction, ...props }: DashboardEquipmentsProperties): ReactElement => {
    const dispatch = useDispatch()

    const [actions] = useState<ActionType[]>([
        { type: 'Timeline', Icon: TimelineIcon },
        { type: 'SPC', Icon: SPCIcon, className: "w-8" },
        { type: 'Graphs', Icon: AnalysisIcon },
        { type: 'Baseline', Icon: BaselineIcon },
        { type: 'Protocols', Icon: ProtocolsIcon },
        { type: 'Properties', Icon: PropertiesIcon },
        { type: 'Information', Icon: InformationIcon }
    ])
    const [filteredActions, setFilteredActions] = useState<ActionType[]>([
        { type: 'Timeline', Icon: TimelineIcon },
        { type: 'SPC', Icon: SPCIcon, className: "w-8" },
        { type: 'Graphs', Icon: AnalysisIcon },
        { type: 'Baseline', Icon: BaselineIcon },
        { type: 'Protocols', Icon: ProtocolsIcon },
        { type: 'Properties', Icon: PropertiesIcon },
        { type: 'Information', Icon: InformationIcon }
    ])

    const locale = useSelector((state: State) => state.ui.locale)
    const loggedUser = useSelector((state: State) => state.login.user)
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)
    const queryParams = useSelector((state: State) => state.ui.sectionQueryParams)

    const handleGoToSymptoms = useCallback((equipment: DashboardEquipment, eventId: string) => {
        fetchTimelineEventData(eventId).then((res) => {
            const paths: BreadcrumbPath[] = [
                {
                    label: equipment.customerName,
                    id: equipment.customerId,
                    destination: { pathname: '/dashboard' },
                    param: { property: `customerId` as keyof RequestQueryParams, value: equipment.customerId }
                },
                {
                    label: equipment.medicalCentreName,
                    id: equipment.medicalCentreId,
                    destination: { pathname: `/dashboard` },
                    param: { property: `medicalCentreId` as keyof RequestQueryParams, value: equipment.medicalCentreId }
                },
                {
                    label: equipment.equipment,
                    id: equipment.id,
                    destination: { pathname: `/dashboard/equipment-detail`, search: `?tab=0&equipmentId=${equipment.id}&page=${timelineFilter.page}` },
                    param: { property: `equipmentId` as keyof RequestQueryParams, value: equipment.id }
                },
                {
                    label: res.data.date ? `${DateTime.fromMillis(res.data.date).toLocaleString({ ...DateTime.DATETIME_SHORT_WITH_SECONDS, locale, numberingSystem: undefined  })}` : '',
                    id: "",
                    destination: { pathname: `/dashboard/timeline-detail`, search: `?tab=${res.data.typeId === "ANALYSED_DOCUMENT" ? 0 : 2}&type=${res.data.typeId}&id=${eventId}&equipmentId=${equipment.id}&redirect=true&page=${timelineFilter.page}` },
                    param: null
                }
            ]

            if (loggedUser?.role !== "ADMINISTRATOR") {
                paths.splice(0, 1)
            }

            dispatch(setPaths(paths))
            dispatch(push(paths[paths.length - 1].destination))
        })
    }, [dispatch, locale, loggedUser, timelineFilter.page])

    const columns = useMemo(() => [
        { id: 'stability', Header: 'dashboard.list.table.header.stability', canGroupBy: false, accessor: 'stabilityTrend', width: 132, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<DashboardEquipment>>): ReactElement {
            return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='stabilityTrend' handleGoToSymptoms={(eventId): void => handleGoToSymptoms(rowProps.row.original, eventId)}></MultiRow>
        } },
        { id: 'phantom', Header: 'dashboard.list.table.header.phantom', canGroupBy: false, accessor: 'name', disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<DashboardEquipment>>): ReactElement {
            return <MultiRow row={rowProps.row.original} fieldKey='name'></MultiRow>
        } },
        { id: 'lastAnalysis', Header: 'dashboard.list.table.header.lastAnalysis', canGroupBy: false, accessor: 'lastAnalysis', Cell: function setColumn(rowProps: PropsWithChildren<CellProps<DashboardEquipment>>): ReactElement {
            return <MultiRow className="absolute top-1 bottom-1" row={rowProps.row.original} fieldKey='lastAnalysis'></MultiRow>
        } },
        { id: 'equipment', Header: 'dashboard.list.table.header.equipment', canGroupBy: false, accessor: 'equipment' },
        { id: 'medicalCentreName', Header: 'dashboard.list.table.header.medicalCentre', canGroupBy: false, accessor: 'medicalCentreName' },
        { id: 'customerName', Header: 'dashboard.list.table.header.organisation', canGroupBy: false, accessor: 'customerName' },
        { id: 'lastEvent', Header: 'dashboard.list.table.header.lastEvent', canGroupBy: false, accessor: 'lastEvent', Cell: function setColumn(rowProps: PropsWithChildren<CellProps<DashboardEquipment>>): ReactElement {
            return <DateCell date={rowProps.row.original.lastEvent}></DateCell>
        } },
        { id: 'actions', Header: '', canGroupBy: false, accessor: '', width: 100, disableSortBy: true, Cell: function setColumn(rowProps: PropsWithChildren<CellProps<DashboardEquipment>>): ReactElement {
            // return <DashboardActions row={rowProps.row.original} handleAction={props.handleAction}></DashboardActions>
            return <ActionsCell actions={filteredActions} row={rowProps.row.original} handleAction={handleAction}></ActionsCell>
        } }
    ], [handleAction, handleGoToSymptoms, filteredActions])

    function getHiddenColumns(): string[] {
        const hiddenColumns: string[] = []
        if (props.loggedUser?.role !== 'ADMINISTRATOR') {
            hiddenColumns.push('customerName')
        }
        if (actions.length === 0) {
            hiddenColumns.push('actions')
        }
        return hiddenColumns
    }

    useFetching(fetchDashboardEquipments, 'dashboard', [], queryParams)

    useEffect(() => {
        dispatch(toggleSidemenu(true))
        setFilteredActions((): ActionType[] => {
            return actions.filter(action => {
                if (props.loggedUser?.acl) {
                    switch(action.type) {
                        case 'Timeline':
                            return props.loggedUser?.acl.includes('LOGEVENTS_LIST')
                        case 'SPC':
                            // Questo ha un permesso?
                            return true
                        case 'Graphs':
                            return props.loggedUser?.acl.includes('ANALYSIS_READ')
                        case 'Baseline':
                            return props.loggedUser?.acl.includes('BASELINES_LIST')
                        case 'Protocols':
                            return props.loggedUser?.acl.includes('PROTOCOL_INSTANCES_LIST')
                        case 'Properties':
                            // Questo ha un permesso?
                            return true
                        case 'Information':
                            // return props.loggedUser?.acl.includes('EQUIPMENT_INFO_READ')
                            return false
                        default:
                            return true
                    }
                } else {
                    return true
                }
            })
        })
    }, [dispatch, props.loggedUser, actions])

    useEffect(() => {
        return (): void => {
            dispatch(setTimelineFilterPage(0))
        }
    }, [dispatch])

    return (
        <div className="h-full w-full pt-4 px-8">
            <CustomTable
                data={props.data}
                columns={columns}
                hiddenColumns={getHiddenColumns()}
                totalData={props.size}
                currentPage={props.page ?? 0}
                currentPageSize={props.limit ?? 10}
                stripedRows={true}
                noDataMessage={intl.formatMessage({ id: 'dashboard.list.table.noDataText' })}
                handlePageChange={props.handlePageChange}
                handlePageSizeChange={props.handleLimitChange}
                handleSort={props.handleSort}
            ></CustomTable>
        </div>
    )
}

DashboardEquipments.propTypes = {
    handleAction: PropTypes.func.isRequired
}

const mapStateToProps = (state: State): { data: DashboardEquipment[]; size: number; page: number | null; limit: number | null; loggedUser: LoggedUser | null }  => ({
    data: state.dashboard.dashboardEquipments,
    size: state.dashboard.dashboardEquipmentsSize,
    page: state.ui.page,
    limit: state.ui.limit,
    loggedUser: state.login.user
})

export default connect(mapStateToProps)(DashboardEquipments)
