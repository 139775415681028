import React, { ReactElement, useEffect, OptionHTMLAttributes, useState, useCallback } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CustomSelectProperties } from '../../../types/component-properties/custom/custom-select-properties'
import Select, { OptionTypeBase, ValueType } from 'react-select'
import { CustomFormProperties } from '../../../types/component-properties/custom/custom-form-properties'
import SortOption from './sort-option'
import { intl } from '../../../intl'
import { deepCopy } from '../../../common/utils'

const classes = {
    'select': css`
        .select__control--is-disabled {
            border-color: var(--color-gray-100);

            svg {
                fill: var(--color-gray-100);
            }

            .select__placeholder {
                color: var(--color-gray-100);
                font-size: 0.9rem;
            }
        }

        .select__control--is-focused {
            border-color: var(--color-primary);
        }

        .select__value-container {
            flex-wrap: nowrap;
            height: 100%;
            padding: 0.25rem 0.5rem;
        }

        .select__control {
            border-radius: 0.25rem;
            min-height: 2.5rem;
            height: 100%;
            overflow: hidden;
            box-shadow: none;
            background: var(--color-white);
            color: var(--color-darkgray);
            cursor: pointer;

            &:hover {
                opacity: 0.8;
                border-color: var(--color-primary);
            }
        }
        
        .select__single-value {
            font-size: 0.9rem;
            color: var(--color-darkgray);
        }

        .select__single-value--is-disabled {
            font-size: 0.9rem;
            color:var(--color-gray-100);
        }

        .select__indicator {
            color: var(--color-darkgray);

            :hover {
                color: var(--color-darkgray);
            }
        }

        .select__placeholder {
            color: var(--color-darkgray);
            font-size: 0.9rem;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__dropdown-indicator {
            padding: 0 0.5rem;

            svg {
                width: 1.25rem;
                height: 1.25rem;
            }
        }

        .select__menu {
            box-shadow: 0 10px 40px -30px rgba(57,57,57,0.60), 0 0 40px 0 rgba(57,57,57,0.10) !important;
        }

        .select__indicators {
            height: 100%;
        }
    `,
    'default': css`
        .select__menu {
            margin: 0;
            border-radius: 0;
            z-index: 20;
            
            .select__menu-list {
                padding: 0;
                margin: 0;

                .select__option {
                    font-size: 0.9rem;
                    margin: 0;
                    cursor: pointer;

                    &:hover {
                        background: var(--color-primary) !important;
                        opacity: 0.5;
                        color: var(--color-white) !important;
                    }
                }

                .select__option--is-focused {
                    background: inherit !important;
                    color: inherit !important;
                }

                .select__option--is-selected {
                    background: var(--color-primary) !important;
                    color: var(--color-white) !important;
                }
            }
        }
    `,
    'event': css`
        .select__menu {
            margin: 0;
            min-width: 12.5rem;

            .select__menu-list {
                padding: 0.25rem 0.75rem;

                .select__option {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.9rem;
                    background: var(--color-lightblue);
                    color: var(--color-primary);
                    font-weight: 700;
                    margin: 0.5rem 0;
                    cursor: pointer;
                    text-align: center;
                    width: 100%;
                    min-height: 2rem;

                    :hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    `,
    'sort': css`
        .select__value-container {
            .dot {
                display: none;
            }
        }

        .select__menu {
            margin: 0;
            border-radius: 0;
            border: none;
            min-width: 13.75rem;

            .select__menu-list {
                padding: 0.5rem 0.25rem;
                
                .select__option {
                    padding: 0.25rem 0.5rem;
                    background: transparent;
                    font-size: 0.9rem;
                    color: var(--color-darkgray);
                    cursor: pointer;

                    .dot {
                        background: var(--color-background-first);
                    }
                }

                .select__option--is-selected {
                    .dot {
                        background: var(--color-primary);
                    }
                }
            }

            .select__group-heading {
                font-weight: 800;
                color: var(--color-darkgray);
                font-size: 0.70rem;
            }
        }
    `,
    'right': css`
        .select__menu {
            right: 0;
        }
    `,
    'hide-icon': css`
        .select__value-container {
            display: flex;
            justify-content: center;
        }

        .select__indicators {
            display: none;
        }
    `
}

const CustomSelect = (props: CustomSelectProperties & CustomFormProperties): ReactElement => {
    const [options, setOptions] = useState(props.options)

    const getValue = useCallback((value: string | string[]): OptionTypeBase | null => {
        let defaultValue = Array.isArray(value) ? value.map(singleValue => props.options.filter(option => option.value === singleValue)[0]) : props.options.filter(option => option.value === value)[0]
        if (props.translate && defaultValue) {
            defaultValue = deepCopy(defaultValue)

            if (Array.isArray(defaultValue)) {
                defaultValue.forEach(value => {
                    (value as OptionHTMLAttributes<HTMLOptionElement>).label = intl.formatMessage({ id: (value as OptionHTMLAttributes<HTMLOptionElement>).label })
                })
            } else {
                defaultValue.label = intl.formatMessage({ id: defaultValue.label })
            }
        }
        return defaultValue ? defaultValue : null
    }, [props.translate, props.options])

    const [selectValue, setSelectValue] = useState(getValue(props.defaultValue ? props.defaultValue as string | string[] : props.value))

    function onChange(option: ValueType<React.OptionHTMLAttributes<HTMLOptionElement>>): void {
        setSelectValue(getValue(props.isMulti ? ((option as OptionHTMLAttributes<HTMLOptionElement>[]) ?? []).map(values => values.value) as string[] : ((option as OptionHTMLAttributes<HTMLOptionElement>)?.value ?? "") as string | string[]))
        props.onChange(props.isMulti ? (option ?? []) : option)
    }

    useEffect(() => {
        setSelectValue(getValue(props.defaultValue ? props.defaultValue as string | string[] : props.value))
    }, [getValue, props.value, props.defaultValue])

    useEffect(() => {
        if (props.translate) {
            setOptions(props.options.map((option: OptionTypeBase) => {
                const newOption = deepCopy(option)
                newOption.label = intl.formatMessage({ id: newOption.label })
                return newOption
            }))
        } else {
            setOptions(props.options)
        }
    }, [props.translate, props.options])

    return (
        <React.Fragment>
            <div className={props.className}>
                <div className="mb-1 font-bold text-darkgray text-xs" hidden={!props.label || props.label === ''}>
                    {props.label} {props.required && props.label !== '' && <span className="text-red">*</span>}
                </div>
                <Select
                    css={[classes.select, classes[props.type], props.cssStyles, props.hideIcon ? classes["hide-icon"] : undefined, props.menuPosition === "right" ? classes.right : undefined]}
                    className="h-10"
                    classNamePrefix="select"
                    isClearable={props.clearable}
                    defaultValue={getValue(props.defaultValue as string | string[])}
                    isDisabled={props.disabled}
                    loading={props.loading}
                    hideSelectedOptions={false}
                    name={props.name}
                    options={options}
                    ref={props.selectRef}
                    rlt={props.rtl}
                    isHidden={true}
                    isMulti={props.isMulti}
                    menuPlacement={props.menuPlacement}
                    placeholder={props.placeholder}
                    searchable={props.searchable}
                    formatOptionLabel={props.type === 'sort' ? SortOption : undefined}
                    value={props.noValue ? null : selectValue}
                    onChange={onChange} />
                <div className="h-6 font-bold text-red text-xs" hidden={props.name === '' || props.hideErrors}>{props.errorMessage}</div>
            </div>
        </React.Fragment>
    )
}

CustomSelect.defaultProps = {
    className: '',
    cssStyles: [css``],
    disabled: false,
    loading: false,
    clearable: false,
    rtl: false,
    searchable: false,
    isMulti: false,
    hideIcon: false,
    placeholder: 'Select...',
    errorMessage: undefined,
    required: false,
    options: [],
    label: '',
    menuPlacement: 'bottom',
    menuPosition: 'left',
    value: '',
    defaultValue: undefined,
    selectDefaultValue: undefined,
    name: '',
    translate: false,
    type: 'default',
    hideErrors: false,
    noValue: false,
    formRef: (): void => undefined,
    selectRef: (): void => undefined,
    datePickerRef: (): void => undefined,
    onChange: (): void => undefined
}

export default CustomSelect
