import React, { useState, ReactElement, useEffect } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Route, Switch } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import Sidebar from './sidebar'
import Dashboard from './dashboard/dashboard'
import Breadcrumb from './breadcrumb'
import MedicalCentres from './medical-centres/medical-centres'
import { BreadcrumbPath } from '../types/entity/breadcrumb-path'
import Equipments from './equipments/equipments'
import Users from './users/users'
import MedicalCentresMenu from './medical-centres/medical-centres-menu'
import DashboardMenu from './dashboard/dashboard-menu'
import EquipmentsMenu from './equipments/equipments-menu'
import UsersMenu from './users/users-menu'
import Customers from './customers/customers'
import CustomersMenu from './customers/customers-menu'
import { State } from '../types/states/state'
import { SidebarOption } from '../types/sidebar-option'
import { SectionName } from '../types/section-name'
import { push } from 'connected-react-router'
import { fetchLogout } from '../reducers/login'
import { addPath, setPaths, setCurrentSection, resetPaths, splicePath, setQueryParams, setLimit, setPage, setFilter, setOrderType, setOrderBy, setCustomerId, fetchTags, updateLastPath, updatePage, fetchUserRoles, setUserRoles } from '../reducers/ui'
import { RequestQueryParams } from '../types/request-query-params'

import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg'
import { ReactComponent as ProtocolsIcon } from '../assets/icons/protocols.svg'
import { ReactComponent as CustomersIcon } from '../assets/icons/customers.svg'
import { ReactComponent as MedicalCentresIcon } from '../assets/icons/medical-centres.svg'
import { ReactComponent as EquipmentsIcon } from '../assets/icons/equipments.svg'
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg'
import { ReactComponent as InformationIcon } from '../assets/icons/information.svg'
import { ReactComponent as PreferencesIcon } from '../assets/icons/preferences.svg'
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg'
import { intl } from '../intl'
import ProtocolsMenu from './protocols/protocols-menu'
import Protocols from './protocols/protocols'
import { LocationDescriptorObject } from 'history'
import { fetchProtocol } from '../reducers/protocols'
import Preferences from './preferences/preferences'
import ReactTooltip from 'react-tooltip'
import { getBreadcrumbPaths, setBreadcrumbPaths, getUser } from '../common/api'
import Information from './information/information'
import Settings from './settings/settings'
import queryString from 'query-string'
import { setTimelineFilterPage } from '../reducers/equipment-detail'
import { fetchClientSettings, fetchSystemSettings, setClientSettings, setDefaultSettings } from '../reducers/settings'

const classes = {
    'container-with-menu': css`
        grid-template-rows: var(--breadcrumb-height) auto;
        grid-template-columns: var(--sidebar-width) var(--sidemenu-width) calc(100vw - var(--sidebar-width) - var(--sidemenu-width));
        grid-template-areas: 'sidebar sidemenu breadcrumb'
                             'sidebar sidemenu content';
        transition: all .3s ease;
        @media(min-width: 1001px) and (max-width: 1279px) {
            grid-template-columns: var(--sidebar-width) 18% calc(100vw - var(--sidebar-width) - 18%);
        }
        @media(max-width: 1024px) {
            grid-template-columns: var(--sidebar-width) var(--sidemenu-width) calc(100vw - var(--sidebar-width) - var(--sidemenu-width));
        }
    `,
    'container-without-menu': css`
        grid-template-rows: var(--breadcrumb-height) auto;
        grid-template-columns: var(--sidebar-width) calc(100vw - var(--sidebar-width));
        grid-template-areas: 'sidebar breadcrumb'
                             'sidebar content';
    `,
    'sidebar': css`
        grid-area: sidebar;
    `,
    'sidemenu': css`
        grid-area: sidemenu;
        z-index: 20;
        transition: max-width 0.3s;
    `,
    'breadcrumb': css`
        grid-area: breadcrumb;
        @media(max-width: 1024px) {
            grid-area: 1 / 2 / 2 / 4;
        }
    `,
    'content': css`
        grid-area: content;
        @media(max-width: 1024px) {
            grid-area: 2 / 2 / 3 / 4;
        }
    `
}

const Inner = (): ReactElement => {
    const dispatch = useDispatch()

    // UI
    const currentSection = useSelector((state: State) => state.ui.currentSection)
    const displaySidemenu = useSelector((state: State) => state.ui.displaySidemenu)
    const paths = useSelector((state: State) => state.ui.breadcrumbPaths)
    const customerId = useSelector((state: State) => state.ui.customerId)
    const limit = useSelector((state: State) => state.ui.limit)
    const page = useSelector((state: State) => state.ui.page)
    const filter = useSelector((state: State) => state.ui.filter)
    const orderBy = useSelector((state: State) => state.ui.orderBy)
    const orderType = useSelector((state: State) => state.ui.orderType)
    const queryParams = useSelector((state: State) => state.ui.sectionQueryParams)
    const location = useSelector((state: State) => state.router.location)
    const timelineFilter = useSelector((state: State) => state.equipmentDetail.timelineFilter)
    const clientId = useSelector((state: State) => state.settings.clientId)
    const settings = useSelector((state: State) => state.settings.clientSettings)
    const systemSettings = useSelector((state: State) => state.settings.defaultSettings)

    const urlQueryParams = queryString.parse(location.search ?? '')

    // TIMELINE
    // const timelineNewEventType = useSelector((state: State) => state.equipmentDetail.timelineNewEventType)

    const [options, setOptions] = useState<SidebarOption[]>([
        { routePath: '/dashboard', section: 'dashboard', title: 'sidebar.dashboard', Icon: DashboardIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/organisations', section: 'organisations', title: 'sidebar.organisations', Icon: CustomersIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/medical-centres', section: 'medical-centres', title: 'sidebar.medicalCentres', Icon: MedicalCentresIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/equipments', section: 'equipments', title: 'sidebar.equipments', Icon: EquipmentsIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/users', section: 'users', title: 'sidebar.users', Icon: UsersIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/protocols', section: 'protocols', title: 'sidebar.protocolsVault', Icon: ProtocolsIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/preferences', section: 'preferences', title: 'sidebar.preferences', Icon: PreferencesIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/settings', section: 'settings', title: 'sidebar.settings', Icon: SettingsIcon, width: '1.675rem', height: '1.675rem' },
        { routePath: '/information', section: 'information', title: 'sidebar.information', Icon: InformationIcon, width: '1.675rem', height: '1.675rem' }
    ])

    function callLogout(): void {
        dispatch(fetchLogout())
    }

    function handleSort(newOrderBy: string | null, newOrderType: string | null): void {
        if (orderBy !== newOrderBy) {
            dispatch(setOrderBy(newOrderBy))
        }

        if (orderType !== newOrderType) {
            dispatch(setOrderType(newOrderType))
        }
    }

    function handleFilterDashboard(paths: BreadcrumbPath[]): void {
        dispatch(setPaths(paths))
        dispatch(push(paths[paths.length - 1].destination))
    }

    function handleFilter(path: BreadcrumbPath): void {
        const loggedUser = getUser()

        switch (currentSection) {
            case 'dashboard': case 'equipments':
                if (paths.length === 0 && loggedUser?.role === 'ADMINISTRATOR') {
                    dispatch(addPath(path))
                } else if (loggedUser?.role === 'ADMINISTRATOR') {
                    dispatch(paths[0].id && paths[0].id !== '' ? setPaths([paths[0], path]) : setPaths([path]))
                } else {
                    dispatch(setPaths([path]))
                }

                break
            case 'protocols':
                if (loggedUser?.role === 'ADMINISTRATOR') {
                    if (paths.length === 0) {
                        dispatch(setPaths([path]))
                    } else {
                        // dispatch(fetchProtocol(path.id))
                        dispatch(setPaths([paths[0], path]))
                        dispatch(setPage(0))
                    }
                } else {
                    dispatch(fetchProtocol(path.id))
                    dispatch(setPaths([path]))
                    dispatch(setPage(0))
                }

                break
            case 'organisations': case 'medical-centres': case 'users':
                dispatch(setPaths([path]))

                break
        }

        dispatch(push(path.destination))
    }

    function handleCreateItem(): void {
        const destination = {
            pathname: `/${currentSection}/new`
        }
        let path: BreadcrumbPath

        switch (currentSection) {
            case 'protocols':
                path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newProtocol' }), id: "", destination: destination, param: null }

                break
            case 'organisations':
                path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newOrganisation' }), id: "", destination: destination, param: null }

                break
            case 'medical-centres':
                path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newMedicalCentre' }), id: "", destination: destination, param: null }

                break
            case 'equipments':
                path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newEquipment' }), id: "", destination: destination, param: null }

                break
            case 'users':
                path = { label: intl.formatMessage({ id: 'breadcrumbPathText.newUser' }), id: "", destination: destination, param: null }

                break
            default:
                path = { label: intl.formatMessage({ id: '' }), id: "", destination: { pathname: '' }, param: null }

                break
        }

        dispatch(setPaths([path]))
        dispatch(push(destination))
    }

    function timelineNavigate(path: BreadcrumbPath, add = true): void {
        if (add) {
            dispatch(addPath(path))
        } else {
            dispatch(updateLastPath(path))
        }
        
        dispatch(push(path.destination as LocationDescriptorObject))
    }

    function sidebarNavigate(option: SidebarOption): void {
        const loggedUser = getUser()
        dispatch(setCurrentSection(option.section))
        dispatch(resetPaths())
        dispatch(setLimit(10))
        dispatch(setPage(0))
        dispatch(setFilter(null))
        dispatch(setOrderBy(null))
        dispatch(setOrderType(null))
        if (loggedUser?.role !== 'ADMINISTRATOR' && loggedUser?.customerId) {
            switch(option.section) {
                case 'medical-centres': case 'protocols':
                    dispatch(setCustomerId(loggedUser.customerId))
            }
        }
        dispatch(push({
            pathname: `/${option.section}`
        }))
    }

    function breadcrumbNavigate(destination: LocationDescriptorObject, index: number, canNavigate: boolean): void {
        if (canNavigate) {
            dispatch(splicePath(index + 1))
            dispatch(push(destination))
        }
    }

    function back(index: number): void {
        if ((index - 1) >= 0) {
            const destination = paths[index - 1].destination
            dispatch(splicePath(index))
            dispatch(push(destination))
        } else {
            dispatch(resetPaths())
            dispatch(push({
                pathname: `/${currentSection}`
            }))
        }
    }

    function handleInputChange(value: string | null): void {
        dispatch(setFilter(value))
    }

    function handleLimitChange(limit: number): void {
        dispatch(setLimit(limit))
    }

    function handlePageChange(page: number): void {
        dispatch(setPage(page - 1))
    }

    useEffect(() => {
        if (urlQueryParams.page) {
            dispatch(setTimelineFilterPage(parseInt(urlQueryParams.page as string, 10)))
        }
    }, [dispatch, urlQueryParams.page])

    useEffect(() => {
        dispatch(updatePage(timelineFilter.page))
    }, [dispatch, timelineFilter.page])

    useEffect(() => {
        const section = (localStorage.getItem('currentSection') ?? 'none') as SectionName
        const paths: BreadcrumbPath[] | null = getBreadcrumbPaths()
        const loggedUser = getUser()

        if (section !== 'none' && paths) {
            dispatch(setCurrentSection(section))
            dispatch(setPaths(paths))
            
            if (paths.length) {
                dispatch(push(paths[paths.length - 1].destination))
            } else {
                dispatch(push(`/${section}`))
            }
        }

        if (loggedUser?.role !== 'ADMINISTRATOR' && loggedUser?.customerId) {
            dispatch(setCustomerId(loggedUser.customerId))
        }
    }, [dispatch])

    useEffect(() => {
        const newParams: RequestQueryParams = {}

        paths.forEach((path) => {
            if (path.param) {
                newParams[path.param.property] = path.param.value
            }
        })

        // localStorage.setItem('paths', JSON.stringify(paths))
        setBreadcrumbPaths(paths)

        if (customerId !== null) {
            newParams.customerId = customerId
        }

        if (limit !== null) {
            newParams.limit = limit.toString()
        }

        if (page !== null) {
            newParams.page = page.toString()
        }

        if (filter !== null) {
            newParams.filter = filter
            if (!queryParams.filter) {
                dispatch(setPage(0))
            }
        }

        if (orderBy !== null) {
            newParams.orderBy = orderBy
        }

        if (orderType !== null) {
            newParams.orderType = orderType
        }

        switch(currentSection) {
            case 'dashboard':
                const severitiesToDisplay = settings?.find((setting) => setting.nameId === "severitiesToDisplay")
                if (severitiesToDisplay) {
                    newParams.severitiesCount = severitiesToDisplay.value
                }

                break
            case 'equipments':
                newParams.showArchived = queryParams.hasOwnProperty('showArchived') ? queryParams.showArchived : 'true'

                break
        }

        if (JSON.stringify(newParams) !== JSON.stringify(queryParams)) {
            dispatch(setQueryParams(newParams))
        }
    }, [dispatch, paths, customerId, limit, page, filter, orderBy, orderType, queryParams, currentSection, settings])

    useEffect(() => {
        const loggedUser = getUser()
        if (loggedUser && loggedUser.acl) {
            setOptions((oldValue): SidebarOption[] => {
                return oldValue.filter((option) => {
                    switch (option.section) {
                        case 'dashboard':
                            return true
                        case 'protocols':
                            return loggedUser.acl.includes('PROTOCOL_MODELS_LIST')
                        case 'organisations':
                            return loggedUser.acl.includes('CUSTOMERS_LIST')
                        case 'medical-centres':
                            return loggedUser.acl.includes('MEDICAL_CENTRES_LIST')
                        case 'equipments':
                            return loggedUser.acl.includes('EQUIPMENTS_LIST')
                        case 'users':
                            return loggedUser.acl.includes('USERS_LIST')
                        case 'information': case'preferences':
                            return true
                        case 'settings':
                            return loggedUser.acl.includes('SETTINGS_READ')
                        default:
                            return false
                    }
                })
            })
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('currentSection', currentSection)
    }, [currentSection])

    useEffect(() => {
        dispatch(fetchTags())
    }, [dispatch])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    useEffect(() => {
        dispatch(fetchUserRoles())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchSystemSettings())
        dispatch(fetchClientSettings(clientId))

        return (): void => {
            dispatch(setDefaultSettings([]))
            dispatch(setClientSettings([]))
            dispatch(setUserRoles([]))
        }
    }, [dispatch, clientId])

    return (
        <React.Fragment>
            {
                settings && systemSettings &&
                <div css={[displaySidemenu ? classes["container-with-menu"] : classes["container-without-menu"]]} className="grid h-screen w-full overflow-x-hidden inner-container">
                    <Sidebar
                        cssStyles={[classes.sidebar]}
                        options={options}
                        handleNavigate={sidebarNavigate}
                        handleLogout={callLogout}></Sidebar>

                    <Breadcrumb
                        cssStyles={[classes.breadcrumb]}
                        className="flex w-full"
                        paths={paths}
                        currentSection={currentSection}
                        handleNavigate={breadcrumbNavigate}></Breadcrumb>

                    {
                        displaySidemenu &&
                        <div css={[classes.sidemenu]} className="lg:hidden bg-background-first sidemenu-container">
                            <Switch>
                                <Route path='/dashboard'>
                                    <DashboardMenu
                                        handleFilter={handleFilter}
                                        handleNavigate={back}
                                        handleCreateItem={handleCreateItem}
                                        handleInputChange={handleInputChange}></DashboardMenu>
                                </Route>
                                <Route path='/protocols'>
                                    <ProtocolsMenu
                                        data={[]}
                                        handleNavigate={back}
                                        handleFilter={handleFilter}
                                        handleCreateItem={handleCreateItem}
                                        handleInputChange={handleInputChange}></ProtocolsMenu>
                                </Route>
                                <Route path='/organisations'>
                                    <CustomersMenu
                                        sidemenuData={[]}
                                        handleFilter={handleFilter}
                                        handleNavigate={back}
                                        handleCreateItem={handleCreateItem}
                                        handleInputChange={handleInputChange}></CustomersMenu>
                                </Route>
                                <Route path='/medical-centres'>
                                    <MedicalCentresMenu
                                        handleFilter={handleFilter}
                                        handleNavigate={back}
                                        handleCreateItem={handleCreateItem}
                                        handleInputChange={handleInputChange}></MedicalCentresMenu>
                                </Route>
                                <Route path='/equipments'>
                                    <EquipmentsMenu
                                        handleFilter={handleFilter}
                                        handleNavigate={back}
                                        handleCreateItem={handleCreateItem}
                                        handleInputChange={handleInputChange}></EquipmentsMenu>
                                </Route>
                                <Route path='/users'>
                                    <UsersMenu
                                        handleFilter={handleFilter}
                                        handleNavigate={back}
                                        handleCreateItem={handleCreateItem}
                                        handleInputChange={handleInputChange}></UsersMenu>
                                </Route>
                            </Switch>
                        </div>
                    }
                    <div css={[classes.content]} className="flex flex-col">
                        <Switch>
                            <Route path='/dashboard'>
                                <Dashboard
                                    handleNavigate={back}
                                    handleFilterDashboard={handleFilterDashboard}
                                    handleLimitChange={handleLimitChange}
                                    handlePageChange={handlePageChange}
                                    handleFilter={handleFilter}
                                    handleSort={handleSort}
                                    timelineNavigate={timelineNavigate}></Dashboard>
                            </Route>
                            <Route path='/protocols'>
                                <Protocols
                                    handleNavigate={back}
                                    handleLimitChange={(): void => undefined}
                                    handlePageChange={(): void => undefined}
                                    handleFilter={handleFilter}
                                    handleSort={handleSort}></Protocols>
                            </Route>
                            <Route path='/organisations'>
                                <Customers
                                    handleNavigate={back}
                                    handleFilter={handleFilter}
                                    handleSort={(): void => undefined}></Customers>
                            </Route>
                            <Route path='/medical-centres'>
                                <MedicalCentres
                                    handleNavigate={back}
                                    handleLimitChange={handleLimitChange}
                                    handlePageChange={handlePageChange}
                                    handleFilter={handleFilter}
                                    handleSort={handleSort}></MedicalCentres>
                            </Route>
                            <Route path='/equipments'>
                                <Equipments
                                    handleNavigate={back}
                                    handleFilterDashboard={handleFilterDashboard}
                                    handleLimitChange={handleLimitChange}
                                    handlePageChange={handlePageChange}
                                    handleFilter={handleFilter}
                                    handleSort={handleSort}></Equipments>
                            </Route>
                            <Route path='/users'>
                                <Users
                                    handleNavigate={back}
                                    handleLimitChange={handleLimitChange}
                                    handlePageChange={handlePageChange}
                                    handleFilter={handleFilter}
                                    handleSort={handleSort}></Users>
                            </Route>
                            <Route path='/information'>
                                <Information></Information>
                            </Route>
                            <Route path='/settings'>
                                <Settings></Settings>
                            </Route>
                            <Route path='/preferences'>
                                <Preferences
                                    handleNavigate={back}></Preferences>
                            </Route>
                        </Switch>
                    </div>
                </div>
            }
            <ReactTooltip id="sidemenu-tooltip" type="dark" effect="solid" place="right" />
        </React.Fragment>
    )
}

export default Inner

// 24: "EQUIPMENTS_UPDATE_LICENSES"
// 31: "LOGEVENTS_CREATE"
// 34: "LOGEVENTS_UPDATE"
// 35: "LOGEVENTS_HIDE"
// 36: "LOGEVENTS_DELETE"
// 38: "ANALYSIS_UPDATE"
// 39: "BASELINES_CREATE"
// 42: "BASELINES_UPDATE"
// 43: "BASELINES_DEFINE"
// 44: "BASELINES_ACTIVATE"
// 45: "BASELINES_DELETE"
// 46: "EQUIPMENT_INFO_CREATE"
// 48: "EQUIPMENT_INFO_UPDATE"