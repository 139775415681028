import React, { ReactElement, useEffect, useState } from 'react'
import CustomChart from '../../custom/custom-chart/custom-chart'
import DashboardTimelineEventsList from './dashboard-timeline-events-list'
import { DashboardTimelineProperties } from '../../../types/component-properties/dashboard-timeline-properties'
import { ChartLine } from '../../../types/chart-line'
import { connect } from 'react-redux'
import { State } from '../../../types/states/state'
import { TimelineFilter } from '../../../types/timeline-filter'
import { ChartArea } from '../../../types/chart-area'
import { ChartLegend } from '../../../types/chart-legend'
import { CustomChartLine } from '../../../types/custom-chart-line'
import { CustomChartArea } from '../../../types/custom-chart-area'
import DashboardTimelineEventEditor from './dashboard-timeline-event-editor'
import { TimelineEventType } from '../../../types/timeline-event-type'
import { TimelineEvent } from '../../../types/entity/timeline-event'
import classNames from 'classnames'
import { Equipment } from '../../../types/entity/equipment'
import { AnalysisChart } from '../../../types/entity/analysis-chart'
import CustomHistogramChart from '../../custom/custom-chart/custom-histogram-chart'
import CustomDispersionChart from '../../custom/custom-chart/custom-dispersion-chart'

const DashboardTimeline = (props: DashboardTimelineProperties): ReactElement => {
    const [selectedType, setSelectedType] = useState<TimelineEventType | undefined>(undefined)

    useEffect(() => {
        setSelectedType(undefined)
    }, [props.timelineEvents])
    
    return (
        <React.Fragment>
            <div className="h-full">
                <DashboardTimelineEventEditor
                    visible={!!selectedType}
                    type={selectedType as TimelineEventType}
                    handleCancel={(): void => setSelectedType(undefined)}
                    handleCreateTimelineEvent={props.handleCreateTimelineEvent} />
                <div className={classNames({
                    'flex mt-6 h-full px-8': true,
                    'flex': !selectedType,
                    'hidden': !!selectedType
                })}>
                    <div className="flex-grow min-w-185">
                        <DashboardTimelineEventsList
                            timelineNavigate={props.timelineNavigate}
                            setSelectedType={setSelectedType}
                            handleTimelineFilter={props.handleTimelineFilter}
                            handleToggleTImelineEventVisibility={props.handleToggleTImelineEventVisibility}
                            handleDeleteTimelineEvent={props.handleDeleteTimelineEvent}></DashboardTimelineEventsList>
                    </div>

                    <div className="flex flex-col flex-grow ml-12 mt-4 min-w-90 max-w-135">
                        {
                            props.timelineChart.length > 0 &&
                            props.timelineChart.map((chart, index) => {
                                const lines: CustomChartLine[] = []
                                const areas: CustomChartArea[] = []
                                const legend: ChartLegend[] = []
                                chart.dataSet.groups.forEach((chartData, index) => {
                                    const legendLines: string[] = []
                                    const legendAreas: string[] = []
                                    chartData.values.forEach((value, stylesIndex) => {
                                        const chartLines: ChartLine[] = []
                                        value.data.forEach(line => {
                                            chartLines.push({
                                                x: chart.type === "FREQUENCY" || chart.type === "CORRELATION" ? line.domain ?? "0" : line.timestamp,
                                                y: !line.value || line.value === "null" ? null : parseFloat(line.value),
                                                id: line.id
                                            })
                                        })
                                        lines.push({
                                            chart: chartLines,
                                            colorIndex: index,
                                            stylesIndex
                                        })
                                        legendLines.push(value.name)
                                    })
                                    chartData.ranges.forEach((range, stylesIndex) => {
                                        const area: ChartArea[] = []
                                        range.data.forEach(point => {
                                            area.push({
                                                x: point.timestamp,
                                                y: point.upper === 'null' ? null : point.upper,
                                                y0: point.lower === 'null' ? null : point.lower
                                            })
                                        })
                                        areas.push({
                                            area,
                                            colorIndex: index,
                                            stylesIndex
                                        })
                                        legendAreas.push(range.name)
                                    })
                                    legend.push({
                                        name: chartData.name,
                                        lines: legendLines,
                                        areas: legendAreas
                                    })
                                })

                                const histogramTickValues: number[] = []

                                if (chart.type === "FREQUENCY") {
                                    chart.dataSet.groups.forEach((group) => {
                                        group.values.forEach((value) => {
                                            value.data.forEach((tick) => {
                                                histogramTickValues.push(parseFloat(tick.domain ?? "0"))
                                            })
                                        })
                                    })
                                }

                                return (
                                    <React.Fragment key={index}>
                                        {
                                            chart.type === "FREQUENCY" &&
                                            <CustomHistogramChart
                                                xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? histogramTickValues.sort() : []}
                                                // xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => parseFloat(tick.value)).sort() : []}
                                                // xTickTimestamp={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                lines={lines}
                                                areas={areas}
                                                xAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 100 },
                                                        grid: { stroke: 'none' }
                                                    }
                                                }}
                                                yAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                    }
                                                }}
                                                scale={{
                                                    x: "linear",
                                                    y: "linear"
                                                }}
                                                title={chart.name}
                                                legend={legend}
                                                hideXAxis={false} />
                                        }
                                        {
                                            chart.type === "CORRELATION" &&
                                            <CustomDispersionChart
                                                xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                // xTickTimestamp={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                lines={lines}
                                                areas={areas}
                                                xAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 0, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'none' }
                                                    }
                                                }}
                                                yAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 13, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                    }
                                                }}
                                                scale={{
                                                    x: "linear",
                                                    y: "linear"
                                                }}
                                                title={chart.name}
                                                legend={legend}
                                                hideXAxis={true}
                                                showMonth={true}
                                                monthSeparators={true} />
                                        }
                                        {
                                            chart.type !== "FREQUENCY" && chart.type !== "CORRELATION" &&
                                            <CustomChart
                                                xTickValues={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                xTickTimestamp={chart.dataSet.groups.length > 0 && chart.dataSet.groups[0].values.length > 0 ? chart.dataSet.groups[0].values[0].data.map(tick => tick.timestamp) : []}
                                                lines={lines}
                                                areas={areas}
                                                xAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'none' }
                                                    }
                                                }}
                                                yAxisProps={{
                                                    style: {
                                                        tickLabels: { fontSize: 10, fill: 'var(--color-black)' },
                                                        axis: { stroke: 'none' },
                                                        axisLabel: { fontSize: 20, padding: 30 },
                                                        grid: { stroke: 'var(--color-gray-100)' }
                                                    }
                                                }}
                                                legend={legend}
                                                hideXAxis={true}
                                                showMonth={true}
                                                monthSeparators={true} />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: State): { equipment: Equipment | null; timelineChart: AnalysisChart[]; timelineFilter: TimelineFilter; timelineEvents: TimelineEvent[]; search: string }  => ({
    equipment: state.dashboard.currentEquipment,
    timelineChart: state.equipmentDetail.timelineCharts,
    timelineFilter: state.equipmentDetail.timelineFilter,
    timelineEvents: state.equipmentDetail.timelineEvents,
    search: state.router.location.search
})

export default connect(mapStateToProps)(DashboardTimeline)
